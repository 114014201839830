import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'

const contactProfileTabKey = [
  'edit',
  'singleRegistration',
  'multiRegistration',
  'paidRegistration',
  'programme',
  'pdfBadge',
  'checkins',
  'certificate',
  'networking',
  'surveys',
  'segments',
  'messages',
  'history',
  'debug',
  'accessPass'
] as const

export type ContactProfileTabKey = typeof contactProfileTabKey[number]

export function isContactProfileTabKey(key: unknown): key is ContactProfileTabKey {
  if (typeof key !== 'string') {
    return false
  }
  return (contactProfileTabKey as unknown as string[]).includes(key)
}

export interface ContactProfileTabItem extends DigiVerticalMenuItem {
  key: ContactProfileTabKey
}

export type ContactProfileQueryParams = {
  contactProfileGuestId: string
  contactProfileActiveTab?: ContactProfileTabKey
}

export const contactProfileQueryParamKeys: (keyof ContactProfileQueryParams)[] = [
  'contactProfileGuestId',
  'contactProfileActiveTab'
]
