import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import AssistanceElementBase from '../AssistanceElementBase'
import { PrefabPropertiesBase } from '../../PrefabElementBase'

export class FaqProperties extends PrefabPropertiesBase {
  @Type(() => FaqItem)
  items: FaqItem[] = []
}

export class FaqItem {
  question: string = ''
  answer: string = ''
}

export class Faq1Properties extends FaqProperties {
  title: string = ''
  questionBackgroundColor: string | null = null
  questionColor: string = ''
  outerTextColor: string = ''
}

export default class Faq1Element extends AssistanceElementBase {
  static create({ palette }: ElementCreationContext): Faq1Element {
    const faq = new Faq1Element()

    faq.properties.items.push(new FaqItem())
    faq.properties.items.push(new FaqItem())
    faq.properties.items.push(new FaqItem())

    faq.properties.title = i18n.t('QUESTIONS_ANSWERS')

    const questionBgColor = palette.mostVividColor
    faq.properties.questionBackgroundColor = questionBgColor.paletteCode

    faq.properties.questionColor = palette.getMostReadableColor(questionBgColor.color).paletteCode

    return faq
  }

  type = 'prefab-faq-1' as const

  @Type(() => Faq1Properties)
  properties: Faq1Properties = new Faq1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_FAQ')
  }
}
