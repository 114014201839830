import type { ElementCreationContext } from '../../../types/ElementTypeDictionary'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'

import store from '@/store'
import { LoremIpsum } from 'lorem-ipsum'
import { CountdownElementBase, CountdownProperties } from '../CountdownElementBase'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class Countdown1Properties extends CountdownProperties {
  title: string = ''
  subtitle: string = ''
  countdownBackgroundColor: string = ''
  outerTextColor: string = ''
  cta: CtaProperties = defaultCtaProperties()
}

export default class Countdown1Element extends CountdownElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): Countdown1Element {
    const event = store.state.event.event
    if (!event) {
      throw new Error('Cannot create countdown element: Element requires event in store, none found.')
    }

    const countdown = new Countdown1Element()

    const countdownBackground = palette.mostVividColor

    countdown.properties = {
      ...countdown.properties,
      title: i18n.t('AN_INSPIRING_TITLE'),
      subtitle: new LoremIpsum({
        wordsPerSentence: { min: 5, max: 15 },
        sentencesPerParagraph: { min: 1, max: 3 }
      }).generateParagraphs(2),
      cta: {
        ...countdown.properties.cta,
        label: i18n.t('REGISTRATION_CTA_BTN_LABEL'),
        color: palette.darkestColor.paletteCode
      },
      countdownBackgroundColor: countdownBackground.paletteCode,
      countdownTextColor: palette.getMostReadableColor(countdownBackground.color).paletteCode,
      outerTextColor: mostReadableColor,
      targetDate: event.eventDate
    }

    return countdown
  }

  type = 'prefab-countdown-1' as const

  @Type(() => Countdown1Properties)
  properties: Countdown1Properties = new Countdown1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_COUNTDOWN')
  }
}
