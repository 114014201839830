











import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { ContactSurveys } from '@/features/surveys'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'

@Component({
  components: { ContactProfileActionsSubMenu, ContactSurveys }
})
export default class ContactProfileSurveysSubMenu extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
