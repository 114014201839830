import type { Operation } from 'fast-json-patch'
import jsonpatch from 'fast-json-patch'
import type { EventId } from '@/models/Event'
import type { Event } from '@/models/Event'
import axios from 'axios'
import store from '@/store'
import type { SupportRepresentative } from '@/models/Account'
import type { EventIdentity, EventPresenceType, EventTypology } from '@/core'

export interface PaginateEventsParams {
  page: number
  itemsPerPage: number
}

export interface SortEventsParams {
  sortBy: string
  sortDirection: 'asc' | 'desc'
}

export interface SearchEventsParams {
  name?: string
  typology?: EventTypology
  isOver?: boolean
  isDemo?: boolean
}

export type GetEventsParams = PaginateEventsParams & SortEventsParams & SearchEventsParams

export interface EventListDto {
  eventIdentities: EventIdentity[]
  totalCount: number
}

export async function getEventIdentities(params: GetEventsParams): Promise<EventListDto> {
  return (await axios.get<EventListDto>('backoffice/events/identities', { params })).data
}

export async function getEventById(eventId: EventId, noHTTPCache: boolean = false): Promise<Event> {
  return (await axios.get(`backoffice/events/${eventId}`, { noCache: noHTTPCache })).data
}

export async function getEventSupportRepresentative(eventId: EventId): Promise<SupportRepresentative> {
  return (await axios.get(`/events/${eventId}/supportRepresentative`)).data
}

export async function countEventContacts(eventId: EventId): Promise<number> {
  return (await axios.get<number>(`backoffice/events/${eventId}/countContacts`)).data
}

// Equivalent to type CreateEventData in digi-api.
export interface EventStubForCreation {
  label: string
  logoUrl: string
  presenceType: EventPresenceType
  typology: EventTypology
  date: Date
  endDate: Date
  isPaymentEnabled: boolean
  registrationType: 'single' | 'group'
}

// Equivalent to type DuplicateEventData in digi-api.
type EventStubForDuplication = Pick<EventStubForCreation, 'label' | 'date' | 'endDate'> & { originalId: string }

export enum EventCreateMethod {
  Coupon = 'coupon',
  Duplicate = 'duplicate',
  Demo = 'demoEvent'
}

export async function createEvent(
  data:
    | { creationMethod: EventCreateMethod.Demo; eventData: EventStubForCreation }
    | { creationMethod: EventCreateMethod.Duplicate; eventData: EventStubForDuplication }
): Promise<Event> {
  return (await axios.post('backoffice/events', { ...data })).data
}

export async function activateEventByCouponCode(id: EventId, couponCode: Event['couponCode']): Promise<void> {
  await axios.put<Event>(`backoffice/events/${id}/activate`, {
    couponCode
  })
}

export async function deleteEvent(id: EventId): Promise<void> {
  await axios.delete(`backoffice/events/${id}`)
}

export type SaveEventPatchesResult =
  | {
      patches: []
      newEvent?: never
    }
  | {
      patches: Operation[]
      newEvent: Record<string, any>
    }

export async function saveEventPatches(eventId: EventId, patches: Operation[]): Promise<SaveEventPatchesResult> {
  if (!patches || patches.length === 0) {
    return {
      patches: []
    }
  }

  const res = await axios.put<{ updatedEvent: Object; patches: Operation[] }>(
    `backoffice/events/${eventId}/applyEventPatches`,
    { patches }
  )
  return {
    newEvent: res.data.updatedEvent,
    patches: res.data.patches || []
  }
}

export async function saveEvent(
  originalEvent: Record<string, any>,
  changedEvent: Record<string, any>
): Promise<SaveEventPatchesResult> {
  const patches = jsonpatch.compare(originalEvent, changedEvent)

  let res = await saveEventPatches(originalEvent._id, patches)
  if (res.patches.length === 0 && 'newEvent' in res === false) {
    res = {
      ...res,
      newEvent: originalEvent
    }
  }
  return res
}

export function getStoreEvent(): Event {
  const event: Event | undefined = store.state.event.event
  if (!event) throw new Error('Could not find event in store.')
  return event
}
