







































































































import FilterModal from '@/features/audience/segments/components/SegmentsModal/index.vue'
import { Component, Prop, VModel, Ref } from 'vue-property-decorator'
import type { Event } from '@/models/Event'
import type { FilterId } from '@/models/Event/Filter'
import type Filter from '@/models/Event/Filter'
import { assertEvent } from '@/services/storeEvent'
import Vue from 'vue'

import SegmentSelectorItem from './SegmentSelectorItem.vue'
import type { BFormInput } from 'bootstrap-vue'
import { isNamedSegment } from '@/features/audience/segments/types'
import { createSegment } from '@/features/audience/segments/service'
import SingleSegmentBadge from './SingleSegmentBadge.vue'
import SegmentSelectorBadges from './SegmentSelectorBadges.vue'
import SegmentSelectorEditButtons from './SegmentSelectorEditButtons.vue'
import type { MixedSort } from '@/models/commons/sort'
import { filterMixedSort, getMixedSortWithoutEmptyGroups, isSortGroup } from '@/utils/sort'
import type { BoostrapColorVariant } from '@/@types/bootstrap'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import type { SaveEventPatchesResult } from '@/services/events'

@Component({
  components: {
    SegmentSelectorEditButtons,
    SegmentSelectorBadges,
    SingleSegmentBadge,
    FilterModal,
    SegmentSelectorItem
  },
  filters: {
    andList(value: string[]): string {
      if (!value) return ''
      else return value.join(' & ')
    }
  }
})
export default class SegmentSelector extends Vue {
  @VModel({ required: true, default: () => [] }) selectedSegments!: FilterId[]
  @Prop() readonly nbGuests?: string | number
  @Prop({ validator: (value) => ['sm', 'md', 'lg'].includes(value) }) readonly size?: 'sm' | 'md' | 'lg'
  @Prop({ default: 'default' }) readonly variant?: BoostrapColorVariant
  @Prop() readonly canEdit!: boolean
  @Prop() readonly monoSelection!: boolean
  @Prop() readonly hideAllGuestOption!: boolean
  @Prop() readonly noCaret!: boolean
  @Prop() readonly maxFilter!: number
  @Prop({ default: false }) readonly showBadges!: boolean
  @Prop({ default: null }) readonly state!: boolean | null

  @Ref() searchInput!: BFormInput
  @Ref() readonly segmentsModal!: FilterModal

  segmentSearch: string = ''

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get showAllContactsOption(): boolean {
    return !this.hideAllGuestOption && this.$t('ALL_GUESTS').includes(this.segmentSearch)
  }

  get eventSegments(): Filter[] {
    return this.storeEvent.filters
  }

  get selectedSegmentNames(): string[] {
    return this.selectedSegments.map((filterId) => {
      const filter = this.segmentsMap[filterId]
      return filter ? filter.name : ''
    })
  }

  get filteredSegmentsSort(): MixedSort {
    if (this.segmentSearch === '') {
      return this.segmentsSortWithoutEmptyGroups
    }

    return filterMixedSort(this.segmentsSortWithoutEmptyGroups, (filterId) => {
      const filter = this.segmentsMap[filterId]
      if (!filter) return false
      return filter.name.toLowerCase().includes(this.segmentSearch.toLowerCase())
    })
  }

  get segmentsMap(): Record<string, Filter> {
    return this.eventSegments.reduce((acc: Record<string, Filter>, filter) => {
      acc[filter._id] = filter
      return acc
    }, {})
  }

  get segmentsSortWithoutEmptyGroups(): MixedSort {
    return getMixedSortWithoutEmptyGroups(this.storeEvent.filtersSort)
  }

  get isNbGuestSet(): boolean {
    // Can be 0 & can be either string or number, for a lack of a better check :
    return this.nbGuests !== undefined && this.nbGuests !== null
  }

  clearSegments(): void {
    if (this.selectedSegments.length > 0) this.selectedSegments = []
  }

  isCurrentSegment(filterId: string): boolean {
    if (this.selectedSegments.length === 0) {
      // Don't bother with IDs if either filter is null and not the other
      return false
    } else {
      // Same ID?
      return this.selectedSegments.includes(filterId)
    }
  }

  sanitizeSegmentsIds(ids: FilterId[]): FilterId[] {
    return ids.filter((id) => {
      return this.eventSegments.some((filter) => filter._id === id)
    })
  }

  toggleSegment(filterId: string): void {
    if (this.monoSelection) {
      if (this.selectedSegments[0] === filterId) {
        this.selectedSegments = []
      } else {
        this.selectedSegments = [filterId]
      }
    } else {
      const exists = this.selectedSegments.includes(filterId)

      if (exists) {
        let sanitized = this.sanitizeSegmentsIds(this.selectedSegments)
        this.selectedSegments = sanitized.filter((id) => id !== filterId)
      } else {
        if (this.maxFilter && this.maxFilter < this.selectedSegments.length + 1) {
          return
        }
        let sanitized = this.sanitizeSegmentsIds(this.selectedSegments)
        this.selectedSegments = [...sanitized, filterId]
      }
    }
  }

  openEditionModal(segment: Filter): void {
    this.segmentsModal.openEditionModal(segment)
  }

  async editSegment(newSegment: Filter): Promise<void> {
    let savedFilter: Filter

    let index: number
    const result: SaveEventPatchesResult = await this.$store.dispatch('postEventMutation', (event: Event) => {
      index = event.filters.findIndex((filter) => filter._id === newSegment._id)
      event.filters.splice(index, 1, newSegment)
      return event
    })
    if (result.newEvent) {
      savedFilter = result.newEvent.filters[index!]
    } else {
      throw new Error('Unexpected: Posting filter edit did not yield any patch.')
    }

    if (this.monoSelection) {
      this.selectedSegments = [savedFilter._id]
    }
  }

  openCreationModal(): void {
    this.segmentsModal.openCreationModal()
  }

  async createSegment(segment: Filter): Promise<void> {
    if (!isNamedSegment(segment)) {
      throw new Error('Filter being created must be named')
    }
    const newSegmentId = await createSegment(segment, this.storeEvent._id)
    if (this.monoSelection) {
      await this.$store.dispatch('reloadCurrentEvent')
      this.selectedSegments = [newSegmentId]
    }
  }

  focusSearchInput() {
    if (this.searchInput) this.searchInput.focus()
  }

  isSortGroup(item: MixedSort[number]): boolean {
    return isSortGroup(item)
  }
}
