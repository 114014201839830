import { z } from 'zod'

import { roundTwoDecimals } from '@/lib/number'

export const amount100 = z
  .number()
  .int()
  .min(0)
  .max(10 * 1000 * 1000) // 10 million — to prevent obvious mistakes
  .brand('Amount100')

export const balance100 = z
  .number()
  .int()
  .min(-10 * 1000 * 1000) // -10 million — to prevent obvious mistakes
  .max(10 * 1000 * 1000) // 10 million — to prevent obvious mistakes
  .brand('Balance100')

const amountDecimal = z.number().transform(roundTwoDecimals).brand('AmountDecimal')
export const rate = z.number().min(0).max(1).brand('Rate')

export type Amount100 = z.infer<typeof amount100>
export type Balance100 = z.infer<typeof balance100>
type AmountDecimal = z.infer<typeof amountDecimal>
export type Rate = z.infer<typeof rate>

export function amount100ToDecimal(amount: number | Amount100): AmountDecimal {
  return amountDecimal.parse(amount / 100)
}

export function amountDecimalToAmount100(decimal: number | AmountDecimal): Amount100 {
  return amount100.parse(Math.round(decimal * 100))
}

export enum BalanceTransferDirection {
  ToPlatform = 'toPlatform',
  ToOrganizer = 'toOrganizer'
}
