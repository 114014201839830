






import { Vue, Component } from 'vue-property-decorator'
import type { EventStatus } from '@/models/Event'
import type { EventIdentity } from '@/core'

@Component({
  components: {}
})
export default class EventStatusBadge extends Vue {
  get eventIdentityStatus(): EventStatus {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity

    const isDemo = eventIdentity.isDemo
    const isEventEnded = this.$store.getters.isEventEnded

    switch (true) {
      case isDemo:
        return 'demo'
      case isEventEnded:
        return 'ended'
      default:
        return 'active'
    }
  }

  get title(): string {
    switch (this.eventIdentityStatus) {
      case 'demo':
        return this.$t('DEMO')
      case 'ended':
        return this.$t('ENDED')
      default:
        return this.$t('ACTIVE')
    }
  }

  get cssClass() {
    return {
      [`event-status-badge--${this.eventIdentityStatus}`]: true
    }
  }
}
