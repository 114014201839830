













import { Component, Vue, Watch } from 'vue-property-decorator'
import MobileNav from '@/layouts/components/MobileNav/MobileNav.vue'
import { DigiSpinner } from '@/components/ui'
import { SupportRequestPanel } from '@/features/support'

@Component({
  components: {
    DigiSpinner,
    MobileNav,
    SupportRequestPanel,
    DigiHeader: () => import('@/layouts/components/Header/DigiHeader.vue'),
    ProductChangesPanel: () => import('@/layouts/components/ProductChangesPanel/index.vue')
  }
})
export default class AuthenticatedLayout extends Vue {
  isNavOpen = false
  get loading(): boolean {
    return Boolean(this.$store.getters.isLoading)
  }
  toggleNav(isOpen: boolean) {
    this.isNavOpen = isOpen
  }

  @Watch('$route')
  onRouteChanged() {
    this.isNavOpen = false
  }
}
