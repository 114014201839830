import store from '@/store'
import type Rule from '@/models/Event/RulesData/Rule'
import { MULTI_REGISTRATION_TRIGGER_KIND } from './multiRegistrationTriggers'
import type { ReadonlyFullRouteConfig } from '@/core'

export const multiRegistrationRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'multiRegistration',
    name: 'event.website.multiregistration.settings',
    component: () => import('./views/MultiRegistrationSettings/MultiRegistrationSettings.vue'),
    meta: {
      name: 'WEBSITE_SETTINGS',
      description: 'FACTORY_MULTI_REGISTRATION_DESC',
      keywords: 'FACTORY_MULTI_REGISTRATION_KEYWORDS',
      iconName: 'group-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.type === 'multi',
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  },
  {
    path: 'multiRegistration/rules',
    name: 'event.website.multiregistration.rules',
    component: () => import('./views/MultiRegistrationRulesView.vue'),
    meta: {
      name: 'MULTI_REGISTRATION_RULES_LIST',
      iconName: 'user-add-line',
      keywords: 'FACTORY_SINGLE_REGISTRATION_RULES_KEYWORDS',
      searchable: true,
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event.rules.ruleItems.filter((rule: Rule) =>
              MULTI_REGISTRATION_TRIGGER_KIND.includes(rule.trigger.kind)
            ).length > 0
          )
        }
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.type === 'multi',
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'multiRegistration/rules/edition/:ruleId?',
    name: 'event.website.multiRegistration.rules.edition',
    component: () => import('./views/MultiRegistrationRulesEditionView.vue'),
    meta: {
      subPageOf: 'event.website.multiregistration.rules',
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.type === 'multi',
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
