import validator from 'validator'

export function validateColor(color: string): boolean {
  return Boolean(color) && /^#[A-F\d]{6}$/i.test(color)
}

export function isValidUrl(url?: string, additionalOptions: validator.IsURLOptions = {}) {
  return Boolean(
    url &&
      validator.isURL(url, {
        protocols: ['http', 'https'],
        ...additionalOptions
      })
  )
}

export function validateEmail(email: string): boolean {
  return validator.isEmpty(email) || validator.isEmail(email)
}

export function isValidHostName(host: string): boolean {
  return /^(?:[a-zA-Z0-9-]+\.)*?[a-zA-Z0-9-]+(?:\.[a-z]{2,63}){1,2}$/m.test(host)
}

export function containsOnlyLettersAndSpaces(str: string): boolean {
  return /^[a-zA-Z ]+$/.test(str)
}
