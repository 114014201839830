import { BoxProperties, PageElementBase } from '../types/PageElement'

export default abstract class ContentElementBase extends PageElementBase {
  abstract type: `content-${string}`

  canHaveBackgroundImage = false

  constructor() {
    super()

    this.box ??= new BoxProperties()
    this.box.backgroundType = 'color'
    this.box.paddingPx = [0, 15]
  }

  box: BoxProperties

  canHaveChildren = false
}
