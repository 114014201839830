import axios from 'axios'

export async function updateGuestInvitationStatus(
  eventId: string,
  guestId: string,
  invitationStatus: string
): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/guests/${guestId}/invitationStatus`, { invitationStatus })
}

type BaseRegistrationSettings = {
  calendarEnabled: boolean
  calendarDescription: string
}

export async function updateBaseRegistrationSettings(
  eventId: string,
  baseRegistrationSettings: BaseRegistrationSettings
): Promise<void> {
  await axios.put(`/events/${eventId}/baseRegistrationSettings`, { settings: baseRegistrationSettings })
}

export function getIcsLink(eventId: string): string {
  return `${process.env.VUE_APP_API_URL}/events/${eventId}.ics`
}
