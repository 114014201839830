import SidePanel from './SidePanel/SidePanel.vue'
import type { PluginObject } from 'vue'

const plugin: PluginObject<undefined> = {
  install(Vue) {
    Vue.component('SidePanel', SidePanel)

    Vue.directive('side-panel', {
      bind(el, binding, vnode) {
        let id: string

        if (binding.value) {
          id = binding.value
        } else {
          const keys = Object.keys(binding.modifiers)
          if (keys.length > 0) id = keys[0]!
          else throw new Error('DigiSidePanel directive received without any valid ID!')
        }

        el.addEventListener('click', () => {
          vnode.context?.$root.$emit('sidePanel::showById', id)
        })
      }
    })
  }
}

export default plugin
