



























import { Component } from 'vue-property-decorator'
import SocialNetworksModal from '@/components/features/SocialNetworksModal.vue'
import { CustomIcon } from '@/components/features/website'
import { PlainTextFragment, SlightlyRichTextFragment } from '../../../fragments'
import { DigiButton } from '@/components/ui/actions'
import type { Trombino1Element } from './TrombinoElement'
import TrombinoSearchCard from '@/features/website/builder/builder/prefabs/trombinoscope/trombino1/components/TrombinoSearchCard.vue'
import { computeReadableTextColor, pickColorWithPalette } from '@/utils/colors'
import TrombinoFakeGuestList from '@/features/website/builder/builder/prefabs/trombinoscope/trombino1/components/TrombinoFakeGuestList.vue'
import { Vue, Prop, Inject } from 'vue-property-decorator'
import type { Website } from '@/models/Event/modules/Website'
import type { BuilderState } from '@/features/website/builder/BuilderState'
import type { PageElementId } from '../../../types'
import { assignElement } from '../../../utils'

@Component({
  components: {
    SlightlyRichTextFragment,
    TrombinoFakeGuestList,
    TrombinoSearchCard,
    DigiButton,
    PlainTextFragment,
    SocialNetworksModal,
    CustomIcon
  }
})
export default class PrefabTrombino1 extends Vue {
  @Prop({ required: true }) readonly id!: PageElementId
  @Prop({ required: true }) readonly website!: Website

  @Inject() builderState!: BuilderState

  get pageElement(): Trombino1Element {
    return this.builderState.assertedPageContent?.elements[this.id] as Trombino1Element
  }

  setElementProperty(propertyPath: string, newValue: any): void {
    const newElement = assignElement(this.pageElement, propertyPath, newValue)

    this.builderState.assertedPageContent!.updateElement(this.id, newElement)
  }

  get prefabClasses(): Record<string, unknown> {
    return {
      'pb-0': this.pageElement.properties.removeBottomSpacing
    }
  }

  get style() {
    const innerBkgColor = this.pageElement.properties.innerBkgColor
    const outerBkgColor = this.pageElement.box.backgroundColor
    const trombinoInnerBkgColor = innerBkgColor
      ? pickColorWithPalette(innerBkgColor, this.$store.getters.colorPalette)
      : '#FFF'

    const trombinoOuterBkgColor = outerBkgColor
      ? pickColorWithPalette(outerBkgColor, this.$store.getters.colorPalette)
      : '#EEE'

    const trombinoOuterReadableColor = computeReadableTextColor(trombinoOuterBkgColor)
    const trombinoInnerReadableColor = computeReadableTextColor(trombinoInnerBkgColor)
    const trombinoInnerSoftReadableColor = trombinoInnerReadableColor === '#ffffff' ? '#ddd' : '#444'
    return {
      '--trombino-inner-bkg-color': trombinoInnerBkgColor,
      '--trombino-inner-readable-color': trombinoInnerReadableColor,
      '--trombino-inner-soft-readable-color': trombinoInnerSoftReadableColor,
      '--trombino-outer-readable-color': trombinoOuterReadableColor
    }
  }
}
