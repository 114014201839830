import type { DateRange } from '@/models/Event/modules/Programme'
import {
  TransformArrayOfDateStringsToDateObjects,
  TransformDateStringToDateObject
} from '@/utils/classTransformerExtensions'
import type { GuestId } from '../..'
import { ScheduledItemFromProgrammeBase } from './Base'

interface MeetingAttendantInfo {
  fullName: string
  _guest: GuestId
  motivation?: string
}

export default class MeetingSchedule extends ScheduledItemFromProgrammeBase {
  kind = 'meeting' as const

  applicant!: MeetingAttendantInfo
  target!: MeetingAttendantInfo
  @TransformArrayOfDateStringsToDateObjects()
  dateRange!: DateRange
  isCanceled!: boolean
  cancellationReason?: string
  @TransformDateStringToDateObject()
  checkDate?: Date
  location?: string
}
