















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DigiLink, DigiButton, DigiIconLink } from '@/components/ui/actions'
import type { SupportRequestForm } from './types'

@Component({
  components: {
    DigiIconLink,
    DigiLink,
    DigiButton
  }
})
export default class FindBestAnswer extends Vue {
  @Prop({ required: true }) readonly supportRequestForm!: Partial<SupportRequestForm>
  @Prop({ required: true }) readonly url!: string

  continueSupportRequest(): void {
    this.$emit('continue')
  }
}
