import type { ReadonlyFullRouteConfig } from '@/core'

export const messageSettingsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'messageSettings',
    name: 'event.settings.messageSettings',
    component: () => import('./views/messageSettings/MessageSettingsView.vue'),
    meta: {
      name: 'FACTORY_MESSAGE_SETTINGS',
      keywords: 'FACTORY_INACTIVE_FEATURES_KEYWORDS',
      iconName: 'star-smile-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'mailBranding',
    name: 'event.settings.messageSettings.mailBranding',
    component: () => import('./views/mailBranding/MailBrandingView.vue'),
    meta: {
      name: 'MAIL_BRANDING_PAGE_TITLE',
      keywords: 'FACTORY_INACTIVE_FEATURES_KEYWORDS',
      iconName: 'star-smile-line',
      searchable: true,
      subPageOf: 'event.settings.messageSettings',
      renderingData: {
        doNotWrap: true
      }
    }
  }
] as const
