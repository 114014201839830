






import { Vue, Component, Prop } from 'vue-property-decorator'
import type { NoPaddingValue } from './types'

@Component({})
export default class ContactProfileSimpleSubMenu extends Vue {
  @Prop({ default: false }) readonly noPadding!: NoPaddingValue

  get classes() {
    return {
      'contact-profile-sub--no-padding': this.noPadding === 'all',
      'contact-profile-sub--no-padding-y': this.noPadding === 'vertical',
      'contact-profile-sub--no-padding-x': this.noPadding === 'horizontal'
    }
  }
}
