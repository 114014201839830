import { zEventLangCode } from '@/models/Event/Localized'
import { z } from 'zod'

const zCustomFontFamily = z.enum(['customFontPrimary', 'customFontSecondary'])
export type CustomFontFamily = z.infer<typeof zCustomFontFamily>

const zFontKind = z.enum(['basic', 'custom'])
export type FontKind = z.infer<typeof zFontKind>

const zBasicFont = z.object({
  kind: z.literal(zFontKind.enum.basic),
  family: z.string(),
  size: z.number()
})
export type BasicFont = z.infer<typeof zBasicFont>

const zCustomFont = z.object({
  kind: z.literal(zFontKind.enum.custom),
  family: zCustomFontFamily,
  size: z.number()
})
export type CustomFont = z.infer<typeof zCustomFont>

const zFont = z.union([zBasicFont, zCustomFont])
export type Font = z.infer<typeof zFont>

const zEventRoundingLevel = z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)])
export type EventRoundingLevel = z.infer<typeof zEventRoundingLevel>

export const zEventPresenceType = z.enum(['onsite', 'virtual', 'hybrid'])
export type EventPresenceType = z.infer<typeof zEventPresenceType>

export const zEventTypology = z.enum(['assembly', 'b2bevent', 'externalEvent', 'internalEvent', 'other'])
export type EventTypology = z.infer<typeof zEventTypology>

export const zEventIdentity = z.object({
  id: z.string(),
  name: z.string(),
  presenceType: zEventPresenceType,
  typology: zEventTypology,
  startDate: z.string().pipe(z.coerce.date()),
  endDate: z.string().pipe(z.coerce.date()),
  timezone: z.string(),
  mainLanguage: zEventLangCode,
  location: z.string().optional(),
  ownerId: z.string(),
  logo: z.string(),
  colorPalette: z.array(z.string()),
  creationDate: z.string().pipe(z.coerce.date()),
  isDemo: z.boolean(),
  roundingLevel: zEventRoundingLevel,
  font: zFont
})
export type EventIdentity = z.infer<typeof zEventIdentity>
