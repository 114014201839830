










import { Component, Prop, Watch } from 'vue-property-decorator'
import { handleError } from '@/utils/errorHandling'
import { doIHavePermission, isValidPermission } from '@/services/eventPermissions'
import type { Permission } from '@/services/eventPermissions'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({})
export default class WithPermission extends Vue {
  isLoading = false
  hasPermission = false

  @Prop({ required: true, type: String, validator: isValidPermission })
  readonly permission!: Permission

  @Prop({ required: false, type: Boolean, default: true })
  readonly showDeniedTooltip!: Permission

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventId(): string {
    return this.storeEvent._id
  }

  mounted() {
    this.fetch()
  }

  @Watch('eventId')
  @Watch('permission')
  async fetch() {
    this.isLoading = true
    try {
      this.hasPermission = await doIHavePermission(this.eventId, this.permission)
    } catch (err) {
      console.error(err)
      handleError(err)
    }
    this.isLoading = false
  }
}
