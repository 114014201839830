import { Expose, Type } from 'class-transformer'
import type { CustomFieldId, CustomFieldType } from '.'
import { LocalizedPrimitive } from '../Localized'

export default abstract class CustomFieldBase {
  _id!: CustomFieldId
  abstract type: CustomFieldType
  @Expose()
  name: string = ''
  @Type(() => LocalizedPrimitive)
  translations?: LocalizedPrimitive<string>
}
