







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SingleSegmentBadge extends Vue {
  @Prop({ required: true }) readonly segmentName!: string
  @Prop({ default: false }) readonly icon!: boolean
}
