import type { Event } from '@/models/Event'
import type { EventRights } from '@/models/Event/EventRights'
import { hasAccessToFront } from '@/services/front.ts/hasAccessToFront'

/**
 * About this file:
 * It's a relic from the time we had to process data about the event outside of what's already stored.
 * This was useful before Event became strongly type, and most importantly, a class.
 * Most of the contents of this file should be eventually converted to getters. Don't add to it.
 */

/**
 * Checks if an event has a custom domain and all the data needed to enable it.
 * @param event The event to check.
 */
export function eventCustomDomain({
  event,
  eventRights
}: {
  event: Event
  eventRights: EventRights
}): string | undefined {
  if (
    eventRights.general.customization.customDomainName &&
    typeof event.front.customDomain?.hostname === 'string' &&
    event.front.customDomain?.hostname?.length > 0
  ) {
    return event.front.customDomain.hostname
  }
}

export function getSiteUrl({ event, eventRights }: { event: Event; eventRights: EventRights }): string {
  if (!hasAccessToFront(eventRights)) {
    throw new Error('Cannot have website URL on an event without website or eventApp.')
  }
  const prettyId = event.modules_data.website.prettyId
  const customHostname = eventCustomDomain({ event, eventRights })

  if (customHostname) {
    return 'https://' + customHostname
  }

  return getSiteUrlWithPrettyId(prettyId)
}

export function getSiteUrlWithPrettyId(prettyId: string): string {
  switch (process.env.NODE_ENV) {
    case 'development':
      return `https://${prettyId}.site.digitevent.work`
    case 'staging':
      return `https://${prettyId}.site-preprod.digitevent.com`
    default:
      return `https://${prettyId}.site.digitevent.com`
  }
}
