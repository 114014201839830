import type { EventRights } from '@/models/Event/EventRights'
import type { Tag, TagCategory } from './types'
import type { BackofficeLanguage } from '@/utils/i18n'

type TagCategoryMetaData = {
  value: TagCategory
  translationSlug: string
  predicate: (eventRights: EventRights) => boolean
}

export const tagCategoryMetaDataList: TagCategoryMetaData[] = [
  {
    value: 'Campaigns',
    translationSlug: 'SUPPORT_REQUEST_TAG_CAMPAIGNS',
    predicate: (eventRights) => eventRights.features.campaigns
  },
  {
    value: 'Certificates',
    translationSlug: 'SUPPORT_REQUEST_TAG_CERTIFICATES',
    predicate: (eventRights) => eventRights.features.certificates
  },
  {
    value: 'Check-in',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECK_IN',
    predicate: (eventRights) => eventRights.features.checkin.app
  },
  {
    value: 'Domain name',
    translationSlug: 'SUPPORT_REQUEST_TAG_DOMAIN_NAME',
    predicate: (eventRights) => eventRights.general.customization.customDomainName
  },
  {
    value: 'Participant app',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP',
    predicate: (eventRights) => eventRights.features.eventApp.allowed
  },
  {
    value: 'Registration (group)',
    translationSlug: 'SUPPORT_REQUEST_TAG_REGISTRATION_GROUP',
    predicate: (eventRights) => eventRights.features.registration.type === 'multi'
  },
  {
    value: 'Registration (single)',
    translationSlug: 'SUPPORT_REQUEST_TAG_REGISTRATION_SINGLE',
    predicate: (eventRights) => eventRights.features.registration.type === 'single'
  },
  {
    value: 'Site',
    translationSlug: 'SUPPORT_REQUEST_TAG_SITE',
    predicate: (eventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Surveys',
    translationSlug: 'SUPPORT_REQUEST_TAG_SURVEYS',
    predicate: (eventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Ticketing',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING',
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER',
    predicate: () => true
  }
]

export type TagMetaData = {
  value: Tag
  translationSlug: string
  category: TagCategory
  documentationUrl: Partial<Record<BackofficeLanguage, string>>
  predicate: (eventRights: EventRights) => boolean
}
const campaignsMetaDataList: (TagMetaData & { category: 'Campaigns' })[] = [
  {
    value: 'Campaigns / Deliverability',
    translationSlug: 'SUPPORT_REQUEST_TAG_CAMPAIGNS_DELIVERABILITY',
    category: 'Campaigns',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/D-livrabilit-des-emails-3f523e1e827843a8875904216b795e10'
    },
    predicate: (eventRights: EventRights) => eventRights.features.campaigns
  },
  {
    value: 'Campaigns / Design and content',
    translationSlug: 'SUPPORT_REQUEST_TAG_CAMPAIGNS_DESIGN_AND_CONTENT',
    category: 'Campaigns',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Configuration-et-personnalisation-des-campagnes-c6b2c06e940d409ea435befe33130d4f'
    },
    predicate: (eventRights: EventRights) => eventRights.features.campaigns
  },
  {
    value: 'Campaigns / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_CAMPAIGNS_OTHER',
    category: 'Campaigns',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Campagnes-61ff6503086e41499d5c2658d3847a99' },
    predicate: (eventRights: EventRights) => eventRights.features.campaigns
  }
]

const checkInMetaDataList: (TagMetaData & { category: 'Check-in' })[] = [
  {
    value: 'Check-in / Access passes and QR codes',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_ACCESS_PASSES_AND_QR_CODES',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Pass-d-acc-s-billet-lectronique-cd10650f488342049c1d277e55cc9fab'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.accessPass
  },
  {
    value: 'Check-in / Check-in app',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_APP',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Prendre-en-main-l-app-de-check-in-84c2f2d76cde4f0386efcba6ae62f558'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.app
  },
  {
    value: 'Check-in / Exports and reporting',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_EXPORTS_AND_REPORTING',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://www.notion.so/wearedigitevent/Prendre-en-main-l-app-de-check-in-84c2f2d76cde4f0386efcba6ae62f558#5f7ae933c8b246f3a860b17ac5070ad0'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.app
  },
  {
    value: 'Check-in / Importing contacts',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_IMPORTING_CONTACTS',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://www.notion.so/wearedigitevent/Imports-mise-jour-de-contacts-9a9e78e013514f16b2dc4f13e052c599'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.app
  },
  {
    value: 'Check-in / Notifications and automations',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_NOTIFICATIONS_AND_AUTOMATIONS',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Notifications-ou-automatisations-78c1ecda516c46ea8eb0a5b06314b923'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.app
  },
  {
    value: 'Check-in / PDF badges',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_PDF_BADGES',
    category: 'Check-in',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Badges-PDF-A4-A6-ceac3e0130c748ff8c5141ac156674c6' },
    predicate: (eventRights: EventRights) => eventRights.features.pdfBadges
  },
  {
    value: 'Check-in / Printing badges',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_PRINTING_BADGES',
    category: 'Check-in',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Impression-de-badges-42249d19dbcf40b6ba837fbcababe07d'
    },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.printBadge
  },
  {
    value: 'Check-in / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_CHECKIN_OTHER',
    category: 'Check-in',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Contr-le-d-acc-s-61ff6503086e41499d5c2658d3847a99' },
    predicate: (eventRights: EventRights) => eventRights.features.checkin.app
  }
]

const domainNameMetaDataList: (TagMetaData & { category: 'Domain name' })[] = [
  {
    value: 'Domain name / Frontend domain (website, participant app...)',
    translationSlug: 'SUPPORT_REQUEST_TAG_DOMAIN_NAME_FRONTEND_DOMAIN',
    category: 'Domain name',
    documentationUrl: {
      fr: 'https://www.notion.so/wearedigitevent/Domaine-et-personnalisation-98c04b79109d4387b27475ce72fc3eb1'
    },
    predicate: (eventRights: EventRights) => eventRights.general.customization.customDomainName
  },
  {
    value: 'Domain name / Mailing email domain',
    translationSlug: 'SUPPORT_REQUEST_TAG_DOMAIN_NAME_MAILING_EMAIL_DOMAIN',
    category: 'Domain name',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Personnaliser-l-email-d-exp-dition-4dfe4c9b5b4f49ca9c329ad9f20db627'
    },
    predicate: (eventRights: EventRights) => eventRights.general.customization.customDomainName
  }
]

const otherMetaDataList: (TagMetaData & { category: 'Other' })[] = [
  {
    value: 'Other / API and Webhooks',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_API_AND_WEBHOOKS',
    category: 'Other',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Pour-les-d-veloppeurs-API-webhooks-3ac335f8d6d74f8aa041dbca0b5cdc74'
    },
    predicate: () => true
  },
  {
    value: 'Other / Data import',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_DATA_IMPORT',
    category: 'Other',
    documentationUrl: {
      fr: 'https://www.notion.so/wearedigitevent/Imports-mise-jour-de-contacts-9a9e78e013514f16b2dc4f13e052c599'
    },
    predicate: () => true
  },
  {
    value: 'Other / Event activation',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_EVENT_ACTIVATION',
    category: 'Other',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Activation-et-cycle-de-vie-de-l-v-nement-d9000dbb0a7f4af0914386130278c93e'
    },
    predicate: () => true
  },
  {
    value: 'Other / Permissions and teammates',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_PERMISSIONS_AND_TEAMMATES',
    category: 'Other',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Permissions-et-co-quipiers-ca92aecb32be496b828ddc05d92fbff4'
    },
    predicate: () => true
  },
  {
    value: 'Other / Security and privacy',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_SECURITY_AND_PRIVACY',
    category: 'Other',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/S-curit-et-RGPD-12a8354468244641be23801050217351' },
    predicate: () => true
  },
  {
    value: 'Other / Segments',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_SEGMENTS',
    category: 'Other',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Segmentation-des-contacts-5be7f6135a1e449098fefa3aad40c709'
    },
    predicate: () => true
  },
  {
    value: 'Other / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_OTHER_OTHER',
    category: 'Other',
    documentationUrl: {},
    predicate: () => true
  }
]

const participantAppMetaDataList: (TagMetaData & { category: 'Participant app' })[] = [
  {
    value: 'Participant app / Chat',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_CHAT',
    category: 'Participant app',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Chat-entre-participants-9ac17d2fbcc84c2f9bbfaeb7839300a9'
    },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.chat
  },
  {
    value: 'Participant app / Matchmaking',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_MATCHMAKING',
    category: 'Participant app',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Matchmaking-gestion-de-RDVs-7dac8d819724466a890b3fbbd2e9b51f'
    },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.matchmaking
  },
  {
    value: 'Participant app / Networking',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_NETWORKING',
    category: 'Participant app',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Networking-bfed914d87d34731a566b09884243b92' },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.networking
  },
  {
    value: 'Participant app / Partners and sponsors',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_PARTNERS_AND_SPONSORS',
    category: 'Participant app',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Partenaires-sponsors-15c9abf0f515492da88870e9c49b724e'
    },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.allowed
  },
  {
    value: 'Participant app / Program and planning',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_PROGRAM_AND_PLANNING',
    category: 'Participant app',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Prendre-en-main-l-app-participant-efa1d6c2612e4c5599b06f5f01b6e213?pvs=74'
    },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.allowed
  },
  {
    value: 'Participant app / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_PARTICIPANT_APP_OTHER',
    category: 'Participant app',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Prendre-en-main-l-app-participant-efa1d6c2612e4c5599b06f5f01b6e213?pvs=74'
    },
    predicate: (eventRights: EventRights) => eventRights.features.eventApp.allowed
  }
]

const singleRegistrationMetaDataList: (TagMetaData & { category: 'Registration (single)' })[] = [
  {
    value: 'Registration (single) / Fields and Form',
    translationSlug: 'SUPPORT_REQUEST_TAG_SINGLE_REGISTRATION_FIELDS_AND_FORM',
    category: 'Registration (single)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-simple-sans-billetterie-01ce2491f6524bd0a63afc4489e3b646#33f1d41592af406aab97f5a802f2a306'
    },
    predicate: (eventRights: EventRights) => eventRights.features.registration.type === 'single'
  },
  {
    value: 'Registration (single) / Notifications and automations',
    translationSlug: 'SUPPORT_REQUEST_TAG_SINGLE_REGISTRATION_NOTIFICATIONS_AND_AUTOMATIONS',
    category: 'Registration (single)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-simple-sans-billetterie-01ce2491f6524bd0a63afc4489e3b646#c280c1326d2f4729ae2eaf735c1fdacb'
    },
    predicate: (eventRights: EventRights) => eventRights.features.registration.type === 'single'
  },
  {
    value: 'Registration (single) / Reporting and exports',
    translationSlug: 'SUPPORT_REQUEST_TAG_SINGLE_REGISTRATION_REPORTING_AND_EXPORTS',
    category: 'Registration (single)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-simple-sans-billetterie-01ce2491f6524bd0a63afc4489e3b646#0ad8d1239b5a4632815f987f3b0551ae'
    },
    predicate: (eventRights: EventRights) => eventRights.features.registration.type === 'single'
  },
  {
    value: 'Registration (single) / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_SINGLE_REGISTRATION_OTHER',
    category: 'Registration (single)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-simple-sans-billetterie-01ce2491f6524bd0a63afc4489e3b646'
    },
    predicate: (eventRights: EventRights) => eventRights.features.registration.type === 'single'
  }
]

const groupRegistrationMetaDataList: (TagMetaData & { category: 'Registration (group)' })[] = [
  {
    value: 'Registration (group) / Fields and Forms',
    translationSlug: 'SUPPORT_REQUEST_TAG_GROUP_REGISTRATION_FIELDS_AND_FORMS',
    category: 'Registration (group)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-de-groupes-sans-billetterie-6f59ebe0562e4080824b3c266a8e78ec#332b0d2268ff4c9b9ff6e8eabe9ed376'
    },
    predicate: (eventRights) => eventRights.features.registration.type === 'multi'
  },
  {
    value: 'Registration (group) / Notifications and automations',
    translationSlug: 'SUPPORT_REQUEST_TAG_GROUP_REGISTRATION_NOTIFICATIONS_AND_AUTOMATIONS',
    category: 'Registration (group)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-de-groupes-sans-billetterie-6f59ebe0562e4080824b3c266a8e78ec#c642e4e049674e5a83bfc5854e88c646'
    },
    predicate: (eventRights) => eventRights.features.registration.type === 'multi'
  },
  {
    value: 'Registration (group) / Reporting and exports',
    translationSlug: 'SUPPORT_REQUEST_TAG_GROUP_REGISTRATION_REPORTING_AND_EXPORTS',
    category: 'Registration (group)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-de-groupes-sans-billetterie-6f59ebe0562e4080824b3c266a8e78ec#60bb2f6eccd647829f254675e842a2a2'
    },
    predicate: (eventRights) => eventRights.features.registration.type === 'multi'
  },
  {
    value: 'Registration (group) / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_GROUP_REGISTRATION_OTHER',
    category: 'Registration (group)',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Inscription-de-groupes-sans-billetterie-6f59ebe0562e4080824b3c266a8e78ec'
    },
    predicate: (eventRights) => eventRights.features.registration.type === 'multi'
  }
]

const siteMetaDataList: (TagMetaData & { category: 'Site' })[] = [
  {
    value: 'Site / Design and content',
    translationSlug: 'SUPPORT_REQUEST_TAG_SITE_DESIGN_AND_CONTENT',
    category: 'Site',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Pages-Design-et-contenu-656f5ca53cf2473a98c826422cfe8596'
    },
    predicate: (eventRights: EventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Site / Multilingual',
    translationSlug: 'SUPPORT_REQUEST_TAG_SITE_MULTILINGUAL',
    category: 'Site',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Multilingue-44cd5fbe3a784f569eb31cfaac78f809' },
    predicate: (eventRights: EventRights) =>
      eventRights.features.website.allowed && eventRights.general.customization.multiLang
  },
  {
    value: 'Site / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_SITE_OTHER',
    category: 'Site',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Param-trages-du-site-5d1e0d8f09c4410fb40ea7b7ac833e94'
    },
    predicate: (eventRights: EventRights) => eventRights.features.website.allowed
  }
]

const surveysMetaDataList: (TagMetaData & { category: 'Surveys' })[] = [
  {
    value: 'Surveys / Access to the survey',
    translationSlug: 'SUPPORT_REQUEST_TAG_SURVEYS_ACCESS_TO_THE_SURVEY',
    category: 'Surveys',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Questionnaires-b4293b59175e44138da30ed22b3f4083#6f2ebf5cb13d49cfb47006cde4fca174'
    },
    predicate: (eventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Surveys / Fields and Form',
    translationSlug: 'SUPPORT_REQUEST_TAG_SURVEYS_FIELDS_AND_FORM',
    category: 'Surveys',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Questionnaires-b4293b59175e44138da30ed22b3f4083#33f1d41592af406aab97f5a802f2a306'
    },
    predicate: (eventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Surveys / Notifications and automations',
    translationSlug: 'SUPPORT_REQUEST_TAG_SURVEYS_NOTIFICATIONS_AND_AUTOMATIONS',
    category: 'Surveys',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Questionnaires-b4293b59175e44138da30ed22b3f4083#4850b3c98e5c47c3903e7913c718967b'
    },
    predicate: (eventRights) => eventRights.features.website.allowed
  },
  {
    value: 'Surveys / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_SURVEYS_OTHER',
    category: 'Surveys',
    documentationUrl: { fr: 'https://wearedigitevent.notion.site/Questionnaires-b4293b59175e44138da30ed22b3f4083' },
    predicate: (eventRights) => eventRights.features.website.allowed
  }
]

const ticketingMetaDataList: (TagMetaData & { category: 'Ticketing' })[] = [
  {
    value: 'Ticketing / Credit and refund',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_CREDIT_AND_REFUND',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#3ffd228c0a1344a28b038998a46d5645'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / Documents (invoices...)',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_DOCUMENTS',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#25f6a62c5dd040e191087598704fc121'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / Funds retrieval',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_FUNDS_RETRIEVAL',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#c0ea668132f042519501b21c1200f0fc'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / KYC',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_KYC',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#ccf8213c7418462892721d34043303cb'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / Promotional codes',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_PROMOTIONAL_CODES',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#556aefe84f7249e3aa97e1f248495b45'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / Ticket configuration',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_TICKET_CONFIGURATION',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca#be76ce358f784770955dffc4e068be9b'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  },
  {
    value: 'Ticketing / Other',
    translationSlug: 'SUPPORT_REQUEST_TAG_TICKETING_OTHER',
    category: 'Ticketing',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Billetterie-v-nements-payants-5ab4f70dc24a499285956cffdeb70eca'
    },
    predicate: (eventRights) => eventRights.features.registration.mode === 'paid'
  }
]

const certificatesMetaDataList: (TagMetaData & { category: 'Certificates' })[] = [
  {
    value: 'Certificates',
    translationSlug: 'SUPPORT_REQUEST_TAG_CERTIFICATES',
    category: 'Certificates',
    documentationUrl: {
      fr: 'https://wearedigitevent.notion.site/Certificats-dip-mes-et-attestations-fad88dce73854a48a12fa11a9eb9d8b5'
    },
    predicate: (eventRights: EventRights) => eventRights.features.certificates
  }
]

export const tagMetaDataList: TagMetaData[] = [
  ...campaignsMetaDataList,
  ...checkInMetaDataList,
  ...otherMetaDataList,
  ...domainNameMetaDataList,
  ...participantAppMetaDataList,
  ...singleRegistrationMetaDataList,
  ...groupRegistrationMetaDataList,
  ...siteMetaDataList,
  ...surveysMetaDataList,
  ...ticketingMetaDataList,
  ...certificatesMetaDataList
]

export function mustFindTagMetaData(tag: Tag): TagMetaData {
  const tagMetaData = tagMetaDataList.find((tagMetaData) => tagMetaData.value === tag)
  if (!tagMetaData) {
    throw new Error(`Could not find tag metadata for tag ${tag}`)
  }
  return tagMetaData
}
