import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabElementBase, PrefabPropertiesBase } from '../../PrefabElementBase'
import { LoremIpsum } from 'lorem-ipsum'

export class TimelineActivity {
  time: string = ''
  timeSubtitle: string = ''
  title: string = ''
  description: string = ''
}

class Timeline1Properties extends PrefabPropertiesBase {
  title: string = ''
  subtitle: string = ''
  activities: TimelineActivity[] = []
  textColor: string = ''
  accentColor: string = ''
}

export class Timeline1Element extends PrefabElementBase {
  static create({ palette }: ElementCreationContext): Timeline1Element {
    const programme = new Timeline1Element()

    const subtitleIpsum = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 }
    })

    programme.properties.activities.push(new TimelineActivity())

    programme.properties.title = i18n.t('PROGRAMME')

    programme.properties.subtitle = subtitleIpsum.generateSentences(1)

    programme.properties.textColor = palette.getMostReadableColor(palette.lightestColor.color).paletteCode
    programme.properties.accentColor = palette.mostVividColor.paletteCode

    return programme
  }

  type = 'prefab-timeline-1' as const

  @Type(() => Timeline1Properties)
  properties: Timeline1Properties = new Timeline1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_PROGRAMME_TIMELINE')
  }
}
