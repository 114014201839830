





















import { Component, Prop } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { GuestFieldKey } from '@/models/Event/GuestField'
import type Guest from '@/models/Guest'
import axios from 'axios'
import { DigiButton } from '@/components/ui/actions'
import EditFieldItem from '@/features/audience/contactEdit/components/EditFieldItem.vue'
import { guestFieldToFieldItem } from '@/features/audience/contactEdit'

@Component({ components: { EditFieldItem, DigiButton } })
export default class CreateAccompanist extends Vue {
  @Prop({ required: true }) readonly masterGuestId!: string

  newAccompanist: Partial<Guest> = {
    name: '',
    firstname: '',
    guestemail: ''
  }
  validationMap: Record<GuestFieldKey, boolean> = {}
  isLoading = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get fields() {
    const guestFieldsItem = guestFieldToFieldItem(this.storeEvent.guestFields)
    return {
      name: guestFieldsItem.get('name')!,
      email: guestFieldsItem.get('guestemail')!,
      firstname: guestFieldsItem.get('firstname')!
    }
  }

  get hasInheritedFields(): boolean {
    return this.inheritedFieldKeys.length > 0
  }

  get inheritedFieldKeys(): string[] {
    return this.storeEvent.modules_data.multiRegistration?.inheritedFields ?? []
  }

  get inheritedFieldsDescription(): string {
    return this.inheritedFieldKeys
      .map((k) => {
        const field = this.storeEvent.guestFields.find((f) => f.key === k)
        if (!field) {
          throw new Error(`Field ${k} not found`)
        }
        return this.$t(field.name)
      })
      .join(', ')
  }

  get isValid(): boolean {
    return Object.values(this.validationMap).every((validity: boolean) => validity === true)
  }

  updateValidationMap(fieldKey: GuestFieldKey, validity: boolean): void {
    this.$set(this.validationMap, fieldKey, validity)
  }

  async submit(): Promise<void> {
    try {
      this.isLoading = true
      await axios.post<{ updatedMasterGuest: Guest }>(
        `/backoffice/registration/events/${this.storeEvent._id}/guests/${this.masterGuestId}/createMinimalAccompanist`,
        {
          newAccompanistData: this.newAccompanist
        }
      )
      this.resetData()
      this.$emit('accompanistCreated', this.masterGuestId)
    } finally {
      this.isLoading = false
    }
  }

  resetData(): void {
    this.newAccompanist = {}
  }
}
