

























import { DigiDateTimePicker } from '@/components/ui/inputs'
import type { EventIdentity } from '@/core'
import moment from 'moment'
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'

@Component({
  components: {
    DigiDateTimePicker
  }
})
export default class EventDatesInput extends Vue {
  @PropSync('startDate', { required: true }) syncedStartDate!: Date
  @PropSync('endDate', { required: true }) syncedEndDate!: Date

  @Prop({ default: false }) readonly isDatePickerDisabled!: boolean

  get eventIdentity(): EventIdentity {
    return this.$store.state.eventIdentity
  }

  get startOfToday(): Date {
    return moment().startOf('day').toDate()
  }

  get dateInAYear(): Date {
    return moment().add(12, 'years').toDate()
  }

  setStartDate(startDate: Date): void {
    this.syncedStartDate = startDate
    if (moment(startDate) > moment(this.syncedEndDate)) {
      this.syncedEndDate = startDate
    }
  }

  setEndDate(endDate: Date): void {
    this.syncedEndDate = endDate
    if (moment(endDate) < moment(this.syncedStartDate)) {
      this.syncedStartDate = endDate
    }
  }
}
