import type { EventLangCode } from '../Localized'
import { LocalizedPrimitive } from '../Localized'
import type { MongoDb } from '@/models/MongoDbTools'
import type FormElement from './Field'
import { FormField, transformFormElements } from './Field'
import type { FilterId } from '@/models/Event/Filter'
import { i18n } from '@/utils/i18n'
import { ObjectID } from 'bson'
import { Transform, Type } from 'class-transformer'
import { ErrorWithExtras } from '@/utils/errorTypes'

export type FormId = MongoDb.ObjectId
export default class Form {
  constructor(translations: EventLangCode[], eventLanguageCode: EventLangCode, formName: string) {
    if (translations) {
      // translations and other arguments will be empty when constructor is called by class-transformer
      this.elements = Form.defaultFormElements
      this._id = new ObjectID().toString()
      this.terminateSignupLabel[eventLanguageCode] = i18n.t('VALIDATE')
      this.successMessage[eventLanguageCode] = i18n.t('FORM_SUBMISSION_MESSAGE')
      this.rsvpLabel[eventLanguageCode] = formName
      for (const translation of translations) {
        this.makeNameForLang(eventLanguageCode, translation)
      }
    }
  }

  static get defaultFormElements(): FormElement[] {
    const defaultFieldsKeys = [...this.mandatoryFieldsKeys, 'firstname']

    return defaultFieldsKeys.map((fieldKey: FormField['key']) => {
      const formField = new FormField(fieldKey)

      if (this.isMandatoryField(formField)) {
        formField.required = true
        formField.locked = true
      }

      return formField
    })
  }

  static get mandatoryFieldsKeys(): FormField['key'][] {
    return ['name']
  }

  static isMandatoryField(field: FormField) {
    return this.mandatoryFieldsKeys.includes(field.key)
  }

  _id!: FormId

  @Transform(transformFormElements)
  elements: FormElement[] = [] as any;

  *keyedFields(): Generator<FormField> {
    for (const element of this.elements) {
      if (element instanceof FormField) yield element
    }
  }
  getKeyedFields(): FormField[] {
    return Array.from(this.keyedFields())
  }

  @Type(() => LocalizedPrimitive)
  successMessage: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  @Type(() => LocalizedPrimitive)
  terminateSignupLabel: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  @Type(() => LocalizedPrimitive)
  rsvpLabel: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()

  makeNameForLang(fromLang: EventLangCode, toLang: EventLangCode): void {
    if (this.rsvpLabel[fromLang] && !this.rsvpLabel[toLang]) {
      this.rsvpLabel[toLang] = `${this.rsvpLabel[fromLang]} (${toLang.toUpperCase()})`
    }
  }

  getLocalizedName(lang: EventLangCode): string {
    if (this.rsvpLabel[lang]) {
      return this.rsvpLabel[lang]!
    } else {
      return ''
    }
  }

  segmentId: FilterId | null = null

  isRegistration: boolean = false

  isUpdateAllowed?: boolean
}

export class FormNotFoundError extends ErrorWithExtras {
  constructor(formId: FormId) {
    super(`No form found with id ${formId}`, { formId })

    this.formId = formId
  }

  formId: FormId
}
