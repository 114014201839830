import Settings from './Settings.vue'
import store from '@/store'
import type { Event } from '@/models/Event'
import type Form from '@/models/Event/Form'
import type { TriggerUtil } from '../../../RuleEdition/types'
import type { AnyTrigger, SurveySubmissionTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'

interface SurveySubmission extends TriggerUtil {
  surveys: Form[]
}

function isSurveySubmissionTrigger(trigger: AnyTrigger): trigger is SurveySubmissionTrigger {
  if (trigger.kind !== TriggerKind.SurveySubmission) {
    throw new Error('SurveySubmissionTriggerUtil can only manage trigger of type surveySubmission')
  }

  return true
}

const surveySubmissionTriggerUtil: SurveySubmission = {
  kind: TriggerKind.SurveySubmission as const,
  label: 'SURVEY_SUBMISSION' as const,
  icon: 'checkbox-multiple-line' as const,
  settings: Settings,

  get surveys() {
    const event: Event | undefined = store.state.event?.event
    const multiRegistrationFormId = event?.modules_data.multiRegistration?.slaveFormId

    return event?.forms.filter((form) => !form.isRegistration && form._id !== multiRegistrationFormId) ?? []
  },

  validator(trigger: AnyTrigger): boolean {
    if (isSurveySubmissionTrigger(trigger)) {
      return Boolean(trigger.formId)
    } else {
      return false
    }
  },

  onSelection(trigger: AnyTrigger): void {
    if (isSurveySubmissionTrigger(trigger)) {
      const defaultSurveyId = this.surveys[0]?._id

      if (!defaultSurveyId) {
        throw new Error('Event has no survey defined')
      }

      trigger.formId = defaultSurveyId
    }
  },

  isAllowed(): boolean {
    return this.surveys.length > 0
  }
}

export default surveySubmissionTriggerUtil
