











import { Component, Vue } from 'vue-property-decorator'
import { DigiCardItem } from '@/components/ui/cardItem'

@Component({ components: { DigiCardItem } })
export default class PlanningCard extends Vue {}
