









import { Vue, Component, Prop } from 'vue-property-decorator'

type DigiCardVariant = 'danger'

@Component
export default class DigiCard extends Vue {
  @Prop() readonly hideHeader!: boolean
  @Prop() readonly variant!: DigiCardVariant
}
