



























import { Component, Prop } from 'vue-property-decorator'

import ListInput from '@/features/audience/contactList/components/ListFieldInput/ListFieldInput.vue'

import type Guest from '@/models/Guest'
import type { GuestField } from '@/models/Event/GuestField'
import { CustomFieldType } from '@/models/Event/CustomField'
import { isNil } from 'lodash'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import GuestFieldTextInput from './inputs/GuestFieldTextInput.vue'
import GuestFieldEmailInput from './inputs/GuestFieldEmailInput.vue'
import GuestFieldPhoneInput from './inputs/GuestFieldPhoneInput.vue'
import GuestFieldBooleanInput from './inputs/GuestFieldBooleanInput.vue'
import GuestFieldLongTextInput from './inputs/GuestFieldLongTextInput.vue'
import GuestFieldFileInput from './inputs/GuestFieldFileInput.vue'
import GuestFieldNumberInput from './inputs/GuestFieldNumberInput.vue'
import GuestFieldDateInput from './inputs/GuestFieldDateInput.vue'
import GuestFieldLinkInput from './inputs/GuestFieldLinkInput.vue'

@Component({
  components: {
    GuestFieldLinkInput,
    GuestFieldDateInput,
    GuestFieldNumberInput,
    GuestFieldFileInput,
    GuestFieldLongTextInput,
    GuestFieldBooleanInput,
    GuestFieldPhoneInput,
    GuestFieldEmailInput,
    GuestFieldTextInput,
    ListInput
  }
})
export default class GuestFieldInput extends Vue {
  @Prop({ required: true }) readonly field!: GuestField
  @Prop({ required: true }) readonly guest!: Partial<Guest>

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get value(): unknown {
    const valueInGuest = this.guest[this.field.key]
    switch (this.field.type) {
      case CustomFieldType.DateWithoutTimezone:
        if (typeof valueInGuest === 'string') {
          const value = new Date(valueInGuest as string)
          return isNaN(value.getTime()) ? null : value
        } else if (valueInGuest instanceof Date) {
          return valueInGuest
        } else {
          return null
        }
      case CustomFieldType.Phone:
        if (typeof valueInGuest === 'string') {
          return valueInGuest
        } else if (isNil(valueInGuest)) {
          return ''
        } else {
          return String(valueInGuest)
        }
      default:
        return valueInGuest
    }
  }

  set value(value: unknown) {
    switch (this.field.type) {
      case CustomFieldType.Link:
        if (typeof value === 'string' && value.length === 0) {
          this.$set(this.guest, this.field.key, null)
        } else {
          this.$set(this.guest, this.field.key, value)
        }
        break
      case CustomFieldType.Number:
        if (typeof value === 'string' && value.length === 0) {
          this.$set(this.guest, this.field.key, null)
        } else {
          this.$set(this.guest, this.field.key, Number(value))
        }
        break
      default:
        this.$set(this.guest, this.field.key, value)
    }
  }

  emitValidity(isFieldValid: boolean): void {
    this.$emit('update-validity', this.field.key, isFieldValid)
  }
}
