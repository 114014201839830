








import { Component, Prop, Vue } from 'vue-property-decorator'
import type { ColorVariant, LinkTargetType, Size } from './types'
import type { Location } from 'vue-router'

@Component
export default class DigiLink extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: 'primary' }) readonly variant!: ColorVariant
  @Prop({ default: 'md' }) readonly size!: Size
  @Prop({ default: false }) readonly block!: boolean
  @Prop({ default: 0 }) readonly tabindex!: number
  @Prop() readonly href?: string | null
  @Prop() readonly to?: Location | null
  @Prop() readonly target?: LinkTargetType

  get properties() {
    if (this.isRouterLink) {
      return {
        to: this.disabled ? '' : this.to,
        disabled: this.disabled,
        class: this.cssClasses,
        tabindex: this.tabindex,
        target: this.target ? this.target : this.href && '_blank',
        'aria-disabled': this.disabled
      }
    }
    return {
      href: this.disabled ? '' : this.href,
      role: 'link',
      class: this.cssClasses,
      tabindex: this.tabindex,
      target: this.target ? this.target : this.href && '_blank',
      'aria-disabled': this.disabled
    }
  }

  get currentTo() {
    return this.disabled ? '' : this.to
  }

  onClick(): void {
    this.$emit('click')
  }

  get isRouterLink(): boolean {
    return this.to !== null && this.to !== undefined
  }

  get cssClasses() {
    return {
      [`digi-link--${this.variant}`]: true,
      [`digi-link--${this.size}`]: true,
      'digi-link--block': this.block
    }
  }
}
