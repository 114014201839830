var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchGuests',{ref:"searchGuests",attrs:{"placeholder":_vm.searchPlaceholder,"search-parameters":_vm.searchParams,"excluded-guest-ids":_vm.excludedGuestIds},scopedSlots:_vm._u([{key:"searchResults",fn:function(ref){
var results = ref.results;
return [_c('div',{staticClass:"w-100",staticStyle:{"overflow-x":"scroll"}},[_c('BTable',{staticClass:"layoutTable",attrs:{"fields":_vm.tableFields,"items":results},scopedSlots:_vm._u([{key:"cell(addBtn)",fn:function(ref){
var contact = ref.item;
return [_c('DigiButton',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"size":"xs","variant":"success","disabled":!_vm.guestCouldBeSlave(contact),"title":_vm._f("t")('ADD')},on:{"click":function($event){return _vm.addAccompanist(contact)}}},[_vm._v(" "+_vm._s(_vm._f("t")('ADD'))+" ")])]}},_vm._l((_vm.selectedFieldsData.filter(function (f) { return f.type === 'list'; })),function(ref){
var key = ref.key;
var list = ref.list;
return {key:"cell("+key+")",fn:function(ref){
var contact = ref.item;
return [_c('ul',{key:key},_vm._l((_vm.findValueFromKey(list, contact[key])),function(value,idx){return _c('li',{key:idx},[_vm._v(_vm._s(value))])}),0)]}}})],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }