










import { Component, Vue } from 'vue-property-decorator'
import { DigiLink } from '@/components/ui/actions'

@Component({ components: { DigiLink } })
export default class BrazilianSupportRequestSection extends Vue {
  get whatsappNumber(): string {
    return this.$t('BRAZILIAN_SUPPORT_TEL_NUM')
  }

  get hrefWithSupportNumber(): string {
    return `https://wa.me/${this.whatsappNumber}`
  }

  get formattedSupportNumber(): string {
    const number = this.whatsappNumber
    return '+' + number.slice(0, 2) + ' ' + number.slice(2, 4) + ' ' + number.slice(4, 9) + '-' + number.slice(9)
  }
}
