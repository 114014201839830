import type { FilterId } from '@/models/Event/Filter'
import { AnonymousFilter } from '@/models/Event/Filter'
import type { EventLangCode } from '@/models/Event/Localized'
import { LocalizedPrimitive } from '@/models/Event/Localized'
import type { MongoDb } from '@/models/MongoDbTools'
import store from '@/store'
import type { TransformFnParams } from 'class-transformer'
import { plainToClass, Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

const lorem = new LoremIpsum()

export type FormFieldKey = string

export type FormElementId = MongoDb.ObjectId

export enum FormElementKind {
  Field = 'field',
  Text = 'text'
}

abstract class FormElementBase {
  _id!: FormElementId
  abstract kind: FormElementKind
  @Type(() => LocalizedPrimitive)
  infoText: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  @Type(() => AnonymousFilter)
  filter?: AnonymousFilter
  segmentsIds: FilterId[] = []
}

export class FormField extends FormElementBase {
  constructor(key: string) {
    super()
    this.key = key
  }

  kind = FormElementKind.Field as const
  key!: FormFieldKey
  required: boolean = false
  locked: boolean = false
  isSignatureDraw?: boolean
  hideListLimits?: boolean
  clearIfConditionNotMatched: boolean = false
}

export class FormText extends FormElementBase {
  kind = FormElementKind.Text as const

  populateInfoText() {
    const websiteLanguages = [
      store.getters.event.mainLanguageCode,
      ...store.getters.event.modules_data.website.websiteTranslations
    ] as EventLangCode[]

    for (const lang of websiteLanguages) {
      this.infoText[lang] = lorem.generateSentences(3)
    }
  }
}

type FormElement = FormField | FormText

export default FormElement

export function transformFormElements({ value }: TransformFnParams): FormElement[] {
  if (Array.isArray(value)) {
    return value.map((el: FormElementBase) => {
      switch (el.kind) {
        case FormElementKind.Field:
          return plainToClass(FormField, el)
        case FormElementKind.Text:
          return plainToClass(FormText, el)
        default:
          throw new Error('Unexpected: Form element is unsupported kind.')
      }
    })
  } else {
    throw new Error('Unexpected: Form elements in plain form are not an array.')
  }
}
