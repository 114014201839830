import Vue from 'vue'
import Vuex from 'vuex'

import event from './modules/event/event'
import type { AccountState } from './modules/account'
import account from './modules/account'

import type { Event } from '@/models/Event'
import type { EventRights } from '@/models/Event/EventRights'
import type { EventIdentity } from '@/core'
import eventIdentity from './modules/event/eventIdentity'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// @TODO: Define this better in the future
export interface StoreState {
  event: {
    event?: Event
    rights: EventRights
    eventIdentity: EventIdentity
  }
  account: AccountState
}

export default new Vuex.Store<StoreState>({
  modules: {
    event,
    account,
    eventIdentity,
    root: {
      state: {
        loading: false
      },
      mutations: {
        SET_LOADING(state, isLoading) {
          state.loading = isLoading
        }
      },
      getters: {
        isLoading(state) {
          return state.loading
        }
      }
    }
  },
  strict: debug
})
