





import { Component, Prop } from 'vue-property-decorator'
import { capitalize } from 'lodash'
import Vue from 'vue'

const availableIcons = [
  'Facebook',
  'Instagram',
  'Linkedin',
  'Medium',
  'Pinterest',
  'Snapchat',
  'Tiktok',
  'Twitter',
  'Youtube',
  'Website'
] as const

type AvailableIcons = typeof availableIcons[number]

@Component({
  components: {
    Facebook: () => import('./Facebook.vue'),
    Instagram: () => import('./Instagram.vue'),
    Linkedin: () => import('./Linkedin.vue'),
    Medium: () => import('./Medium.vue'),
    Pinterest: () => import('./Pinterest.vue'),
    Snapchat: () => import('./Snapchat.vue'),
    Tiktok: () => import('./Tiktok.vue'),
    Twitter: () => import('./Twitter.vue'),
    Youtube: () => import('./Youtube.vue'),
    Website: () => import('./Website.vue')
  }
})
export default class CustomIcon extends Vue {
  @Prop({ default: 'black' }) readonly fillColor!: string
  @Prop({ required: true }) readonly name!: string

  get capitalizedName(): string {
    return capitalize(this.name)
  }

  get isValidIcon(): boolean {
    return availableIcons.includes(this.capitalizedName as AvailableIcons)
  }

  mounted(): void {
    if (!this.isValidIcon) {
      throw new Error(`Icon ${this.name} is not defined as custom icon`)
    }
  }
}
