import { ElementTypeDictionary } from '../../types'
import Speakers1Element from './speakers1/Speakers1Element'
import Speakers2Element from './speakers2/Speakers2Element'

export const speakersDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Speakers1Element,
    renderer: () => import('./speakers1/Speakers1.vue'),
    properties: [
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'color', label: 'BG_COLOR_SPONSOR_ITEMS', key: 'properties.itemBgColor' },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    isAvailable({ eventRights }) {
      return eventRights.features.eventApp.allowed
    },
    typeNameSlug: 'WEBSITE_BLOCK_SPEAKERS_1',
    previewImageLink: require('@/assets/img/prefab-previews/speakers.jpg')
  })
  .addType({
    dataClass: Speakers2Element,
    renderer: () => import('./speakers2/Speakers2.vue'),
    properties: [
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor', toContrastWith: 'properties.itemBgColor' },
      { type: 'color', label: 'BG_COLOR_SPONSOR_ITEMS', canBeTransparent: true, key: 'properties.itemBgColor' },
      { type: 'speakersSelector', label: 'SPEAKERS_SELECTOR', key: 'properties.speakersIds' },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    isAvailable({ eventRights }) {
      return eventRights.features.eventApp.allowed
    },
    typeNameSlug: 'WEBSITE_BLOCK_SPEAKERS_2',
    previewImageLink: require('@/assets/img/prefab-previews/speakers2.jpg')
  })
  .finish()
