




import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { DigiDatePicker } from '@/components/ui/inputs'
import type DateTimeCustomField from '@/models/Event/CustomField/fieldTypes/DateWithoutTimezone'
import { isNil } from 'lodash'

@Component({
  components: { DigiDatePicker }
})
export default class GuestFieldDateInput extends Vue {
  @VModel() readonly fieldValue!: unknown
  @Prop({ required: true }) readonly field!: DateTimeCustomField

  get fieldState(): boolean | null {
    if (isNil(this.fieldValue)) {
      return null
    } else if (this.fieldValue instanceof Date) {
      return !isNaN(this.fieldValue.getTime())
    }
    return false
  }
}
