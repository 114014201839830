


























import { Vue, Component, Prop } from 'vue-property-decorator'
import { isUrlReturningAnImage } from '@/utils/fileTypes'
import { deleteGuestImage } from '@/services/fileStorage'
import type { GuestId } from '@/models/Guest'
import { DigiIconButton } from '@/components/ui/actions'
import { DigiLink } from '@/components/ui/actions'

@Component({
  components: { DigiLink, DigiIconButton }
})
export default class FileUploadPreview extends Vue {
  @Prop({ required: true }) readonly fileUrl!: string
  @Prop({ default: null }) readonly type!: string | null
  @Prop({ default: null }) readonly guestId!: GuestId | null
  @Prop({ default: null }) readonly fieldKey!: string | null
  @Prop({ default: false }) readonly minimal!: boolean

  isHovering = false

  get isAnImage(): boolean {
    return isUrlReturningAnImage(this.fileUrl)
  }

  get fileName(): string | null {
    if (this.isAnImage) {
      return null
    }

    return this.extractFileNameFromUrl(this.fileUrl) ?? this.$t('FILE')
  }

  removeFile() {
    if (this.type === 'guestImage') {
      if (!this.guestId || !this.fieldKey) {
        throw new Error('Cannot delete guest image: Operation requires guest ID and field key.')
      }

      deleteGuestImage(this.$store.getters.eventId, this.guestId, this.fieldKey)
    }
    this.$emit('remove-file')
  }

  extractFileNameFromUrl(url: string) {
    const urlObject: URL = new URL(url)

    return urlObject.pathname.split('/').pop()
  }
}
