


















import { Component, Prop } from 'vue-property-decorator'
import { DigiFormLayout } from '@/components/ui/form/formLayouts'
import { cloneDeep } from 'lodash'
import type { SocialLinks } from '@/models/Event/Identity'
import SocialNetworksFormGroup from './SocialNetworksFormGroup.vue'
import { DigiFormLayout2 } from '@/components/ui/form/formLayouts'
import { getObjectDiff } from '@/utils/object'
import { patchEventSocialsLink } from './service'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    DigiFormLayout2,
    DigiFormLayout,
    SocialNetworksFormGroup
  }
})
export default class EditSocialNetworks extends Vue {
  @Prop() readonly modalDisplay!: boolean
  @Prop({}) readonly title!: string

  isSaving: boolean = false

  localSocialNetworksUrls: SocialLinks = {}

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get styles() {
    return {
      border: this.modalDisplay ? 'none' : false
    }
  }

  created(): void {
    this.copyStoreSocialNetworks()
  }

  get savedSocialNetworks(): SocialLinks {
    return this.storeEvent.identity?.socialNetworks ?? {}
  }

  get modifiedValues(): Partial<SocialLinks> {
    return getObjectDiff(this.savedSocialNetworks, this.localSocialNetworksUrls)
  }

  get hasModifications(): boolean {
    return Object.keys(this.modifiedValues).length !== 0
  }

  copyStoreSocialNetworks(): void {
    if (this.storeEvent.identity.socialNetworks) {
      this.localSocialNetworksUrls = cloneDeep(this.storeEvent.identity.socialNetworks)
    }
    this.$emit('hide-modal')
  }

  async saveNetworks(): Promise<void> {
    this.isSaving = true

    try {
      await patchEventSocialsLink(this.localSocialNetworksUrls, this.storeEvent._id)
      this.$emit('save')
      this.$notify({
        text: this.$t('MODIFICATIONS_SAVED'),
        type: 'success'
      })
    } finally {
      this.isSaving = false
    }
  }
}
