import axios from 'axios'

export type FrontSettings = {
  googleTagManagerId: string | null
  faviconUrl: string | null
  prettyId: string
  footerBkgColor: string | null
  contactEmail: string
  customDomain: {
    hostname: string
    frontDomainConfigId: string | null
    status: 'OPERATIONAL' | 'NONE' | 'DEPLOYING'
  } | null
}

export async function getFrontSettings(eventId: string): Promise<FrontSettings> {
  const res = await axios.get<FrontSettings>(`/backoffice/events/${eventId}/frontSettings`)
  return res.data
}

export async function setCustomDomain(eventId: string, domainUrl: string): Promise<void> {
  await axios.post(`/events/${eventId}/front/customDomain`, { domainUrl })
}

export async function unsetCustomDomain(eventId: string): Promise<void> {
  await axios.delete(`/events/${eventId}/front/customDomain`)
}

export * from './pages'
