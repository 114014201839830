






import { Vue, Component, Prop } from 'vue-property-decorator'
import { GSM_CHARACTERS_REGEX } from '@/utils/constants'

@Component
export default class SmsInvalidCharsChecker extends Vue {
  @Prop({ required: true }) readonly content!: string

  get invalidChars(): Set<string> {
    return new Set(this.content.match(GSM_CHARACTERS_REGEX))
  }

  get hasInvalidChars(): boolean {
    return this.invalidChars.size > 0
  }

  get invalidCharsString(): string {
    return Array.from(this.invalidChars).join(', ')
  }
}
