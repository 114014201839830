enum InvitationStatus {
  NoStatus = 'no_status',
  Confirmed = 'confirmed',
  Invited = 'invited',
  Declined = 'declined',
  Blacklisted = 'blacklisted',
  Active = 'active'
}

export default InvitationStatus
