import { z } from 'zod'

const zUserUrgencyLevel = z.union([z.literal('HIGHEST'), z.literal('HIGH'), z.literal('MEDIUM')])
const zAttachment = z.instanceof(File).nullable()
const zAttachments = z.tuple([zAttachment, zAttachment, zAttachment])
const zPreferredFeedbackMethod = z.union([z.literal('Email'), z.literal('Phone'), z.literal('Any')])

const zCampaignsTag = z.union([
  z.literal('Campaigns / Deliverability'),
  z.literal('Campaigns / Design and content'),
  z.literal('Campaigns / Other')
])

const zCheckInTag = z.union([
  z.literal('Check-in / Access passes and QR codes'),
  z.literal('Check-in / Check-in app'),
  z.literal('Check-in / Exports and reporting'),
  z.literal('Check-in / Importing contacts'),
  z.literal('Check-in / Notifications and automations'),
  z.literal('Check-in / Other'),
  z.literal('Check-in / PDF badges'),
  z.literal('Check-in / Printing badges')
])

const zDomainNameTag = z.union([
  z.literal('Domain name / Frontend domain (website, participant app...)'),
  z.literal('Domain name / Mailing email domain')
])

const zOtherTag = z.union([
  z.literal('Other / API and Webhooks'),
  z.literal('Other / Data import'),
  z.literal('Other / Event activation'),
  z.literal('Other / Other'),
  z.literal('Other / Permissions and teammates'),
  z.literal('Other / Security and privacy'),
  z.literal('Other / Segments')
])

const zParticipantAppTag = z.union([
  z.literal('Participant app / Chat'),
  z.literal('Participant app / Matchmaking'),
  z.literal('Participant app / Networking'),
  z.literal('Participant app / Other'),
  z.literal('Participant app / Partners and sponsors'),
  z.literal('Participant app / Program and planning')
])

const zSingleRegistrationTag = z.union([
  z.literal('Registration (single) / Fields and Form'),
  z.literal('Registration (single) / Notifications and automations'),
  z.literal('Registration (single) / Other'),
  z.literal('Registration (single) / Reporting and exports')
])

const zGroupRegistrationTag = z.union([
  z.literal('Registration (group) / Fields and Forms'),
  z.literal('Registration (group) / Notifications and automations'),
  z.literal('Registration (group) / Other'),
  z.literal('Registration (group) / Reporting and exports')
])

const zSiteTag = z.union([
  z.literal('Site / Design and content'),
  z.literal('Site / Multilingual'),
  z.literal('Site / Other')
])

const zSurveysTag = z.union([
  z.literal('Surveys / Access to the survey'),
  z.literal('Surveys / Fields and Form'),
  z.literal('Surveys / Notifications and automations'),
  z.literal('Surveys / Other')
])

const zTicketingTag = z.union([
  z.literal('Ticketing / Credit and refund'),
  z.literal('Ticketing / Documents (invoices...)'),
  z.literal('Ticketing / Funds retrieval'),
  z.literal('Ticketing / KYC'),
  z.literal('Ticketing / Other'),
  z.literal('Ticketing / Promotional codes'),
  z.literal('Ticketing / Ticket configuration')
])

export const zTag = z.union([
  z.literal('Certificates'),
  zCampaignsTag,
  zCheckInTag,
  zDomainNameTag,
  zGroupRegistrationTag,
  zOtherTag,
  zParticipantAppTag,
  zSingleRegistrationTag,
  zSiteTag,
  zSurveysTag,
  zTicketingTag
])

export const zSupportRequestForm = z.object({
  attachments: zAttachments,
  description: z.string(),
  preferredFeedbackMethod: zPreferredFeedbackMethod,
  urgencyLevel: zUserUrgencyLevel,
  tag: zTag.optional()
})

export type UserUrgencyLevel = z.infer<typeof zUserUrgencyLevel>
export type PreferredFeedbackMethod = z.infer<typeof zPreferredFeedbackMethod>
export type SupportRequestForm = z.infer<typeof zSupportRequestForm>
export type Attachments = z.infer<typeof zAttachments>
export type Attachment = z.infer<typeof zAttachment>
export type Tag = z.infer<typeof zTag>

export type SupportRequest = {
  attachments: Attachments
  description: string
  eventId?: string
  preferredFeedbackMethod: PreferredFeedbackMethod
  urgencyLevel: UserUrgencyLevel
  userAgent: string
  tag?: Tag
}

export type TagCategory =
  | 'Campaigns'
  | 'Certificates'
  | 'Check-in'
  | 'Domain name'
  | 'Other'
  | 'Participant app'
  | 'Registration (group)'
  | 'Registration (single)'
  | 'Site'
  | 'Surveys'
  | 'Ticketing'
