import store from '@/store'
import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import { i18n } from '@/utils/i18n'

const declinedMeetingRequestTriggerUtil: TriggerUtil = {
  kind: TriggerKind.DeclinedMeetingRequest as const,
  label: 'DECLINED_MEETING_REQUEST' as const,
  icon: 'user-unfollow-line' as const,
  nominativeTags: [
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_FULLNAME'), value: '*|MEETING_TARGET_FULLNAME|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_ORGANIZATION'), value: '*|MEETING_TARGET_ORGANIZATION|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_JOB'), value: '*|MEETING_TARGET_JOB|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_DECLINE_REASON'), value: '*|MEETING_DECLINE_REASON|*' }
  ] as const,
  isAllowed(): boolean {
    return store.state.event.rights.features.eventApp.matchmaking
  }
}

export default declinedMeetingRequestTriggerUtil
