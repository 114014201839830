import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import { BoxProperties } from '../../../types/PageElement'
import TextInfiniteScrollElementBase, { TextInfiniteScrollProperties } from '../TextInfiniteScrollElementBase'

export class TextInfiniteScroll1Properties extends TextInfiniteScrollProperties {
  text: string = ''
  textColor: string = ''
}

export default class TextInfiniteScrollElement extends TextInfiniteScrollElementBase {
  static create({ palette }: ElementCreationContext): TextInfiniteScrollElement {
    const textInfiniteScroll = new TextInfiniteScrollElement()

    const backgroundColor = palette.mostVividColor

    textInfiniteScroll.box ??= new BoxProperties()
    textInfiniteScroll.box.backgroundColor = backgroundColor.paletteCode
    textInfiniteScroll.properties.textColor = palette.getMostReadableColor(backgroundColor.color).paletteCode
    textInfiniteScroll.properties.text = new LoremIpsum().generateSentences(1)

    return textInfiniteScroll
  }

  type = `prefab-text-infinite-scroll-1` as const
  @Type(() => TextInfiniteScroll1Properties)
  properties: TextInfiniteScroll1Properties = new TextInfiniteScroll1Properties()

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 60
  }

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TEXT_INFINITE_SCROLL')
  }
}
