const IMAGE_EXTENSIONS: Set<string> = new Set(['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp', 'ico'])
const IMAGE_SERVING_DOMAINS: Set<string> = new Set(['images.unsplash.com'])

export function isUrlReturningAnImage(url: string): boolean {
  const urlObject = new URL(url)

  if (IMAGE_SERVING_DOMAINS.has(urlObject.hostname)) {
    return true
  } else {
    const ext = urlObject.pathname.split('.').pop()

    if (ext) {
      return IMAGE_EXTENSIONS.has(ext)
    } else {
      return false
    }
  }
}

export function moToOctet(mo: number): number {
  return Math.round(mo * 1e6)
}

export function octetsToText(octets: number): string {
  const sizeScale: number = Math.floor(Math.log(octets) / Math.log(1000))
  const displayedNumber: number = octets / Math.pow(1000, sizeScale)

  switch (sizeScale) {
    case 0:
      return `${displayedNumber}o`
    case 1:
      return `${displayedNumber}Ko`
    case 2:
      return `${displayedNumber}Mo`
    case 3:
      return `${displayedNumber}Go`
    default:
      return ''
  }
}
