export const dashboardMenuSection = {
  key: 'dashboard',
  titleSlug: 'EVENT_MENU_AUDIENCE_DASHBOARD_LINK',
  iconName: 'dashboard-3-line',
  routeName: 'event.dashboard',
  items: [
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_DASHBOARD_LINK',
      routeName: 'event.dashboard'
    }
  ]
} as const
