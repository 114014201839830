


















import { Vue, Component, Prop } from 'vue-property-decorator'

import type Guest from '@/models/Guest'
import type { GuestField } from '@/models/Event/GuestField'
import type { SortGroup } from '@/models/commons/sort'

@Component({
  components: {}
})
export default class CustomFieldSection extends Vue {
  @Prop({ required: true }) readonly customFieldsSection!: SortGroup
  @Prop({ required: true }) readonly guest!: Guest

  get guestFields(): GuestField[] {
    const guestFields: GuestField[] = []

    for (const fieldId of this.customFieldsSection.items) {
      const guestField: GuestField = this.$store.getters.guestFieldKeyMap[fieldId]
      guestFields.push(guestField)
    }

    return guestFields
  }

  get fieldNamesString(): string {
    return this.guestFields.map((field: GuestField) => field.name).join(', ')
  }
}
