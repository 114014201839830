import type { AxiosResponse } from 'axios'
import type { EventId } from '@/models/Event'
import type { Event } from '@/models/Event'
import type { FieldType, GuestId } from '@/models/Guest'
import Guest from '@/models/Guest'
import type { GuestFieldKey } from '@/models/Event/GuestField'
import type { AnonymousFilter } from '@/models/Event/Filter'
import type Filter from '@/models/Event/Filter'
import { plainToClass } from 'class-transformer'
import axios from 'axios'
import type InvitationStatus from '@/models/Guest/InvitationStatus'

export async function populateTestGuests(eventId: EventId): Promise<Guest[]> {
  const { data: guests } = await axios.post<Guest[]>(`backoffice/events/${eventId}/populateTestGuests`)
  return guests.map((rawGuest) => plainToClass(Guest, rawGuest))
}

export interface TargetedGuestsObj {
  filter: Filter | AnonymousFilter | null
  search: string | null
  searchFields: GuestFieldKey[]
  selectionData: {
    selectedAll: boolean
    selectedGuests: GuestId[]
    unselectedGuests: GuestId[]
  }
}

export enum ModificationChoice {
  Modify = 'modify',
  Delete = 'delete',
  SetToTrue = 'true',
  SetToFalse = 'false'
}

export interface GroupModificationDescriptor {
  choices: Record<GuestFieldKey, ModificationChoice>
  values: Record<GuestFieldKey, FieldType>
}

export interface GroupEditionData {
  modifications: GroupModificationDescriptor
  targetedGuestsObj: TargetedGuestsObj
}

export function groupEditGuests(editionData: GroupEditionData, eventId: EventId): Promise<AxiosResponse<void>> {
  return axios.post<void>(`backoffice/events/${eventId}/guests/group-edit`, editionData)
}

export interface GetGuestsPayload {
  guests: Guest[]
  totalCount: number
  page: number
  limit: number
  skip: number
}

export interface GetGuestsParams {
  filter?: AnonymousFilter | Filter
  limit?: number
  search?: string
  searchFields?: GuestFieldKey[]
  skip?: number
  projection?: Record<string, number>
  sort?: {
    key: GuestFieldKey
    descending: boolean
  }
}

export async function doEventHasGuests(eventId: string) {
  return getGuests(eventId).then(({ guests }) => guests.length > 0)
}

export function getGuests(eventId: EventId, params: GetGuestsParams = {}): Promise<GetGuestsPayload> {
  return axios
    .get<GetGuestsPayload>(`backoffice/${eventId}/guests.json`, {
      params
    })
    .then((res) => {
      return {
        ...res.data,
        guests: res.data.guests.map((rawGuest) => plainToClass(Guest, rawGuest))
      }
    })
}

export async function getGuestById(eventId: EventId, guestId: GuestId): Promise<Guest> {
  const res = await axios.get<Guest>(`backoffice/events/${eventId}/guests/${guestId}`)
  return plainToClass(Guest, res.data)
}

export async function createGuest(guest: Partial<Guest>, eventId: EventId): Promise<Guest> {
  return (await axios.post<Guest>(`backoffice/events/${eventId}/guests`, guest)).data
}

export async function updateGuest(guest: Partial<Guest>, eventId: EventId): Promise<Guest> {
  return (await axios.put<Guest>(`backoffice/events/${eventId}/guests/${guest._id}`, guest)).data
}

export function deleteGuest(
  eventId: EventId,
  guestId: GuestId,
  deleteAccompanists: boolean
): Promise<AxiosResponse<void>> {
  return axios.delete<void>(`backoffice/events/${eventId}/guests/${guestId}?deleteAccompanists=${deleteAccompanists}`)
}

export interface DeleteMultipleGuestsParams extends TargetedGuestsObj {
  deleteAccompanists: boolean
}

export function deleteGuests(eventId: EventId, params: DeleteMultipleGuestsParams): Promise<AxiosResponse<void>> {
  return axios.delete<void>(`backoffice/events/${eventId}/guests`, {
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    data: params
  })
}

export function deleteAllGuests(eventId: EventId): Promise<AxiosResponse<{ savedEvent: Event }>> {
  return axios.delete<{ savedEvent: Event }>(`backoffice/event/${eventId}/allGuests`)
}

export type InvitationStatusStats = Partial<Record<InvitationStatus, number>>

export function getInvitationStatusStats(eventId: EventId, ignoreCache: boolean): Promise<InvitationStatusStats> {
  return axios
    .get<InvitationStatusStats>(`events/${eventId}/dashboard/countGuestsByInvitationStatus`, {
      params: { ignoreCache }
    })
    .then(({ data }) => data)
}
