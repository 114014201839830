import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export const segmentRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'eventSettings/filters',
    name: 'event.filters',
    component: () => import('./views/Segments.vue'),
    meta: {
      name: 'FACTORY_SEGMENTS',
      description: 'FACTORY_SEGMENTS_DESC',
      keywords: 'FACTORY_SEGMENTS_KEYWORDS',
      iconName: 'filter-2-line',
      searchable: true,
      supportLink: {
        supportPostId: '27000046406',
        slug: 'LEARN_SEGMENTS'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.filters.length > 0
        }
      }
    }
  }
] as const
