











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DigiOverlay extends Vue {
  @Prop({ default: true }) readonly active!: boolean
  @Prop({ default: true, type: Boolean }) readonly clickable!: boolean
  @Prop({ default: 0.5 }) readonly overlayOpacity!: number

  get classes() {
    return {
      'digi-overlay--active': this.active,
      'digi-overlay--clickable': this.clickable
    }
  }

  get styles() {
    return {
      '--foreground-color-opacity': this.overlayOpacity
    }
  }
}
