











import { Component, Vue } from 'vue-property-decorator'
import DigiTitleCard from './DigiTitleCard.vue'

@Component({
  components: { DigiTitleCard }
})
export default class DigiTipCard extends Vue {}
