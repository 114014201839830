import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import ActionElementBase, { ActionProperties } from '../ActionElementBase'

import store from '@/store'

import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class Action1Properties extends ActionProperties {
  cta: CtaProperties = defaultCtaProperties()
  title: string = ''
  mainText: string = ''
  titleColor: string = ''
  textColor: string = ''
  blockColor: string = ''
}

export class Action1Element extends ActionElementBase {
  static create({ palette }: ElementCreationContext): Action1Element {
    const action = new Action1Element()

    action.properties.title = store.state.event.event?.label || i18n.t('AN_INSPIRING_TITLE')
    action.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 1 }
    }).generateParagraphs(1)
    action.properties.cta.label = i18n.t('REGISTRATION_CTA_BTN_LABEL')

    action.properties.blockColor = palette.mostVividColor.paletteCode

    action.properties.titleColor = palette.getMostReadableColor(palette.darkestColor.color).paletteCode
    action.properties.textColor = palette.getMostReadableColor(palette.darkestColor.color).paletteCode

    action.properties.cta.color = palette.darkestColor.paletteCode

    return action
  }

  type = `prefab-action-1` as const

  @Type(() => Action1Properties)
  properties: Action1Properties = new Action1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_ACTION')
  }
}
