import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'

export class Media3Properties extends PrefabPropertiesBase {
  title: string = ''
  mainText: string = new LoremIpsum(
    {
      wordsPerSentence: { min: 3, max: 8 },
      sentencesPerParagraph: { min: 2, max: 3 }
    },
    undefined,
    `\n\n`
  ).generateParagraphs(2)
  tagText: string = ''
  imageUrls: string[] = ['', '', '']

  titleColor: string = ''
  mainTextColor: string = ''
  tagTextColor: string = ''
}

export default class Media3Element extends MediaElementBase {
  static create({ palette }: ElementCreationContext): Media3Element {
    const media3 = new Media3Element()
    const darkestColor = palette.darkestColor.paletteCode

    media3.properties.title = i18n.t('AN_INSPIRING_TITLE')
    media3.properties.tagText = i18n.t('SECTION_NAME')

    media3.properties.titleColor = darkestColor
    media3.properties.mainTextColor = darkestColor
    media3.properties.tagTextColor = darkestColor

    return media3
  }

  type = `prefab-media-3` as const

  @Type(() => Media3Properties)
  properties: Media3Properties = new Media3Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_IMAGES3')
  }
}
