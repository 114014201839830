import type Guest from '@/models/Guest'
import type { Event } from '@/models/Event'
import type { ScheduledItemId, ScheduledItemKind } from '@/models/Guest/Planning/ScheduledItem'
import { getMandatoryGroupActivitiesOfGuest } from './groupActivity'
import type { DateRange, EventActivityId, GroupActivity, MatchmakingSession } from '@/models/Event/modules/Programme'
import { i18n } from '@/utils/i18n'
import type GroupActivitySchedule from '@/models/Guest/Planning/ScheduledItem/GroupActivity'
import type MeetingSchedule from '@/models/Guest/Planning/ScheduledItem/Meeting'
import type Unavailability from '@/models/Guest/Planning/ScheduledItem/Unavailability'

export * as GroupActivity from './groupActivity'
export * as MatchmakingSession from './matchmakingSession'
export {
  TimeSlot,
  getAvailableTimeSlotsForMeeting,
  MatchmakingSessionStats,
  fetchMatchmakingSessionStats,
  MeetingStatus,
  cancelMeeting,
  removeNonMeetingScheduledItem
} from './matchmakingSession'

type PlanningItemKind = ScheduledItemKind | 'unavailability'

type GuestPlanningIteration =
  | {
      kind: 'groupActivity'
      programmeData: GroupActivity
      scheduleData: GroupActivitySchedule | null
    }
  | {
      kind: 'meeting'
      programmeData: MatchmakingSession
      scheduleData: MeetingSchedule
    }
  | {
      kind: 'unavailability'
      scheduleData: Unavailability
    }

function* iterateGuestPlanning(guest: Guest, event: Event): IterableIterator<GuestPlanningIteration> {
  for (const activity of getMandatoryGroupActivitiesOfGuest(guest, event)) {
    yield {
      kind: 'groupActivity',
      programmeData: activity,
      scheduleData: null
    }
  }

  if (guest.planning) {
    for (const scheduledItem of guest.planning.scheduledItems) {
      if (scheduledItem.kind === 'unavailability') {
        yield {
          kind: 'unavailability',
          scheduleData: scheduledItem
        }
      } else {
        if (scheduledItem.kind === 'meeting') {
          const activityDefinition = event.modules_data.programme.getMatchmakingSessionById(
            scheduledItem.eventActivityId
          )

          yield {
            kind: 'meeting',
            programmeData: activityDefinition,
            scheduleData: scheduledItem
          }
        } else if (scheduledItem.kind === 'groupActivity') {
          const activityDefinition = event.modules_data.programme.getGroupActivityById(scheduledItem.eventActivityId)

          yield {
            kind: 'groupActivity',
            programmeData: activityDefinition,
            scheduleData: scheduledItem
          }
        }
      }
    }
  }
}

interface GuestPlanningItemBase {
  kind: PlanningItemKind
  dateRange: DateRange
  title: string
  description?: string
}

interface GroupActivityPlanningItem extends GuestPlanningItemBase {
  kind: 'groupActivity'
  eventActivityId: EventActivityId
  scheduledItemId: ScheduledItemId | null
  hasVisio: boolean
  visioLink?: string
  isVisioEmbededInDigitevent: boolean
}

export interface MeetingPlanningItem extends GuestPlanningItemBase {
  kind: 'meeting'
  eventActivityId: EventActivityId
  scheduledItemId: ScheduledItemId
  isCanceled: boolean
  cancellationReason?: string
  includedBreakDuration: number
  location?: string
  hasVisio: boolean
}

interface UnavailabilityPlanningItem extends GuestPlanningItemBase {
  kind: 'unavailability'
  scheduledItemId: ScheduledItemId
}

export type GuestPlanningItem = GroupActivityPlanningItem | MeetingPlanningItem | UnavailabilityPlanningItem

export function computeGuestPlanning(guest: Guest, event: Event): GuestPlanningItem[] {
  const planning: GuestPlanningItem[] = []

  for (const planInfo of iterateGuestPlanning(guest, event)) {
    switch (planInfo.kind) {
      case 'groupActivity':
        planning.push({
          kind: 'groupActivity',
          description: planInfo.programmeData.description,
          dateRange: planInfo.programmeData.dateRange,
          eventActivityId: planInfo.programmeData._id,
          hasVisio: planInfo.programmeData.hasVisio,
          isVisioEmbededInDigitevent: planInfo.programmeData.isVisioEmbededInDigitevent,
          scheduledItemId: planInfo.scheduleData ? planInfo.scheduleData._id : null,
          title: planInfo.programmeData.title,
          visioLink: planInfo.programmeData.visioLink
        })
        break
      case 'meeting': {
        const roleInMeeting = planInfo.scheduleData.applicant._guest === guest._id ? 'applicant' : 'target'
        const counterpartRole = roleInMeeting === 'applicant' ? 'target' : 'applicant'

        planning.push({
          kind: 'meeting',
          location: planInfo.scheduleData.location,
          description: planInfo.scheduleData[counterpartRole].motivation,
          cancellationReason: planInfo.scheduleData.cancellationReason,
          dateRange: planInfo.scheduleData.dateRange,
          eventActivityId: planInfo.scheduleData.eventActivityId,
          hasVisio: planInfo.programmeData.hasVisio,
          includedBreakDuration: planInfo.programmeData.includedBreakDuration,
          isCanceled: planInfo.scheduleData.isCanceled,
          scheduledItemId: planInfo.scheduleData._id,
          title: planInfo.scheduleData[counterpartRole].fullName
        })
        break
      }
      case 'unavailability':
        planning.push({
          kind: 'unavailability',
          description: planInfo.scheduleData.comment,
          title: i18n.t('UNAVAILABILITY'),
          dateRange: planInfo.scheduleData.dateRange,
          scheduledItemId: planInfo.scheduleData._id
        })
        break
    }
  }

  // let's sort the planning
  planning.sort((a, b) => a.dateRange[0].valueOf() - b.dateRange[0].valueOf())

  return planning
}

export function countGuestGroupActivities(guest: Guest, event: Event): number {
  let total = 0

  for (const planInfo of iterateGuestPlanning(guest, event)) {
    if (planInfo.kind === 'groupActivity') ++total
  }

  return total
}

export function countGuestMeetings(guest: Guest, event: Event): number {
  let total = 0

  for (const planInfo of iterateGuestPlanning(guest, event)) {
    if (planInfo.kind === 'meeting' && !planInfo.scheduleData.isCanceled) ++total
  }

  return total
}

export function getGuestPlanningLength(guest: Guest, event: Event): number {
  return countGuestGroupActivities(guest, event) + countGuestMeetings(guest, event)
}
