







































import { Component, Prop, Watch } from 'vue-property-decorator'
import type { SponsorId } from '@/models/Event/modules/Programme/Sponsor'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type {
  MultiSelectorGroup,
  MultiSelectorOption
} from '@/components/ui/multiItemSelector/DigiMultiItemSelector.vue'
import DigiMultiItemSelector from '@/components/ui/multiItemSelector/DigiMultiItemSelector.vue'
import { DigiLimitedList } from '@/components/ui'
import type Sponsor from '@/models/Event/modules/Programme/Sponsor'
import { DigiButton } from '@/components/ui/actions'
import { DigiLink } from '@/components/ui/actions'
import { isSortGroup } from '@/utils/sort'
import { cloneDeep } from 'lodash'

@Component({ components: { DigiLink, DigiButton, DigiLimitedList, DigiMultiItemSelector, VSelect } })
export default class SponsorSelector extends Vue {
  @Prop({ required: true }) readonly selectedSponsorIds!: SponsorId[]

  selectorOpened = false

  localSponsorIds: SponsorId[] = []

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  @Watch('selectedSponsorIds', { immediate: true })
  onChange() {
    this.localSponsorIds = cloneDeep(this.selectedSponsorIds).filter(
      (id) => this.mappedAffiliatedSponsors.find((s) => s._id === id) !== undefined
    )
  }

  get sponsorGroups() {
    return this.storeEvent.modules_data.programme.sponsorsSort.filter(isSortGroup)
  }

  get multiSelectorGroups(): MultiSelectorGroup[] {
    return this.sponsorGroups.map((group) => ({
      id: group._id,
      name: group.name
    }))
  }

  get options(): MultiSelectorOption[] {
    const sponsors = this.storeEvent.modules_data.programme.sponsors
    if (!sponsors) return []
    const findSponsorGroup = (sponsorId: SponsorId) => {
      const group = this.sponsorGroups.find((group) => group.items.includes(sponsorId))
      if (!group) return undefined
      return group._id
    }
    return sponsors.map((sponsor) => ({
      id: sponsor._id,
      text: sponsor.name,
      groupId: findSponsorGroup(sponsor._id)
    }))
  }

  get mappedAffiliatedSponsors(): Sponsor[] {
    const sponsors = this.storeEvent.modules_data.programme.sponsors
    if (!sponsors) return []
    const affiliatedSponsors: Sponsor[] = []
    for (const sponsor of this.selectedSponsorIds) {
      const foundSponsor = sponsors.find((s) => s._id === sponsor)
      if (foundSponsor) affiliatedSponsors.push(foundSponsor)
    }
    return affiliatedSponsors
  }

  save() {
    this.selectorOpened = false
    this.$emit('update', this.localSponsorIds)
  }
}
