import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'
import type { EventId } from '../Event'
import type { CheckinPointId } from '../Event/modules/Checkin'
import type { FormId } from '../Event/Form'
import type { MongoDb } from '../MongoDbTools'
import type InvitationStatus from './InvitationStatus'
import GuestMessage from './Message'
import Order from './Order'
import GuestPlanning from './Planning'
import type { GuestTicket } from './Ticket'
import type { EventLangCode } from '@/models/Event/Localized'

export type GuestId = MongoDb.ObjectId

export type GuestSource =
  | 'back'
  | 'extra'
  | 'excel'
  | `excel::${number}/${number}/${number} ${number}:${number}`
  | 'register'

export type FieldType = string | number | boolean | Date | string[] | null

export class SubmittedForm {
  formId!: FormId
  @TransformDateStringToDateObject()
  lastSubmissionDate!: Date
  userAgent!: string
}

enum CheckpointType {
  GroupActivity = 'groupActivity'
}

export class WebsiteCheck {
  @TransformDateStringToDateObject()
  public checkDate!: Date
  public checkpointId!: MongoDb.ObjectId
  public checkpointType?: CheckpointType
}

export class GuestCheckin {
  @TransformDateStringToDateObject()
  public date!: Date
  public checkinPointId!: CheckinPointId
  public workerName?: string
  public signatureImageURL?: string
}

export interface ScannedGuest {
  guestId: string
  addedDate: Date
  qualifications: Record<string, string | number | boolean>
}

export interface NetworkingForGuest {
  scannedGuests?: ScannedGuest[]
}

export default class Guest {
  _id!: GuestId
  firstname?: string
  name!: string
  mobilephone?: string
  guestemail?: string
  photo?: string
  job?: string
  organization?: string
  unsubscribe?: boolean
  registerLanguage?: EventLangCode

  @TransformDateStringToDateObject()
  creationDate!: Date
  invitationStatus!: InvitationStatus
  source!: GuestSource
  networking?: NetworkingForGuest

  @Type(() => Order)
  orders: Order[] = []
  @Type(() => GuestPlanning)
  planning?: GuestPlanning
  @Type(() => GuestMessage)
  messages?: GuestMessage[]

  tickets?: GuestTicket[]

  parentEvent!: EventId

  slaveRegistrationOf?: GuestId
  masterRegistrationOf?: GuestId[]

  registrationUrlQuery?: string

  @Type(() => SubmittedForm)
  submittedForms?: SubmittedForm[]

  @Type(() => WebsiteCheck)
  checks?: WebsiteCheck[]

  @Type(() => GuestCheckin)
  checkins?: GuestCheckin[];

  [k: string]: unknown
}
