









import { Component, Prop } from 'vue-property-decorator'
import { type AnyAction } from '@/models/Event/RulesData/Rule/Action'
import Vue from 'vue'
import { MessageType } from '../types'
import SendMessageToGuest from '../sendMessageToGuest.vue'
@Component({ components: { SendMessageToGuest }})
export default class SendSmsToGuestSettings extends Vue {
  @Prop({ required: true }) readonly action!: AnyAction

  messageType: MessageType = MessageType.Sms

  handleUpdate(action: AnyAction, messageId: string) {
    this.$emit('update', { ...action, ['messageTemplateId']: messageId })
  }
}
