import { Exclude } from 'class-transformer'
import { PageElementBase } from '../types'

export class PrefabPropertiesBase {
  removeBottomSpacing: boolean = false
}

export abstract class PrefabElementBase extends PageElementBase {
  abstract type: `prefab-${string}`

  @Exclude()
  isFullPrefab: boolean = false
  canHaveChildren = false as const

  properties: PrefabPropertiesBase = new PrefabPropertiesBase()
}
