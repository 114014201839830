import type { AnyAction, SendSMStoGuestAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import type { ActionUtil } from '@/features/rules/RuleEdition/types'
import Settings from './Settings.vue'
import store from '@/store'
import type { Event } from '@/models/Event'

function isSendSMSToGuestAction(action: AnyAction): action is SendSMStoGuestAction {
  if (action.kind !== ActionKind.SendSMSToGuest) {
    throw new Error('SendSmsToGuestactionUtil can only manage action of kind SendSMSToGuest')
  }

  return true
}

const sendSMStoGuestActionUtil: ActionUtil = {
  kind: ActionKind.SendSMSToGuest as const,
  label: 'SEND_SMS_TO_GUEST' as const,
  icon: 'smartphone-line' as const,
  settings: Settings,

  validator(action: AnyAction): boolean {
    if (isSendSMSToGuestAction(action)) {
      const event: Event | undefined = store.state.event?.event

      return Boolean(
        action.messageTemplateId &&
          event?.messages.templates.some((template) => template._id === action.messageTemplateId)
      )
    } else {
      return false
    }
  }
}

export default sendSMStoGuestActionUtil
