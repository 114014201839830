import { Type } from 'class-transformer'
import Vue from 'vue'
import type { FilterId } from '../../Filter'
import { $enum } from 'ts-enum-util'
import type { EventLangCode } from '../../Localized'
import { LocalizedPrimitive } from '../../Localized'
import { ErrorWithExtras } from '@/utils/errorTypes'
import type { Website } from '@/models/Event/modules/Website'

function generatePseudoUniqueId() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export interface TitleContext {
  lang: EventLangCode
}

export interface NavItemRemovedContext {
  nav: NavData
}

export type NavItem<ItemType extends NavItemType = NavItemType> = ItemType | `${ItemType}:${string}`

export interface ICanBeInNavigation<ItemType extends NavItemType = NavItemType> {
  idForNavigation: NavItem<ItemType>
  getTitleForNav(context: TitleContext, defaultTitle?: string): string
  setTitleForNav(newTitle: string, context: TitleContext): void
  onRemovedFromNav?(context: NavItemRemovedContext): void
}

export interface ICanLocateNavItems {
  locateNavItem<ItemType extends NavItemType>(item: NavItem<ItemType>): ICanBeInNavigation<ItemType>
}

export interface CreateLink {
  name: string
  destination: string
  newTab: boolean
  segmentIds: FilterId[]
}

export class Link implements ICanBeInNavigation<NavItemType.Link> {
  constructor() {}

  linkId = generatePseudoUniqueId()
  destination = 'https://'
  newTab = false
  segmentIds: FilterId[] = []

  @Type(() => LocalizedPrimitive)
  name: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  makeNameForLang(fromLang: EventLangCode, toLang: EventLangCode) {
    if (this.name[fromLang] && !this.name[toLang]) {
      this.name[toLang] = `${this.name[fromLang]} (${toLang.toUpperCase()})`
    }
  }

  //#region Nav integration
  get idForNavigation(): NavItem<NavItemType.Link> {
    return makeNavItem(NavItemType.Link, this.linkId)
  }
  getTitleForNav({ lang }: TitleContext): string {
    return this.name[lang] ?? ''
  }
  setTitleForNav(newTitle: string, { lang }: TitleContext): void {
    Vue.set(this.name, lang, newTitle)
  }
  //#endregion
}

export class Menu implements ICanBeInNavigation {
  constructor(name: string, website: Website, eventLanguageCode: EventLangCode) {
    if (website) {
      this.name[eventLanguageCode] = name
      website.websiteTranslations.forEach((lang) => {
        this.makeNameForLang(eventLanguageCode, lang)
      })
    }
  }

  menuId = generatePseudoUniqueId()
  contents: NavItem[] = []

  @Type(() => LocalizedPrimitive)
  name: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  makeNameForLang(fromLang: EventLangCode, toLang: EventLangCode) {
    if (this.name[fromLang] && !this.name[toLang]) {
      this.name[toLang] = `${this.name[fromLang]} (${toLang.toUpperCase()})`
    }
  }

  //#region Nav integration
  get idForNavigation(): NavItem<NavItemType.Menu> {
    return makeNavItem(NavItemType.Menu, this.menuId)
  }
  getTitleForNav({ lang }: TitleContext): string {
    return this.name[lang] ?? ''
  }
  setTitleForNav(newTitle: string, { lang }: TitleContext): void {
    Vue.set(this.name, lang, newTitle)
  }
  onRemovedFromNav(context: NavItemRemovedContext): void {
    context.nav.navItems.push(...this.contents)
    context.nav.menus = context.nav.menus.filter((menu) => menu !== this)
  }
  //#endregion
}

export type NavContents = NavItem[]

export enum NavItemType {
  Page = 'page',
  Menu = 'menu',
  Link = 'link'
}

export function makeNavItem<ItemType extends NavItemType>(type: ItemType, identifier?: string): NavItem<ItemType> {
  if (identifier !== undefined) {
    return `${type}:${identifier}` as NavItem<ItemType>
  } else {
    return type
  }
}

export function tryBreakdownNavItem(navItem: string): { type: string; identifier?: string } {
  const [typeString, identifier] = navItem.split(':', 2)

  if (!typeString) throw new ErrorWithExtras('Invalid nav item: Item does not have a type indicator.', { navItem })

  return {
    type: typeString,
    identifier
  }
}

export function isNavItem(navItem: string): navItem is NavItem {
  return $enum(NavItemType).isValue(tryBreakdownNavItem(navItem).type)
}

export function breakdownNavItem<ItemType extends NavItemType = NavItemType>(
  navItem: NavItem<ItemType>
): { type: ItemType; identifier?: string } {
  const { type, identifier } = tryBreakdownNavItem(navItem)

  return {
    type: $enum(NavItemType).asValueOrThrow(type) as ItemType,
    identifier
  }
}

/**
 * This class contains the data pertaining to organization of a navbar in a website
 */
export default class NavData {
  /**
   * The navbar is divided in slots defined in {@link NAV_SLOT_NAMES}
   */
  navItems: NavItem[] = []

  get totalElementCount(): number {
    let total = this.navItems.length

    for (const menu of this.menus) {
      total += menu.contents.length
    }

    return total
  }

  get totalRootElementCount(): number {
    return this.navItems.length
  }
  get isAtLeastOnePageEltAtRoot(): boolean {
    return this.navItems.some((navItem) => breakdownNavItem(navItem).type === NavItemType.Page)
  }

  get firstPage(): NavItem<NavItemType.Page> | undefined {
    return this.navItems.find(
      (navItem) => breakdownNavItem(navItem).type === NavItemType.Page
    ) as NavItem<NavItemType.Page>
  }
  // #endregion

  // #region Methods to manage the content of the navbar

  setElementVisibility(visibility: boolean, element: ICanBeInNavigation<NavItemType>): void {
    if (visibility && !this.isElementInNav(element)) {
      this.navItems.push(element.idForNavigation)
    } else if (!visibility && this.isElementInNav(element)) {
      this.removeElementFromNav(element)
    }
  }

  /**
   * Reports presence or absence of a reference to the specified element anywhere in the navbar.
   * @param element The element to locate.
   */
  isElementInNav(element: ICanBeInNavigation): boolean {
    return (
      this.navItems.includes(element.idForNavigation) ||
      this.menus.some((menu) => menu.contents.includes(element.idForNavigation))
    )
  }
  /**
   * Wipes all references to an element from the navbar.
   * @param element The element to remove.
   * @returns `true` if the element has been found and removed at least once, `false` otherwise.
   */
  removeElementFromNav(element: ICanBeInNavigation): void {
    let removed = false
    const newNavItems = this.navItems.filter((item) => {
      return item !== element.idForNavigation
    })
    if (newNavItems.length < this.navItems.length) {
      removed = true
    }
    this.navItems = newNavItems
    for (const menu of this.menus) {
      const newMenuContent = menu.contents.filter((item) => item !== element.idForNavigation)
      if (newMenuContent.length < menu.contents.length) {
        removed = true
      }
      menu.contents = newMenuContent
    }
    if (removed && element.onRemovedFromNav) {
      element.onRemovedFromNav({ nav: this })
    }
  }

  cleanupInvalidItems(): void {
    this.navItems = this.navItems.filter(isNavItem)

    for (const menu of this.menus) {
      menu.contents = menu.contents.filter(isNavItem)
    }
  }

  cleanupDeadItems(locator: ICanLocateNavItems): void {
    // Given that this function might be called on the fly from the console, this extra bit of security should be a welcome one.
    // If the locator was nil, all attempts to locate would throw, potentially wiping all the nav in the process.
    if (!locator) throw new Error('This function requires an item locator, none provided.')

    function itemIsLive(item: NavItem): boolean {
      try {
        return Boolean(locator.locateNavItem(item))
      } catch {
        return false
      }
    }

    this.navItems = this.navItems.filter(itemIsLive)

    for (const menu of this.menus) {
      menu.contents = menu.contents.filter(itemIsLive)
    }
  }

  // #endregion

  // #region Menu management

  @Type(() => Menu)
  menus: Menu[] = []

  private createMenu(name: string, website: Website, lang: EventLangCode): Menu {
    const newMenu = new Menu(name, website, lang)
    this.menus.push(newMenu)
    return newMenu
  }
  addMenu(name: string, website: Website, lang: EventLangCode): Menu {
    const newMenu: Menu = this.createMenu(name, website, lang)
    this.navItems.push(newMenu.idForNavigation)
    return newMenu
  }
  getMenuById(id: string): Menu {
    const menu = this.menus.find((menu) => menu.menuId === id)
    if (!menu) {
      throw new ErrorWithExtras(`No menu found with ID ${id}.`, { menuId: id })
    }
    return menu
  }

  // #endregion

  // #region Link management

  private createLink(website: Website, eventLanguageCode: EventLangCode, link: CreateLink): Link {
    const newLink = new Link()
    newLink.name[eventLanguageCode] = link.name
    website.websiteTranslations.forEach((lang) => {
      newLink.makeNameForLang(eventLanguageCode, lang)
    })
    newLink.destination = link.destination
    newLink.newTab = link.newTab
    newLink.segmentIds = link.segmentIds
    this.links.push(newLink)
    return newLink
  }
  deleteLink(linkId: Link['linkId']) {
    this.removeElementFromNav(this.getLinkById(linkId))
    this.links = this.links.filter((link) => {
      return link.linkId !== linkId
    })
  }
  addLink(website: Website, lang: EventLangCode, createLink: CreateLink): Link {
    const link = this.createLink(website, lang, createLink)
    this.navItems.push(link.idForNavigation)
    return link
  }
  getLinkById(id: string) {
    const link = this.links.find((link) => link.linkId === id)
    if (!link) {
      throw new ErrorWithExtras(`No link found with ID ${id}.`, { linkId: id })
    }
    return link
  }

  @Type(() => Link)
  links: Link[] = []
  // #endregion
}
