import { ElementTypeDictionary } from '../../types'
import { Timeline1Element } from './timeline1'
import { SessionListElement } from './sessionsPrefab'

export const programmeDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Timeline1Element,
    renderer: () => import('./timeline1/PrefabTimeline1.vue'),
    properties: [
      {
        type: 'color',
        label: 'TEXT_COLOR',
        key: 'properties.textColor',
        toContrastWith: 'box.backgroundColor'
      },
      {
        type: 'color',
        label: 'WEBSITE_BLOCK_TIMELINE_ACCENT_COLOR',
        key: 'properties.accentColor',
        toContrastWith: 'box.backgroundColor'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TIMELINE',
    previewImageLink: require(`@/assets/img/prefab-previews/timeline1.jpg`)
  })
  .addType({
    dataClass: SessionListElement,
    renderer: () => import('./sessionsPrefab/PrefabSessionsList.vue'),
    properties: [
      {
        type: 'color',
        label: 'WEBSITE_BLOCK_PROGRAMME_SESSION_OUTER_TEXT_COLOR',
        key: 'properties.outerTextColor',
        toContrastWith: 'box.backgroundColor'
      },
      {
        type: 'color',
        label: 'WEBSITE_BLOCK_PROGRAMME_SESSION_INNER_BG_COLOR',
        key: 'properties.innerBackgroundColor',
        toContrastWith: 'properties.innerTextColor'
      },
      {
        type: 'color',
        label: 'WEBSITE_BLOCK_PROGRAMME_SESSION_INNER_TEXT_COLOR',
        key: 'properties.innerTextColor',
        toContrastWith: 'properties.innerBackgroundColor'
      }
    ],
    isAvailable: ({ eventRights }) => eventRights.features.eventApp.allowed,
    typeNameSlug: 'WEBSITE_BLOCK_PROGRAMME_SESSIONS',
    previewImageLink: require(`@/assets/img/prefab-previews/sessionList.jpg`)
  })
  .finish()
