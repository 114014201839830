import { Type } from 'class-transformer'
import type { FilterId } from '@/models/Event/Filter'
import type { AnyTrigger } from './Trigger'
import { TriggerKind, TriggerBase, trigger } from './Trigger'
import type { AnyAction } from './Action'
import { ActionKind, ActionBase, action } from './Action'
import type { MongoDb } from '@/models/MongoDbTools'

export type RuleId = MongoDb.ObjectId

export default class Rule {
  _id!: RuleId
  isEnabled: boolean = false
  @Type(() => TriggerBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'kind',
      subTypes: [
        { value: trigger.ParticipationDecline, name: TriggerKind.ParticipationDecline },
        { value: trigger.OrderInit, name: TriggerKind.OrderInit },
        { value: trigger.OrderPaid, name: TriggerKind.OrderPaid },
        { value: trigger.TicketGrant, name: TriggerKind.TicketGrant },
        { value: trigger.GuestRegistration, name: TriggerKind.GuestRegistration },
        { value: trigger.AcceptedMeetingRequest, name: TriggerKind.AcceptedMeetingRequest },
        { value: trigger.RescheduledMeetingRequest, name: TriggerKind.RescheduledMeetingRequest },
        { value: trigger.DeclinedMeetingRequest, name: TriggerKind.DeclinedMeetingRequest },
        { value: trigger.CanceledMeeting, name: TriggerKind.CanceledMeeting },
        { value: trigger.TargetReceivesMeetingRequest, name: TriggerKind.TargetReceivesMeetingRequest },
        { value: trigger.CheckinAnyCheckpoint, name: TriggerKind.CheckinAny },
        { value: trigger.CheckinExactCheckpoint, name: TriggerKind.CheckinExact },
        { value: trigger.SurveySubmission, name: TriggerKind.SurveySubmission },
        { value: trigger.AccompanistRegistration, name: TriggerKind.AccompanistRegistration }
      ]
    }
  })
  trigger!: AnyTrigger
  @Type(() => ActionBase, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'kind',
      subTypes: [
        { value: action.PostWebhook, name: ActionKind.PostWebhook },
        { value: action.SendEmailToAddress, name: ActionKind.SendEmailToAddress },
        { value: action.SendEmailToGuest, name: ActionKind.SendEmailToGuest },
        { value: action.SendSMStoGuest, name: ActionKind.SendSMSToGuest },
        { value: action.SendSMStoPhoneNumber, name: ActionKind.SendSMSToPhoneNumber }
      ]
    }
  })
  actions: AnyAction[] = []
  nbTimesTriggered!: number
  segmentsIds?: FilterId[] = []
  lastExecution?: Date
  lastExecutionErrors?: string[]
}
