






import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'

@Component
export default class SidePanel extends Vue {
  /**
   * Determines the side of the viewport to put a panel on
   */
  @Prop() readonly position!: 'left' | 'right'
  /**
   * If set to true, will leave room for our header.
   * If set to false, will take all the viewport's height.
   */
  @Prop() readonly topOffset!: boolean
  /**
   * Self-explanatory
   * (unit is pixels, of course)
   */
  @Prop({ default: 550 }) readonly width!: number
  /**
   * An identifier to recognize the panel by.
   *
   * This may be useful if you have several triggers to the same content.
   */
  @Prop() readonly id?: string | number
  /**
   * Request not to have a close button on the panel.
   */
  @Prop() readonly noClose!: boolean

  get internalPanelId() {
    if (this.id !== undefined) {
      return this.id
    } else {
      return this.$uuid
    }
  }

  created() {
    this.$root.$on('sidePanel::showById', this.respondToShowById)
  }

  beforeDestroy() {
    this.$root.$off('sidePanel::showById', this.respondToShowById)
    this.close(true)
  }

  open() {
    this.$root.$emit(
      'sidePanel::show',
      {
        topOffset: this.topOffset,
        position: this.position,
        width: this.width,
        noClose: this.noClose
      },
      this.internalPanelId
    )
  }

  close(force: boolean) {
    this.$root.$emit('sidePanel::hide', this.internalPanelId, force)
  }

  respondToShowById(id: SidePanel['internalPanelId']) {
    if (id === this.internalPanelId) {
      this.open()
    }
  }
}
