import postWebhook from './postWebhook'
import sendEmailToGuest from './messages/sendMessageToGuest/sendEmailToGuest'
import sendEmailToAddress from './messages/sendEmailToAddress'
import sendSmsToGuest from './messages/sendMessageToGuest/sendSmsToGuest'
import sendSmsToPhoneNumber from './messages/sendSmsToPhoneNumber'
import type { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import type { ActionUtil } from '../RuleEdition/types'

const actionUtils: Record<ActionKind, ActionUtil> = {
  sendEmailToGuest,
  sendEmailToAddress,
  sendSmsToGuest,
  sendSmsToPhoneNumber,
  postWebhook
}

export default actionUtils
