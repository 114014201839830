import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { QuoteElementBase } from '../QuoteElementBase'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import { Type } from 'class-transformer'

export class Quote2ItemProperties {
  author: string = ''
  quote: string = ''
  avatarUrl: string | null = null
  company: string = ''
}

class Quote2Properties extends PrefabPropertiesBase {
  tagText: string = ''
  tagColor: string = ''
  titleText: string = ''
  titleColor: string = ''
  quoteTextColor: string = ''
  quoteBackgroundColor: string = ''
  @Type(() => Quote2ItemProperties)
  quotes: Quote2ItemProperties[] = []
  displayAvatarInGrayscale: boolean = false
}

export default class Quote2Element extends QuoteElementBase {
  static create({ palette }: ElementCreationContext): Quote2Element {
    const quote = new Quote2Element()

    quote.properties.tagText = i18n.t('TESTIMONY')
    quote.properties.tagColor = palette.darkestColor.paletteCode

    quote.properties.titleText = i18n.t('AN_INSPIRING_TITLE')
    quote.properties.titleColor = palette.darkestColor.paletteCode
    quote.properties.quoteTextColor = palette.darkestColor.paletteCode
    quote.properties.quoteBackgroundColor = palette.lightestColor.paletteCode
    quote.properties.quotes = [new Quote2ItemProperties()]
    quote.properties.displayAvatarInGrayscale = false

    return quote
  }

  type = `prefab-quote-2` as const

  @Type(() => Quote2Properties)
  properties = new Quote2Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_QUOTE_2')
  }
}
