















import { Component, Vue } from 'vue-property-decorator'
import { DigiLink } from '@/components/ui/actions'
import { DIGITEVENT_DOCUMENTATION_LINK } from '../support'
import type { Account } from '@/models/Account'

@Component({
  components: {
    DigiLink
  }
})
export default class SuccessMessage extends Vue {
  get digiteventDocumentationLink(): string {
    return DIGITEVENT_DOCUMENTATION_LINK
  }
  get isFrenchUser(): boolean {
    const account = this.$store.state.account.account as Account
    return account.language === 'fr'
  }
}
