








import type Guest from '@/models/Guest'
import { Component, Prop } from 'vue-property-decorator'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { DigiLink } from '@/components/ui/actions'
import { getGuestAccessPassUrl } from '@/features/checkin'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { DigiLink, ContactProfileTab }
})
export default class ContactProfilePdfBadgeTab extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get accessPassURL(): string {
    if (this.isDisabled) return ''
    return getGuestAccessPassUrl(this.storeEvent._id, this.guest)
  }

  get isDisabled(): boolean {
    return !this.isPassEnabled || !this.isGuestRegistered
  }

  get isPassEnabled(): boolean {
    return this.storeEvent.modules_data.accessPass?.isEnabled ?? false
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get isGuestRegistered(): boolean {
    if (this.eventRights.features.registration.mode === 'paid') {
      return this.guest.tickets?.some((t) => !t.canceled) ?? false
    } else {
      return this.guest.invitationStatus === 'confirmed'
    }
  }

  get disabledText(): string {
    if (!this.isPassEnabled) {
      return this.$t('CONTACT_DETAILS_ACCESS_PASS_DISABLED')
    }
    if (!this.isGuestRegistered) {
      return this.$t('CONTACT_DETAILS_ACCESS_PASS_DISABLED_NOT_REGISTERED')
    }
    return ''
  }
}
