import type { DeleteContext, Padding } from '../../types/PageElement'
import { BoxProperties } from '../../types/PageElement'
import { LinearLayoutElementBase } from '../LinearLayout/LinearLayoutElementBase'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { VerticalAlignment } from '../types'

class RowProperties {
  gutterSize: number = 10
  verticalAlignment: VerticalAlignment = VerticalAlignment.Top
}

export enum PageFit {
  Wide = 'wide',
  Contained = 'contained'
}

export class RowBoxProperties extends BoxProperties {
  pageFit: PageFit = PageFit.Contained
  paddingPx: Padding = [0, 0]
}

export default class RowElement extends LinearLayoutElementBase {
  static create(): RowElement {
    return new RowElement()
  }

  type: 'layout-row' = 'layout-row'

  get prettyName() {
    if (this.children.length > 1) {
      return i18n.t('ROW_OF_X_BLOCKS', { x: this.children.length })
    } else if (this.children.length === 1) {
      return i18n.t('ROW_OF_ONE_BLOCK')
    } else {
      return i18n.t('EMPTY_ROW')
    }
  }
  box: RowBoxProperties = new RowBoxProperties()
  @Type(() => RowProperties)
  properties = new RowProperties()

  onDeleteElement(context: DeleteContext) {
    const wasChild = this.isIdChild(context.deletedId)
    super.onDeleteElement(context)

    if (wasChild && this.hasAnyChildren === false) {
      context.pageConfig.deleteElement(context.id)
    }
  }
}
