







import { Component, Prop } from 'vue-property-decorator'
import type { GuestHistory } from '@/services/guestHistory'
import type Guest from '@/models/Guest'
import { timezoneFormat } from '@/lib/timezoneFormat'
import fromNow from '@/lib/fromNow'
import type { EventIdentity } from '@/core'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({})
export default class ContactProfileHistoryTabContent extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly history!: GuestHistory

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get formattedCreationDate(): string {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return timezoneFormat(this.history.creation.date ?? '', eventIdentity.timezone)
  }

  get lastUpdateDate(): string | null {
    const entries = this.history.entries ?? []
    const lastEntry = entries[entries.length - 1]
    if (!lastEntry) return null
    return fromNow(lastEntry.date)
  }

  get lastUpdateFormattedText(): string {
    return this.$t('CONTACT_PROFILE_HISTORY_TAB_CONTENT_LAST_UPDATE', {
      lastUpdateDate: this.lastUpdateDate,
      interpolation: {
        escapeValue: false
      }
    })
  }

  get creationFormattedText(): string {
    return this.$t('CONTACT_PROFILE_HISTORY_TAB_CONTENT', {
      formattedCreationDate: this.formattedCreationDate,
      interpolation: {
        escapeValue: false
      }
    })
  }
}
