import type { ReadonlyFullRouteConfig } from '@/core'

export const permissionsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'permissions',
    name: 'event.settings.permissions',
    component: () => import('@/features/eventSettings/permissions/PermissionsSettings.vue'),
    meta: {
      name: 'FACTORY_PERMISSIONS',
      keywords: 'FACTORY_PERMISSIONS_KEYWORDS',
      iconName: 'lock-unlock-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['writeRoles'],
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
