










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UploadMediaItem extends Vue {
  @Prop({ required: true }) readonly iconName!: string
  @Prop({ required: true }) readonly mediaLabelSlug!: string
  @Prop({ default: false }) readonly small!: boolean

  handleLinkClick(ev: MouseEvent): void {
    if (this.$listeners.action) {
      ev.stopPropagation()
      this.$emit('action')
    }
  }
}
