

















import { getGuests } from '@/services/guest'
import SearchBar from './SearchBar.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { AnonymousFilter } from '@/models/Event/Filter'

@Component({ components: { SearchBar } })
export default class SearchGuests extends Vue {
  @Prop({ default: () => '' }) readonly placeholder!: string
  @Prop({ default: () => [] }) readonly excludedGuestIds!: string[]
  search = {
    isSearching: false,
    input: ''
  }

  searchResults: Guest[] | null = null

  get hasSearchResults() {
    return Array.isArray(this.searchResults)
  }

  get areSearchResultsEmpty() {
    return this.searchResults?.length === 0
  }

  async searchContacts() {
    this.searchResults = null

    if (this.search.input.length < 1) {
      return
    }

    this.search.isSearching = true
    const filter: AnonymousFilter = {
      andCriterias: [],
      orCriterias: []
    }

    if (this.excludedGuestIds.length > 0) {
      filter.andCriterias.push({
        property: '_id',
        operator: 'NOT_IN_ARRAY',
        testedValue: this.excludedGuestIds
      })
    }

    const params = {
      filter,
      search: this.search.input,
      limit: 5,
      searchFields: this.search.input.includes('@') ? ['guestemail'] : ['firstname', 'name']
    }

    try {
      const guests = await getGuests(this.$store.getters.event.id, params)
      this.searchResults = guests.guests
    } finally {
      this.search.isSearching = false
    }
  }
}
