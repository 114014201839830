






































import { Component, Prop } from 'vue-property-decorator'
import { DigiLink } from '@/components/ui/actions'
import type Guest from '@/models/Guest'
import type Order from '@/models/Guest/Order'
import type { RawLocation } from 'vue-router'
import type { BvTableFieldArray } from 'bootstrap-vue'
import { timezoneFormat } from '@/lib/timezoneFormat'
import { amount } from '@/features/payment/amount'
import { humanizeOrderStatus } from '@/services/order'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { EventIdentity } from '@/core'

@Component({
  components: { DigiLink }
})
export default class ContactOrders extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  getUrlForOrder(order: Order): RawLocation {
    return {
      name: 'payment.orderDetails',
      params: {
        guestId: this.guest._id,
        orderId: order._id
      }
    }
  }

  get hasNoOrder(): boolean {
    return this.guest.orders.length === 0
  }

  get tableFields(): BvTableFieldArray {
    function tdClass(_value: any, _key: string, item: Order) {
      return item.status === 'canceled' ? 'canceled' : null
    }
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity

    return [
      {
        key: 'creationDate',
        label: this.$t('ORDER_DATE'),
        formatter: (value: Date) => timezoneFormat(value, eventIdentity.timezone, 'L'),
        tdClass
      },
      {
        key: 'items',
        label: this.$t('TICKET_NUMBER'),
        tdClass
      },
      {
        key: 'amount',
        label: this.$t('RSVP_TOTAL'),
        formatter: (value) => (value !== null ? amount(value) : '...'),
        tdClass
      },
      {
        key: 'status',
        formatter: (value) => humanizeOrderStatus(value),
        label: this.$t('STATUS'),
        tdClass
      },
      {
        key: 'details',
        label: '',
        tdClass
      }
    ]
  }
}
