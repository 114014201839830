







































/*
 * Right now we only use vue2-dropzone for adding files with click or drag and drop,
 * we handle the upload to S3 ourselves because vue2-dropzone doesn't work as intended with S3 :
 *  - file acceptance checks don't prevent the upload (can't cancel the upload)
 *  - S3 'error' event doesn't fire (https://github.com/rowanwins/vue-dropzone/issues/506)
 *
 * The current implementation works only for single file's upload.
 *
 * The issues should be resolved with version 3.7 of vue2-dropzone
 * (https://github.com/rowanwins/vue-dropzone/milestone/1)
 */

import { Component, Prop, Ref, VModel } from 'vue-property-decorator'
import Dropzone from 'vue2-dropzone'
import type { DropzoneOptions } from 'dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import FileUploadPreview from './FileUploadPreview.vue'
import type UnsplashModal from './unsplash/UnsplashModal.vue'
import type { GuestId } from '@/models/Guest'
import { CustomFieldType } from '@/models/Event/CustomField'
import UploadMediaItem from './UploadMediaItem.vue'
import { uploadFile } from '@/services/fileStorage'
import FileTypeInfo from '@/components/features/fileUpload/components/FileTypeInfo.vue'
import { moToOctet, octetsToText } from '@/utils/fileTypes'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

const MIME_TO_EXT: Record<string, string> = {
  // Image
  'image/png': 'png',
  'image/jpeg': 'jpg, jpeg',
  'image/webp': 'webp',
  'image/gif': 'gif',
  'image/svg+xml': 'svg'
}

@Component({
  components: {
    FileTypeInfo,
    Dropzone,
    FileUploadPreview,
    UploadMediaItem
  }
})
export default class AvatarUpload extends Vue {
  @VModel({ default: null }) fileUrl!: string | null
  @Prop({ default: null }) readonly fieldKey!: string | null
  @Prop({ required: true }) readonly maxFileSizeInMo!: number
  @Prop({ required: true }) readonly acceptedFiles!: string
  @Prop({ default: null }) readonly recommendedWidth!: number | null
  @Prop({ default: null }) readonly recommendedHeight!: number | null
  @Prop({ default: null }) readonly guestId!: GuestId | null
  @Prop({ default: null }) readonly inputState!: boolean | null

  @Ref() readonly explorePhotoModal!: UnsplashModal
  @Ref() readonly vueDropzone!: Dropzone
  @Ref() readonly uploadMediaItem!: UploadMediaItem

  type = 'websiteBtnImage'

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get acceptedFieldMimes(): string[] | null {
    if (!this.fieldKey) return null

    const field = this.storeEvent.tryGetGuestFieldByKey(this.fieldKey)

    if (!field || field.type !== CustomFieldType.File) return null

    return field.fileFieldPossibleMimes
  }

  get acceptedExt(): string {
    return this.acceptedFilesChoice.map((mime: string) => MIME_TO_EXT[mime]).join(', ')
  }

  get acceptedFilesChoice(): string[] {
    // use acceptedFieldMimes if we are in a custom field context, else use the prop
    return this.acceptedFieldMimes || this.acceptedFiles.split(', ')
  }

  get dropzoneOptions(): DropzoneOptions {
    return {
      url: () => '',
      autoQueue: false,
      acceptedFiles: this.acceptedFiles
    }
  }

  get maxFileSizeInOctet(): number {
    return moToOctet(this.maxFileSizeInMo)
  }

  get maxSizeText(): string {
    if (!this.maxFileSizeInOctet) throw new Error('There is no file size')

    return octetsToText(this.maxFileSizeInOctet)
  }

  get classes(): Record<string, boolean> {
    return {
      'valid-state': this.inputState === true,
      'invalid-state': this.inputState === false
    }
  }

  checkFileValidity(file: File): boolean {
    if (file.size > this.maxFileSizeInOctet) {
      this.$notify({ text: this.$t('TOO_BIG_FILE', { x: this.maxSizeText }), type: 'error' })
      return false
    }
    if (!this.acceptedFilesChoice.includes(file.type)) {
      this.$notify({ text: this.$t('INVALID_FILE_TYPE', { x: this.acceptedExt }), type: 'error' })
      return false
    }
    return true
  }

  async validateAndUpload(file: File) {
    if (!this.checkFileValidity(file)) {
      return this.vueDropzone.removeAllFiles(true)
    }
    this.fileUrl = await uploadFile(
      file,
      this.type,
      this.storeEvent._id,
      this.guestId || undefined,
      this.fieldKey || undefined
    )
  }
}
