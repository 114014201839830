import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

export class TextMediaProperties extends PrefabPropertiesBase {
  mainText: string = ''
  textColor: string = ''
}

export default abstract class TextMediaElementBase extends PrefabElementBase {
  abstract type: `prefab-text-media-${number}`

  @Type(() => TextMediaProperties)
  properties: TextMediaProperties = new TextMediaProperties()
}
