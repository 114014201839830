import { render, staticRenderFns } from "./TrombinoFakeGuestList.vue?vue&type=template&id=6553d75c&scoped=true"
import script from "./TrombinoFakeGuestList.vue?vue&type=script&lang=ts"
export * from "./TrombinoFakeGuestList.vue?vue&type=script&lang=ts"
import style0 from "./TrombinoFakeGuestList.vue?vue&type=style&index=0&id=6553d75c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6553d75c",
  null
  
)

export default component.exports