import type { PromoCode } from '@/models/Event/modules/Payment'
import type { MongoDb } from '@/models/MongoDbTools'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'
import CreditNote from './CreditNote'
import type TicketItem from './Item'
import type { Transaction } from './Transaction'

export type OrderPromoCode = Pick<
  PromoCode,
  'promoCodeTag' | 'discountPercentage' | 'eligibleTickets' | 'eligibleAddons'
>

export enum OrderStatus {
  ToRefund = 'to_refund',
  InProgress = 'in_progress',
  Paid = 'paid',
  Canceled = 'canceled',
  Refunded = 'refund',
  Failed = 'failed'
}

export enum OrderOrigin {
  Website = 'website',
  Back = 'back'
}

interface Invoice {
  invoiceNumber: number
}

export type OrderId = MongoDb.ObjectId

export interface OrderBillingInformation {
  name: string
  firstname: string
  address?: string
  city?: string
  zipCode?: string
  country?: string
  organization?: string
  incorporationIdentifier?: string
  internalPurchaseReference?: string
}

type OrderVatExemption = { hasVatExemption: true; reason: string } | { hasVatExemption: false }

export default class Order {
  _id!: OrderId
  id!: OrderId

  // @Type(() => OrderItem)
  items!: TicketItem[]

  @Type(() => CreditNote)
  creditNote?: CreditNote

  transactions!: Transaction[]

  invoice?: Invoice

  promoCode?: OrderPromoCode

  createdFrom?: OrderOrigin

  @TransformDateStringToDateObject()
  creationDate!: Date

  status!: OrderStatus

  cerfa?: {
    deductibleAmount?: number
    cerfaNumber?: number
  }

  areTicketsDispatched!: boolean

  ticketDispatchError?: string

  amount?: number //in the context of contact list, guest can have an amount...
  billingInformation?: OrderBillingInformation
  vatExemption!: OrderVatExemption
}
