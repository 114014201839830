



































import { Component, Vue } from 'vue-property-decorator'

import type { DigiFormGroupItem } from '@/components/ui/form/formGroup'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

import { DigiFormLayout2 } from '@/components/ui/form/formLayouts'

import moment from 'moment'
import { isRegistrationClosed } from '@/models/Event/modules/Website/Registration'
import DigiCard from '@/DigiCard.vue'
import type { EventIdentity } from '@/core'
import { DigiDateTimePicker } from '@/components/ui/inputs'
import type { EventLangCode } from '@/models/Event/Localized'
import { getLangFromRoute } from '@/features/website'
import { updateClosedRegistrationSettings } from '@/digiteventApi'
import { cloneDeep } from 'lodash'

type RegistrationState = 'opened' | 'closed' | 'scheduled'

@Component({ components: { DigiCard, DigiFormGroup, DigiDateTimePicker, DigiFormLayout2 } })
export default class RegistrationStateSettings extends Vue {
  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get lang(): EventLangCode {
    return getLangFromRoute(this.$store.getters.event.mainLanguageCode, this.$route)
  }

  closingDate: Date | null = cloneDeep(this.storeEvent.modules_data.website.registration.closingDate)
  closedRegistrationMessage: string =
    this.storeEvent.modules_data.website.registration.closedRegistrationMessage[this.lang] || ''

  storedRegistrationState: RegistrationState | null = null

  registrationState: RegistrationState | null = null

  resetClosedRegistrationSettings(): void {
    this.closingDate = cloneDeep(this.storeEvent.modules_data.website.registration.closingDate)
    this.closedRegistrationMessage =
      this.storeEvent.modules_data.website.registration.closedRegistrationMessage[this.lang] || ''
    this.initRegistrationState()
  }

  registrationStateOptions: { value: RegistrationState; text: string }[] = [
    {
      value: 'opened',
      text: this.$t('REGISTRATION_OPENED')
    },
    {
      value: 'closed',
      text: this.$t('REGISTRATION_CLOSED')
    },
    {
      value: 'scheduled',
      text: this.$t('REGISTRATION_SCHEDULED')
    }
  ]

  get eventIdentity(): EventIdentity {
    return this.$store.state.eventIdentity
  }

  get eventEndDate(): Date {
    return moment(this.eventIdentity.endDate).tz(this.eventIdentity.timezone).toDate()
  }

  get formItems(): DigiFormGroupItem[] {
    return [
      {
        key: 'registrationState',
        label: 'REGISTRATION_STATE'
      },
      {
        key: 'closedRegistrationMessage',
        label: 'REGISTRATION_CLOSE_MESSAGE_DESCRIPTION',
        shouldDisplay: this.registrationState !== 'opened',
        validationRules: { required: true }
      }
    ]
  }

  get eventTimezone(): string {
    return this.eventIdentity.timezone
  }

  get hasClosingDate(): boolean {
    return this.closingDate !== null
  }

  get hasModifications(): boolean {
    const hasClosingDateChanged =
      (!moment(this.closingDate).isSame(this.storeEvent.modules_data.website.registration.closingDate) &&
        this.closingDate !== null) ||
      (this.closingDate === null && this.storeEvent.modules_data.website.registration.closingDate !== this.closingDate)
    const hasClosedRegistrationMessageChanged =
      this.closedRegistrationMessage !==
      this.storeEvent.modules_data.website.registration.closedRegistrationMessage[this.lang]

    if (hasClosedRegistrationMessageChanged || hasClosingDateChanged) {
      return true
    }

    return false
  }

  mounted() {
    this.initRegistrationState()
  }

  setRegistrationState(state: RegistrationState): void {
    switch (state) {
      case 'opened':
        this.closingDate = null
        break
      case 'closed':
        if (moment().isBefore(this.storeEvent.modules_data.website.registration.closingDate)) {
          this.closingDate = new Date()
          break
        }
        this.closingDate = cloneDeep(this.storeEvent.modules_data.website.registration.closingDate) || new Date()
        break
      case 'scheduled':
        this.closingDate = cloneDeep(this.storeEvent.modules_data.website.registration.closingDate) || new Date()
    }
    this.registrationState = state
  }

  initRegistrationState(): void {
    if (this.storeEvent.modules_data.website.registration.closingDate === null) {
      this.registrationState = 'opened'
    } else if (isRegistrationClosed(this.storeEvent.modules_data.website.registration.closingDate)) {
      this.registrationState = 'closed'
    } else {
      this.registrationState = 'scheduled'
    }
  }

  isLoading = false
  async updateClosedRegistrationSettings(): Promise<void> {
    this.isLoading = true

    const closedRegistrationMessage = cloneDeep(
      this.storeEvent.modules_data.website.registration.closedRegistrationMessage
    )

    closedRegistrationMessage[this.lang] = this.closedRegistrationMessage

    try {
      await updateClosedRegistrationSettings(this.storeEvent._id, closedRegistrationMessage, this.closingDate)

      this.$notify({
        text: this.$t('MODIFICATIONS_SAVED'),
        type: 'success'
      })
    } finally {
      this.isLoading = false
    }
  }
}
