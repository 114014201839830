import type { Permission } from '@/core'
import type { Renderable } from '@/models/VueTools'
import type { EventRights } from '@/models/Event/EventRights'

export interface EventMenuSection {
  readonly key: string
  readonly titleSlug: string
  readonly iconName: string
  readonly items: readonly EventMenuItem[]
}

export interface EventMenuItemBase {
  readonly titleSlug: string
  readonly isAllowedInEvent: (eventRights: EventRights) => boolean
  readonly requiredPermissions: readonly Permission[]
}

export interface EventMenuItemLink extends EventMenuItemBase {
  readonly routeName: string
}

export interface EventMenuItemComponent extends EventMenuItemBase {
  readonly component: Renderable
}

export function isEventMenuItemLink(item: EventMenuItem): item is EventMenuItemLink {
  return 'routeName' in item
}

export type EventMenuItem = EventMenuItemLink | EventMenuItemComponent
