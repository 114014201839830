




































import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { EventId } from '@/models/Event'
import type { GuestId } from '@/models/Guest'
import type Guest from '@/models/Guest'
import type { ScheduledItemId } from '@/models/Guest/Planning/ScheduledItem'
import type MeetingSchedule from '@/models/Guest/Planning/ScheduledItem/Meeting'
import { getAvailableTimeSlotsForMeeting } from '@/features/eventApp'
import axios from 'axios'
import moment from 'moment'
import { Component, Prop, Watch } from 'vue-property-decorator'

interface TimeSlot {
  start: string
  end: string
}

async function rescheduleMeeting(
  eventId: EventId,
  scheduledItemId: ScheduledItemId,
  targetId: GuestId,
  applicantId: GuestId,
  newSlot: TimeSlot
): Promise<void> {
  return await axios.put(`/backoffice/events/${eventId}/matchmaking/meeting/${scheduledItemId}/changeMeetingDate`, {
    applicantId,
    targetId,
    newStartDate: newSlot.start
  })
}

@Component({})
export default class RescheduleMeeting extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly meetingId!: string

  active: boolean = false
  isFetchingSlots: boolean = false
  timeSlots: TimeSlot[] | null = null
  pickedTimeSlot: TimeSlot | null = null
  isRescheduling: boolean = false

  doReschedule(): void {
    this.active = true
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get hasAvailableSlots(): boolean {
    return this.timeSlots !== null && this.timeSlots.length > 0
  }

  get scheduledItem(): MeetingSchedule {
    const foundItem = this.guest.planning!.scheduledItems.find((sched) => sched._id === this.meetingId)

    if (!foundItem) {
      throw new Error('Could not find schedule item associated with planning.')
    } else if (foundItem.kind !== 'meeting') {
      throw new Error('Discrepancy between planning and schedule item kinds.')
    } else {
      return foundItem
    }
  }

  @Watch('active')
  async onActiveChange(active: boolean): Promise<void> {
    if (active) {
      this.pickedTimeSlot = null
      await this.fetchPossibleTimes()
    }
  }

  async fetchPossibleTimes(): Promise<void> {
    this.isFetchingSlots = true
    this.timeSlots = null

    try {
      this.timeSlots = await getAvailableTimeSlotsForMeeting(
        this.scheduledItem.applicant._guest,
        this.scheduledItem.target._guest,
        this.scheduledItem.eventActivityId
      )
    } finally {
      this.isFetchingSlots = false
    }
  }

  get timeSlotOptions(): { text: string; value: TimeSlot }[] {
    if (this.timeSlots) {
      return this.timeSlots.map((slot) => ({
        text: moment(slot.start).format('l LT'),
        value: slot
      }))
    } else {
      return []
    }
  }

  get canConfirm(): boolean {
    return (
      this.isFetchingSlots === false &&
      this.pickedTimeSlot !== null &&
      this.timeSlotOptions.some((opt) => opt.value === this.pickedTimeSlot)
    )
  }

  get counterPartRole() {
    return this.scheduledItem.target._guest === this.guest._id ? 'applicant' : 'target'
  }

  get counterPartFullName() {
    return this.scheduledItem[this.counterPartRole].fullName
  }

  async confirmReschedule(): Promise<void> {
    if (this.isRescheduling) throw new Error('Already rescheduling.')
    if (!this.pickedTimeSlot) throw new Error('Cannot confirm reschedule: No time slot was picked.')

    this.isRescheduling = true

    try {
      await rescheduleMeeting(
        this.storeEvent._id,
        this.scheduledItem._id,
        this.scheduledItem.target._guest,
        this.scheduledItem.applicant._guest,
        this.pickedTimeSlot
      )
      this.$emit('rescheduled')
      this.active = false
    } finally {
      this.isRescheduling = false
    }
  }
}
