






import { Component, Prop } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import type { AnyTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import Vue from 'vue'

@Component({
  model: {
    prop: 'trigger',
    event: 'update'
  }
})
export default class SurveySubmissionSettings extends Vue {
  @Prop() readonly trigger!: AnyTrigger

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get surveyOptions(): { value: string; text: string | null }[] {
    const multiRegistrationFormId = this.storeEvent.modules_data.multiRegistration?.slaveFormId || null
    const surveys = this.storeEvent.forms.filter(
      (form) => !form.isRegistration && !(form._id === multiRegistrationFormId)
    )

    return (
      surveys?.map((form) => ({
        value: form._id,
        text: form.rsvpLabel[this.storeEvent.mainLanguageCode] ?? null
      })) ?? []
    )
  }
}
