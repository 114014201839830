import { ElementTypeDictionary } from '../types'
import { coverDictionary } from './cover'
import { actionDictionary } from './action'
import { textMediaDictionary } from './textMedia'
import { textDictionary } from './text'
import { quoteDictionary } from './quote'
import { assistanceDictionary } from './assistance'
import { sponsorsDictionary } from './sponsors'
import { socialsDictionary } from './socials'
import { mapDictionary } from './map'
import { speakersDictionary } from './speakers'
import { mediaDictionary } from './media'
import { countdownDictionary } from './countdown'
import { textInfiniteScrollDictionary } from './textInfiniteScroll'
import { keyFiguresDictionary } from './keyFigures'
import { iframeDictionary } from './iframe'
import { trombinoDictionary } from './trombinoscope'
import { programmeDictionary } from './programme'

export const prefabDictionary: ElementTypeDictionary = ElementTypeDictionary.merge(
  coverDictionary,
  actionDictionary,
  textMediaDictionary,
  textDictionary,
  quoteDictionary,
  assistanceDictionary,
  sponsorsDictionary,
  socialsDictionary,
  mapDictionary,
  speakersDictionary,
  mediaDictionary,
  countdownDictionary,
  textInfiniteScrollDictionary,
  keyFiguresDictionary,
  iframeDictionary,
  trombinoDictionary,
  programmeDictionary
)
