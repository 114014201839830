






















import { Component, VModel, Vue } from 'vue-property-decorator'
import { validateAttachment } from './supportRequest'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({ components: { DigiSimpleIconButton } })
export default class AttachmentInput extends Vue {
  @VModel() attachment!: File | null

  get isAttachmentValid(): boolean {
    return validateAttachment(this.attachment)
  }

  deleteAttachment(): void {
    this.attachment = null
  }
}
