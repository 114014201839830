import type { Event } from '@/models/Event'
import type Guest from '@/models/Guest'
import { doesGuestMatchFilterId } from '@/services/filter'

export function getCertificatesModules(event: Event) {
  const module = event.modules_data.certificate
  if (!module) {
    throw new Error('Certificate module not initialized')
  }
  return module
}

export function getGuestCertificates(guest: Guest, event: Event) {
  const { certificates } = getCertificatesModules(event)
  return certificates.filter((c) => !c.segmentId || doesGuestMatchFilterId(guest, event, c.segmentId))
}

export function getCertificateUrl({
  eventId,
  guestId,
  certificateId
}: {
  eventId: string
  guestId: string
  certificateId: string
}) {
  return `${process.env.VUE_APP_API_URL}/events/${eventId}/guests/${guestId}/certificates/${certificateId}.pdf`
}
