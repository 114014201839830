




import type { GroupActivity } from '@/models/Event/modules/Programme'
import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningLocation from '../PlanningLocation.vue'

@Component({
  components: {
    PlanningLocation
  }
})
export default class GroupActivityLocation extends Vue {
  @Prop({ required: true }) readonly groupActivitySession!: GroupActivity
}
