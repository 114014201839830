












import { Vue, Component, Prop } from 'vue-property-decorator'
import type { PasswordCriteria, PasswordCriteriaValidation } from '@/utils/validation'
import { passwordCriterias } from '@/utils/validation'

const SLUG_BY_CRITERIA: Record<PasswordCriteria, string> = {
  minSymbols: 'MIN_SYMBOL_CRITERIA',
  minLength: 'MIN_LENGTH_CRITERIA',
  minNumbers: 'MIN_NUMBER_CRITERIA',
  minUppercase: 'MIN_UPPERCASE_CRITERIA',
  minLowercase: 'MIN_LOWERCASE_CRITERIA'
}

type Criteria = {
  isValid: boolean
  slug: string
}

@Component
export default class PasswordValidationCriteria extends Vue {
  @Prop() readonly criterias!: PasswordCriteriaValidation

  get criteriasArray(): Criteria[] {
    return passwordCriterias.map((criteria) => ({
      isValid: this.criterias[criteria],
      slug: SLUG_BY_CRITERIA[criteria]
    }))
  }
}
