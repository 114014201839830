




















































import { Vue, Component, Prop } from 'vue-property-decorator'
import type { MessageStatus } from '@/models/Guest/Message'
import MessageStatusBadge from '@/components/features/MessageStatusBadge.vue'
import type { Location } from 'vue-router'
import type { MessageMethod } from '@/models/Event/Messages/template'

import type { MessageTemplateUser } from '../../types'

@Component({
  components: { MessageStatusBadge }
})
export default class MessageHistoryHeader extends Vue {
  @Prop({ required: true }) readonly messageName!: string
  @Prop({ required: true }) readonly messageTime!: string
  @Prop({ required: true }) readonly messageMethod!: MessageMethod
  @Prop({ required: true }) readonly messageUser!: MessageTemplateUser
  @Prop({ required: true }) readonly messageStatus!: MessageStatus
  @Prop({ required: true }) readonly messageEditUrl!: Location
  @Prop({ required: true }) readonly isOpen!: boolean
}
