







import { Vue, Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import InvitationStatusBadge from '../components/InvitationStatusBadge.vue'
import InvitationStatus from '@/models/Guest/InvitationStatus'

@Component({ components: { InvitationStatusBadge } })
export default class PaidRegistrationTabContent extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get ordersNumber(): number {
    return this.guest.orders.length
  }

  get ticketsNumber(): number {
    return this.guest.tickets?.length ?? 0
  }

  get showInvitationStatus(): boolean {
    return [InvitationStatus.Invited, InvitationStatus.NoStatus, InvitationStatus.Declined].includes(
      this.guest.invitationStatus
    )
  }
}
