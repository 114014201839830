
















import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { doesGuestMatchFilter } from '@/services/filter'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import ProfileSegmentItem from './components/ProfileSegmentItem.vue'
import type Filter from '@/models/Event/Filter'

@Component({ components: { ProfileSegmentItem } })
export default class ContactSegments extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get segments(): Filter[] {
    return this.storeEvent.filters.filter((filter) => doesGuestMatchFilter(this.guest, filter))
  }
}
