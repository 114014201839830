import type { FullRouteConfig, FullRoute, RouteMetaData } from '@/core'
import store from '@/store'
import type { NavigationGuardNext, Location } from 'vue-router'
import type { Dictionary } from 'vue-router/types/router'
import { listMyPermissions } from '@/services/eventPermissions'
import type { EventRights } from '@/models/Event/EventRights'

function redirectOnSuccess(to: FullRoute, from: FullRoute, next: NavigationGuardNext): void {
  if (to.name === 'event') {
    next({ name: 'event.dashboard', params: to.params })
  } else {
    next()
  }
}

// As an extra failsafe, here's a function that parses a full path to try finding an event ID in it
// In theory, this shouldn't be necessary
// However practice has proven that we do need to account for improbable mistakes in our routing
function parsePathForEventId(path: string): string | null {
  const pathSlices = path.split('/')
  if (pathSlices.length >= 2 && pathSlices[0] === 'event') {
    return pathSlices[1]!
  } else {
    return null
  }
}

// Function that checks if we have en EventID and a matching event
// @TODO: change name this doesn't "load" an event
export async function eventLoaderMiddleware(to: FullRoute, from: FullRoute, next: NavigationGuardNext): Promise<void> {
  // Trying to find an event ID somewhere in our routing
  const foundEventId =
    to.params.id || from.params.id || parsePathForEventId(to.fullPath) || parsePathForEventId(from.fullPath)

  // If no event ID is specified or has been specified in the past
  if (!foundEventId) {
    // Get outta here
    return next(new Error('No EventID could be found'))
  }

  /**
   * This checks for user permissions if the event we're looking for is loaded.
   *
   * IMPORTANT: This means that we trust EventLoader to call the permission check
   * once it has loaded the event!
   */
  if (store.getters.hasEvent && store.getters.eventId === foundEventId) {
    const eventRights = store.state.event.rights
    const permCheck = await checkRouteAccessibility(to, eventRights)

    if (permCheck.mustRedirect) {
      return next(permCheck.newRoute)
    } else {
      return redirectOnSuccess(to, from, next)
    }
  } else {
    return redirectOnSuccess(to, from, next)
  }
}

interface RouteAccessibilityWithRedirect {
  mustRedirect: true
  newRoute: Location
}

interface RouteAccessibilityWithoutRedirect {
  mustRedirect: false
  newRoute: null
}

type RouteAccessibility = RouteAccessibilityWithRedirect | RouteAccessibilityWithoutRedirect

export async function checkRouteAccessibility(
  targetRoute: FullRoute,
  eventRights: EventRights
): Promise<RouteAccessibility> {
  const newRouteParams: Dictionary<string> = {}

  if (targetRoute.params.id) newRouteParams.id = targetRoute.params.id

  if (routeRequiresPermissions(targetRoute)) {
    const hasPermissions = await hasRequiredPermissions(targetRoute)
    if (!hasPermissions) {
      return {
        mustRedirect: true,
        newRoute: {
          name: targetRoute.meta.requiredPermissions.redirectName,
          params: newRouteParams
        }
      }
    }
  }
  if (targetRoute.meta?.requiredRights) {
    const hasRights = targetRoute.meta.requiredRights.hasRequiredRights(eventRights)
    if (!hasRights) {
      return {
        mustRedirect: true,
        newRoute: {
          name: targetRoute.meta.requiredRights.redirectName,
          params: newRouteParams
        }
      }
    }
  }
  return {
    mustRedirect: false,
    newRoute: null
  }
}

interface RouteThatRequiresPermissions {
  meta: RouteMetaData & {
    requiredPermissions: NonNullable<RouteMetaData['requiredPermissions']>
  }
}

function routeRequiresPermissions<RouteT extends FullRoute | FullRouteConfig>(
  route: RouteT
): route is RouteT & RouteThatRequiresPermissions {
  return Boolean(route.meta?.requiredPermissions)
}

export async function hasRequiredPermissions(route: FullRoute | FullRouteConfig): Promise<boolean> {
  const requiredPermissions = route.meta?.requiredPermissions
  if (!requiredPermissions) return true
  const eventId = store.getters.eventId
  const permissions = await listMyPermissions(eventId)
  return requiredPermissions.permissions.every((permission) => permissions.includes(permission))
}
