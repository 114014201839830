import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import { set } from 'lodash'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import type { VideoPlatform } from '../../../types/helperTypes'
import MediaElementBase from '../MediaElementBase'
import { VideoProperties } from '../../../types/helperTypes'

export class Media6ElementVideoProperties extends VideoProperties {
  label: string = ''
}

export class Media6Properties extends PrefabPropertiesBase {
  videos: Media6ElementVideoProperties[] = []
  tagText: string = ''
  tagColor: string = ''
  title: string = ''
  titleColor: string = ''
  mainText: string = ''
  textColor: string = ''
}

export default class Media6Element extends MediaElementBase {
  static create({ palette }: ElementCreationContext): Media6Element {
    const video = new Media6Element()

    video.properties.title = i18n.t('AN_INSPIRING_TITLE')
    video.properties.tagText = i18n.t('SECTION_NAME')

    video.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)

    const darkestColor = palette.darkestColor

    video.properties.titleColor = darkestColor.paletteCode
    video.properties.textColor = darkestColor.paletteCode
    video.properties.tagColor = darkestColor.paletteCode

    return video
  }

  type = `prefab-media-6` as const

  @Type(() => Media6Properties)
  properties: Media6Properties = new Media6Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_MULTI_VIDEO')
  }

  addVideo(video: Media6ElementVideoProperties): void {
    this.properties.videos.push(video)
  }

  removeVideo(videoIndex: number): void {
    this.properties.videos.splice(videoIndex, 1)
  }

  setVideoProperty(videoIndex: number, property: string, value: VideoPlatform | string | null): void {
    const foundVideo = this.properties.videos[videoIndex]
    if (!foundVideo) {
      throw new Error('No video found with the given index')
    }
    set(foundVideo, property, value)
  }
}
