import type { EventRights } from '@/models/Event/EventRights'
export const certificatesMenuSection = {
  key: 'certificates',
  titleSlug: 'EVENT_MENU_CERTIFICATES_TITLE',
  iconName: 'award-fill',
  items: [
    {
      titleSlug: 'EVENT_MENU_CERTIFICATES_LIST_LINK',
      routeName: 'certificates',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.certificates
    }
  ]
} as const
