




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DigiIntroLayout extends Vue {
  @Prop({ default: null }) readonly title!: string | null
  @Prop() readonly noSharedLayout!: boolean
}
