import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'
import moment from 'moment'
import { LocalizedPrimitive } from '../../Localized'

export class Registration {
  @TransformDateStringToDateObject()
  closingDate: Date | null = null

  @Type(() => LocalizedPrimitive)
  closedRegistrationMessage!: LocalizedPrimitive<string>

  @Type(() => LocalizedPrimitive)
  fullRegistrationMessage!: LocalizedPrimitive<string>
}

export function isRegistrationClosed(closingDate: Date | null) {
  return closingDate !== null && moment(closingDate).isBefore(new Date())
}
