import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

export class TextProperties extends PrefabPropertiesBase {
  mainText: string = ''
  textColor: string = ''
}

export default abstract class TextElementBase extends PrefabElementBase {
  abstract type: `prefab-text-${number}`

  @Type(() => TextProperties)
  properties: TextProperties = new TextProperties()
}
