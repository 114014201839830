



























import type { DigiFormGroupItem } from '@/components/ui/form/formGroup'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import { Vue, Component, Prop } from 'vue-property-decorator'
import type { SocialLinks, SocialNetwork } from '@/models/Event/Identity'
import { S3_ASSETS_URL } from '@/utils/constants'
import { socialsNetworksList } from '@/models/Event/Identity'

@Component({ components: { DigiFormGroup } })
export default class SocialNetworksFormGroup extends Vue {
  @Prop({ required: true }) readonly networks!: SocialLinks

  S3_ASSETS_URL = S3_ASSETS_URL

  get formItems(): DigiFormGroupItem[] {
    return [
      {
        key: 'socialNetworks',
        label: 'SOCIAL_NETWORKS_FORM_GROUP_LABEL'
      }
    ]
  }

  get socialNetworks() {
    return socialsNetworksList
  }

  editSocialNetworks(key: SocialNetwork, value: string) {
    const socialNetworks = { ...this.networks }
    socialNetworks[key] = value
    this.$emit('edit', socialNetworks)
  }
}
