import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import AssistanceElementBase from '../AssistanceElementBase'
import { LoremIpsum } from 'lorem-ipsum'

export class Contact1Properties extends PrefabPropertiesBase {
  title: string = ''
  mainText: string = ''
  textColor: string = ''
  mainBlockColor: string = ''
  mainBlockImg: any = ''
}

export default class Contact1Element extends AssistanceElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): Contact1Element {
    const contact = new Contact1Element()
    const darkestColor = palette.darkestColor.paletteCode

    contact.properties.title = i18n.t('CONTACT_THE_ORGANIZER')
    contact.properties.textColor = mostReadableColor
    contact.properties.mainText = new LoremIpsum({ wordsPerSentence: { min: 5, max: 15 } }).generateSentences(1)
    contact.properties.mainBlockColor = darkestColor
    contact.properties.mainBlockImg = ''

    return contact
  }

  type = 'prefab-contact-1' as const

  @Type(() => Contact1Properties)
  properties: Contact1Properties = new Contact1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_CONTACT')
  }
}
