





















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import DigiFormLayout2Actions from './DigiFormLayout2Actions.vue'

import type { ValidationObserver } from 'vee-validate'
import DigiCard from '@/DigiCard.vue'

@Component({ components: { DigiCard, DigiFormLayout2Actions } })
export default class DigiFormLayout2 extends Vue {
  @Prop() readonly formTitle!: string
  @Prop({ default: false }) readonly hideHeader!: boolean
  @Prop({ required: true }) readonly hasModifications!: boolean
  @Prop({ default: false }) readonly alwaysShowActions!: boolean
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly isLoading!: boolean

  @Ref() readonly observer!: InstanceType<typeof ValidationObserver>

  async submit(): Promise<void> {
    const isValid = await this.observer.validate()
    if (!isValid) {
      return
    }
    this.$emit('submit')
  }
}
