import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabElementBase, PrefabPropertiesBase } from '../../PrefabElementBase'
import { LoremIpsum } from 'lorem-ipsum'

class SessionListProperties extends PrefabPropertiesBase {
  title: string = ''
  subtitle: string = ''
  innerBackgroundColor: string = ''
  outerTextColor: string = ''
  innerTextColor: string = ''
}

export class SessionListElement extends PrefabElementBase {
  static create({ mostReadableColor, palette }: ElementCreationContext): SessionListElement {
    const programme = new SessionListElement()

    const subtitleIpsum = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 }
    })

    programme.properties.title = i18n.t('PROGRAMME')

    programme.properties.subtitle = subtitleIpsum.generateSentences(1)

    programme.properties.outerTextColor = mostReadableColor
    programme.properties.innerBackgroundColor = palette.mostVividColor.paletteCode
    programme.properties.innerTextColor = palette.getMostReadableColor(palette.mostVividColor.color).paletteCode

    return programme
  }

  type = 'prefab-session-list' as const

  @Type(() => SessionListProperties)
  properties: SessionListProperties = new SessionListProperties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_PROGRAMME_SESSIONS')
  }
}
