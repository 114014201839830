import axios from 'axios'

export async function grantFreeTickets({
  eventId,
  ticketId,
  guestIds
}: {
  eventId: string
  ticketId: string
  guestIds: string[]
}) {
  await axios.put(`/events/${eventId}/registration/paid/grant/${ticketId}`, {
    guestIds
  })
}
