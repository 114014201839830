import type { MongoDb } from '@/models/MongoDbTools'

export enum ActionKind {
  PostWebhook = 'postWebhook',
  SendEmailToAddress = 'sendEmailToAddress',
  SendEmailToGuest = 'sendEmailToGuest',
  SendSMSToGuest = 'sendSmsToGuest',
  SendSMSToPhoneNumber = 'sendSmsToPhoneNumber'
}

export abstract class ActionBase {
  abstract readonly kind: ActionKind
}

abstract class MessageToGuestActionBase extends ActionBase {
  messageTemplateId!: MongoDb.ObjectId
}

export class PostWebhookAction extends ActionBase {
  kind = ActionKind.PostWebhook as const
  url!: string
}

export class SendEmailToAddressAction extends ActionBase {
  kind = ActionKind.SendEmailToAddress as const
  emailAddress!: string
  fromName!: string
  subject!: string
  emailContent!: string
}

export class SendEmailToGuestAction extends MessageToGuestActionBase {
  kind = ActionKind.SendEmailToGuest as const
}

export class SendSMStoGuestAction extends MessageToGuestActionBase {
  kind = ActionKind.SendSMSToGuest as const
}

export class SendSMStoPhoneNumberAction extends ActionBase {
  kind = ActionKind.SendSMSToPhoneNumber as const
  phoneNumber!: string
  senderName!: string
  content?: string
  useUSC2EncodingFormat?: boolean
}

export type AnyAction =
  | PostWebhookAction
  | SendEmailToAddressAction
  | SendEmailToGuestAction
  | SendSMStoGuestAction
  | SendSMStoPhoneNumberAction

export const action = {
  PostWebhook: PostWebhookAction,
  SendEmailToAddress: SendEmailToAddressAction,
  SendEmailToGuest: SendEmailToGuestAction,
  SendSMStoGuest: SendSMStoGuestAction,
  SendSMStoPhoneNumber: SendSMStoPhoneNumberAction
}

export type MessageToGuestAction = SendEmailToGuestAction | SendSMStoGuestAction

export function isMessageToGuestAction(action: AnyAction): action is MessageToGuestAction {
  return [ActionKind.SendEmailToGuest, ActionKind.SendSMSToGuest].includes(action.kind)
}
