import { LinearLayoutElementBase } from '../LinearLayout/LinearLayoutElementBase'
import { i18n } from '@/utils/i18n'

export default class MainColumnElement extends LinearLayoutElementBase {
  static create(): MainColumnElement {
    return new MainColumnElement()
  }

  type: 'layout-main' = 'layout-main'

  get prettyName() {
    return i18n.t('PAGE')
  }
}
