






import { Component, Prop } from 'vue-property-decorator'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import type Guest from '@/models/Guest'
import { getLeadExportUrl, getLeadsCount } from '@/features/eventApp'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileNetworkingTab extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get networkingLeadsUrl() {
    const eventId = this.storeEvent._id
    const guestId = this.guest._id
    return this.networkingLeadsCount > 0 ? getLeadExportUrl({ eventId, guestId }) : ''
  }

  get networkingLeadsCount(): number {
    return getLeadsCount(this.guest)
  }
}
