































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DigiBurgerMenu } from '@/components/ui'
import DigitIconButton from '@/components/ui/actions/DigiIconButton.vue'
import HeaderAccountMenu from '@/layouts/components/Header/components/HeaderAccountMenu.vue'
import HeaderLogo from '@/layouts/components/Header/components/HeaderLogo.vue'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import HeaderNavItem from '@/layouts/components/Header/components/HeaderNavItem.vue'
import { DocumentationLink } from '@/features/support'

@Component({
  components: {
    DigiBurgerMenu,
    DigiSimpleIconButton,
    DigitIconButton,
    DocumentationLink,
    HeaderAccountMenu,
    HeaderLogo,
    HeaderNavItem
  }
})
export default class DigiHeader extends Vue {
  @Prop({ default: false }) readonly isMenuOpen!: boolean
  @Prop({ default: false }) readonly fixed!: boolean

  get cssClasses() {
    return {
      'digi-header--fixed': this.fixed
    }
  }
}
