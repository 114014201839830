import 'vue-class-component/hooks'
import './utils/vue-class-component-hooks'
import 'reflect-metadata'
import Vue from 'vue'
import App from './App.vue'
import { router } from './navigation'
import store from './store'

import { i18n } from './utils/i18n'

import '@/styles/bootstrapExtended.scss'
import 'vue-tel-input/dist/vue-tel-input.css'
import '@/utils/tinymce-setup'
import 'remixicon/fonts/remixicon.css'
import './utils/sentry'
import {
  AlertPlugin,
  AspectPlugin,
  AvatarPlugin,
  BadgePlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  EmbedPlugin,
  FormCheckboxPlugin,
  FormDatepickerPlugin,
  FormFilePlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormRadioPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  FormTimepickerPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  ModalPlugin,
  NavPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  SkeletonPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  TooltipPlugin
} from 'bootstrap-vue'
import VueDebounce from 'vue-debounce'
import VueClipboard from 'vue-clipboard2'
import VueNotification from 'vue-notification'
import Vue2Filters from 'vue2-filters'
import VueUUID from './utils/uuid'
import { VuePlugin as VueErrorHandling } from './utils/errorHandling'
import PortalVue from 'portal-vue'
import DigiSidePanel from '@/layouts/components/sidePanel/plugin'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import axios from 'axios'
import { setupAxiosInstance } from '@/utils/axios-setup'
import { DigiSpinner } from '@/components/ui'
import RemixIcon from './components/ui/RemixIcon.vue'
import VueGtm from '@gtm-support/vue2-gtm'
import { ValidationObserver, ValidationProvider, extend as extendValidationRules } from 'vee-validate'
import { required, min, max, min_value, oneOf, regex } from 'vee-validate/dist/rules'
import MasonryWall from '@yeger/vue2-masonry-wall'
import { isDev } from './utils/isDev'
import { setupAxiosRedirectToLoginOnUnauthorizedRequests } from './views/authentication/routes'

setupAxiosInstance(axios)
setupAxiosRedirectToLoginOnUnauthorizedRequests(axios, router)

Vue.use(VueGtm, {
  id: 'GTM-KRKQH8Q', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  enabled: navigator.doNotTrack !== '1' && isDev === false // Defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
})

//Bootstrap Vue - to delete if no longer used

Vue.use(AlertPlugin)
Vue.use(AvatarPlugin)
Vue.use(BadgePlugin)
Vue.use(CardPlugin)
Vue.use(CardPlugin)
Vue.use(CollapsePlugin)
Vue.use(DropdownPlugin)
Vue.use(DropdownPlugin)
Vue.use(EmbedPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormTextareaPlugin)
Vue.use(FormTimepickerPlugin)
Vue.use(ImagePlugin)
Vue.use(InputGroupPlugin)
Vue.use(LayoutPlugin)
Vue.use(LinkPlugin)
Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(NavPlugin)
Vue.use(OverlayPlugin)
Vue.use(PaginationPlugin)
Vue.use(PopoverPlugin)
Vue.use(SkeletonPlugin)
Vue.use(SpinnerPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(TooltipPlugin)
Vue.use(AspectPlugin)

Vue.use(VueClipboard)
Vue.use(VueDebounce)
Vue.use(VueNotification, {
  componentName: 'Notifications'
})
Vue.use(Vue2Filters)
Vue.use(VueUUID)
Vue.use(VueErrorHandling)
Vue.use(PortalVue)
Vue.use(DigiSidePanel)
Vue.use(
  new VueSocketIO({
    connection: SocketIO(process.env.VUE_APP_API_URL!, { transports: ['websocket', 'polling'] }),
    vuex: {
      store
    }
  })
)
Vue.use(MasonryWall)

extendValidationRules('required', required)
extendValidationRules('min', min)
extendValidationRules('max', max)
extendValidationRules('min_value', min_value)
extendValidationRules('oneOf', oneOf)
extendValidationRules('regex', regex)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

Vue.component('VRemixIcon', RemixIcon)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('DigiSpinner', DigiSpinner)

Vue.filter('json', JSON.stringify)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
