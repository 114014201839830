








import type { PDFBadgeModule, PDFBadgeVariant } from '@/models/Event/modules/PDFBadge'
import type Guest from '@/models/Guest'
import { Component, Prop } from 'vue-property-decorator'
import { findBadgeMatchingGuest, getBadgeURLForGuest } from '@/features/checkin'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { getSegmentFromId } from '@/features/audience/segments'
import { joinIfExist } from '@/utils/string'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfilePdfBadgeTab extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get module(): PDFBadgeModule {
    const module = this.storeEvent.modules_data.pdfBadge
    if (!module) {
      throw new Error('PDF badge module not initialized')
    }
    return module
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get matchingVariant(): PDFBadgeVariant | null {
    return findBadgeMatchingGuest({
      variants: this.module.variants,
      sort: this.module.sort,
      guest: this.guest,
      event: this.storeEvent,
      eventRights: this.eventRights
    })
  }

  get matchingVariantSegmentName(): string {
    if (!this.matchingVariant) {
      return ''
    }
    const segment = getSegmentFromId(this.storeEvent, this.matchingVariant.segmentId)
    if (!segment) {
      return ''
    }
    return `"${segment.name}"`
  }

  get hasBadge(): boolean {
    return this.matchingVariant !== null
  }

  get badgeURL(): string {
    return this.hasBadge ? getBadgeURLForGuest(this.guest, this.storeEvent._id) : ''
  }

  get title(): string {
    return joinIfExist([this.item.label, this.matchingVariantSegmentName], ' ')
  }
}
