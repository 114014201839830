import { ElementTypeDictionary } from '../../types'
import Quote1Element from './quote1/Quote1Element'
import { Quote2Element } from './quote2'

export const quoteDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Quote1Element,
    renderer: () => import('./quote1/Quote1.vue'),
    properties: [
      {
        type: 'color',
        key: 'properties.tagColor',
        label: 'TAG_COLOR'
      },
      {
        type: 'color',
        key: 'properties.quoteTextBackgroundColor',
        label: 'TEXT_BACKGROUND_COLOR',
        toContrastWith: 'properties.quoteTextColor'
      },
      {
        type: 'color',
        key: 'properties.quoteTextColor',
        label: 'TEXT_COLOR',
        toContrastWith: 'properties.quoteTextBackgroundColor'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_QUOTE_1',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    dataClass: Quote2Element,
    renderer: () => import('./quote2/Quote2.vue'),
    properties: [
      {
        type: 'color',
        key: 'properties.tagColor',
        label: 'TAG_COLOR'
      },
      {
        type: 'color',
        key: 'properties.titleColor',
        label: 'TITLE_COLOR'
      },
      {
        type: 'color',
        key: 'properties.quoteTextColor',
        label: 'QUOTE_TEXT_COLOR',
        toContrastWith: 'properties.quoteBackgroundColor'
      },
      {
        type: 'color',
        key: 'properties.quoteBackgroundColor',
        label: 'QUOTE_BKG_COLOR',
        toContrastWith: 'properties.quoteTextColor'
      },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_QUOTE_1',
    previewImageLink: require('@/assets/img/prefab-previews/quotes2.jpg')
  })
  .finish()
