import type { FullRouteConfig } from '@/core'

const routes: FullRouteConfig[] = [
  {
    path: '',
    name: 'eventsList',
    component: () => import('./EventsList/EventsList.vue')
  },
  {
    path: 'create',
    name: 'createEvent',
    component: () => import('./NewEvent.vue')
  },
  {
    path: 'duplicate/:originalEventId',
    name: 'duplicateEvent',
    component: () => import('./NewEvent.vue'),
    props: true
  }
]

export default routes
