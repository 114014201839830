















import { Component, Vue, Prop } from 'vue-property-decorator'
import type { GroupActivity } from '@/models/Event/modules/Programme'

@Component
export default class GroupActivityLinkToEdition extends Vue {
  @Prop({ required: true }) readonly groupActivitySession!: GroupActivity
}
