







import { Vue, Component, VModel, Prop } from 'vue-property-decorator'

@Component
export default class PlainTextFragment extends Vue {
  @VModel({ required: true }) textContent!: string
  @Prop() placeholder: string | undefined
  @Prop() readonly noInput!: boolean
  @Prop({ type: Boolean }) readonly disabled!: boolean

  get computedPlaceholder(): string {
    if (typeof this.placeholder === 'string') {
      return this.placeholder
    } else {
      return this.$t('FORM_TEXTAREA_PLACEHOLDER')
    }
  }

  get blockStyle(): Record<string, any> {
    return {
      '--placeholder-text': `"${this.computedPlaceholder}"`
    }
  }
}
