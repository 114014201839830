import { ElementTypeDictionary } from '../../types'
import Socials1Element from './socials1/Socials1Element'
import SocialPost1Element from './socialPost/SocialPost1Element'

export const socialsDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Socials1Element,
    renderer: () => import('./socials1/Socials1.vue'),
    properties: [
      {
        type: 'color',
        label: 'TITLE_COLOR',
        key: 'properties.titleColor',
        toContrastWith: 'properties.containerBackgroundColor'
      },
      {
        type: 'color',
        label: 'LOGOS_COLOR',
        key: 'properties.logosColor',

        toContrastWith: 'properties.containerBackgroundColor'
      },
      { type: 'color', label: 'CONTAINER_BACKGROUND_COLOR', key: 'properties.containerBackgroundColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_SOCIALS_LINKS',
    previewImageLink: require('@/assets/img/prefab-previews/social.jpg')
  })
  .addType({
    dataClass: SocialPost1Element,
    renderer: () => import('./socialPost/SocialPost1.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_SOCIAL_POST',
    previewImageLink: require('@/assets/img/prefab-previews/socialPost.jpg')
  })
  .finish()
