import { getGuests } from '@/services/guest'

// potential refactor
export async function fetchSpeakers(idsOfSpeakers: string[], eventId: string) {
  const { guests } = await getGuests(eventId, {
    filter: {
      andCriterias: [
        {
          property: '_id',
          operator: 'IN_ARRAY',
          testedValue: idsOfSpeakers
        }
      ],
      orCriterias: []
    }
  })
  return guests
}
