










import { Component, Prop } from 'vue-property-decorator'
import type { CreationEntry } from '@/services/guestHistory'
import { timezoneFormat } from '@/lib/timezoneFormat'
import { importExcel } from '@/lib/importExcel'
import HistoryBlock from '@/components/features/HistoryBlock.vue'
import type { EventIdentity } from '@/core'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { HistoryBlock }
})
export default class CreationCard extends Vue {
  @Prop({ required: true }) readonly entry!: CreationEntry

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get iconName(): string {
    if (this.entry.creation === 'extra') {
      return 'group-line'
    }
    if (this.entry.creation === 'register') {
      return 'share-line'
    }
    if (this.entry.creation.includes('excel')) {
      return 'file-add-line'
    }

    return 'user-add-line'
  }

  get formattedDate() {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return timezoneFormat(this.entry.date, eventIdentity.timezone)
  }

  get excelCreation() {
    if (this.entry.creation.includes('excel')) {
      return importExcel(this.entry.creation)
    }
    return ''
  }
}
