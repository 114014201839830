import { ElementTypeDictionary } from '../types'
import TextElement from './TextTiny/TextElement'
import ImageElement from './Image/ImageElement'
import DividerElement from './Divider/DividerElement'
import ButtonElement from './Button/ButtonElement'
import MapElement from './Map/MapElement'
import SocialElement from './Social/SocialElement'

export const customBlockDirectory: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    renderer: () => import('./TextTiny/TextTiny.vue'),
    dataClass: TextElement,
    typeNameSlug: 'BLOCK_TEXT',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Image/Image.vue'),
    dataClass: ImageElement,
    properties: [
      {
        type: 'collapsegroup',
        label: 'IMAGE_LINK',
        content: [
          {
            key: 'properties.linkTo',
            type: 'url',
            label: 'SET_LINK_DESTINATION'
          }
        ]
      },
      {
        type: 'collapsegroup',
        label: 'CATEGORY_ACCESSIBILITY',
        content: [
          {
            label: 'IMAGE_ALT_TEXT',
            type: 'plaintext',
            key: 'properties.altText'
          }
        ]
      },
      {
        type: 'collapsegroup',
        label: 'IMAGE_STYLE',
        visible: true,
        content: [
          {
            key: 'properties.useWidthAsPercent',
            type: 'boolean',
            label: 'IMAGE_USE_PERCENTAGE_OF_BLOCK_WIDTH'
          },
          {
            key: 'properties.widthPercent',
            type: 'range',
            label: 'BUILDER_EL_O_WIDTH',
            min: 5,
            max: 100,
            default: 100,
            unit: '%',
            condition: ({ element }) => element.properties.useWidthAsPercent
          },
          {
            key: 'properties.align',
            type: 'align',
            label: 'ALIGNMENT',
            condition: ({ element }) => !element.properties.fullWidth
          }
        ]
      }
    ],
    typeNameSlug: 'IMAGE',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Divider/Divider.vue'),
    dataClass: DividerElement,
    properties: [
      {
        type: 'collapsegroup',
        label: 'STYLE',
        visible: true,
        content: [
          {
            type: 'rowgroup',
            content: [
              {
                key: 'properties.size',
                type: 'range',
                min: 0,
                max: 20,
                unit: 'px',
                label: 'LINE_WIDTH'
              }
            ]
          },
          {
            type: 'seamlessgroup',
            condition: ({ element }) => element.properties.size > 0,
            content: [
              {
                key: 'properties.color',
                type: 'color',
                label: 'COLOR'
              },
              {
                key: 'properties.style',
                type: 'borderstyle',
                label: 'LINE_STYLE'
              }
            ]
          }
        ]
      }
    ],
    typeNameSlug: 'BLOCK_DIVIDER',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Button/Button.vue'),
    dataClass: ButtonElement,
    properties: [
      {
        type: 'collapsegroup',
        label: 'LINK',
        visible: true,
        content: [
          {
            key: 'properties.linkTo',
            type: 'url',
            label: 'SET_LINK_DESTINATION'
          }
        ]
      },
      {
        type: 'collapsegroup',
        label: 'BUTTON_STYLE',
        content: [
          {
            key: 'properties.backgroundColor',
            type: 'color',
            label: 'BACKGROUND_COLOR',
            canBeTransparent: true
          },
          {
            type: 'color',
            label: 'BUILDER_EL_O_TEXT_COLOR',
            key: 'properties.font.color'
          },
          {
            type: 'rowgroup',
            content: [
              {
                type: 'fontfamily',
                label: 'FONT_FAMILY',
                key: 'properties.font.family'
              },
              {
                type: 'fontsize',
                label: 'FONT_SIZE',
                key: 'properties.font.size'
              }
            ]
          },
          {
            type: 'boolean',
            label: 'BOLD',
            key: 'properties.font.bold'
          },
          {
            type: 'seamlessgroup',
            label: 'BORDER',
            content: [
              {
                type: 'rowgroup',
                content: [
                  {
                    key: 'properties.border.size',
                    type: 'range',
                    min: 0,
                    max: 20,
                    unit: 'px',
                    label: 'BORDER'
                  },
                  {
                    key: 'properties.border.radius',
                    type: 'range',
                    min: 0,
                    max: 50,
                    unit: 'px',
                    label: 'RADIUS'
                  }
                ]
              },
              {
                type: 'seamlessgroup',
                condition: ({ element }) => element.properties.border.size > 0,
                content: [
                  {
                    key: 'properties.border.color',
                    type: 'color',
                    label: 'BORDER_COLOR'
                  }
                ]
              }
            ]
          },
          {
            type: 'rowgroup',
            content: [
              {
                key: 'properties.fullWidth',
                type: 'boolean',
                label: 'FULL_WIDTH'
              },
              {
                key: 'properties.align',
                type: 'align',
                label: 'ALIGNMENT',
                condition: ({ element }) => !element.properties.fullWidth
              }
            ]
          },
          {
            type: 'seamlessgroup',
            label: 'Spacing',
            content: [
              {
                key: 'properties.padding',
                type: 'padding',
                label: 'PADDING'
              }
            ]
          }
        ]
      }
    ],
    typeNameSlug: 'BUTTON',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Map/Map.vue'),
    dataClass: MapElement,
    properties: [
      {
        type: 'collapsegroup',
        label: 'MAP_PARAMS',
        visible: true,
        content: [
          {
            key: 'properties.marker.address',
            type: 'plaintext',
            label: 'LOCATION'
          }
        ]
      }
    ],
    typeNameSlug: 'BLOCK_MAP',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Social/Social.vue'),
    dataClass: SocialElement,
    properties: [
      {
        key: 'properties.align',
        type: 'align',
        label: 'ALIGNMENT'
      }
    ],
    controlPanelExtra: () => import('./panelContentExtras/SocialModalButton.vue'),
    typeNameSlug: 'SOCIAL_LINKS',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .finish()
