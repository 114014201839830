
















import { Component, Prop, Vue } from 'vue-property-decorator'
import type { DigiVerticalMenuItem } from './types'
import DigiVerticalMenuTab from './parts/DigiVerticalMenuTab.vue'
import DigiVerticalMenuSub from './parts/DigiVerticalMenuSub.vue'

@Component({
  components: { DigiVerticalMenuSub, DigiVerticalMenuTab }
})
export default class DigiVerticalMenu extends Vue {
  @Prop({ required: true }) readonly items!: DigiVerticalMenuItem[]
  @Prop({}) readonly initialOpenedSubKey?: string

  activeItemKey: string | null = null

  openSubMenu(key: string) {
    this.activeItemKey = key
    this.$emit('sub-menu-opened', key)
  }

  closeSubMenu() {
    this.$emit('sub-menu-closed')
    this.activeItemKey = null
  }

  get isInSubMenu(): boolean {
    return this.activeItemKey !== null
  }

  get activeItem(): DigiVerticalMenuItem | undefined {
    return this.items.find((item) => item.key === this.activeItemKey)
  }

  mounted() {
    if (this.initialOpenedSubKey) {
      this.openSubMenu(this.initialOpenedSubKey)
    }
  }
}
