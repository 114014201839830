import type { EventId } from '@/models/Event'
import type { CustomFieldType } from '@/models/Event/CustomField'
import type { GuestFieldType } from '@/models/Event/GuestField'
import type { GuestId, GuestSource } from '@/models/Guest'
import axios from 'axios'

type GuestLogContext = 'back' | 'knox' | 'api' | 'checkin' | 'networking' | 'registration' | 'server'

interface BaseEntry {
  date: Date
  updatedBy?: string
}

interface BasicModification {
  value: any
  fieldType: Exclude<GuestFieldType, CustomFieldType.List>
}

interface ListModification {
  value: string[]
  fieldType: CustomFieldType.List
}

export type Modification = BasicModification | ListModification

export type Modifications = Record<string, Modification>

export interface ModificationEntry extends BaseEntry {
  context: GuestLogContext
  modifications: Modifications
}

export interface CreationEntry extends BaseEntry {
  creation: GuestSource
}

export interface GuestHistory {
  entries: ModificationEntry[]
  creation: CreationEntry
}

export async function getGuestHistory(eventId: EventId, guestId: GuestId): Promise<GuestHistory> {
  const res = await axios.get<GuestHistory>(`backoffice/events/${eventId}/guests/${guestId}/history`)
  return res.data
}
