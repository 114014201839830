







import { Vue, Component, Prop } from 'vue-property-decorator'
import SingleSegmentBadge from './SingleSegmentBadge.vue'

const MAX_SEGMENTS = 5
@Component({
  components: { SingleSegmentBadge }
})
export default class SegmentSelectorBadges extends Vue {
  @Prop({ required: true }) readonly segmentsName!: string[]

  get displayedSegmentsName(): string[] {
    return this.segmentsName.slice(0, MAX_SEGMENTS)
  }

  get remainingSegments(): number {
    return this.segmentsName.length - MAX_SEGMENTS
  }
}
