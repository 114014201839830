import { render, staticRenderFns } from "./DigiOverlay.vue?vue&type=template&id=50565d26&scoped=true"
import script from "./DigiOverlay.vue?vue&type=script&lang=ts"
export * from "./DigiOverlay.vue?vue&type=script&lang=ts"
import style0 from "./DigiOverlay.vue?vue&type=style&index=0&id=50565d26&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50565d26",
  null
  
)

export default component.exports