













































import type { DateRange } from '@/models/Event/modules/Programme'
import store from '@/store'
import moment from 'moment'
import { Component, VModel, Prop } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { EventIdentity } from '@/core'

interface DateOptions {
  minDate: string
  maxDate: string
}

@Component
export default class DigiDateTimeRangePicker extends Vue {
  @VModel({ required: true, type: Array }) range!: DateRange
  @Prop({
    default: () => ({
      minDate: moment(store.getters.event.eventDate).format(),
      maxDate: moment(store.getters.event.eventEndDate).format()
    })
  })
  readonly dateOptions!: DateOptions

  @Prop({ default: null }) readonly state!: boolean | null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get localTimezone(): string {
    return moment.tz.guess()
  }

  get eventTimezone(): string {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return (eventIdentity as EventIdentity).timezone
  }

  get shouldMentionTimezone(): boolean {
    return this.localTimezone !== this.eventTimezone
  }

  get locale(): string {
    return this.$i18n.i18next.language
  }

  get isFormValid(): boolean {
    return Boolean(this.date && this.startTime && this.endTime)
  }

  get date(): string {
    return moment(this.range[0]).tz(this.eventTimezone).format('YYYY-MM-DD')
  }
  set date(newDate: string) {
    this.range = [
      this.isoDateAtEventTimezoneToDate(`${newDate}T${this.startTime}`),
      this.isoDateAtEventTimezoneToDate(`${newDate}T${this.endTime}`)
    ]
  }

  get startTime(): string {
    return moment(this.range[0]).tz(this.eventTimezone).format('HH:mm:ss')
  }
  set startTime(newTime: string) {
    const newStartDate: Date = this.isoDateAtEventTimezoneToDate(`${this.date}T${newTime}`)

    const newEndDate: Date =
      newStartDate > this.range[1] ? moment(newStartDate).add(2, 'hours').toDate() : this.range[1]

    this.range = [newStartDate, newEndDate]
  }

  get endTime(): string {
    return moment(this.range[1]).tz(this.eventTimezone).format('HH:mm:ss')
  }
  set endTime(newTime: string) {
    this.range = [this.range[0], this.isoDateAtEventTimezoneToDate(`${this.date}T${newTime}`)]
  }

  isoDateAtEventTimezoneToDate(timestamp: string): Date {
    return moment.tz(timestamp, this.eventTimezone).toDate()
  }
}
