





























import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import PlanningCard from '../PlanningCard.vue'
import type Guest from '@/models/Guest'
import type Unavailability from '@/models/Guest/Planning/ScheduledItem/Unavailability'
import { removeNonMeetingScheduledItem } from '@/features/eventApp'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import UnavailabilityDateRange from './UnavailabilityDateRange.vue'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'

@Component({
  components: { PlanningCard, UnavailabilityDateRange, DigiSimpleIconButton, DigiDeleteConfirmationModal }
})
export default class UnavailabilityCard extends Vue {
  @Prop({ required: true }) readonly unavailabilityId!: string
  @Prop({ required: true }) readonly guest!: Guest

  isLoading = false

  get unavailability(): Unavailability {
    const unavailability = this.guest.planning?.scheduledItems.find(
      (item): item is Unavailability => item._id === this.unavailabilityId && item.kind === 'unavailability'
    )
    if (!unavailability) {
      throw new Error('Could not find unavailability in planning.')
    }
    return unavailability
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }

  async deleteUnavailability() {
    await removeNonMeetingScheduledItem(this.$store.getters.eventId, this.guest._id, this.unavailability._id)
    this.$emit('deleted')
  }
}
