import { z } from 'zod'
import type { EventLangCode } from '../Localized'
import { ErrorWithExtras } from '@/utils/errorTypes'
import { amount100, rate } from '@/models/Guest/Order/money'

enum MpKYCLevel {
  Regular = 'REGULAR',
  Light = 'LIGHT'
}
type MangoPayData = {
  id: string
  KYCLevel: MpKYCLevel
  bankAccountId?: string
  walletId: string
}

interface FeesTransfer {
  date: Date
  amount: number
  status: TransactionStatus
  resultMessage: string
}

enum TransactionStatus {
  Init = 'CREATED',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}
interface FeesBilling {
  isEligible: boolean
  invoiceRef?: string
  note?: string
  feesDiscountAmount?: number
}

export enum Currency {
  Eur = 'EUR',
  Usd = 'USD',
  Cad = 'CAD',
  Gbp = 'GBP',
  Chf = 'CHF'
}

export interface Cerfa {
  object?: string
  cerfaNumberStart: number
  prefixCerfaNumber: string
  legalRepresentantName: string
  signatureURL?: string
}

export type GeneralPaymentSettings = {
  allowMultipleTicketsPerOrder: boolean
  allowMultipleOrders: boolean
  isProformaEnabled: boolean
  isCerfaEnabled: boolean
  explanationText?: string
  overallTicketQuota: number | null
  highlightedPriceVATMode: 'vatIncluded' | 'vatExcluded'
  supportsVatExemption: boolean
}

type InvoiceLanguage = EventLangCode.French | EventLangCode.English

export interface InvoiceTemplate {
  logoURL?: string
  invoiceNumberStart: number
  prefixInvoiceNumber: string
  lang: InvoiceLanguage
  footerText?: string
}

export const zMethodFees = z.object({
  /**
   * Rate — between 0 and 1
   *  0 = 0%, 1 = 100%, 1% = 0.01
   */
  variable: rate,
  /**
   * Always in the currency of the event. In cents (amount100 format : 100 = 100eurocents = 1euro)
   * Amount100 = cents. 100 = 1euro. 50 = 0.5euro, 150 = 1.5euro
   */
  fixed100: amount100
})

export const zPaymentMethodType = z.enum([
  'card',
  'cardAmex',
  'bankWire',
  'cash',
  'otherPaymentMethod',
  'notChosen',
  'sepaDebit',
  'stripeLink'
])
export type PaymentMethodType = z.infer<typeof zPaymentMethodType>
export const zPaymentMethodWithFees = zPaymentMethodType.exclude(['cash', 'otherPaymentMethod', 'notChosen'])
export const zPerPaymentMethodFees = z.record(zPaymentMethodWithFees, zMethodFees)
export type PerPaymentMethodFees = z.infer<typeof zPerPaymentMethodFees>

export class PaymentModule {
  isCerfaEnabled!: boolean
  requiredFields!: unknown[]
  mangoPay!: MangoPayData
  cerfa?: Cerfa
  invoice!: InvoiceTemplate
  isProformaEnabled!: boolean
  feesTransfers!: FeesTransfer[]
  feesBilling?: FeesBilling
  termsOfSalesHtmlDescription?: string
  overallTicketQuota: number | null = null
  tickets!: Ticket[]
  ticketsSort!: string[]
  supportsVatExemption!: boolean

  getTicketById(id: string): Ticket {
    const result = this.tickets.find((template) => template._id === id)

    if (!result) {
      throw new ErrorWithExtras('Ticket not found', { ticketId: id })
    }

    return result
  }

  addons!: Addon[]
  addonsSort!: string[]

  getAddonById(id: string): Addon {
    const result = this.addons.find((template) => template._id === id)

    if (!result) {
      throw new ErrorWithExtras('Add-on not found', { addonId: id })
    }

    return result
  }

  promoCodes!: PromoCode[]

  allowMultipleOrders!: boolean
  allowMultipleTicketsPerOrder!: boolean
  explanationText?: string
  otherPaymentMethod!: {
    enable: boolean
    textInfoPayment: string
  }
  bankWire!: boolean
  cash!: {
    enable: boolean
    textInfoPayment: string
  }
  currency!: Currency
  highlightedPriceVATMode!: VATMode
}

export type PaymentMethods = Pick<PaymentModule, 'cash' | 'otherPaymentMethod' | 'bankWire'>

export type VATMode = 'vatIncluded' | 'vatExcluded'

export type Ticket = PaymentItemBase & {
  maxOrderableQuantity: number
  minOrderableQuantity: number
  isHolderNameRequired: boolean
}

export type Addon = PaymentItemBase & {
  maxOrderableQuantityPerTicket?: number
  TVAPercent: number
  affiliatedTickets: string[]
}

export type PaymentItemBase = {
  _id: string
  TVAPercent: number
  amount: number
  amountDonation?: number
  description?: string
  enable?: boolean
  forceTicketSelectionIfMatchFilters?: boolean
  form: FormElement[]
  maxAvailablePlaces?: number | null
  name: string
  segmentId?: string
}

export type FormElement = FormElementField | FormElementText

export type FormElementField = {
  kind: 'field'
  fieldKey: string
  required: boolean
  description: string
}

export type FormElementText = {
  kind: 'text'
  value: string
}

export const promoCodeValidator = z.object({
  _id: z.string(),
  promoCodeTag: z.string(),
  discountPercentage: z.number().min(0.1).max(100),
  quotas: z.number().min(0).optional(),
  isActive: z.boolean(),
  eligibleTickets: z.array(z.string()),
  eligibleAddons: z.array(z.string()),
  description: z.string()
})

export type PromoCode = z.infer<typeof promoCodeValidator>

export const promoCodeCreationValidator = promoCodeValidator.omit({ _id: true })

export type PromoCodeCreation = z.infer<typeof promoCodeCreationValidator>
