import Settings from './Settings.vue'
import store from '@/store'
import type { Event } from '@/models/Event'
import type { TriggerUtil } from '../../../RuleEdition/types'
import type { AnyTrigger, CheckinExactCheckpointTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import type { CheckinPointId } from '@/models/Event/modules/Checkin'

function isCheckinExactPointTrigger(trigger: AnyTrigger): trigger is CheckinExactCheckpointTrigger {
  return trigger.kind === TriggerKind.CheckinExact
}

function getDefaultCheckinPointId(): CheckinPointId | undefined {
  const event: Event | undefined = store.state.event.event

  if (!event) throw new Error('No event in store')

  return event.modules_data.checkin.points[0]?._id
}

const checkinExactTriggerUtil: TriggerUtil = {
  kind: TriggerKind.CheckinExact as const,
  label: 'CHECKIN_ARRIVED_EXACT' as const,
  icon: 'map-pin-2-line' as const,
  settings: Settings,

  validator(trigger: AnyTrigger): boolean {
    if (!isCheckinExactPointTrigger(trigger)) {
      throw new Error('CheckinExactUtil need a trigger of type CheckinExactCheckpointTrigger')
    }

    return Boolean(trigger.checkPointId)
  },

  isAllowed(): boolean {
    return store.state.event?.rights.features.checkin.app
  },

  onSelection(trigger: AnyTrigger): void {
    if (isCheckinExactPointTrigger(trigger)) {
      const defaultId = getDefaultCheckinPointId()

      if (!defaultId) {
        throw new Error('Unexpected: can not set checkin rule rule without defined checkpoint in event')
      }

      trigger.checkPointId = defaultId
    }
  }
}

export default checkinExactTriggerUtil
