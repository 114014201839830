import type { EventRights } from '@/models/Event/EventRights'
import type { Event } from '@/models/Event'
import { getSiteUrl } from './customDomainUtils'

/**
 * URL parameters for front preview.
 * If guestId is provided, the URL will pass through a proxy that will
 * authenticate the browser.
 */
export interface FrontPreviewUrlParams {
  eventId: string
  path?: string
  query?: Record<string, string>
  /**
   * If guestId is provided, the URL will be authenticated.
   */
  guestId?: string
}

export function getFrontPreviewUrl({ eventId, guestId, path = '', query = {} }: FrontPreviewUrlParams): string {
  const modeUrl = guestId ? `asGuest/${guestId}` : 'anonymous'
  const url = new URL(`${process.env.VUE_APP_API_URL}/events/${eventId}/front/preview/${modeUrl}`)
  url.searchParams.set('path', path)
  url.searchParams.set('query', JSON.stringify(query))
  return url.toString()
}

export function getPagePath(pageSlug: string): string {
  return `/page/${pageSlug}/`
}

export function getPublicFrontUrl({
  event,
  eventRights,
  path,
  query
}: {
  path?: string
  query?: Record<string, string>
  event: Event
  eventRights: EventRights
}): string {
  const siteUrl = new URL(path ?? '', getSiteUrl({ event, eventRights }))

  for (const [key, value] of Object.entries(query ?? {})) {
    siteUrl.searchParams.set(key, value)
  }

  return siteUrl.toString()
}
