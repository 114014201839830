











import { nominativeTagMapper } from '@/features/messages/emailTags/emailNomativeTags'
import { Component, Vue, VModel, Prop } from 'vue-property-decorator'

@Component
export default class SmsInput extends Vue {
  @VModel({ required: true }) sms!: string

  @Prop({ required: true }) readonly disabled!: boolean

  get smsValue(): string {
    return nominativeTagMapper.mapToEditView({
      value: this.sms,
      guestFields: this.$store.getters.orderedGuestFields
    })
  }

  set smsValue(value: string) {
    this.sms = nominativeTagMapper.mapToSave({
      value,
      guestFields: this.$store.getters.orderedGuestFields
    })
  }
}
