import { Type } from 'class-transformer'
import type { TCountryCode } from 'countries-list'

export const socialsNetworksList = [
  'facebook',
  'linkedin',
  'instagram',
  'pinterest',
  'twitter',
  'medium',
  'snapchat',
  'youtube',
  'tiktok',
  'website'
] as const

export type SocialNetwork = typeof socialsNetworksList[number]

export type SocialLinks = Partial<Record<SocialNetwork, string>>

export enum LegalType {
  Business = 'business',
  Association = 'association',
  Other = 'other'
}

export interface PrivacyConditionsDefault {
  kind: 'default'
}

export interface PrivacyConditionsHtml {
  kind: 'html'
  htmlContent: string
}

export interface PrivacyConditionsLink {
  kind: 'link'
  link: string
}

export type PrivacyConditions = PrivacyConditionsHtml | PrivacyConditionsLink | PrivacyConditionsDefault

export class LegalEntity {
  name!: string
  address!: string
  city!: string
  zipCode!: string
  country!: TCountryCode
  incorporationReference!: string
  legalType!: LegalType
}

export default class Identity {
  @Type(() => String)
  logo: string | null = null
  socialNetworks?: SocialLinks
  @Type(() => LegalEntity)
  legalEntity?: LegalEntity
  privacyConditions: PrivacyConditions = {
    kind: 'html',
    htmlContent: ''
  }
}
