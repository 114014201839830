






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiCardItem } from '@/components/ui/cardItem'
import { DigiLink } from '@/components/ui/actions'
import type Filter from '@/models/Event/Filter'
import FilterCriterias from '@/components/features/FilterCriterias.vue'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton, FilterCriterias, DigiLink, DigiCardItem }
})
export default class ProfileSegmentItem extends Vue {
  @Prop({ required: true }) readonly segment!: Filter

  get routeToMatchingGuests() {
    return {
      name: 'contacts',
      query: {
        filterId: this.segment._id
      }
    }
  }
}
