






























import { Component, PropSync, Vue } from 'vue-property-decorator'
import AttachmentInput from './AttachmentInput.vue'
import { DigiButton } from '@/components/ui/actions'

@Component({
  components: {
    AttachmentInput,
    DigiButton
  }
})
export default class AttachmentInputs extends Vue {
  @PropSync('attachment1') syncedAttachment1!: File | undefined
  @PropSync('attachment2') syncedAttachment2!: File | undefined
  @PropSync('attachment3') syncedAttachment3!: File | undefined

  showInput2: boolean = false
  showInput3: boolean = false
}
