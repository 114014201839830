








import { Vue, Component, Prop } from 'vue-property-decorator'
import type { UnscheduledMeeting } from '@/models/Guest/Planning/UnscheduledMeeting'

@Component
export default class MeetingRequestListing extends Vue {
  @Prop({ required: true }) readonly requests!: UnscheduledMeeting[]
}
