












import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'
import { DigiButton } from '@/components/ui/actions'
import type { BModal } from 'bootstrap-vue'

@Component({
  components: { DigiButton }
})
export default class DigiConfirmModal extends Vue {
  @Ref() readonly modal!: BModal
  @Prop({ required: true, type: String })
  readonly title!: string

  @Prop({ required: true, type: String })
  readonly ctaText!: string

  @Prop({ required: false, type: String, default: 'primary' })
  readonly ctaVariant!: string

  @VModel({ type: Boolean })
  readonly modalVisible!: boolean

  confirm() {
    this.$emit('confirm')
  }

  hide() {
    this.modal.hide()
  }
}
