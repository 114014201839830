























import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import DigiCardItemActions from './DigiCardItemActions.vue'
import { DigiLink } from '../actions'
import DigiCardItem from './DigiCardItem.vue'

@Component({
  components: {
    DigiCardItemActions,
    DigiLink,
    DigiCardItem
  }
})
export default class DigiCardItemInformation extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly editLink!: any
}
