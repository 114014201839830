












import type Guest from '@/models/Guest'
import { Component, Prop } from 'vue-property-decorator'
import { ContactEditForm, updateGuestAndNotify } from '@/features/audience/contactEdit'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    ContactProfileActionsSubMenu,
    ContactEditForm
  }
})
export default class ContactProfileEditSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly title!: string

  isDuplicateEmail = false
  isSubmitting = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  async save(newGuest: Partial<Guest>): Promise<void> {
    this.isSubmitting = true
    try {
      await updateGuestAndNotify(newGuest, this.storeEvent._id)
      this.$emit('saved')
    } catch (err: any) {
      if (err.isAxiosError && err.response?.data.slug === 'SERVERR_DUPLICATE_EMAIL') {
        this.isDuplicateEmail = true
      } else throw err
    } finally {
      this.isSubmitting = false
    }
  }
}
