import type { MixedSort, Sort, SortGroup } from '@/models/commons/sort'

export function filterMixedSort(mixedSort: MixedSort, filter: (item: string) => boolean): MixedSort {
  const filteredMixedSort: MixedSort = []
  for (const item of mixedSort) {
    if (isSortGroup(item)) {
      const filteredGroupItems = item.items.filter(filter)
      if (filteredGroupItems.length) {
        filteredMixedSort.push({ ...item, items: filteredGroupItems })
      }
    } else if (filter(item)) {
      filteredMixedSort.push(item)
    }
  }
  return filteredMixedSort
}

export function getMixedSortWithoutEmptyGroups(mixedSort: MixedSort): MixedSort {
  return mixedSort.filter((item) => !isSortGroup(item) || item.items.length)
}

export function isSortGroup(item: MixedSort[number]): item is SortGroup {
  return typeof item === 'object' && 'name' in item && 'items' in item
}

export function sort<T extends {}>({
  arrayToSort,
  sortOrder,
  sortKey
}: {
  arrayToSort: T[]
  sortOrder: Sort
  sortKey: keyof T
}): T[] {
  let sortedArray: T[] = []
  for (const keyValue of sortOrder) {
    sortedArray = findAndAddItem({ key: sortKey, keyValue, items: arrayToSort, arr: sortedArray })
  }
  return sortedArray
}

export function sortMixed<T extends {}>({
  arrayToSort,
  sortOrder,
  sortKey
}: {
  arrayToSort: T[]
  sortOrder: MixedSort
  sortKey: keyof T
}): T[] {
  let sortedArray: T[] = []
  for (const mixedSortItem of sortOrder) {
    if (isSortGroup(mixedSortItem)) {
      const sortedGroup = sort({ arrayToSort, sortOrder: mixedSortItem.items, sortKey })
      sortedArray.push(...sortedGroup)
    } else {
      sortedArray = findAndAddItem({ key: sortKey, keyValue: mixedSortItem, items: arrayToSort, arr: sortedArray })
    }
  }
  return sortedArray
}

function findAndAddItem<T extends {}>({
  key,
  keyValue,
  items,
  arr
}: {
  key: keyof T
  keyValue: string
  items: T[]
  arr: T[]
}) {
  const item = items.find((i) => i[key] === keyValue)
  if (item) {
    arr = [...arr, item]
  }
  return arr
}
