













import { Component, Prop, Vue } from 'vue-property-decorator'
import { InvitationStatusDropdown } from '@/features/registration'
import type Guest from '@/models/Guest'
import ContactProfileActionsSubMenu from '../../ui/ContactProfileActionsSubMenu.vue'
import UtmTracking from './components/UtmTracking.vue'
import RegistrationSource from './components/RegistrationContext.vue'
import RegistrationStatusEditor from './components/RegistrationStatusEditor.vue'

@Component({
  components: {
    RegistrationSource,
    UtmTracking,
    InvitationStatusDropdown,
    ContactProfileActionsSubMenu,
    RegistrationStatusEditor
  }
})
export default class ContactProfileSingleRegistrationSub extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly guest!: Guest
}
