




























































import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

import type { MixedSort, SortGroup } from '@/models/commons/sort'
import DiscardableTipCard from '@/components/features/sortable/sortableMixed/DiscardableTipCard.vue'

import GroupItem from './groupItem/GroupItem.vue'
import FlatItem from '../FlatItem.vue'
import { isSortGroup } from '@/utils/sort'
import { DigiIconButton } from '@/components/ui/actions'

@Component({ components: { DigiIconButton, DiscardableTipCard, Draggable, FlatItem, GroupItem } })
export default class SortableMixed extends Vue {
  @VModel({ required: true }) mixedSort!: MixedSort

  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly hiddenItems?: string[]
  @Prop() readonly tipStoragePrefix!: string
  @Prop({ default: true }) readonly tipEnabled!: boolean
  @Prop({ required: true, type: Function }) readonly deleteGroup!: (groupId: string) => Promise<void>
  @Prop() readonly deleteGroupModalSlugTitle!: string

  get showTip(): boolean {
    const count = { group: 0, ungrouped: 0 }
    for (const item of this.mixedSort) {
      if (isSortGroup(item)) {
        count.group++
      } else {
        count.ungrouped++
      }
    }
    return count.ungrouped >= 12 && count.group === 0
  }

  isSortGroup(item: MixedSort[number]): item is SortGroup {
    return isSortGroup(item)
  }

  canBePullOfTheList(_from: unknown, _to: unknown, element: HTMLElement): boolean {
    return element.classList.contains('can-be-pull-of-the-list')
  }

  isItemHidden(id: string): boolean {
    if (!this.hiddenItems) {
      return false
    }
    return this.hiddenItems.includes(id)
  }
}
