


















import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import MethodSelection from './MethodSelection.vue'
import type { AddAccompanistMethod } from './types'
import CreateAccompanist from './CreateAccompanist.vue'
import SelectAccompanist from './SelectAccompanist.vue'
import type Guest from '@/models/Guest'
import type { GuestField } from '@/models/Event/GuestField'

@Component({ components: { MethodSelection, CreateAccompanist, SelectAccompanist } })
export default class AddAccompanistModal extends Vue {
  @VModel({ required: true }) showModal!: boolean

  @Prop({ required: true }) readonly master!: Guest
  @Prop({ required: true }) readonly selectedFieldsData!: GuestField[]

  method: AddAccompanistMethod = 'create'

  onAccompanistCreated(): void {
    this.$emit('accompanistCreated')
    this.hide()
  }

  onAccompanistSelected(accompanist: Guest): void {
    this.$emit('accompanistSelected', accompanist)
    this.hide()
  }

  hide(): void {
    this.showModal = false
  }
}
