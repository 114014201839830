import type { MongoDb } from '@/models/MongoDbTools'
import type { FilterId } from '../../Filter'

export type MasterRuleId = MongoDb.ObjectId

export default class MasterRule {
  _id!: MasterRuleId
  mastersSegmentsIds: [] | [FilterId] = []
  maxSlavesRegistration: number = 1
}
