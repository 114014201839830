







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PlanningDateRange extends Vue {
  @Prop({ required: true }) readonly dateRangeString!: string
}
