


























import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { DigiButton } from '@/components/ui/actions'
import { DigiIconButton } from '@/components/ui/actions'
import { computeSearchParamState } from '../utils'

@Component({
  components: { DigiIconButton, DigiButton, DigiSimpleIconButton }
})
export default class SearchBar extends Vue {
  @VModel({ required: true, type: String }) searchString!: string
  @Prop({ required: true, default: false, type: Boolean }) isSearching!: boolean
  @Prop({ default: 'md' }) size?: string
  @Prop(String) placeholder?: string

  get inputIsNotEmpty() {
    return this.searchString.length > 0
  }

  isSearchValid: boolean | null = null

  handleSearch(): void {
    this.isSearchValid = computeSearchParamState(this.searchString)

    if (!this.isSearchValid) {
      return
    }

    this.search()
  }

  search(): void {
    this.$emit('search')
  }

  clearSearch(): void {
    this.$emit('input', '')
  }
}
