import type { EventRights } from '@/models/Event/EventRights'
export const campaignMenuSection = {
  key: 'campaigns',
  titleSlug: 'EVENT_MENU_CAMPAIGNS_TITLE',
  iconName: 'mail-send-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_CAMPAIGNS_LIST_LINK',
      routeName: 'event.campaigns',
      requiredPermissions: ['sendCampaigns'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.campaigns
    }
  ]
} as const
