



























import PrefabCta from '../../components/cta/PrefabCta.vue'

import { ImageFragment, PlainTextFragment, SlightlyRichTextFragment } from '../../../fragments'

import type { Action1Element } from './Action1Element'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import type { Website } from '@/models/Event/modules/Website'
import { pickColorWithPalette } from '@/utils/colors'
import type { PageElementId } from '../../../types'
import type { BuilderState } from '@/features/website/builder/BuilderState'
import { assignElement } from '../../../utils'

@Component({ components: { ImageFragment, PlainTextFragment, SlightlyRichTextFragment, PrefabCta } })
export default class PrefabAction1 extends Vue {
  @Prop({ required: true }) readonly id!: PageElementId
  @Prop({ required: true }) readonly website!: Website

  @Inject() builderState!: BuilderState

  get pageElement(): Action1Element {
    return this.builderState.assertedPageContent?.elements[this.id] as Action1Element
  }

  setElementProperty(propertyPath: string, newValue: any): void {
    const newElement = assignElement(this.pageElement, propertyPath, newValue)

    this.builderState.assertedPageContent!.updateElement(this.id, newElement)
  }

  get cta(): CtaProperties {
    return this.pageElement.properties.cta
  }

  get titleColor() {
    return pickColorWithPalette(this.pageElement.properties.titleColor, this.$store.getters.colorPalette)
  }

  get textColor() {
    return pickColorWithPalette(this.pageElement.properties.textColor, this.$store.getters.colorPalette)
  }

  get blockColor() {
    return pickColorWithPalette(this.pageElement.properties.blockColor, this.$store.getters.colorPalette)
  }
}
