import { VideoPlatform } from './types/helperTypes'

export function getIframeSrc(chosenPlatform: VideoPlatform, videoCode: string): string {
  switch (chosenPlatform) {
    case VideoPlatform.Youtube:
      return `https://www.youtube.com/embed/${videoCode}`
    case VideoPlatform.Vimeo:
      return `https://player.vimeo.com/video/${videoCode}?h=4bec5f48ba&color=ffffff&title=0&byline=0&portrait=0&badge=0`
    case VideoPlatform.Custom:
      return videoCode
    default:
      throw new Error('Chosen platform value is not supported')
  }
}
