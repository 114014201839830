import { BoxProperties } from '../../../types'
import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import TextElementBase, { TextProperties } from '../TextElementBase'

export class Text1Properties extends TextProperties {
  title: string = ''
  titleColor: string = ''
}

export default class Text1Element extends TextElementBase {
  static create({ palette }: ElementCreationContext): Text1Element {
    const text = new Text1Element()

    text.properties.title = i18n.t('AN_INSPIRING_TITLE')

    text.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)

    text.box ??= new BoxProperties()
    text.box.backgroundColor = palette.lightestColor.paletteCode

    text.properties.textColor = palette.getMostReadableColor(palette.lightestColor.color).paletteCode
    text.properties.titleColor = palette.darkestColor.paletteCode

    return text
  }

  type = `prefab-text-1` as const

  @Type(() => Text1Properties)
  properties: Text1Properties = new Text1Properties()

  get prettyName(): string {
    return i18n.t('BLOCK_TEXT')
  }
}
