import type { EventRights } from '@/models/Event/EventRights'
export const surveysMenuSection = {
  key: 'surveys',
  titleSlug: 'EVENT_MENU_SURVEYS_TITLE',
  iconName: 'chat-poll-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_SURVEYS_CONFIG_LINK',
      routeName: 'event.website.surveys',
      requiredPermissions: ['configEvent'] as const,
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.website.allowed
    },
    {
      titleSlug: 'EVENT_MENU_SURVEYS_RULES_LINK',
      routeName: 'event.website.surveys.rules',
      requiredPermissions: ['configEvent'] as const,
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.website.allowed
    }
  ]
} as const
