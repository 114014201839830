import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

export class ActionProperties extends PrefabPropertiesBase {}

export default abstract class ActionElementBase extends PrefabElementBase {
  abstract type: `prefab-action-${string}`
  isFullPrefab: boolean = true

  @Type(() => ActionProperties)
  properties: ActionProperties = new ActionProperties()
}
