






import { Component, Prop } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import type { AnyTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import Vue from 'vue'

@Component({
  model: {
    prop: 'trigger',
    event: 'update'
  }
})
export default class CheckinExactSettings extends Vue {
  @Prop() readonly trigger!: AnyTrigger

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get checkinPoints(): { value: string; text: string }[] {
    if (!this.eventRights.features.checkin.app) return []
    return this.storeEvent.modules_data.checkin.points.map((point) => ({ value: point._id, text: point.name }))
  }
}
