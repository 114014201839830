import { $enum } from 'ts-enum-util'
import { EventLangCode } from '@/models/Event/Localized'
import type { ReadonlyFullRouteConfig } from '@/core'

export const websiteBuilderRoutes: ReadonlyFullRouteConfig = [
  {
    path: `website/:lang(${$enum(EventLangCode).getValues().join('|')})?`,
    name: 'event.website.websiteBuilder',
    component: () => import('@/features/website/builder/view/WebsiteBuilder.vue'),
    meta: {
      get name() {
        return 'WEBSITE_CONTENT'
      },
      description: 'FACTORY_CUSTOM_YOUR_WEBSITE_DESC',
      iconName: 'mac-line',
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.website.allowed,
        redirectName: 'event.dashboard'
      },

      renderingData: {
        doNotWrap: true,
        hideEventMenu: true
      }
    },
    children: [
      {
        path: 'page/:pageId',
        name: 'website.websiteBuilder.page',
        component: () => import('./components/MainBody/components/PageRenderer.vue'),
        meta: {
          subPageOf: 'event.website.content'
        }
      }
    ]
  }
]
