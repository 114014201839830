








import EventLayout from '@/layouts/eventLayout/EventLayout.vue'
import { eventLoaderMiddleware, checkRouteAccessibility } from '@/navigation/routingExtras'
import type { AxiosError } from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import DigiHeader from '@/layouts/components/Header/DigiHeader.vue'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'

@Component<EventLoader>({
  components: {
    DigiHeader,
    EventLayout
  },
  beforeRouteUpdate(to, from, next) {
    return eventLoaderMiddleware(to, from, next)
  },
  sockets: {
    async event_updated(data) {
      if (data.eventId === this.$store.getters.eventId) {
        if (this.skipNextEventUpdate) {
          this.skipNextEventUpdate = false
        } else {
          this.$store.dispatch('reloadCurrentEvent')
          this.$store.dispatch('reloadCurrentEventIdentity')
        }
      }
    },
    push_notification(notification) {
      let notificationType =
        notification.type && ['success', 'error'].includes(notification.type) ? notification.type : 'info'

      this.$notify({
        type: notificationType,
        text: this.$t(notification.message)
      })
    }
  }
})
export default class EventLoader extends Vue {
  skipNextEventUpdate = false
  storeUnsubscribe!: Function
  isEventLoaded = false

  get eventIsReady() {
    return this.$store.getters.hasEvent && this.$store.getters.eventId === this.$route.params.id && this.isEventLoaded
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  async mounted() {
    this.storeUnsubscribe = this.$store.subscribeAction(({ type }) => {
      if (type === 'saveEvent') {
        this.skipNextEventUpdate = true
      }
    })
    await this.loadEventIfNeeded()
    this.$socket.emit('follow_synchro', { eventId: this.$store.getters.eventId })
    this.$socket.emit('follow_backoffice', {
      eventId: this.$store.getters.eventId,
      accountId: this.$store.state.account.account._id
    })
  }

  async updated() {
    await this.loadEventIfNeeded()
  }

  async loadEventIfNeeded() {
    if (!this.eventIsReady) {
      await this.loadEvent()
      await this.runPermissionCheck()
    }
  }

  beforeDestroy() {
    if (this.storeUnsubscribe) this.storeUnsubscribe()
  }

  destroyed() {
    this.$store.commit('REMOVE_EVENT')
  }

  get eventId() {
    const eventId = this.$route.params.id
    if (!eventId) {
      throw new Error('Event id not found')
    }
    return eventId
  }
  async loadEvent() {
    this.isEventLoaded = false
    try {
      await this.$store.dispatch('loadEventById', { id: this.eventId })
      await this.$store.dispatch('loadEventIdentityById', { id: this.eventId })
      this.isEventLoaded = true
    } catch (err: AxiosError | Error | unknown) {
      if (err instanceof Error && (err as AxiosError).response?.status === 404) {
        this.$notify({
          text: this.$t('NO_EVENT_FOUND'),
          type: 'error'
        })
        this.$router.replace({ name: 'eventsList' })
      }
      throw err
    }
  }

  async runPermissionCheck() {
    try {
      const permCheck = await checkRouteAccessibility(this.$route, this.eventRights)

      if (permCheck.mustRedirect) {
        this.$router.replace(permCheck.newRoute)
      }
    } catch (error) {
      console.error(error)
    }
  }
}
