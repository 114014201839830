import { render, staticRenderFns } from "./Quote2.vue?vue&type=template&id=ae32308e&scoped=true"
import script from "./Quote2.vue?vue&type=script&lang=ts"
export * from "./Quote2.vue?vue&type=script&lang=ts"
import style0 from "./Quote2.vue?vue&type=style&index=0&id=ae32308e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae32308e",
  null
  
)

export default component.exports