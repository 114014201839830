










import { Component, Vue, VModel } from 'vue-property-decorator'

@Component
export default class DescriptionInput extends Vue {
  @VModel({ required: true }) readonly description!: string | undefined
}
