





























import { Component, Prop } from 'vue-property-decorator'
import type Form from '@/models/Event/Form'
import { getRegistrationForm } from '@/features/registration'
import type { SubmittedForm } from '@/models/Guest'
import type Guest from '@/models/Guest'
import { formatUserAgent } from '@/utils/userAgent'
import { timezoneFormat } from '@/lib/timezoneFormat'
import { assertEventRights } from '@/services/eventRights'
import { extractDateFromExcelImport } from '@/lib/importExcel'
import { getEventLangCodeSlug } from '@/models/Event/Localized'
import type { EventIdentity } from '@/core'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

interface BaseRegistrationContext {
  date: string
  source: string
}

interface FormRegistrationContext extends BaseRegistrationContext {
  kind: 'form'
  lang: string
  userAgent: string
}

type RegistrationContextObject = (BaseRegistrationContext & { kind: 'base' }) | FormRegistrationContext

@Component({})
export default class RegistrationContext extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get guestLang() {
    if (!this.guest.registerLanguage) return null
    return this.$t(getEventLangCodeSlug(this.guest.registerLanguage))
  }

  get registrationForm(): Form | undefined {
    return getRegistrationForm(this.storeEvent.forms)
  }

  get eventRights() {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get userRegistrationFormSubmission(): SubmittedForm | undefined {
    const registrationForm = this.registrationForm
    if (!registrationForm || !this.guest.submittedForms) return undefined
    const registerFormsIds = [registrationForm._id]
    if (
      this.eventRights.features.registration.mode === 'free' &&
      this.eventRights.features.registration.type === 'multi'
    ) {
      const multiRegistrationFormId = this.storeEvent.modules_data.multiRegistration.slaveFormId
      registerFormsIds.push(multiRegistrationFormId)
    }
    return this.guest.submittedForms.find((submittedForm) => registerFormsIds.includes(submittedForm.formId))
  }

  get registrationContext(): RegistrationContextObject {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    const timezone = eventIdentity.timezone
    if (this.guest.source === 'register' && this.userRegistrationFormSubmission) {
      const userAgent = formatUserAgent(this.userRegistrationFormSubmission.userAgent)
      return {
        kind: 'form',
        source: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_FORM'),
        date: timezoneFormat(this.userRegistrationFormSubmission.lastSubmissionDate, timezone, 'LLL'),
        lang: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_LANG', { lang: this.guestLang }),
        userAgent: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_USER_AGENT', { userAgent })
      }
    }
    if (this.guest.source.startsWith('excel')) {
      return {
        kind: 'base',
        source: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_EXCEL'),
        date: timezoneFormat(extractDateFromExcelImport(this.guest.source), timezone)
      }
    }
    const date = timezoneFormat(this.guest.creationDate, timezone)
    if (this.guest.source === 'back') {
      return {
        kind: 'base',
        source: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_BACK'),
        date
      }
    } else if (this.guest.source === 'extra') {
      return {
        kind: 'base',
        source: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_EXTRA'),
        date
      }
    } else {
      return {
        kind: 'base',
        source: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_SOURCE_UNKNOWN'),
        date
      }
    }
  }
}
