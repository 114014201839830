import store from '@/store'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import type { TriggerUtil } from '../../RuleEdition/types'
import { i18n } from '@/utils/i18n'

const orderPaidTriggerUtil: TriggerUtil = {
  kind: TriggerKind.OrderPaid as const,
  label: 'ORDER_PAID' as const,
  icon: 'coins-line' as const,
  nominativeTags: [{ value: '*|ORDER_INVOICE_LINK|*', title: () => i18n.t('TAG_NAME_ORDER_INVOICE_LINK') }] as const,

  isAllowed(): boolean {
    return store.getters.isPaidEvent
  }
}

export default orderPaidTriggerUtil
