import { matchAllFilters } from '@digitevent/filterutils'
import store from '@/store'
import type { Activity, GroupActivity } from '@/models/Event/modules/Programme'
import type Guest from '@/models/Guest'
import type { Event } from '@/models/Event'
import axios from 'axios'

export function isGuestEligibleToActivity(guest: Guest, activity: GroupActivity) {
  const event = store.state.event.event
  if (!event) {
    throw new Error('Event not loaded in store, cannot evaluate eligibility.')
  }
  return matchAllFilters(
    activity.eligibleSegmentIds.map((id) => event.getFilterById(id)),
    guest
  )
}

export function getMandatoryGroupActivitiesOfGuest(guest: Guest, event: Event): GroupActivity[] {
  const activities = []

  for (const activity of event.modules_data.programme.groupActivities()) {
    if (activity.mandatoryIfEligible && isGuestEligibleToActivity(guest, activity)) {
      activities.push(activity)
    }
  }

  return activities
}

export async function createGroupActivity(
  data: Pick<GroupActivity, 'title' | 'hasVisio' | 'dateRange'>,
  eventId: string
): Promise<string> {
  return axios
    .post<{ groupActivityId: string }>(`/backoffice/events/${eventId}/programme/groupActivities`, {
      data
    })
    .then((res) => res.data.groupActivityId)
}

export async function updateGroupActivity(groupActivity: GroupActivity, eventId: string): Promise<void> {
  return axios.put(`/backoffice/events/${eventId}/programme/groupActivities/${groupActivity._id}`, {
    groupActivity
  })
}

export async function duplicateGroupActivity({
  groupActivityId,
  eventId
}: {
  groupActivityId: string
  eventId: string
}): Promise<string> {
  return axios
    .post<{ groupActivityId: string }>(`/backoffice/events/${eventId}/programme/activity/${groupActivityId}/duplicate`)
    .then((res) => res.data.groupActivityId)
}

export function makeEmptyGroupActivity(): GroupActivity {
  return {
    _id: '',
    kind: 'groupActivity',
    title: '',
    dateRange: [new Date(), new Date()],
    location: '',
    liveAccessOffset: 0,
    hasVisio: false,
    description: '',
    eligibleSegmentIds: [],
    speakers: [],
    isVisioEmbededInDigitevent: false,
    visioLink: '',
    tagIds: [],
    mandatoryIfEligible: false,
    sponsors: [],
    isQuotaReached: false
  }
}

export function mustFindGroupActivityById(groupActivityId: string, activities: Activity[]) {
  const groupActivity = activities.find(
    (activity): activity is GroupActivity => activity.kind === 'groupActivity' && activity._id === groupActivityId
  )
  if (!groupActivity) {
    throw new Error('Group activity not found')
  }
  return groupActivity
}
