import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'
import { uniqueId } from 'lodash'

export class ImageSlider {
  id: string = uniqueId()
  url: string | null = ''
  title: string = new LoremIpsum().generateWords(1)
}

export class SliderProperties extends PrefabPropertiesBase {
  title: string = ''
  mainText: string = new LoremIpsum(
    {
      wordsPerSentence: { min: 3, max: 8 },
      sentencesPerParagraph: { min: 2, max: 3 }
    },
    undefined,
    `\n\n`
  ).generateParagraphs(2)
  tagText: string = ''
  imageSliders: ImageSlider[] = []

  titleColor: string = ''
  textColor: string = ''
  tagColor: string = ''
  isAutoplay: boolean = true
}

export default class SliderElement extends MediaElementBase {
  actualEditingSlide: number = 0
  static create({ palette }: ElementCreationContext): SliderElement {
    const slider = new SliderElement()
    const darkestColor = palette.darkestColor.paletteCode

    slider.properties.title = i18n.t('AN_INSPIRING_TITLE')
    slider.properties.tagText = i18n.t('SECTION_NAME')

    slider.properties.titleColor = darkestColor
    slider.properties.textColor = darkestColor
    slider.properties.tagColor = darkestColor

    slider.properties.imageSliders = [new ImageSlider(), new ImageSlider(), new ImageSlider()]

    return slider
  }

  type = `prefab-slider` as const

  @Type(() => SliderProperties)
  properties: SliderProperties = new SliderProperties()

  get prettyName(): string {
    return i18n.t('BLOCK_SLIDER')
  }
}
