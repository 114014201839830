import type { EventRights } from '@/models/Event/EventRights'
import type { AnonymousFilter } from '@/models/Event/Filter'
import type { FilterBeingCreated } from '../components/SegmentsModal/types'

export function isSegmentOverMaxConditions(
  segment: AnonymousFilter | FilterBeingCreated,
  eventRights: EventRights
): boolean {
  const numberOfConditions = (segment.andCriterias?.length ?? 0) + (segment.orCriterias?.length ?? 0)
  return numberOfConditions > eventRights.general.segments.maxConditionsPerSegment
}
