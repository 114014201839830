


































import { Component } from 'vue-property-decorator'

import { ImageFragment, PlainTextFragment, SlightlyRichTextFragment } from '../../../fragments'

import Quote2Item from './components/Quote2Item.vue'
import type Quote2Element from './Quote2Element'
import { Quote2ItemProperties } from './Quote2Element'
import VueDraggable from 'vuedraggable'
import { Vue, Prop, Inject } from 'vue-property-decorator'
import type { Website } from '@/models/Event/modules/Website'
import type { BuilderState } from '@/features/website/builder/BuilderState'
import { pickColorWithPalette } from '@/utils/colors'
import type { PageElementId } from '../../../types'
import { assignElement } from '../../../utils'

const MAX_QUOTE_COUNT = 3

@Component({ components: { VueDraggable, Quote2Item, ImageFragment, PlainTextFragment, SlightlyRichTextFragment } })
export default class PrefabQuote2 extends Vue {
  @Prop({ required: true }) readonly id!: PageElementId
  @Prop({ required: true }) readonly website!: Website

  @Inject() builderState!: BuilderState

  get pageElement(): Quote2Element {
    return this.builderState.assertedPageContent?.elements[this.id] as Quote2Element
  }

  setElementProperty(propertyPath: string, newValue: any): void {
    const newElement = assignElement(this.pageElement, propertyPath, newValue)

    this.builderState.assertedPageContent!.updateElement(this.id, newElement)
  }

  get prefabClasses(): Record<string, unknown> {
    return {
      'pb-0': this.pageElement.properties.removeBottomSpacing
    }
  }

  get quoteBkgColor(): string {
    return pickColorWithPalette(this.pageElement.properties.quoteBackgroundColor, this.$store.getters.colorPalette)
  }

  get tagTextColor(): string {
    return pickColorWithPalette(this.pageElement.properties.tagColor, this.$store.getters.colorPalette)
  }

  get quoteTextColor(): string {
    return pickColorWithPalette(this.pageElement.properties.quoteTextColor, this.$store.getters.colorPalette)
  }

  get quotes(): Quote2ItemProperties[] {
    return this.pageElement.properties.quotes
  }
  set quotes(newQuotes: Quote2ItemProperties[]) {
    this.pageElement.properties.quotes = newQuotes
  }

  get titleText(): string {
    return this.pageElement.properties.titleText
  }

  get tagText(): string {
    return this.pageElement.properties.tagText
  }

  get titleColor(): string {
    return pickColorWithPalette(this.pageElement.properties.titleColor, this.$store.getters.colorPalette)
  }

  get canAddQuote(): boolean {
    return this.quotes.length < MAX_QUOTE_COUNT
  }

  deleteQuoteItem(index: number) {
    this.pageElement.properties.quotes.splice(index, 1)
  }

  addQuote() {
    this.pageElement.properties.quotes.push(new Quote2ItemProperties())
  }
}
