export type PreferredCountries = 'FR' | 'GB' | 'US' | 'ES' | 'BE' | 'IT'

export const S3_ASSETS_URL: string = 'https://static.digitevent.com'
export const IMAGE_FILE_MIME_TYPES: string = 'image/jpeg, image/png, image/gif, image/webp'
// @TODO: remove GSM_CHARACTER_REGEX
export const GSM_CHARACTERS_REGEX: RegExp =
  /[^A-Za-z0-9 !"#$%&'()*+,-./:;<=>?@£¥,§¿_ΔΦΓΛΩΠΨΣΘΞèéùìòÇØøÅåÆæßÉÄÖÑÜäöñüàäöñüà^{}[\]~|€\r\n]/g
export const SMS_SENDER_CHARACTER_LIMIT: number = 11
export const PREFERRED_COUNTRIES: PreferredCountries[] = ['FR', 'GB', 'US', 'ES', 'BE', 'IT']
// Modification of DEFAULT_FONTS should be echoed on digi-site
export const DEFAULT_FONTS: string[] = ['Arial', 'Courier New', 'Times New Roman']
