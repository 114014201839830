

































































































import Draggable from 'vuedraggable'

import { Component, VModel, Prop, Watch, Emit, Vue } from 'vue-property-decorator'

import type { GuestFieldKey } from '@/models/Event/GuestField'
import { ErrorWithExtras } from '@/utils/errorTypes'
import { fromArrayToMap } from '@/utils/arrays'
import SquishTransition from './components/SquishTransition.vue'
import MultiSelectorHeading from './components/MultiSelectorHeading.vue'
import MultiSelectorItem from './components/MultiSelectorItem.vue'
import HeightTransitionGroup from './components/HeightTransitionGroup.vue'
import type { IconType } from '@/services/iconLibrary'

interface BaseMultiSelectorOption {
  id: string
}

export interface MultiSelectorOption extends BaseMultiSelectorOption {
  iconLibraryKey?: IconType
  text: string
  groupId?: string
}

type DisabledMultiSelectorOption = MultiSelectorOption & {
  disabled: boolean
  reason?: string
}

export interface RequiredMultiSelectorOption extends BaseMultiSelectorOption {
  reason?: string
}

export interface ForbiddenMultiSelectorOption extends MultiSelectorOption {
  reason?: string
}

export interface MultiSelectorGroup {
  id: string
  name: string
}

interface MultiSelectorGroupWithOptions extends MultiSelectorGroup {
  id: string
  name: string
  options: MultiSelectorOption[]
}

@Component({
  components: {
    HeightTransitionGroup,
    MultiSelectorItem,
    MultiSelectorHeading,
    SquishTransition,
    Draggable
  }
})
export default class DigiMultiItemSelector extends Vue {
  @VModel({ required: true }) selectedItems!: string[]
  @Prop({ required: true }) readonly options!: MultiSelectorOption[]
  @Prop({ default: () => [] }) readonly requiredOptions!: RequiredMultiSelectorOption[]
  @Prop({ default: () => [] }) readonly forbiddenOptions!: ForbiddenMultiSelectorOption[]
  @Prop({ default: () => [] }) readonly groups!: MultiSelectorGroup[]
  @Prop({ default: '' }) readonly forbiddenOptionsTooltip!: string
  @Prop({ default: '' }) readonly requiredOptionsDefaultTooltip?: string
  @Prop({ required: true }) readonly shownText!: string
  @Prop({ required: true }) readonly noItemShownText!: string
  @Prop({ required: true }) readonly hiddenText!: string
  @Prop({ required: true }) readonly noItemHiddenText!: string
  @Prop({ default: '' }) readonly maximumReachedText?: string
  @Prop({ default: false }) readonly draggable!: boolean

  @Prop() readonly noIcons!: boolean
  @Prop({ type: Number, default: null }) readonly maximum!: number | null

  get requiredSet(): Set<string> {
    return new Set(this.requiredOptions.map((f) => f.id))
  }

  get requiredMap(): Record<GuestFieldKey, RequiredMultiSelectorOption> {
    return fromArrayToMap(this.requiredOptions, 'id')
  }

  get forbiddenOptionsWithIcons(): Array<ForbiddenMultiSelectorOption> {
    return this.forbiddenOptions.map((option) => ({
      ...option
    }))
  }

  get optionsMap(): Map<string, MultiSelectorOption> {
    const optionsMap: Map<string, MultiSelectorOption> = new Map()

    for (const option of this.options) {
      optionsMap.set(option.id, { ...option })
    }

    return optionsMap
  }

  get groupsMap(): Map<string, MultiSelectorGroup> {
    const groupsMap: Map<string, MultiSelectorGroup> = new Map()

    for (const group of this.groups) {
      groupsMap.set(group.id, group)
    }

    return groupsMap
  }

  get optionGroups(): MultiSelectorGroupWithOptions[] {
    const result: MultiSelectorGroupWithOptions[] = []
    for (const group of this.groups) {
      const groupOptions = this.unselectedOptions.filter((option) => option.groupId === group.id)
      if (groupOptions.length > 0) {
        result.push({
          ...group,
          options: groupOptions
        })
      }
    }
    return result
  }

  get ungroupedOptions(): MultiSelectorOption[] {
    return this.unselectedOptions.filter(
      (option) => option.groupId === undefined || !this.groupsMap.has(option.groupId)
    )
  }

  get selectedOptions(): DisabledMultiSelectorOption[] {
    const selectedOptions: DisabledMultiSelectorOption[] = []

    for (const item of this.selectedItems) {
      const option = this.optionsMap.get(item)

      if (!option) {
        throw new Error(`Unexpected: Options of FieldSelector component do not include selected field of key: ${item}`)
      }

      const required = this.requiredMap[item]

      selectedOptions.push({
        ...option,
        disabled: Boolean(required),
        reason: required && required.reason ? required.reason : this.requiredOptionsDefaultTooltip
      })
    }

    return selectedOptions
  }

  get unselectedOptions(): MultiSelectorOption[] {
    return this.options.filter((option) => !this.selectedItems.includes(option.id))
  }

  get allSelected(): boolean {
    return this.selectedItems.length === this.options.length
  }

  get noneSelected(): boolean {
    return this.selectedItems ? this.selectedItems.length === 0 : true
  }

  get maximumReached(): boolean {
    return !!this.maximum && this.selectedOptions.length >= this.maximum
  }

  @Watch('requiredOptions', { immediate: true })
  onRequiredOptionsChanged(newValue: this['requiredOptions']): void {
    if (newValue && newValue.length > 0) {
      this.addMissingRequiredOptions()
    }
  }

  @Emit('drag-end')
  onDragEnd() {
    return this.selectedItems
  }

  removeAll(): void {
    this.selectedItems = this.selectedItems.filter((key) => this.requiredSet.has(key))
  }

  addAll(): void {
    let allFieldsSelected: string[] = [...this.selectedItems, ...this.unselectedOptions.map((field) => field.id)]

    if (this.maximum) {
      this.selectedItems = allFieldsSelected.slice(0, this.maximum)
    } else {
      this.selectedItems = allFieldsSelected
    }
  }

  handleClickOnSelectedOption(option: MultiSelectorOption): void {
    if (!this.requiredSet.has(option.id)) {
      this.remove(option)
    }
  }

  remove(option: MultiSelectorOption): void {
    if (this.requiredSet.has(option.id)) {
      throw new ErrorWithExtras('Cannot remove required option.', { option })
    }

    this.selectedItems = this.selectedItems.filter((key) => key !== option.id)
  }

  add(option: MultiSelectorOption): void {
    if (this.maximumReached) {
      throw new Error('Cannot add more fields, maximum reached.')
    } else {
      this.selectedItems = [...this.selectedItems, option.id]
    }
  }

  addMissingRequiredOptions(): void {
    const missingOptions = new Set(this.requiredSet)

    for (const key of this.selectedItems) {
      missingOptions.delete(key)
    }

    if (missingOptions.size > 0) {
      this.selectedItems = [...Array.from(missingOptions), ...this.selectedItems]
    }
  }
}
