













import { Component, Prop } from 'vue-property-decorator'
import HistoryBlock from '@/components/features/HistoryBlock.vue'
import { getDateAndHour } from '@/utils/time'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { CompleteGuestCheckin } from './types'
import type { EventIdentity } from '@/core'

@Component({
  components: { HistoryBlock }
})
export default class ContactCheckinItem extends Vue {
  @Prop({ required: true }) readonly checkin!: CompleteGuestCheckin

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get checkinTime(): string {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return getDateAndHour(this.checkin.date, eventIdentity.timezone)
  }
}
