






import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import type { EventMenuSection } from '../../types'
import MenuItem from '../MenuItem.vue'
import { isItemActive } from '../../utils'
import type { FullRouteConfig } from '@/core'
import { isEventMenuItemLink } from '../../types'
import LinkMenuSection from './LinkMenuSection.vue'
import ListMenuSection from './ListMenuSection.vue'

@Component({
  components: { ListMenuSection, LinkMenuSection, MenuItem }
})
export default class BaseMenuSection extends Vue {
  @Ref() readonly menuSection?: ListMenuSection
  @Prop({ required: true }) readonly section!: EventMenuSection
  isActive = false

  get classes() {
    return {
      'menu-section--active': this.isActive
    }
  }

  get isLink() {
    const firstItem = this.section.items[0]
    return this.section.items.length === 1 && firstItem && isEventMenuItemLink(firstItem)
  }

  @Watch('$route')
  onRouteChange() {
    this.isActive = this.section.items.some((item) => isItemActive(item, this.$route as FullRouteConfig))

    if (this.menuSection) {
      if (this.isActive) this.menuSection.open()
      else this.menuSection.close()
    }
  }

  mounted() {
    this.onRouteChange()
  }
}
