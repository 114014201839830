

























































import { Component, Prop, Ref } from 'vue-property-decorator'
import TicketDisplay from '@/features/payment/ticketDisplay/TicketDisplay.vue'
import type Guest from '@/models/Guest'
import { toggleGuestTicketValidity } from '@/services/order'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { RawLocation } from 'vue-router'
import { DigiLink } from '@/components/ui/actions'
import type { GuestTicket } from '@/models/Guest/Ticket'
import { DigiButton } from '@/components/ui/actions'
import FreeTicketGrantingModal from '@/features/payment/ticketGranting/FreeTicketGrantingModal.vue'

@Component({
  components: { FreeTicketGrantingModal, DigiButton, TicketDisplay, DigiLink }
})
export default class ContactTickets extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Ref() readonly ticketGrantsModal!: FreeTicketGrantingModal

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  openTicketGrantsModal(): void {
    this.ticketGrantsModal.openModal()
  }

  getUrlForOrder(ticket: GuestTicket): RawLocation | null {
    if (ticket.kind === 'manuallyDispatched') return null
    return {
      name: 'payment.orderDetails',
      params: {
        guestId: ticket.orderSummary.guestId,
        orderId: ticket.orderSummary.orderId
      }
    }
  }

  get hasTicket(): boolean {
    return !!this.guest.tickets?.length
  }

  async toggleTicketStatus(ticket: GuestTicket): Promise<void> {
    const newStatus: 'canceled' | 'active' = ticket.canceled ? 'active' : 'canceled'

    let confirmation = true

    if (newStatus === 'canceled') {
      confirmation = await this.$bvModal
        .msgBoxConfirm(this.$t('CANCEL_TICKET_DESC'), {
          okTitle: this.$t('CANCEL_TICKET'),
          okVariant: 'danger',
          cancelTitle: this.$t('BACK'),
          cancelVariant: 'default',
          centered: true
        })
        .catch()
    }

    if (confirmation) {
      const guest = await toggleGuestTicketValidity(this.guest._id, this.storeEvent._id, ticket._id, newStatus)
      this.$emit('updated', guest)
    }
  }
}
