













import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import moment from 'moment'

@Component
export default class DigiDatePicker extends Vue {
  @VModel({ type: Date }) inputValue: Date | null | undefined

  @Prop({ default: true }) readonly state?: boolean
  @Prop({ default: 'md' }) readonly size?: string

  /**
   * By default, <b-form-datepicker> returns dates as a string in the YYYY-MM-DD format
   * @link https://bootstrap-vue.org/docs/components/form-datepicker
   */
  datePickerFormat: string = 'YYYY-MM-DD'

  get date(): string | null {
    if (!this.inputValue) return null
    return moment(this.inputValue).utc().format(this.datePickerFormat)
  }
  // Time have to be set manually in order to prevent js Date to convert date in UTC-0 then fit converted date to desired timezone
  set date(newDate: string | null) {
    if (!newDate) {
      this.inputValue = null
    } else {
      this.inputValue = moment.utc(newDate, this.datePickerFormat).toDate()
    }
  }
}
