








































import { Component, Prop } from 'vue-property-decorator'
import { MessageStatus } from '@/models/Guest/Message'
import MessageStatusBadge from '@/components/features/MessageStatusBadge.vue'
import { getSlugForMessageStatusDefinition } from '@/features/messages/services/messages'
import type { MessageMethod } from '@/models/Event/Messages/template'
import { getDateAndHour } from '@/utils/time'
import type { EventIdentity } from '@/core'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { MessageStatusBadge }
})
export default class MessageHistoryActivity extends Vue {
  @Prop() readonly activityTime!: Date
  @Prop() readonly status!: MessageStatus
  @Prop() readonly method!: MessageMethod
  @Prop() readonly details!: string

  isDefinitionExpanded: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get messageTime(): string {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return getDateAndHour(this.activityTime, eventIdentity.timezone)
  }
  get statusDefinition(): string {
    return this.$t(getSlugForMessageStatusDefinition(this.status))
  }

  get statusDefinitionParts(): string[] {
    return this.statusDefinition.split('##')
  }

  get isDropped(): boolean {
    return this.status === MessageStatus.Dropped
  }

  get isVulgarised(): boolean {
    return this.statusDefinitionParts[1] !== undefined
  }
}
