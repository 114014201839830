import type { ColorPalette } from '@/models/Event/Identity/ColorPalette'
import Color from 'color'

// @TODO: This is a duplicate of our SCSS color palette
// Find a way to dedupe it
export const colorPalette = {
  black: '#000000',
  white: '#FFFFFF',
  nearWhite: '#f3f3f3',
  nearBlack: '#0D0D0D',

  // Blue
  lightestBlue: '#74A2FF',
  lightBlue: '#256EFF',
  blue: '#1F5BD1',
  darkBlue: '#1D53BE',
  darkestBlue: '#1846A3',
  blackBlue: '#172b4d',

  // Green
  lightestGreen: '#60E2A9',
  lightGreen: '#3DDC97',
  green: '#32B57C',
  darkGreen: '#2EA571',
  darkestGreen: '#278C61',

  // Red
  lightestRed: '#FF6A79',
  lightRed: '#FF495C',
  red: '#D13C4C',
  darkRed: '#BE3746',
  darkestRed: '#A32F3B',

  // Yellow
  lightestYellow: '#FFDC61',

  lightYellow: '#FFD53F',
  yellow: '#D1AF34',
  darkYellow: '#BEA030',
  darkestYellow: '#A38829',

  // Purple
  lightestPurple: '#674B92',

  lightPurple: '#46237A',
  purple: '#3A1D64',
  darkPurple: '#331A59',
  darkestPurple: '#2D174E',

  // White
  lightestGrey: '#f4f4f4',

  lightGrey: '#C8C8C8',
  grey: '#B3B3B3',
  darkGrey: '#707070',
  darkestGrey: '#3E3E3E'
}

export function computeReadableTextColor(baseColor: string): '#ffffff' | '#000000' {
  const color = Color(baseColor)

  if (color.isDark()) {
    return '#ffffff'
  } else {
    return '#000000'
  }
}

export function isDark(color: string): boolean {
  return Color(color).isDark()
}

export function hasInsufficientContrast(c1: string, c2: string): boolean {
  const color1 = Color(c1)
  const color2 = Color(c2)
  return color1.contrast(color2) < 2
}

export const paletteCodeRegex = /palette:(?<index>\d+)/

export function extractIndexFromPaletteCode(code: string): number | null {
  const numberString = paletteCodeRegex.exec(code)?.groups?.index

  if (!numberString) {
    return null
  }

  const number = Number(numberString)

  if (Number.isNaN(number)) {
    return null
  } else {
    return number
  }
}

export function makePaletteCodeForIndex(index: number): string {
  return `palette:${index}`
}

export function pickColorWithPalette(colorString: string | null, palette: ColorPalette): string {
  return palette.pickColor(colorString)
}

export function hexadecimalToRgb(hexColor: string) {
  const rgbObj = { r: 0, g: 0, b: 0 }
  const color: string = hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor
  rgbObj.r = parseInt(color.substring(0, 2), 16)
  rgbObj.g = parseInt(color.substring(2, 4), 16)
  rgbObj.b = parseInt(color.substring(4, 6), 16)
  return rgbObj
}

export function getColorLuminosity(hexColor: string): number {
  return new Color(hexColor).luminosity()
}

export function isColorEqual(color1: string | undefined, color2: string | undefined): boolean {
  if (!color1 || !color2 || color1 === 'transparent' || color2 === 'transparent') {
    return color1 === color2
  }
  return Color(color1).hex() === Color(color2).hex()
}
