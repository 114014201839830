

















import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiVerticalMenuSub } from '@/components/ui/verticalMenu'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import type { RawLocation } from 'vue-router'
import type { NoPaddingValue } from './types'

@Component({
  components: { DigiSimpleIconButton, DigiVerticalMenuSub }
})
export default class ContactProfileActionsSubMenu extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly settingsTooltip!: string
  @Prop({ required: true }) readonly settingsRoute!: RawLocation
  @Prop({ default: false }) readonly noPadding!: NoPaddingValue

  get classes() {
    return {
      'contact-profile-sub--no-padding': this.noPadding === 'all',
      'contact-profile-sub--no-padding-y': this.noPadding === 'vertical',
      'contact-profile-sub--no-padding-x': this.noPadding === 'horizontal'
    }
  }
}
