












import { Vue, Component, Ref, VModel, Prop } from 'vue-property-decorator'
import type { ModelEvents } from './tinyMce/types'
import type { TextEditorToolbar } from './tinyMce/types'
import { getCompleteTInyMceOptions } from '@/components/features/wysiwyg/tinyMce/tinyMceOptions'
import FixedTinyMceEditor from '@/components/features/wysiwyg/FixedTinyMceEditor.vue'

@Component({
  components: { FixedTinyMceEditor }
})
export default class WysiwygEditor extends Vue {
  @Ref() readonly tinyEditor!: FixedTinyMceEditor
  @VModel({ required: true }) editorValue!: string

  /**
   * See FixedTinyMceEditor.vue for more info
   */
  @Prop({ default: 0 }) readonly timeToWaitMilliseconds!: number
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: () => ['change', 'undo', 'redo'] }) readonly modelEvents!: ModelEvents[]
  @Prop({ default: () => [] }) readonly toolbar!: TextEditorToolbar
  @Prop({ default: false }) readonly addAutoFontSize!: boolean
  @Prop({ default: false }) readonly addFontFormat!: boolean
  @Prop({ default: 200 }) readonly height!: number
  @Prop({ default: false }) readonly inline!: boolean
  @Prop({ default: false }) readonly addFontSelect!: boolean
  @Prop({ default: '' }) readonly forceRootStyle!: string
  @Prop() readonly placeholder?: string
  @Prop() readonly blurHandler?: () => void
  @Prop({ default: false }) readonly rawEncoding?: boolean

  get editorOptions() {
    return {
      ...getCompleteTInyMceOptions(
        {
          addAutoFontSize: this.addAutoFontSize,
          addFontFormat: this.addFontFormat,
          thirdToolbar: this.toolbar,
          height: this.height,
          inline: this.inline,
          addFontSelect: this.addFontSelect,
          forceRootStyle: this.forceRootStyle,
          blurHandler: this.blurHandler,
          rawEncoding: this.rawEncoding
        },
        this.$store.getters.colorPalette
      ),
      placeholder: this.placeholder
    }
  }

  save() {
    this.tinyEditor.save()
  }

  focus() {
    this.tinyEditor.focus()
  }
}
