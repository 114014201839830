import { ElementTypeDictionary } from '../../types'
import { Action1Element } from './action1'

export const actionDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Action1Element,
    renderer: () => import('./action1/PrefabAction1.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor', toContrastWith: 'properties.blockColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor', toContrastWith: 'properties.blockColor' },
      { type: 'color', label: 'BLOCK_COLOR', key: 'properties.blockColor', toContrastWith: 'box.backgroundColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_ACTION_1_TITLE',
    previewImageLink: require('@/assets/img/prefab-previews/action1.jpg')
  })
  .finish()
