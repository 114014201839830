




import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningLocation from '../PlanningLocation.vue'
import type { GuestAppProfile } from '@/features/eventApp/contactPlanningEditor/PlanningItem/services'
import { joinIfExist } from '@/utils/string'

@Component({
  components: { PlanningLocation }
})
export default class MeetingLocation extends Vue {
  @Prop({}) readonly meetingLocation?: string
  @Prop({ required: true }) readonly meetingTarget!: GuestAppProfile
  @Prop({ required: true }) readonly matchmakingSession!: MatchmakingSession

  get location(): string {
    let location = ''
    if (this.matchmakingSession.hasVisio) {
      location += 'visio'
    } else if (this.matchmakingSession.doTargetsHaveAssignedTables) {
      const targetFullName = joinIfExist([this.meetingTarget.firstname, this.meetingTarget.name], ' ')
      const organization = this.meetingTarget.organization || targetFullName
      const table = this.matchmakingSession.cardHighlightOn === 'person' ? targetFullName : organization
      location += `${this.$t('MEETING_CARD_LOCATION_TABLE')} ${table}`
    } else if (this.meetingLocation) {
      location += `${this.$t('MEETING_CARD_LOCATION_TABLE')} ${this.meetingLocation}`
    }
    if (this.matchmakingSession.location) {
      location += ` - ${this.matchmakingSession.location}`
    }
    return location
  }
}
