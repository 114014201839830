import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'

export default class CreditNote {
  @TransformDateStringToDateObject()
  date!: Date
  amountWithoutVat!: number
  amountVatIncluded!: number
  description!: string
  organizerComment?: string
  creditNoteNumber!: number
}
