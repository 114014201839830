











import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'

import type { RawEditorSettings } from 'tinymce'
import type { ModelEvents } from './tinyMce/types'

@Component({ components: { TinyMceEditor: () => import('@tinymce/tinymce-vue') } })
export default class FixedTinyMceEditor extends Vue {
  @Ref() readonly tinyEditor?: Vue
  @VModel({ required: true }) editorValue!: string

  @Prop() readonly init!: RawEditorSettings
  /**
   * The TinyMCEVue component has a bug where only the first instance is displayed when multiple instances of the component need to be mounted simultaneously.
   * To fix this issue, we need to mount the components at different times.
   * It seems reasonable to introduce a gap of 300 milliseconds between each mounting process.
   * However, please note that this fix is not flawless. It is possible that the bug is already fixed in tinymce-vue V4 (for Vue 3). You can check for updates when migrating to Vue 3, or alternatively, consider customizing the TinyMCE integration yourself to have more control and avoid potential issues.
   */
  @Prop({ default: 0 }) readonly timeToWaitMilliseconds!: number
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: () => ['change', 'undo', 'redo'] }) readonly modelEvents!: ModelEvents[]

  displayEditor = false

  get modelEventsString(): string {
    return this.modelEvents.join(' ')
  }

  mounted(): void {
    this.deferTinyMCEMount()
  }

  deferTinyMCEMount(): void {
    setTimeout(() => {
      this.displayEditor = true
    }, this.timeToWaitMilliseconds)
  }

  save() {
    // Workaround for issue tinymce/tinymce-vue#57
    if (this.displayEditor && this.tinyEditor) this.tinyEditor.editor.save()
  }

  focus() {
    if (this.displayEditor && this.tinyEditor) {
      this.tinyEditor.editor.focus()
    }
  }
}
