











import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton }
})
export default class HeaderNavItem extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop() readonly href?: string
  @Prop() readonly target?: '_blank'
}
