
























import { Component, Vue, Prop } from 'vue-property-decorator'
import MeetingCard from './MeetingCard/MeetingCard.vue'
import GroupActivityCard from './GroupActivityCard/GroupActivityCard.vue'
import UnavailabilityCard from './UnavailabilityCard/UnavailabilityCard.vue'
import type Guest from '@/models/Guest'

@Component({ components: { MeetingCard, GroupActivityCard, UnavailabilityCard } })
export default class PlanningItem extends Vue {
  @Prop({ required: true }) readonly planningItemId!: string
  @Prop({ required: true }) readonly kind!: 'meeting' | 'groupActivity' | 'unavailability'
  @Prop({ required: true }) readonly guest!: Guest
}
