import store from '@/store'
import type { FullRouteConfig } from '@/core'
import type Rule from '@/models/Event/RulesData/Rule'
import { SINGLE_REGISTRATION_TRIGGER_KIND } from './singleRegistrationTriggers'

export const registrationSettingsRoutes: FullRouteConfig[] = [
  {
    path: 'singleRegistration/settings',
    name: 'event.website.singleRegistration.settings',
    component: () => import('./views/SingleRegistrationSettings/SingleRegistrationSettings.vue'),
    meta: {
      name: 'WEBSITE_SETTINGS',
      iconName: 'user-add-line',
      keywords: 'FACTORY_REGISTRATION_SETTINGS_KEYWORDS',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) =>
          eventRights.features.registration.mode === 'free' && eventRights.features.registration.type === 'single',
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'singleRegistration/rules',
    name: 'event.website.singleRegistration.rules',
    component: () => import('./views/SingleRegistrationRulesView.vue'),
    meta: {
      name: 'FACTORY_SINGLE_REGISTRATION_RULES_LIST',
      iconName: 'user-add-line',
      keywords: 'FACTORY_SINGLE_REGISTRATION_RULES_KEYWORDS',
      searchable: true,
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event.rules.ruleItems.filter((rule: Rule) =>
              SINGLE_REGISTRATION_TRIGGER_KIND.includes(rule.trigger.kind)
            ).length > 0
          )
        }
      },
      requiredRights: {
        hasRequiredRights: (eventRights) =>
          eventRights.features.registration.mode === 'free' && eventRights.features.registration.type === 'single',
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'singleRegistration/rules/edition/:ruleId?',
    name: 'event.website.singleRegistration.rules.edition',
    component: () => import('./views/SingleRegistrationRulesEditionView.vue'),
    meta: {
      subPageOf: 'event.website.singleRegistration.rules',
      requiredRights: {
        hasRequiredRights: (eventRights) =>
          eventRights.features.registration.mode === 'free' && eventRights.features.registration.type === 'single',
        redirectName: 'event.dashboard'
      }
    }
  }
]
