














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MultipleSegmentsBadge extends Vue {
  @Prop({ required: true }) readonly segments!: string[]
  @Prop({ default: false }) readonly hasPunctualSegment!: boolean

  get text(): string {
    const firstSegment = this.segments[0]
    if (!firstSegment) {
      return this.$t('ALL_GUESTS')
    }
    let text = this.$t(this.$store.getters.filterIdMap[firstSegment].name)
    if (this.segments.length > 1) {
      text += ` + ${this.segments.length - 1} ${this.$t('SEGMENTS').toLowerCase()}`
    }

    return text
  }

  get tooltip(): string {
    if (this.segments.length < 2) {
      return ''
    }
    const translatedSegments = this.segments.map((id) => {
      const segment = this.$store.getters.filterIdMap[id]
      if (!segment) {
        return `(Unknown segment ${id})`
      }
      return this.$t(segment.name)
    })
    return translatedSegments.join(' & ')
  }
}
