import Vue from 'vue'
import { i18n } from './i18n'
import store from '@/store'
import * as Sentry from '@sentry/browser'
import type { Contexts } from '@sentry/types'
import type { AxiosError } from 'axios'
import type { AxiosResponse } from 'axios'
import type { DigiError } from './errorTypes'
import { ErrorWithExtras, UnauthenticatedError } from './errorTypes'
import { isDev } from './isDev'

/**
 * Creates a toast notification for an error.
 * This is for less severe errors that can probablt be blamed on client wrongdoings.
 * Inactive in prod.
 * @param text Error message to display.
 */
function notifyClientError(text: string): void {
  if (process.env.NODE_ENV !== 'production') {
    Vue.notify({ text, type: 'warn', duration: 60000 })
  }
}

/**
 * Creates a toast notification for a server error that might be of use to the end user.
 * @param text Error message to display.
 * @param type Notification type to use.
 */
function notifyServerError(text: string): void {
  Vue.notify({ text, type: 'error', duration: 60000 })
}

/**
 * Reports an error to sentry.
 * Does not operate in dev because do you have any idea of how many useless errors happen on dev???
 * @param error Error to report to Sentry
 * @param extra Any extra info to pass
 */
export function reportErrorToSentry(error: Error | string, extraErrorData?: Record<string, any>): void {
  if (!isDev) {
    const extra: Record<string, any> = {
      ...extraErrorData
    }

    let user: Sentry.User | undefined = undefined
    const contexts: Contexts = {}

    if (store.state.account.account) {
      user = {
        id: store.state.account.account._id,
        email: store.state.account.account.accountEmail
      }
    }
    if (store.state.event.event) {
      contexts.event = {
        id: store.state.event.event._id,
        label: store.state.event.event.label
      }
    }

    Sentry.captureException(error, {
      extra,
      user,
      contexts
    })
  }
}

/**
 * Notifies the user that an error has occured.
 * @param error The error to notify about
 */
export function handleError(
  error: AxiosError | AxiosResponse | DigiError | Error | ErrorWithExtras | string | null | undefined | unknown
): void {
  console.error(error)
  /**
   * Several branches of the logic tree lead to handling a DigiError payload
   * So this function is a factorization of the handling process
   * @param error A DigiError payload
   */
  function notifyDigiError(error: DigiError | (DigiError & AxiosError)): void {
    const langCode = i18n.i18next.language
    let axiosErrorMsg
    if (error.slug && i18n.i18next.exists(error.slug)) {
      axiosErrorMsg = i18n.t(error.slug)
    } else if (error.localized && error.localized[langCode]) {
      axiosErrorMsg = error.localized[langCode]!
    } else {
      axiosErrorMsg = error.message || 'Unknown server error message'
    }
    notifyServerError(axiosErrorMsg)
  }

  if (!error) {
    reportErrorToSentry(new Error('notifyError called without error'))
  } else if (typeof error === 'string') {
    // Errors that are just strings
    reportErrorToSentry(error)
    notifyClientError(error)
  } else if (error instanceof UnauthenticatedError) {
    console.warn('UnauthenticatedError', error)
  } else if (isError(error) && isAxiosError(error)) {
    // The AxiosError type is a superset of Error, easily told apart by isAxiosError
    if (error.message === 'Network Error') {
      // We just silence network errors because reasons
      return
    } else if (error.message.includes('Loading chunk')) {
      // Yeah we *could* have a better deployment solution.
      // ..We don't. So have this. Reloads if a needed chunk has been deleted.
      location.reload()
      return
    } else if (error.response) {
      if ('criticity' in error.response.data) {
        notifyDigiError(error.response.data)
        if (error.response.data.criticity !== 0) {
          reportErrorToSentry(error)
        }
      }
    }
  } else if (isError(error) && isDigiError(error)) {
    // This section is for a DigiError payload
    notifyDigiError(error)
    if (error.criticity !== 0) {
      reportErrorToSentry(error)
    }
  } else if (isError(error) && error instanceof ErrorWithExtras) {
    // This section is for the ErrorWithExtras class
    reportErrorToSentry(error, error.extras)
    notifyClientError(error.message)
  } else if (isError(error)) {
    // This section is a fallback for regular Errors
    reportErrorToSentry(error)
    notifyClientError(error.message)
  } else {
    reportErrorToSentry(new Error('Not a valid Error'), error)
    notifyServerError(String(error))
  }

  throw error
}

export const VuePlugin: Vue.PluginObject<undefined> = {
  install(VueArg) {
    VueArg.config.errorHandler = handleError
  }
}

function isError(err: unknown): err is Error & Record<string, unknown> {
  return err instanceof Error
}

function isAxiosError(err: Error): err is AxiosError<any> {
  return 'isAxiosError' in err
}

function isDigiError(err: Error): err is DigiError {
  return 'criticity' in err
}

export function transformToDigiError(error: unknown): DigiError | null {
  if (!isError(error)) {
    return null
  }
  if (isDigiError(error)) {
    return error
  }
  if (isAxiosError(error)) {
    if (error.response?.data && isDigiError(error.response.data)) {
      return error.response.data
    }
  }
  return null
}

export function getLocalizedErrorMessage(error: DigiError): string {
  const langCode = i18n.i18next.language
  if (error.slug && i18n.i18next.exists(error.slug)) {
    return i18n.t(error.slug)
  } else if (error.localized && error.localized[langCode]) {
    return error.localized[langCode]!
  } else {
    return error.message || 'Unknown server error message'
  }
}
