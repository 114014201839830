








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DigiVerticalMenuTab extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) icon!: string
}
