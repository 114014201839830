import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import { QuoteElementBase } from '../QuoteElementBase'
import { PrefabPropertiesBase } from '../../PrefabElementBase'

export class Quote1Properties extends PrefabPropertiesBase {
  author: string = ''
  quote: string = ''
  tagText: string = ''
  tagColor: string = ''
  imageUrl: string | null = null
  authorDetail: string = ''
  quoteTextBackgroundColor: string = ''
  quoteTextColor: string = ''
}

export default class Quote1Element extends QuoteElementBase {
  static create({ palette }: ElementCreationContext): Quote1Element {
    const quote = new Quote1Element()

    quote.properties.tagText = i18n.t('TESTIMONY')
    quote.properties.tagColor = palette.lightestColor.paletteCode

    quote.properties.author = i18n.t('FIRSTNAME_NAME')
    quote.properties.authorDetail = i18n.t('JOB')
    quote.properties.quote = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(1)

    quote.properties.quoteTextBackgroundColor = palette.darkestColor.paletteCode
    quote.properties.quoteTextColor = palette.lightestColor.paletteCode

    return quote
  }

  type = `prefab-quote-1` as const

  @Type(() => Quote1Properties)
  properties: Quote1Properties = new Quote1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_QUOTE')
  }
}
