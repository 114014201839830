import { uniqueId } from 'lodash'
import type { PluginObject } from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $uuid: string
  }
}

const plugin: PluginObject<undefined> = {
  install(VueClass) {
    VueClass.mixin({
      beforeCreate() {
        this.$uuid = uniqueId()
      }
    })
  }
}

export default plugin
