














import { Component, Prop } from 'vue-property-decorator'

import type { GuestHistory, ModificationEntry } from '@/services/guestHistory'
import type Guest from '@/models/Guest'

import ModificationCard from './components/ModificationCard.vue'
import CreationCard from './components/CreationCard.vue'
import HistoryBlock from '@/components/features/HistoryBlock.vue'
import moment from 'moment/moment'
import ContactProfileSimpleSubMenu from '../../ui/ContactProfileSimpleSubMenu.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    ContactProfileSimpleSubMenu,
    HistoryBlock,
    ModificationCard,
    CreationCard
  }
})
export default class ContactProfileHistorySubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly history!: GuestHistory

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get sortedEntries(): ModificationEntry[] {
    return this.history.entries.sort((a, b) => moment(b.date).diff(moment(a.date))) ?? []
  }
}
