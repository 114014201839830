import type { EventRights } from '@/models/Event/EventRights'

export const checkinMenuSection = {
  key: 'checkin',
  titleSlug: 'EVENT_MENU_CHECKIN_TITLE',
  iconName: 'qr-scan-2-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_CHECKIN_APP_LINK',
      routeName: 'checkin.settings.application',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.checkin.app
    },
    {
      titleSlug: 'EVENT_MENU_CHECKIN_ACCESS_PASS_LINK',
      routeName: 'event.accessPass',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.features.checkin.app && eventRights.features.checkin.accessPass
    },
    {
      titleSlug: 'EVENT_MENU_CHECKIN_LABEL_LINK',
      routeName: 'checkin.settings.checkinStickerBadge',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.features.checkin.app && eventRights.features.checkin.printBadge
    },
    {
      titleSlug: 'EVENT_MENU_CHECKIN_PDF_BADGE_LINK',
      routeName: 'checkin.settings.pdfBadge',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.checkin.app && eventRights.features.pdfBadges
    },
    {
      titleSlug: 'EVENT_MENU_CHECKIN_RULES_LINK',
      routeName: 'checkin.rules',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.checkin.app
    }
  ]
} as const
