




















import { Component, Vue, VModel } from 'vue-property-decorator'
import { hasAccessToHighestUserUrgencyLevel } from './supportRequest'
import { type Event } from '@/models/Event'
import type { UserUrgencyLevel } from './types'

@Component
export default class UserUrgencyLevelInput extends Vue {
  @VModel() userUrgencyLevel!: UserUrgencyLevel | undefined

  hasAccessToHighestUserUrgencyLevel: boolean = false
  isHighestUrgencyLevelConfirmed: boolean = false

  get storeEvent(): Event | null {
    if (this.$store.state.event.event) {
      return this.$store.state.event.event
    }
    return null
  }

  get options(): { value: UserUrgencyLevel; text: string }[] {
    return [
      {
        value: 'HIGHEST',
        text: this.$t('SUPPORT_REQUEST_URGENCY_LEVEL_HIGHEST')
      },
      { value: 'HIGH', text: this.$t('SUPPORT_REQUEST_URGENCY_LEVEL_HIGH') },
      { value: 'MEDIUM', text: this.$t('SUPPORT_REQUEST_URGENCY_LEVEL_MEDIUM') }
    ]
  }

  get isValid(): boolean {
    if (!this.userUrgencyLevel) {
      return false
    }
    if (this.userUrgencyLevel !== 'HIGHEST') {
      return true
    }
    if (!this.hasAccessToHighestUserUrgencyLevel) {
      return false
    }
    return this.isHighestUrgencyLevelConfirmed
  }

  async mounted(): Promise<void> {
    this.hasAccessToHighestUserUrgencyLevel = await hasAccessToHighestUserUrgencyLevel(this.storeEvent?._id)
  }
}
