import { Type } from 'class-transformer'

import NavData from './NavData'
import type { EventLangCode } from '../../Localized'
import { LocalizedPrimitive } from '../../Localized'
import type { GuestFieldKey } from '../../GuestField'
import { i18n } from '@/utils/i18n'
import store from '@/store'
import type { FilterId } from '../../Filter'
import type Filter from '../../Filter'
import { Registration } from './Registration'
import type { RegistrationIncentivePopup } from './registrationIncentivePopup'
import type { CustomFontFamily, Font } from '@/core/EventIdentity'

class InvitationOnlyInfo {
  active: boolean = false
  portalMode: boolean = false
  unlockFieldId: GuestFieldKey = 'codeId'
  @Type(() => LocalizedPrimitive)
  portalText: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
}

export class RegistrationQuota {
  segmentId: FilterId = ''
  value: number = 1
}

export interface WebsiteEventFont {
  heading: Font
}

export type NavAlignment = 'left' | 'center' | 'right'

export function isCustomFontFamily(fontFamily: string): fontFamily is CustomFontFamily {
  return fontFamily === 'customFontPrimary' || fontFamily === 'customFontSecondary'
}

export class Website {
  websiteTranslations: EventLangCode[] = []

  get websiteLanguages(): EventLangCode[] {
    return [store.getters.event.mainLanguageCode, ...this.websiteTranslations] as EventLangCode[]
  }

  gtmId?: string

  showFooter!: boolean
  prettyId!: string

  fonts: WebsiteEventFont = {
    heading: {
      family: 'EB Garamond',
      size: 36,
      kind: 'basic'
    }
  }

  navAlignment!: NavAlignment

  navFontColor!: string
  navBkgColor!: string

  allowDecline: boolean = true
  modificationAllowed: boolean = false

  @Type(() => InvitationOnlyInfo)
  onInvitationOnly!: InvitationOnlyInfo

  async initializeDataForLanguage(baseLanguage: EventLangCode, targetLanguage: EventLangCode): Promise<void> {
    await i18n.i18next.loadLanguages(targetLanguage)
    const fixedT = i18n.i18next.getFixedT(targetLanguage)

    for (const menu of this.nav.menus) {
      menu.makeNameForLang(baseLanguage, targetLanguage)
    }

    for (const link of this.nav.links) {
      link.makeNameForLang(baseLanguage, targetLanguage)
    }

    if (!this.registrationCtaBtnLabel[targetLanguage]) {
      this.registrationCtaBtnLabel[targetLanguage] = fixedT('REGISTRATION_CTA_BTN_LABEL')
    }
  }

  mainColor!: string

  @Type(() => Registration)
  registration!: Registration

  maxPublicRegistration!: number

  hasRegistrationQuotas!: boolean
  registrationQuotas?: RegistrationQuota[]
  setDefaultRegistrationQuotas(filters: Filter[]): void {
    const defaultFilter = filters.find((filter) => filter.defaultOrigin === 'confirmed')
    if (!defaultFilter) {
      throw new Error('Cannot find default filter for status confirmed')
    }
    this.registrationQuotas = [{ segmentId: defaultFilter._id, value: 100 }]
  }

  @Type(() => NavData)
  nav: NavData = new NavData()

  @Type(() => LocalizedPrimitive)
  registrationCtaBtnLabel: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  showHeaderRegistrationCta!: boolean

  logo?: string

  favicon?: string
  footerBkgColor?: string

  navSticky: boolean = false

  navScrollBkgColor?: string
  navScrollFontColor?: string

  backgroundColor!: string

  displayAddToCalendarBtn: boolean = false

  registrationIncentivePopup!: RegistrationIncentivePopup
}
