import { matchFilter } from '@digitevent/filterutils'
import type { EventId } from '@/models/Event'
import type { Event } from '@/models/Event'
import type { AnonymousFilter, FilterId } from '@/models/Event/Filter'
import type Guest from '@/models/Guest'
import { MessageStatusCriteriaValue, SourceCriteriaValue } from '@/models/Event/Filter/Criteria'
import { $enum } from 'ts-enum-util'
import axios from 'axios'
import { i18n } from '@/utils/i18n'
import type Filter from '@/models/Event/Filter'

/**
 * Get how many contacts fit a filter
 * @param eventId The ID of the event of interest
 * @param filterParams The filter,o to count
 */
export async function countFilterContacts(
  eventId: EventId,
  filterParams: { filterId?: string; filter?: AnonymousFilter } = {}
) {
  const res = await axios.get<number>(`backoffice/events/${eventId}/countMatchingGuests`, { params: filterParams })
  return res.data
}

export interface FilterStat {
  filterId: string
  nbContacts: number
  name: string
}

/**
 * For a given guest, try to match it with a given filter
 * @param guest The guest to match
 * @param filter The requested filter
 */
export function doesGuestMatchFilter(guest: Guest, filter: AnonymousFilter): boolean {
  return matchFilter(filter, guest)
}

/**
 * For a given guest, try to match it with a filter that has a given ID
 * @param guest The guest to match
 * @param event The event to use as context
 * @param filterId The ID of the requested filter
 */
export function doesGuestMatchFilterId(guest: Guest, event: Event, filterId: FilterId): boolean {
  const filter = event.getFilterById(filterId)
  return matchFilter(filter, guest)
}

const slugsForMessageStatuses: Record<MessageStatusCriteriaValue, string> = {
  [MessageStatusCriteriaValue.AllSent]: 'MSG_STATUS_ALL_SENT',
  [MessageStatusCriteriaValue.NotSent]: 'MSG_STATUS_NOT_SENT',
  [MessageStatusCriteriaValue.Opened]: 'MSG_STATUS_OPEN',
  [MessageStatusCriteriaValue.Clicked]: 'MSG_STATUS_CLICK',
  [MessageStatusCriteriaValue.Error]: 'MSG_STATUS_ALL_ERROR'
}

const slugsForSources: Record<SourceCriteriaValue, string> = {
  [SourceCriteriaValue.Extra]: 'CRITERIA_EXTRA',
  [SourceCriteriaValue.Excel]: 'CRITERIA_EXCEL',
  [SourceCriteriaValue.Register]: 'CRITERIA_REGISTER',
  [SourceCriteriaValue.Back]: 'CRITERIA_MANUAL'
}

export function getSlugForMessageStatusCriteriaValue(status: MessageStatusCriteriaValue): string {
  return slugsForMessageStatuses[$enum(MessageStatusCriteriaValue).asValueOrThrow(status)]
}

export function getSlugForSourceCriteriaValue(value: SourceCriteriaValue): string {
  return slugsForSources[$enum(SourceCriteriaValue).asValueOrThrow(value)]
}

export function getSegmentName(segmentId: string | null, segments: Filter[]): string {
  if (!segmentId) {
    return i18n.t('ALL_GUESTS')
  }
  const segment = mustFindSegmentById(segmentId, segments)
  return i18n.t(segment.name)
}

export function mustFindSegmentById(id: string, segments: Filter[]): Filter {
  const segment = findSegmentById(id, segments)
  if (!segment) {
    throw new Error(`Segment with id ${id} not found`)
  }
  return segment
}

export function findSegmentById(id: string, segments: Filter[]): Filter | undefined {
  return segments.find((s) => s._id === id)
}
