











import { Vue, Component, Prop } from 'vue-property-decorator'
import { ContactSegments } from '@/components/features/multipleSegmentsBadge'
import type Guest from '@/models/Guest'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'

@Component({
  components: { ContactProfileActionsSubMenu, ContactSegments }
})
export default class ContactProfileSegmentsSubMenu extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly guest!: Guest
}
