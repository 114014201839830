import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import ContentElementBase from '../ContentElementBase'
import type { Padding, LinkDestination } from '../helperTypes'
import {
  TextAlignment,
  BoxBorder,
  BorderStyle,
  LinkDestinationBase,
  LinkDestinationDiscriminator,
  MainCtaLinkDestination
} from '../helperTypes'
import type { ElementCreationContext } from '../../types/ElementTypeDictionary'

class ButtonFont {
  color: string = '#FFFFFF'
  family: string = 'inherit'
  size: number = 15
  bold: boolean = true
}

class ButtonProperties {
  align: TextAlignment = TextAlignment.Center
  @Type(() => BoxBorder)
  border: BoxBorder = {
    color: '#FFFFFF',
    radius: 3,
    size: 0,
    style: BorderStyle.Solid
  }
  @Type(() => ButtonFont)
  font = new ButtonFont()
  padding: Padding = [12, 20]
  backgroundColor: string | null = '#000000'
  buttonText = i18n.t('BUTTON')
  fullWidth = false
  @Type(() => LinkDestinationBase, {
    discriminator: LinkDestinationDiscriminator,
    keepDiscriminatorProperty: true
  })
  linkTo: LinkDestination = new MainCtaLinkDestination()
}

export default class ButtonElement extends ContentElementBase {
  type = 'content-button' as const

  static create({ website }: ElementCreationContext): ButtonElement {
    const button = new ButtonElement()

    button.properties.backgroundColor = website.mainColor

    return button
  }

  @Type(() => ButtonProperties)
  properties: ButtonProperties = new ButtonProperties()

  get prettyName() {
    if (this.properties.buttonText) {
      return `${i18n.t('BLOCK_BUTTON')}: "${this.properties.buttonText}"`
    } else {
      return i18n.t('BLOCK_BUTTON')
    }
  }
}
