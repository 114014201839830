import axios from 'axios'

export interface GuestAppProfile {
  _id: string
  name: string
  firstname?: string
  photo?: string
  job?: string
  organization?: string
}

export async function getGuestAppProfile({
  eventId,
  guestId
}: {
  eventId: string
  guestId: string
}): Promise<GuestAppProfile> {
  const { data } = await axios.get<{ appProfile?: GuestAppProfile }>(
    `/backoffice/events/${eventId}/guests/${guestId}/appProfile`
  )
  if (!data.appProfile) {
    throw new Error('Invalid response: missing appProfile key')
  }
  return data.appProfile
}
