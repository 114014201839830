import targetReceivesMeetingRequest from './targetReceivesMeetingRequest'
import acceptedMeetingRequest from './acceptedMeetingRequest'
import declinedMeetingRequest from './declinedMeetingRequest'
import canceledMeeting from './canceledMeeting'
import { rescheduledMeetingRequest } from './rescheduledMeetingRequest'

export default {
  acceptedMeetingRequest,
  targetReceivesMeetingRequest,
  declinedMeetingRequest,
  canceledMeeting,
  rescheduledMeetingRequest
}
