






























import { Component, Prop } from 'vue-property-decorator'
import type { DigiFormGroupItem } from '@/components/ui/form/formGroup'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import { DigiFormModal2 } from '@/components/ui/form/formLayouts'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { grantFreeTickets } from './grantFreeTickets'
import { DigiTitleCard } from '@/components/ui'
import type { Ticket } from '@/models/Event/modules/Payment'

@Component({
  components: { DigiTitleCard, DigiFormModal2, DigiFormGroup }
})
export default class FreeTicketGrantingModal extends Vue {
  @Prop({ required: true }) readonly guestIds!: string[]

  showModal = false
  ticketId = ''
  isLoading = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get freeTickets(): Ticket[] {
    const paymentModule = this.storeEvent.modules_data.payment
    return paymentModule.tickets.filter((ticket) => ticket.amount === 0 && ticket.enable)
  }

  get hasModifications() {
    return this.ticketId.length > 0
  }

  get freeTicketOptions(): { value: string; text: string }[] {
    return this.freeTickets.map((ticket) => ({
      value: ticket._id,
      text: ticket.name
    }))
  }

  openModal() {
    this.ticketId = ''
    this.showModal = true
  }

  closeModal() {
    this.showModal = false
  }

  get formItems(): DigiFormGroupItem[] {
    return [
      {
        key: 'ticket',
        label: this.$t('FREE_TICKET_GRANTING_TICKET_LABEL'),
        displayMode: 'rows'
      }
    ]
  }
  async onSubmit() {
    this.isLoading = true
    try {
      await grantFreeTickets({
        guestIds: this.guestIds,
        ticketId: this.ticketId,
        eventId: this.storeEvent._id
      })
      this.$emit('updated')
      this.closeModal()
    } finally {
      this.isLoading = false
    }
  }
}
