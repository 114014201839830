


















import { Component, Vue, Ref } from 'vue-property-decorator'
import RightPanel from '@/components/ui/RightPanel.vue'
import SupportRequestFormSection from './SupportRequestForm/SupportRequestFormSection.vue'
import BrazilianSupportRequestSection from './BrazilianSupportRequestSection.vue'
import { canContactSales, hasOpenTicketPermission } from './support'
import ContactSalesSection from './ContactSalesSection.vue'
import type { Account } from '@/models/Account'

@Component({
  components: { RightPanel, SupportRequestFormSection, BrazilianSupportRequestSection, ContactSalesSection }
})
export default class SupportRequestPanel extends Vue {
  isPanelOpen: boolean = false
  isContactSalesSectionHidden: boolean = false

  @Ref() readonly supportRequestFormSection?: SupportRequestFormSection

  get isBrazilianAccount(): boolean {
    const account: Account = this.$store.state.account.account
    return account.zone === 'br'
  }

  get canContactSales(): boolean {
    return canContactSales(this.$store.state.eventIdentity) && !this.isContactSalesSectionHidden
  }

  created(): void {
    this.$root.$on('open-support-request-panel', this.open)
  }

  async open(): Promise<void> {
    const hasPermission: boolean = await hasOpenTicketPermission(this.$store.state.event._id)
    if (!hasPermission) {
      await this.$bvModal.msgBoxOk(this.$t('MSG_CANT_OPEN_TICKET'), {
        centered: true,
        okTitle: this.$t('CLOSE'),
        okVariant: 'light'
      })
      return
    }
    this.isPanelOpen = true
  }

  onClose(): void {
    this.supportRequestFormSection?.reset()
    this.isContactSalesSectionHidden = false
  }
}
