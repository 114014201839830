import axios from 'axios'
import type { InferArrayElement } from '@/utils/utilityTypes'

// Duplicate of src/models/FullRouteConfig.ts
// We maintain dyplicates to prevent circular dependencies
// If one changes there will be compilation error
// TLDR: compatibility over dependency
const permissions = [
  'access',
  'configEvent',
  'sendCampaigns',
  'accessStats',
  'guestList_read',
  'guestList_write',
  'guestList_import',
  'data_export',
  'writeRoles',
  'owner',
  'openSupportTicket',
  'accessInKnox'
] as const
export type Permission = InferArrayElement<typeof permissions>

export function isValidPermission(permission: string | Permission): permission is Permission {
  return permissions.includes(permission as Permission)
}

export async function listMyPermissions(eventId: string): Promise<Permission[]> {
  const resp = await axios.get<{ permissions: string[] }>(`/events/${eventId}/permissions`)
  return resp.data.permissions as Permission[]
}

export async function doIHavePermission(eventId: string, permission: Permission): Promise<boolean> {
  const list = await listMyPermissions(eventId)
  return list.includes(permission)
}

export async function changeOwner(eventId: string, newEmail: string) {
  await axios.put(`/events/${eventId}/permissions/owner`, { email: newEmail })
}

interface AccountDTO {
  id: string
  email: string
}

export async function getEventCollabolators(eventId: string): Promise<AccountDTO[]> {
  const { data } = await axios.get<{ collabolators: AccountDTO[] }>(`/events/${eventId}/permissions/collabolators`)
  return data.collabolators
}
