








































import type { PendingMeeting } from '@/models/Guest/Planning/UnscheduledMeeting'
import type { TimeSlot } from '@/features/eventApp'
import { getAvailableTimeSlotsForMeeting } from '@/features/eventApp'
import moment from 'moment'
import { Component } from 'vue-property-decorator'
import axios from 'axios'
import { DigiButton } from '@/components/ui/actions'
import { assertEvent } from '@/services/storeEvent'
import type { Event as EventModel } from '@/models/Event'
import Vue from 'vue'

interface TimeSlotOption {
  text: string
  value?: TimeSlot
  disabled?: boolean
}

@Component({
  components: { DigiButton }
})
export default class MeetingRequestAcceptationModal extends Vue {
  private isLoadingSlots: boolean = false
  private slots: TimeSlot[] | null = null
  private pickedTimeSlot: TimeSlot | null = null
  private showModal: boolean = false
  private request: PendingMeeting | null = null
  private isAccepting: boolean = false

  get storeEvent(): EventModel {
    const storeEvent = assertEvent(this.$store.state.event.event)

    return storeEvent
  }

  private get timeSlotOptions(): TimeSlotOption[] {
    if (this.slots) {
      if (this.slots.length > 0) {
        const options: TimeSlotOption[] = [
          {
            disabled: true,
            text: this.$t('PICK_A_TIME_SLOT')
          }
        ]

        return options.concat(
          this.slots.map((slot) => ({
            text: moment(slot.start).format('l LT'),
            value: slot
          }))
        )
      } else {
        return [
          {
            text: this.$t('NO_MEETING_SLOT_AVAILABLE'),
            disabled: true
          }
        ]
      }
    } else {
      return []
    }
  }

  private get canSelectATimeSlot(): boolean {
    return !this.isLoadingSlots && !this.isAccepting && Array.isArray(this.slots) && this.slots.length > 0
  }

  private async loadSlots(): Promise<void> {
    if (this.isLoadingSlots) throw new Error('Already loading slots.')
    if (!this.request) throw new Error('Cannot load slots: No request to load slots for.')

    this.slots = null
    this.pickedTimeSlot = null
    this.isLoadingSlots = true
    try {
      this.slots = await getAvailableTimeSlotsForMeeting(
        this.request.applicant._guest,
        this.request.target._guest,
        this.request.eventActivityId
      )
    } finally {
      this.isLoadingSlots = false
    }
  }

  acceptRequest(request: PendingMeeting): void {
    this.request = request
    this.pickedTimeSlot = null
    this.showModal = true
  }

  private async finishAcceptingRequest(): Promise<void> {
    if (!this.request) throw new Error('Cannot accept request: no request set.')
    if (!this.pickedTimeSlot) throw new Error('Cannot accept request: no time slot picked.')
    if (this.isAccepting) throw new Error('Already accepting request.')

    this.isAccepting = true
    try {
      await axios.put(`backoffice/events/${this.storeEvent._id}/matchmaking/acceptMeetingRequest/${this.request._id}`, {
        startDate: this.pickedTimeSlot.start
      })
      this.showModal = false
    } finally {
      this.isAccepting = false
    }

    this.$emit('accept-request', this.request)
  }

  private handleModalHide(event: Event): void {
    if (this.isAccepting) event.preventDefault()
  }

  private handleModalHidden(): void {
    this.pickedTimeSlot = null
    this.slots = null
    this.request = null
  }
}
