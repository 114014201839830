











import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import type { GuestField } from '@/models/Event/GuestField'
import { isNil } from 'lodash'

@Component({})
export default class GuestFieldTextInput extends Vue {
  @VModel() readonly fieldValue!: string
  @Prop({ required: true }) readonly field!: GuestField

  get textPattern(): string | null {
    if (this.field?.type === 'text' && this.field.pattern) {
      return this.field.pattern
    } else {
      return null
    }
  }

  get isRequired(): boolean {
    return this.field.key === 'name'
  }

  get fieldState(): boolean | null {
    if (this.field.key === 'name') {
      return Boolean(this.fieldValue)
    } else if (isNil(this.fieldValue)) {
      return null
    } else if (this.textPattern) {
      return new RegExp(this.textPattern).test(this.fieldValue)
    } else {
      return null
    }
  }
}
