import type { StoreState } from '@/store'
import type { Module } from 'vuex'
import type { Event } from '@/models/Event'
import { deleteOne, patchElements } from '@/features/surveys'

function assertEventState(data: Event | undefined): data is Event {
  if (!data) throw new Error('No event loaded')
  return true
}

const formsModule: Module<undefined, StoreState> = {
  actions: {
    async deleteForm({ commit, rootState }, formId) {
      if (!assertEventState(rootState.event.event)) return

      const event = await deleteOne(formId, rootState.event.event._id)
      commit('SET_EVENT', event)
    },

    async patchFormElements({ commit, rootState }, { patch, formId }) {
      if (!assertEventState(rootState.event.event)) return

      const event = await patchElements(patch, formId, rootState.event.event._id)
      commit('SET_EVENT', event)
    }
  }
}

export default formsModule
