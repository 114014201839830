




















































































































































import { Component, Prop } from 'vue-property-decorator'
import DigiVerticalMenu from '@/components/ui/verticalMenu/DigiVerticalMenu.vue'
import type Guest from '@/models/Guest'
import type { EventRights } from '@/models/Event/EventRights'
import { getRegistrationType } from './registration/registrationType'
import ContactProfileTab from './ui/ContactProfileTab.vue'
import SingleRegistrationTabContent from './registration/tabs/SingleRegistrationTabContent.vue'
import MultiRegistrationTabContent from './registration/tabs/MultiRegistrationTabContent.vue'
import PaidRegistrationTabContent from './registration/tabs/PaidRegistrationTabContent.vue'
import ContactProfilePaidRegistrationSubMenu from './registration/subs/ContactProfilePaidRegistrationSub.vue'
import ContactProfileMultiRegistrationSubMenu from './registration/subs/multi/ContactProfileMultiRegistrationSubMenu.vue'
import ContactProfileCheckinTabContent from './checkin/ContactProfileCheckinTabContent.vue'
import ContactProfileCheckinSubMenu from './checkin/ContactProfileCheckinSubMenu.vue'
import ContactProfileProgrammeTabContent from './programme/ContactProfileProgrammeTabContent.vue'
import ContactProfileProgrammeSubMenu from './programme/ContactProfileProgrammeSubMenu.vue'
import ContactProfileEditSubMenu from './edit/ContactProfileEditSubMenu.vue'
import ContactProfileSegmentsTabContent from './segments/ContactProfileSegmentsTabContent.vue'
import ContactProfileSegmentsSubMenu from './segments/ContactProfileSegmentsSubMenu.vue'
import ContactProfileMessagesTabContent from './messages/ContactProfileMessagesTabContent.vue'
import ContactProfileMessagesSubMenu from './messages/sub/ContactProfileMessagesSubMenu.vue'
import ContactProfileHistoryTabContent from './history/ContactProfileHistoryTabContent.vue'
import ContactProfileHistorySubMenu from './history/sub/ContactProfileHistorySubMenu.vue'
import type { GuestHistory } from '@/services/guestHistory'
import ContactProfileDebugSubMenu from './debug/ContactProfileDebugSubMenu.vue'
import type { Permission } from '@/services/eventPermissions'
import ContactProfilePdfBadgeTab from './pdfBadge/ContactProfilePdfBadgeTab.vue'
import ContactProfileCertificatesSubMenu from './certificates/ContactProfileCertificatesSubMenu.vue'
import ContactProfileCertificatesTabContent from './certificates/ContactProfileCertificatesTabContent.vue'
import ContactProfileNetworkingTab from './networking/ContactProfileNetworkingTab.vue'
import ContactProfileSurveysSubMenu from './surveys/ContactProfileSurveysSubMenu.vue'
import ContactProfileSurveysTabContent from './surveys/ContactProfileSurveysTabContent.vue'
import ContactProfileSingleRegistrationSubMenu from './registration/subs/ContactProfileSingleRegistrationSub.vue'
import type { ContactProfileQueryParams, ContactProfileTabItem, ContactProfileTabKey } from '../types'
import ContactProfileAccessPassTab from './accessPass/ContactProfileAccessPassTab.vue'
import { getSurveys } from '@/features/surveys/utils'
import { convertQueryStringToParamsObject } from '@/lib/url'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    ContactProfileAccessPassTab,
    ContactProfileSingleRegistrationSubMenu,
    ContactProfileSurveysTabContent,
    ContactProfileSurveysSubMenu,
    ContactProfileNetworkingTab,
    ContactProfileCertificatesTabContent,
    ContactProfileCertificatesSubMenu,
    ContactProfilePdfBadgeTab,
    ContactProfileDebugSubMenu,
    ContactProfileHistorySubMenu,
    ContactProfileHistoryTabContent,
    ContactProfileMessagesSubMenu,
    ContactProfileMessagesTabContent,
    ContactProfileSegmentsSubMenu,
    ContactProfileSegmentsTabContent,
    ContactProfileEditSubMenu,
    ContactProfileProgrammeSubMenu,
    ContactProfileProgrammeTabContent,
    ContactProfileCheckinSubMenu,
    ContactProfileCheckinTabContent,
    ContactProfileMultiRegistrationSubMenu,
    ContactProfilePaidRegistrationSubMenu,
    PaidRegistrationTabContent,
    MultiRegistrationTabContent,
    SingleRegistrationTabContent,
    ContactProfileTab,
    DigiVerticalMenu
  }
})
export default class ContactProfileMenu extends Vue {
  @Prop({ required: true }) readonly guestData!: Guest
  @Prop({ required: true }) readonly eventRights!: EventRights
  @Prop({ required: true }) readonly history!: GuestHistory
  @Prop({ required: true }) readonly permissions!: Permission[]
  @Prop({}) readonly initialOpenedTab!: ContactProfileTabKey

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get menuItems(): ContactProfileTabItem[] {
    const menuItems: ContactProfileTabItem[] = [
      { key: 'edit', label: this.$t('CONTACT_PROFILE_EDIT_TAB'), icon: 'user-settings-line' }
    ]

    if (this.hasRegistration) {
      switch (this.registrationType) {
        case 'single':
          menuItems.push({
            key: 'singleRegistration',
            label: this.$t('CONTACT_PROFILE_SINGLE_REGISTRATION_TAB'),
            icon: 'user-follow-line'
          })
          break
        case 'multi':
          menuItems.push({
            key: 'multiRegistration',
            label: this.$t('CONTACT_PROFILE_MULTI_REGISTRATION_TAB'),
            icon: 'group-line'
          })
          break
        case 'paid':
          menuItems.push({
            key: 'paidRegistration',
            label: this.$t('CONTACT_PROFILE_PAID_REGISTRATION_TAB'),
            icon: 'coupon-3-line'
          })
          break
      }
    }

    if (this.hasAccessPass) {
      menuItems.push({
        key: 'accessPass',
        label: this.$t('CONTACT_PROFILE_ACCESS_PASS_TAB'),
        icon: 'qr-code-line'
      })
    }

    if (this.hasProgrammeModule) {
      menuItems.push({
        key: 'programme',
        label: this.$t('CONTACT_PROFILE_PROGRAMME_TAB'),
        icon: 'calendar-2-line'
      })
    }

    if (this.hasPdfBadgeModule) {
      menuItems.push({
        key: 'pdfBadge',
        label: this.$t('CONTACT_PROFILE_PDFBADGE_TAB'),
        icon: 'article-line'
      })
    }

    if (this.hasCheckinEnabled) {
      menuItems.push({
        key: 'checkins',
        label: this.$t('CHECKINS'),
        icon: 'qr-scan-line'
      })
    }

    if (this.hasNetworkModule) {
      menuItems.push({
        key: 'networking',
        label: this.$t('CONTACT_PROFILE_NETWORKING_TAB'),
        icon: 'body-scan-line'
      })
    }

    if (this.hasSurveyTab) {
      menuItems.push({
        key: 'surveys',
        label: this.$t('CONTACT_PROFILE_SURVEYS_TAB'),
        icon: 'chat-poll-line'
      })
    }

    menuItems.push(
      { key: 'segments', label: this.$t('CONTACT_PROFILE_SEGMENTS_TAB'), icon: 'filter-2-line' },
      { key: 'messages', label: this.$t('CONTACT_PROFILE_MESSAGE_TAB'), icon: 'archive-line' }
    )

    if (this.hasCertificateModule) {
      menuItems.push({
        key: 'certificate',
        label: this.$t('CONTACT_PROFILE_CERTIFICATES_TAB'),
        icon: 'award-line'
      })
    }

    menuItems.push({ key: 'history', label: this.$t('CONTACT_PROFILE_HISTORY_TAB'), icon: 'history-line' })

    if (this.permissions.includes('accessInKnox')) {
      menuItems.push({ key: 'debug', label: this.$t('CONTACT_PROFILE_DEBUG_TAB'), icon: 'bug-line' })
    }

    return menuItems
  }

  get hasProgrammeModule(): boolean {
    return this.eventRights.features.eventApp.allowed || this.eventRights.features.eventApp.matchmaking
  }

  get hasAccessPass(): boolean {
    return this.eventRights.features.checkin.accessPass
  }

  get hasCheckinEnabled(): boolean {
    return this.eventRights.features.checkin.app
  }

  get hasCertificateModule(): boolean {
    return this.eventRights.features.certificates
  }

  get hasRegistration() {
    return this.eventRights.features.registration.mode !== 'none'
  }

  get registrationType() {
    return getRegistrationType(this.eventRights)
  }

  get hasPdfBadgeModule(): boolean {
    return this.eventRights.features.pdfBadges
  }

  get hasNetworkModule(): boolean {
    return this.eventRights.features.eventApp.networking
  }

  get hasWebsiteModule(): boolean {
    return this.eventRights.features.website.allowed
  }

  get hasSurveyTab() {
    if (!this.hasWebsiteModule) return false
    return getSurveys(this.storeEvent.forms, this.storeEvent.modules_data.multiRegistration?.slaveFormId).length > 0
  }

  reload(tab: ContactProfileTabKey) {
    this.$emit('reload', tab)
  }

  closeAndReload() {
    this.$emit('closeAndReload')
  }

  setActiveTabQueryParam(tabKey: ContactProfileTabKey) {
    const urlParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlParams.entries())
    const activeTabQueryParamKey: keyof ContactProfileQueryParams = 'contactProfileActiveTab'
    const newQuery = { ...queryParams, [activeTabQueryParamKey]: tabKey }
    const { href } = this.$router.resolve({ query: newQuery })
    history.pushState({}, '', href)
  }

  removeActiveTabQueryParam() {
    const queryParams = convertQueryStringToParamsObject(window.location.search)
    const activeTabQueryParamKey: keyof ContactProfileQueryParams = 'contactProfileActiveTab'
    delete queryParams[activeTabQueryParamKey]
    const { href } = this.$router.resolve({ query: queryParams })
    history.pushState({}, '', href)
  }
}
