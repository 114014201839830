










import { Component, Vue, VModel } from 'vue-property-decorator'
import type { PreferredFeedbackMethod } from './types'

@Component
export default class PreferredFeedbackMethodInput extends Vue {
  @VModel({ required: true }) preferredFeedbackMethod!: PreferredFeedbackMethod

  get options(): { value: PreferredFeedbackMethod; text: string }[] {
    return [
      {
        value: 'Email',
        text: this.$t('SUPPORT_REQUEST_PREFERRED_FEEDBACK_METHOD_EMAIL')
      },
      {
        value: 'Phone',
        text: this.$t('SUPPORT_REQUEST_PREFERRED_FEEDBACK_METHOD_PHONE')
      },
      {
        value: 'Any',
        text: this.$t('SUPPORT_REQUEST_PREFERRED_FEEDBACK_METHOD_NO_PREFERENCE')
      }
    ]
  }
}
