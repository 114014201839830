









import { Component, Prop } from 'vue-property-decorator'
import ContactCheckinItem from './ContactCheckinItem.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { CheckinPoint } from '@/models/Event/modules/Checkin'
import { fromArrayToMap } from '@/utils/arrays'
import moment from 'moment/moment'
import type { GuestCheckin } from '@/models/Guest'
import type Guest from '@/models/Guest'
import type { CompleteGuestCheckin } from './types'

@Component({
  components: { ContactCheckinItem }
})
export default class ContactCheckins extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get checkins(): GuestCheckin[] {
    return this.guest.checkins ?? []
  }

  get checkinsMap(): Record<string, CheckinPoint> {
    return fromArrayToMap(this.storeEvent.modules_data?.checkin.points, '_id')
  }

  get completeGuestCheckins(): CompleteGuestCheckin[] {
    return this.checkins.map((c) => ({
      ...c,
      checkinPointName: this.checkinsMap[c.checkinPointId]?.name ?? this.$t('UNKNOWN_CHECKIN_POINT')
    }))
  }

  get checkinsSorted(): CompleteGuestCheckin[] {
    return this.completeGuestCheckins.sort((a, b) => moment(b.date).diff(moment(a.date)))
  }
}
