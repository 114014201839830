












import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { ContactCheckins } from '@/features/checkin'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { DigiVerticalMenuSub } from '@/components/ui/verticalMenu'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileActionsSubMenu, DigiSimpleIconButton, DigiVerticalMenuSub, ContactCheckins }
})
export default class ContactProfileCheckinSubMenu extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
