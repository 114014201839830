







import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import ContactOrders from './parts/ContactOrders.vue'
import ContactTickets from './parts/ContactTickets.vue'

@Component({ components: { ContactTickets, ContactOrders } })
export default class ContactTicketing extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
