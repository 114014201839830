import type { EventRights } from '@/models/Event/EventRights'

export const multiRegistrationMenuSection = {
  key: 'multiRegistration',
  titleSlug: 'EVENT_MENU_MULTI_REGISTRATION_TITLE',
  iconName: 'group-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_MULTI_REGISTRATION_SETTINGS_LINK',
      routeName: 'event.website.multiregistration.settings',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.features.registration.type === 'multi' && eventRights.features.website.allowed
    },
    {
      titleSlug: 'EVENT_MENU_MULTI_REGISTRATION_RULES_LINK',
      routeName: 'event.website.multiregistration.rules',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.features.registration.type === 'multi' && eventRights.features.website.allowed
    }
  ]
} as const
