import { ElementTypeDictionary } from '../../types'
import KeyFiguresElement from './keyFigures1/KeyFiguresElement'

export const keyFiguresDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: KeyFiguresElement,
    renderer: () => import('./keyFigures1/KeyFigures.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.mainTextColor' },
      { type: 'color', label: 'KEY_FIGURES_COLOR', key: 'properties.keyFigureColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_KEY_FIGURES',
    previewImageLink: require('@/assets/img/prefab-previews/keyFigures.jpg')
  })
  .finish()
