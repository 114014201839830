import { isNil } from 'lodash'
import type { FilterId } from '@/models/Event/Filter'
import type { GuestFieldKey } from '@/models/Event/GuestField'
import type { MongoDb } from '@/models/MongoDbTools'
import type { BackofficeLanguage } from '@/utils/i18n'

export enum ExportStatus {
  Preparing = 'preparing',
  Ready = 'ready',
  Failed = 'failed'
}

export enum ExportKind {
  GuestList = 'guestList',
  OrderList = 'orderList',
  MatchmakingPlanning = 'matchmakingPlanning',
  SignatureReport = 'signatureReport',
  PDFBadge = 'pdfBadge',
  Cerfa = 'cerfa',
  CreditNote = 'creditNote',
  CustomFile = 'file',
  Invoice = 'invoice',
  QRCode = 'qr',
  Certificate = 'certificate'
}

export type NullableFilterId = FilterId | null

export function queryParamAsNullableString(value: unknown): string | null {
  if (isNil(value) || value === '') {
    return null
  } else if (typeof value === 'string') {
    return value
  }
  throw new Error('Bad query parameter')
}

export interface Segmentable {
  segmentIds: string[]
}

interface ExportBase {
  _id: MongoDb.ObjectId
  kind: ExportKind
  status: ExportStatus
  date: Date
  expiryDate: Date
  creator: string
  url?: string // Undefined if not ready.
  entriesCount?: number // Undefined if not ready.
  error?: string // Undefined if not failed.
}

interface QRCodeExport extends ExportBase, Segmentable {
  kind: ExportKind.QRCode
}

interface PDFBadgeExport extends ExportBase, Segmentable {
  kind: ExportKind.PDFBadge
}

interface CerfaExport extends ExportBase, Segmentable {
  kind: ExportKind.Cerfa
}

interface InvoiceExport extends ExportBase, Segmentable {
  kind: ExportKind.Invoice
}

interface ImageFileExport extends ExportBase, Segmentable {
  kind: ExportKind.CustomFile
  documentKey: GuestFieldKey
}

interface CreditNoteExport extends ExportBase, Segmentable {
  kind: ExportKind.CreditNote
}

export interface GuestListOptions {
  fields: GuestFieldKey[]
  language: BackofficeLanguage
}
interface GuestListExport extends ExportBase, Segmentable, GuestListOptions {
  kind: ExportKind.GuestList
}

export interface SignatureReportOptions {
  fields: GuestFieldKey[]
  language: BackofficeLanguage
}
interface SignatureReportExport extends ExportBase, Segmentable, SignatureReportOptions {
  kind: ExportKind.SignatureReport
}

export interface OrderListOptions {
  language: BackofficeLanguage
}
interface OrderListExport extends ExportBase, OrderListOptions {
  kind: ExportKind.OrderList
}

interface MatchmakingPlanningExport extends ExportBase {
  kind: ExportKind.MatchmakingPlanning
}

type Export =
  | QRCodeExport
  | PDFBadgeExport
  | CerfaExport
  | InvoiceExport
  | CreditNoteExport
  | GuestListExport
  | SignatureReportExport
  | ImageFileExport
  | MatchmakingPlanningExport
  | OrderListExport

export function isSegmentableExport(exportItem: Export): exportItem is Extract<Export, Segmentable> {
  return [
    ExportKind.QRCode,
    ExportKind.PDFBadge,
    ExportKind.Cerfa,
    ExportKind.Invoice,
    ExportKind.CustomFile,
    ExportKind.CreditNote,
    ExportKind.GuestList,
    ExportKind.SignatureReport
  ].includes(exportItem.kind)
}

export default Export
