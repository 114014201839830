






























import { Component, Prop } from 'vue-property-decorator'
import {
  createRule,
  updateRule,
  checkIfTriggerIsValid,
  checkIfActionIsValid,
  mustFindRuleById,
  getRuleFromCache
} from '../service'
import { cloneDeep } from 'lodash'
import triggerUtils from '../triggerUtils'
import actionUtils from '../actionUtils'
import TriggerStep from './components/steps/TriggerStep.vue'
import Rule from '@/models/Event/RulesData/Rule'
import type { Route, NavigationGuardNext } from 'vue-router'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import { DigiButton } from '@/components/ui/actions'

@Component({
  components: {
    DigiButton,
    TriggerStep,
    FilterStep: () => import('./components/steps/FilterStep.vue'),
    ActionStepsWrapper: () => import('./components/ActionStepsWrapper/index.vue')
  }
})
export default class RuleEdition extends Vue {
  @Prop({ required: true }) readonly availableTriggers!: TriggerKind[]
  rule: Rule | null = null
  isSaving: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get ruleStateText(): 'ENABLED' | 'DISABLED' {
    return this.rule?.isEnabled ? 'ENABLED' : 'DISABLED'
  }

  get shouldShowFilterAndActions(): boolean {
    return Boolean(
      this.isTriggerValid || this.rule?.actions.some((action) => action.kind) || this.rule?.segmentsIds?.length
    )
  }

  get isCreatingRule(): boolean {
    return !this.rule?._id
  }

  // Validation
  get isRuleValid(): boolean {
    return this.isTriggerValid && this.areActionsValid
  }

  get isTriggerValid(): boolean {
    return Boolean(this.rule?.trigger && this.areTriggerSettingsValid)
  }

  get areTriggerSettingsValid(): boolean | null {
    if (this.rule?.trigger) {
      return checkIfTriggerIsValid(triggerUtils[this.rule.trigger.kind], this.rule.trigger)
    } else {
      return null
    }
  }

  get areActionsValid(): boolean {
    if (this.rule && this.rule.actions.length > 0) {
      return this.rule.actions.every((action) => checkIfActionIsValid(actionUtils[action.kind], action))
    } else {
      return false
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.name === this.$route.meta?.subPageOf) {
      this.resetCachedRule()
    }
    next()
  }

  created(): void {
    const ruleId = this.$route.params.ruleId
    const cachedRule = this.$store.state.event.rulesModule.cachedRule
    if (!ruleId) {
      this.rule = cloneDeep(getRuleFromCache(ruleId, cachedRule)) ?? new Rule()
      return
    }
    this.rule = cloneDeep(mustFindRuleById(ruleId, cachedRule, this.storeEvent.rules.ruleItems))
  }

  setCachedRule(): void {
    this.$store.commit('SET_CACHED_RULE', this.rule)
  }

  resetCachedRule(): void {
    this.$store.commit('RESET_CACHED_RULE')
  }

  async save(): Promise<void> {
    if (this.rule) {
      this.isSaving = true
      try {
        if (this.isCreatingRule) {
          const newRule = await createRule(this.rule)
          this.rule = Object.assign({}, this.rule, newRule)
          this.resetCachedRule()
        } else {
          await updateRule(this.rule)
        }

        await this.$store.dispatch('reloadCurrentEvent') // sync event with backend
        this.$notify({
          type: 'success',
          text: this.$t('MODIFICATIONS_SAVED')
        })
      } finally {
        this.isSaving = false
      }
    }
  }
}
