import type { ElementCreationContext } from '../../../types/ElementTypeDictionary'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import MapElementBase, { MapProperties } from '../MapElementBase'
import { LoremIpsum } from 'lorem-ipsum'

export class Map1Properties extends MapProperties {
  title: string = ''
  titleColor: string = ''
  textColor: string = ''
  mainText: string = ''
}

export default class Map1Element extends MapElementBase {
  static create({ palette }: ElementCreationContext): Map1Element {
    const map = new Map1Element()

    map.properties.title = i18n.t('ACCESS_MAP')
    map.properties.titleColor = palette.darkestColor.paletteCode
    map.properties.textColor = palette.darkestColor.paletteCode

    map.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 10 },
      sentencesPerParagraph: { min: 1, max: 1 }
    }).generateParagraphs(1)

    return map
  }

  type = 'prefab-map-1' as const

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 450
  }

  @Type(() => Map1Properties)
  properties: Map1Properties = new Map1Properties()

  get prettyName(): string {
    return i18n.t('BLOCK_MAP')
  }
}
