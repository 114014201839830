









import { Component, Prop, Ref } from 'vue-property-decorator'
import FreeTicketGrantingModal from './FreeTicketGrantingModal.vue'
import { DigiIconButton } from '@/components/ui/actions'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { DigiIconButton, FreeTicketGrantingModal }
})
export default class TicketGrantCta extends Vue {
  @Prop({ required: true }) readonly selectedGuestIds!: string[]
  @Ref() readonly freeTicketGrantingModal!: FreeTicketGrantingModal

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get displayCta(): boolean {
    return this.eventRights.features.registration.mode === 'paid'
  }
}
