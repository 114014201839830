



































import { Component, Prop, Vue } from 'vue-property-decorator'
import moment from 'moment'
import MessageHistoryHeader from './MessageHistoryHeader.vue'
import MessageHistoryActivity from './MessageHistoryActivity.vue'
import HistoryBlock from '@/components/features/HistoryBlock.vue'
import type { CompleteGuestMessage } from '../../types'

@Component({
  components: { HistoryBlock, MessageHistoryActivity, MessageHistoryHeader }
})
export default class MessageHistory extends Vue {
  @Prop() readonly message!: CompleteGuestMessage

  isOpen = false

  get messageTime(): string {
    return moment(this.message.eventDate).format('DD/MM/YYYY - HH:mm')
  }
}
