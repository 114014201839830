










import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ticketColor extends Vue {
  @Prop({ default: '#000000' }) readonly ticketColor!: string
}
