import { ElementTypeDictionary } from '../types'
import ColumnElement from './Column/ColumnElement'
import RowElement from './Row/RowElement'
import MainColumnElement from './MainColumn/MainColumnElement'

export const layoutDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    renderer: () => import('./Column/Column.vue'),
    dataClass: ColumnElement,
    typeNameSlug: 'Column of blocks',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./Row/Row.vue'),
    dataClass: RowElement,
    properties: [
      {
        type: 'seamlessgroup',
        condition: ({ isTopLevel }) => isTopLevel,
        content: [
          {
            key: 'box.pageFit',
            type: 'pagefit',
            label: 'WIDE_CONTAINER'
          },
          {
            key: 'box.padding.0',
            type: 'range',
            label: 'PADDING',
            unit: 'px',
            min: 0,
            max: 40,
            step: 5,
            defaultValue: () => 0
          }
        ]
      },
      {
        type: 'seamlessgroup',
        condition: ({ element }) => element.children.length > 1,
        content: [
          {
            type: 'range',
            label: 'COLUMN_SPACING',
            unit: 'px',
            min: 0,
            max: 40,
            step: 5,
            key: 'properties.gutterSize',
            defaultValue: () => 0
          },
          {
            type: 'rowaligncontent',
            label: 'VERTICAL_ALIGNMENT',
            key: 'properties.verticalAlignment'
          }
        ]
      }
    ],
    typeNameSlug: 'Row of blocks',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .addType({
    renderer: () => import('./MainColumn/MainColumn.vue'),
    dataClass: MainColumnElement,
    typeNameSlug: 'PAGE',
    previewImageLink: require('@/assets/img/prefab-previews/quote.jpg')
  })
  .finish()
