














import { Component, Prop, Ref } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { InvitationStatusDropdown } from '@/features/registration'
import { joinIfExist } from '@/utils/string'
import { isAnAdditional } from '@/features/registration/multiRegistration'
import { getGuestById } from '@/services/guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { DigiIconButton } from '@/components/ui/actions'
import type ContactProfileModal from '../../../../../ContactProfileModal.vue'

@Component({
  components: {
    DigiIconButton,
    InvitationStatusDropdown,
    ContactProfileModal: () => import('../../../../../ContactProfileModal.vue')
  }
})
export default class MultiRegistrationAdditionnalAttendeeSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Ref() readonly contactProfileModal!: ContactProfileModal

  mainAttendee: Guest | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get mainAttendeeName() {
    return this.mainAttendee ? joinIfExist([this.mainAttendee.firstname, this.mainAttendee.name], ' ') : ''
  }

  openContactProfileModal() {
    if (!this.mainAttendee) return
    this.contactProfileModal.loadGuestAndOpenModal(this.mainAttendee, 'multiRegistration')
  }

  async mounted() {
    if (isAnAdditional(this.guest)) {
      this.mainAttendee = await getGuestById(this.storeEvent._id, this.guest.slaveRegistrationOf)
    }
  }
}
