































import { Component, Prop, Ref } from 'vue-property-decorator'
import { DigiButton } from '@/components/ui/actions'
import type { EditLegalEntityModal } from '@/features/eventSettings/legalEntity'
import { mustFindRuleById } from '@/features/rules/service'
import { isMessageToGuestAction, type AnyAction, type MessageToGuestAction } from '@/models/Event/RulesData/Rule/Action'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'
import type { MessageTemplate } from '@/models/Event/Messages/template';
import { isEmailTemplate } from '@/models/Event/Messages/template'
import { doIHavePermission } from '@/services/eventPermissions'
import { Icon, MessageType } from './types'

@Component({
  components: {
    DigiButton,
    AddNewMessage: () => import('../addNewMessage/AddNewMessageFormer.vue'),
    EditLegalEntityModal: () => import('@/components/features/EditLegalEntityModal.vue')
  }
})
export default class SendMessageToGuest extends Vue {
  @Ref() readonly legalEntityModal!: EditLegalEntityModal

  @Prop({ required: true }) readonly action!: AnyAction
  @Prop({ required: true }) readonly messageType!: MessageType

  isCreatingMessage: boolean = false
  canConfigEvent: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get messageToGuestAction(): MessageToGuestAction {
    if (!isMessageToGuestAction(this.action)) {
      throw new Error('MessageToGuestMixin can only  manage action of type MessageToGuestAction')
    }

    return this.action
  }

  get showLegalInfosError(): boolean {
    return (
      this.messageType === MessageType.Email &&
      this.selectedMessage !== null &&
      isEmailTemplate(this.selectedMessage) &&
      this.selectedMessage.useTheme &&
      !this.storeEvent.states.isEventLegalInfosSet
    )
  }

  get icon(): Icon {
    switch (this.messageType) {
      case MessageType.Email:
        return Icon.Email
      case MessageType.Sms:
        return Icon.Phone
      default:
        throw new Error('Unexpected: unknown message type')
    }
  }

  get selectedMessage(): MessageTemplate | null {
    if (this.messageToGuestAction.messageTemplateId) {
      const selectedMessage = this.storeEvent.messages.templates.find(
        (message) => message._id === this.messageToGuestAction.messageTemplateId
      )

      if (!selectedMessage) {
        throw new Error('Unexpected: selected message id can not be found into event, at messageToGuestMixinFactory')
      }

      return selectedMessage
    } else {
      return null
    }
  }

  async mounted() {
    this.canConfigEvent = await doIHavePermission(this.storeEvent._id, 'configEvent')
  }

  goToMessageTemplatePage(message: MessageTemplate) {
    // Set the id of the message we want to edit in messageTemplateId, so it's selected when we come back.
    this.$emit('update', this.action, message._id)

    // Cache the rule in the store so we can retrieve it later
    this.$emit('requestSetCachedRule')
    // Go the message editor

    switch (message.method) {
      case 'email':
        this.$router.push({
          name: 'event.rules.message.email',
          params: { messageId: message._id, ruleId: this.currentRuleId }
        })
        break
      case 'sms':
        this.$router.push({
          name: 'event.rules.message.sms',
          params: { messageId: message._id, ruleId: this.currentRuleId }
        })
    }
  }

  async addNewMessage(message: MessageTemplate): Promise<void> {
    try {
      this.isCreatingMessage = true
      await this.$store.dispatch('postEventMutation', (event: Event) => {
        event.messages.templates.push(message)
        return event
      })
      const createdMessage = this.storeEvent.messages.templates[this.storeEvent.messages.templates.length - 1]

      if (!createdMessage) {
        throw new Error('Unexpected: can not find created message. Store event has no messages')
      }

      this.goToMessageTemplatePage(createdMessage)
    } finally {
      this.isCreatingMessage = false
    }
  }

  get currentRuleId(): string {
    const ruleId = this.$route.params.ruleId
    const cachedRule = this.$store.state.event.rulesModule.cachedRule
    const rule = mustFindRuleById(ruleId, cachedRule, this.storeEvent.rules.ruleItems)
    return rule._id
  }
}
