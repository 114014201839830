import type { ReadonlyFullRouteConfig } from '@/core'

export const contactListRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'contacts',
    name: 'contacts',
    component: () => import('./ContactList.vue'),
    meta: {
      name: 'FACTORY_MY_CONTACTS',
      iconName: 'contacts-line',
      keywords: 'FACTORY_MY_CONTACTS_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['guestList_read'],
        redirectName: 'event.dashboard'
      },
      renderingData: {
        isContentFullWidth: true,
        shouldDisplayPageHeader: false
      }
    }
  }
] as const
