













































import { Component, Prop, Ref } from 'vue-property-decorator'
import { cancelMeeting } from '../../../sessions/services'
import type ScheduledItem from '@/models/Guest/Planning/ScheduledItem'
import type Guest from '@/models/Guest'
import { DigiIconButton } from '@/components/ui/actions'
import RescheduleMeeting from '../../RescheduleMeeting.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { DigiSimpleIconButton } from '@/components/ui/actions'
import PlanningCard from '../PlanningCard.vue'
import type MeetingSchedule from '@/models/Guest/Planning/ScheduledItem/Meeting'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import type { GuestAppProfile } from '../services'
import { getGuestAppProfile } from '../services'
import CounterPartProfile from './CounterPartProfile.vue'
import PlanningCheckDate from '../PlanningCheckDate.vue'
import MeetingCancelBadge from './MeetingCancelBadge.vue'
import MeetingLocation from './MeetingLocation.vue'
import MeetingDateRange from './MeetingDateRange.vue'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'

@Component({
  components: {
    PlanningCard,
    CounterPartProfile,
    PlanningCheckDate,
    RescheduleMeeting,
    DigiIconButton,
    DigiSimpleIconButton,
    MeetingCancelBadge,
    MeetingDateRange,
    MeetingLocation,
    DigiDeleteConfirmationModal
  }
})
export default class MeetingCard extends Vue {
  @Prop({ required: true }) readonly meetingId!: string
  @Prop({ required: true }) readonly guest!: Guest

  @Ref() readonly rescheduleModal!: RescheduleMeeting

  isLoading = false
  counterPartAppProfile: GuestAppProfile | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get counterPartId(): string {
    return this.meeting[this.counterPartRole]._guest
  }

  get counterPartRole() {
    return this.meeting.target._guest === this.guest._id ? 'applicant' : 'target'
  }

  get meeting(): MeetingSchedule {
    const scheduledItem = this.guest.planning?.scheduledItems.find(
      (item: ScheduledItem): item is MeetingSchedule => item._id === this.meetingId && item.kind === 'meeting'
    )
    if (!scheduledItem) {
      throw new Error('Could not find scheduled meeting.')
    }
    return scheduledItem
  }

  get matchmakingSession(): MatchmakingSession {
    const matchmakingSession = this.storeEvent.modules_data.programme?.eventActivities.find(
      (activity): activity is MatchmakingSession =>
        activity._id === this.meeting.eventActivityId && activity.kind === 'matchmakingSession'
    )
    if (!matchmakingSession) {
      throw new Error(`Could not find matchmaking session with id ${this.meeting.eventActivityId}.`)
    }
    return matchmakingSession
  }

  reschedule() {
    this.rescheduleModal.doReschedule()
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }

  async cancelMeeting(): Promise<void> {
    await cancelMeeting(this.$store.getters.eventId, this.meeting._id)
    this.$emit('deleted')
  }

  async getCounterPartappProfile() {
    try {
      this.isLoading = true
      this.counterPartAppProfile = await getGuestAppProfile({
        guestId: this.counterPartId,
        eventId: this.storeEvent._id
      })
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.getCounterPartappProfile()
  }
}
