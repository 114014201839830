






import { Vue, Component } from 'vue-property-decorator'
import TrombinoFakeGuestCard from './TrombinoFakeGuestCard.vue'

@Component({
  components: { TrombinoFakeGuestCard }
})
export default class TrombinoFakeGuestList extends Vue {}
