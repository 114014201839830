








































import type * as CSS from 'csstype'

import PrefabCta from '../../components/cta/PrefabCta.vue'

import { ImageFragment, PlainTextFragment, SlightlyRichTextFragment } from '../../../fragments'

import type { Cover1Element } from './Cover1Element'

import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import type { Website } from '@/models/Event/modules/Website'
import { pickColorWithPalette } from '@/utils/colors'
import type { PageElementId } from '../../../types'
import type { BuilderState } from '@/features/website/builder/BuilderState'
import { assignElement } from '../../../utils'

@Component({ components: { ImageFragment, PlainTextFragment, SlightlyRichTextFragment, PrefabCta } })
export default class PrefabCover1 extends Vue {
  @Prop({ required: true }) readonly id!: PageElementId
  @Prop({ required: true }) readonly website!: Website

  @Inject() builderState!: BuilderState

  get pageElement(): Cover1Element {
    return this.builderState.assertedPageContent?.elements[this.id] as Cover1Element
  }

  setElementProperty(propertyPath: string, newValue: any): void {
    const newElement = assignElement(this.pageElement, propertyPath, newValue)

    this.builderState.assertedPageContent!.updateElement(this.id, newElement)
  }

  get cta(): CtaProperties | null {
    return this.pageElement.properties.cta.destination.type === 'none' ? null : this.pageElement.properties.cta
  }

  get computedTextColor(): string {
    return pickColorWithPalette(this.pageElement.properties.textColor, this.$store.getters.colorPalette)
  }

  get tagTextColor(): string {
    return pickColorWithPalette(this.pageElement.properties.tagColor, this.$store.getters.colorPalette)
  }

  get mainBlockStyle(): CSS.Properties & { '--min-height': string } {
    return {
      color: this.computedTextColor,
      '--min-height': `${this.pageElement.properties.minHeight / 100}`
    }
  }

  get textBlockStyle(): CSS.Properties {
    return {
      color: this.computedTextColor
    }
  }
}
