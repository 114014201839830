import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import type { MediaProperties } from '../../../types/helperTypes'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'

export class Media2Properties extends PrefabPropertiesBase {
  title: string = ''
  mainText: string = ''
  tagText: string = ''
  media: MediaProperties | null = null
  titleColor: string = ''
  mainTextColor: string = ''
  tagTextColor: string = ''
}

export default class Media2Element extends MediaElementBase {
  static create({ palette }: ElementCreationContext): Media2Element {
    const media2 = new Media2Element()
    const darkestColor = palette.darkestColor.paletteCode

    media2.properties.title = i18n.t('AN_INSPIRING_TITLE')
    media2.properties.mainText = new LoremIpsum(
      {
        wordsPerSentence: { min: 3, max: 8 },
        sentencesPerParagraph: { min: 2, max: 3 }
      },
      undefined,
      `\n\n`
    ).generateParagraphs(2)
    media2.properties.tagText = i18n.t('SECTION_NAME')
    media2.properties.titleColor = darkestColor
    media2.properties.mainTextColor = darkestColor
    media2.properties.tagTextColor = darkestColor

    return media2
  }

  type = `prefab-media-2` as const

  @Type(() => Media2Properties)
  properties: Media2Properties = new Media2Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_IMAGES2')
  }
}
