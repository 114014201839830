import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'

export type Operator =
  | 'IS'
  | 'ISNT'
  | 'CONTAINS'
  | 'NOT_CONTAINS'
  | 'IS_EMPTY'
  | 'ISNT_EMPTY'
  | 'GT'
  | 'LT'
  | 'IS_CHECKED'
  | 'ISNT_CHECKED'
  | 'IS_SUBSCRIBE'
  | 'ISNT_SUBSCRIBE'
  | 'IS_ACCOMPANIST'
  | 'IS_NOT_ACCOMPANIST'
  | 'IS_ACCOMPANIED'
  | 'HAS_NO_ACCOMPANIST'
  | 'IS_ARRIVED'
  | 'ISNT_ARRIVED'
  | 'BEFORE'
  | 'AFTER'
  | 'IS_DATE'
  | 'SUBMITTED_BEFORE'
  | 'SUBMITTED_AFTER'
  | 'SUBMITTED_ON'
  | 'IS_SUBMITTED'
  | 'ISNT_SUBMITTED'
  | 'HAS_STATUS'
  | 'HASNT_STATUS'
  | 'IN_ARRAY'
  | 'NOT_IN_ARRAY'
  | 'HAS_CONFIRMED_MEETING'
  | 'HAS_CANCELED_MEETING'
  | 'HAS_AWAITING_MEETING'
  | 'HAS_DECLINED_MEETING'
  | 'HAS_A_VALID_TICKET'
  | 'HAS_NO_VALID_TICKET'
  | 'HAS_ONLY_CANCELED_TICKETS'

export enum MessageStatusCriteriaValue {
  NotSent = 'not_sent',
  AllSent = 'all_sent',
  Opened = 'open',
  Clicked = 'click',
  Error = 'all_error'
}

export enum SourceCriteriaValue {
  Extra = 'extra',
  Excel = 'excel',
  Register = 'register',
  Back = 'back'
}

export type CriteriaProperty = string

export type CriteriaValue = string | number | MessageStatusCriteriaValue | SourceCriteriaValue | string[]

export default class Criteria {
  property!: CriteriaProperty
  operator!: Operator
  // Strongly typing this variable is quite difficult but not impossible
  // @TODO: Consider the challenge at some point
  testedValue?: CriteriaValue
  @TransformDateStringToDateObject()
  testedDate?: Date
}
