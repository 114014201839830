















import { Vue, Component, Prop } from 'vue-property-decorator'
import type { FieldItem } from '../types'
import GuestFieldInput from './GuestFieldInput/GuestFieldInput.vue'
import type Guest from '@/models/Guest'
import RegexBadge from '@/features/audience/RegexBadge.vue'

@Component({
  components: { RegexBadge, GuestFieldInput }
})
export default class EditFieldItem extends Vue {
  @Prop({ required: true }) readonly field!: FieldItem
  @Prop({ required: true }) readonly guest!: Guest
}
