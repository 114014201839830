import { LoremIpsum } from 'lorem-ipsum'
import { Type } from 'class-transformer'

import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'

import { PrefabPropertiesBase } from '../../PrefabElementBase'
import SocialsElementBase from '../SocialsElementBase'

export class Post {
  authorName: string = ''
  authorPicture: null | string = null
  text: string = ''
  picture: null | string = null
  likesCount: number = 0
  commentsCount: number = 0
  originalLink: string = ''
}

export class SocialPost1Properties extends PrefabPropertiesBase {
  tag: string = ''
  tagColor: string = ''

  title: string = ''
  titleColor: string = ''

  text: string = ''
  textColor: string = ''

  @Type(() => Post)
  posts: Post[] = [
    // Shows a default post when the models is selected
    new Post()
  ]
}

export default class SocialPost1Element extends SocialsElementBase {
  static create({ palette }: ElementCreationContext): SocialPost1Element {
    const socialPost = new SocialPost1Element()
    const darkestColor = palette.darkestColor.paletteCode

    socialPost.properties.tag = i18n.t('SECTION_NAME')
    socialPost.properties.tagColor = darkestColor

    socialPost.properties.title = i18n.t('AN_INSPIRING_TITLE')
    socialPost.properties.titleColor = darkestColor

    socialPost.properties.text = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)
    socialPost.properties.textColor = darkestColor

    return socialPost
  }

  type = `prefab-social-post-1` as const

  @Type(() => SocialPost1Properties)
  properties: SocialPost1Properties = new SocialPost1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SOCIAL_POST')
  }
}
