import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import KeyFiguresElementBase, { KeyFiguresProperties } from '../KeyFiguresElementBase'

export default class KeyFiguresElement extends KeyFiguresElementBase {
  static create({ palette }: ElementCreationContext): KeyFiguresElement {
    const element = new KeyFiguresElement()
    const darkestColor = palette.darkestColor.paletteCode

    element.properties.title = i18n.t('AN_INSPIRING_TITLE')
    element.properties.titleColor = darkestColor

    element.properties.keyFigureColor = darkestColor

    return element
  }

  type = 'prefab-key-figures-1' as const

  @Type(() => KeyFiguresProperties)
  properties: KeyFiguresProperties = new KeyFiguresProperties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_KEY_FIGURES')
  }
}
