import store from '@/store'
import type { RuleId } from '@/models/Event/RulesData/Rule'
import type Rule from '@/models/Event/RulesData/Rule'
import type { ActionUtil, TriggerUtil, StepUtil } from './RuleEdition/types'
import type { AnyAction } from '@/models/Event/RulesData/Rule/Action'
import type { AnyTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import axios from 'axios'

export async function duplicateRule(ruleId: string): Promise<{ ruleId: string }> {
  const res = await axios.post(`/backoffice/events/${store.getters.eventId}/rules/${ruleId}/duplicate`)
  return res.data
}

export async function deleteRule(ruleId: RuleId): Promise<void> {
  await axios.delete(`/backoffice/events/${store.getters.eventId}/rules/${ruleId}`)
}

export async function createRule(rule: Partial<Rule>): Promise<Rule> {
  const res = await axios.post(`/backoffice/events/${store.getters.eventId}/rules`, {
    rule
  })

  return res.data
}

export async function updateRule(rule: Partial<Rule>): Promise<void> {
  await axios.put(`/backoffice/events/${store.getters.eventId}/rules/${rule._id}`, {
    rule
  })
}

export function checkIfItemIsAllowed(itemDefinition: StepUtil): boolean {
  if (typeof itemDefinition?.isAllowed === 'function') {
    return itemDefinition.isAllowed()
  } else {
    return true
  }
}

export function checkIfTriggerIsValid(triggerUtil: TriggerUtil, trigger: AnyTrigger): boolean {
  if (typeof triggerUtil.validator === 'function') {
    return triggerUtil.validator(trigger)
  } else {
    return true
  }
}

export function checkIfActionIsValid(actionUtil: ActionUtil, action: AnyAction): boolean {
  if (typeof actionUtil.validator === 'function') {
    return actionUtil.validator(action)
  } else {
    return true
  }
}

function findRuleById(ruleId: string | undefined, rules: Rule[]): Rule | undefined {
  return rules.find((rule) => rule._id === ruleId)
}

export function getRuleFromCache(ruleId: string | undefined, cachedRule: Rule): Rule | undefined {
  if (cachedRule._id === ruleId) return cachedRule
}

export function mustFindRuleById(ruleId: string | undefined, cachedRule: Rule, rules: Rule[]): Rule {
  const rule = getRuleFromCache(ruleId, cachedRule) ?? findRuleById(ruleId, rules)
  if (!rule) {
    throw new Error('rule not found')
  }
  return rule
}
