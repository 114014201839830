










import { Component, Prop } from 'vue-property-decorator'
import type GuestMessage from '@/models/Guest/Message'
import type Campaign from '@/models/Event/modules/Campaigns/Campaign'
import type { MixedSort } from '@/models/commons/sort'
import type { MessageMethod, MessageTemplate } from '@/models/Event/Messages/template'
import { fromArrayToMap } from '@/utils/arrays'
import MessageHistory from './MessageHistory/index.vue'
import type { Location } from 'vue-router'
import { isMessageToGuestAction } from '@/models/Event/RulesData/Rule/Action'
import type Guest from '@/models/Guest'
import moment from 'moment'
import type { CompleteGuestMessage } from '../types'
import type Rule from '@/models/Event/RulesData/Rule'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { MessageHistory }
})
export default class MessageHistoryContainer extends Vue {
  @Prop({ required: true }) readonly messagesSent!: GuestMessage[]
  @Prop({ required: true }) readonly guestIdentity!: Pick<Guest, 'firstname' | 'name'>

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get campaigns(): Campaign[] {
    return this.storeEvent.modules_data.campaigns?.campaignItems ?? []
  }

  get messageTemplateRulesMap(): Record<string, Rule> {
    const result: Record<string, Rule> = {}

    for (const rule of this.storeEvent.rules.ruleItems) {
      for (const actions of rule.actions) {
        if (!isMessageToGuestAction(actions)) continue
        result[actions.messageTemplateId] = rule
      }
    }

    return result
  }

  get messageTemplates(): MessageTemplate[] {
    return this.storeEvent.messages.templates ?? []
  }

  get sort(): MixedSort | undefined {
    return this.storeEvent.modules_data.campaigns?.sort
  }

  get messagesTemplatesMap(): Record<string, MessageTemplate> {
    return fromArrayToMap<MessageTemplate>(this.messageTemplates, '_id')
  }

  get messageTemplateCampaignIdMap(): Record<string, string> {
    const result: Record<string, string> = {}
    this.campaigns.forEach(({ messageTemplateId, _id }) => (result[messageTemplateId] = _id))
    return result
  }

  get completeMessageSent(): CompleteGuestMessage[] {
    const result: CompleteGuestMessage[] = []
    const messageSent = this.messagesSent.sort((a, b) => moment(b.eventDate).diff(moment(a.eventDate)))
    for (const message of messageSent) {
      const messageTemplate = this.messagesTemplatesMap[message.messageId]
      if (!messageTemplate) throw new Error('No message template for this id ' + message.messageId)
      const completeMessageData = this.getCompleteMessageData(messageTemplate)
      result.push({
        ...message,
        activities: message.activities.sort((a, b) => moment(b.eventDate).diff(moment(a.eventDate))),
        messageTemplateUser: completeMessageData.messageTemplateUser,
        editUrl: completeMessageData.editUrl,
        messageTemplate
      })
    }
    return result
  }

  getCampaignEditUrl(campaignId: string, method: MessageMethod): Location {
    return { name: 'event.campaign.' + method, params: { campaignId } }
  }

  getRuleEditUrl(messageId: string, ruleId: string, method: MessageMethod): Location {
    return { name: 'event.rules.message.' + method, params: { messageId, ruleId } }
  }

  getCompleteMessageData(
    messageTemplate: MessageTemplate
  ): Pick<CompleteGuestMessage, 'messageTemplateUser' | 'editUrl'> {
    const campaignId = this.messageTemplateCampaignIdMap[messageTemplate._id]
    if (campaignId) {
      return { messageTemplateUser: 'campaign', editUrl: this.getCampaignEditUrl(campaignId, messageTemplate.method) }
    }
    const rule = this.messageTemplateRulesMap[messageTemplate._id]
    if (rule) {
      return {
        messageTemplateUser: 'rule',
        editUrl: this.getRuleEditUrl(messageTemplate._id, rule._id, messageTemplate.method)
      }
    }
    return {
      messageTemplateUser: 'unknown',
      editUrl: null
    }
  }
}
