




















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

import EditableTitle from '@/components/features/EditableTitle.vue'
import { DigiDeleteButton } from '@/components/ui/actions'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'

@Component({ components: { DigiSimpleIconButton, DigiDeleteButton, EditableTitle, DigiDeleteConfirmationModal } })
export default class GroupHeader extends Vue {
  @Prop({ required: true }) readonly groupName!: string
  @Prop({ required: true }) readonly disabled!: boolean
  @Prop({ required: true, type: Function }) readonly deleteGroup!: () => Promise<void>
  @Prop() readonly deleteGroupModalSlugTitle!: string

  editGroupName(newName: string): void {
    if (newName === this.groupName) {
      return
    }
    this.$emit('editGroupName', newName)
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }
}
