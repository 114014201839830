import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import CoverElementBase, { CoverProperties } from '../CoverElementBase'

import store from '@/store'

import moment from 'moment'
import type { CtaProperties } from '../../components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class Cover1Properties extends CoverProperties {
  cta: CtaProperties = defaultCtaProperties()
  tagText: string = ''
  tagColor: string = ''

  imageUrl: string | null = null
}

export class Cover1Element extends CoverElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): Cover1Element {
    const cover = new Cover1Element()

    cover.properties.title = store.state.event.event?.label || i18n.t('AN_INSPIRING_TITLE')
    cover.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(1)
    cover.properties.tagText = moment(store.state.event.event?.eventDate).format('YYYY-MM-DD') || i18n.t('SECTION_NAME')
    cover.properties.cta = {
      ...cover.properties.cta,
      label: i18n.t('REGISTRATION_CTA_BTN_LABEL'),
      color: palette.darkestColor.paletteCode
    }

    cover.properties.textColor = mostReadableColor
    cover.properties.tagColor = mostReadableColor

    return cover
  }

  type = `prefab-cover-1` as const

  @Type(() => Cover1Properties)
  properties: Cover1Properties = new Cover1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_COVER')
  }
}
