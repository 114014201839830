import { ElementTypeDictionary } from '../../types'
import Sponsors1Element from './sponsors1/Sponsors1Element'
import Sponsors2Element from './sponsors2/Sponsors2Element'
import Sponsors3Element from './sponsors3/Sponsors3Element'
import type { RadioButtonTextOption } from '@/features/website/builder/components/ControlPanel/components/PageBlocks/ElementPropertyItem/properties/RadioButtonProperty/index.vue'
import type { CtaMode } from '@/features/website/builder/builder/prefabs/components/cta/types'
import { i18n } from '@/utils/i18n'

type RadioButtonTextOptionForCtaMode = RadioButtonTextOption & { value: CtaMode }

const ctaModeOptions: RadioButtonTextOptionForCtaMode[] = [
  {
    value: 'filled',
    get text() {
      return i18n.t('CTA_OPTIONS_MODE_FILLED_LABEL')
    },
    type: 'text'
  },
  {
    value: 'outlined',
    get text() {
      return i18n.t('CTA_OPTIONS_MODE_OUTLINED_LABEL')
    },
    type: 'text'
  }
]

export const sponsorsDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Sponsors1Element,
    renderer: () => import('./sponsors1/Sponsors1.vue'),
    properties: [
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    isAvailable({ eventRights }) {
      return eventRights.features.eventApp.allowed
    },
    typeNameSlug: 'WEBSITE_BLOCK_SPONSORS_1',
    previewImageLink: require('@/assets/img/prefab-previews/sponsors.jpg')
  })
  .addType({
    dataClass: Sponsors2Element,
    renderer: () => import('./sponsors2/Sponsors2.vue'),
    properties: [
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'color', label: 'BUTTONS_COLOR', key: 'properties.ctaColor' },
      {
        type: 'radiobutton',
        label: 'PREFAB_CTA_MODE',
        key: 'properties.ctaMode',
        options: ctaModeOptions,
        disposition: 'row'
      },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    isAvailable({ eventRights }) {
      return eventRights.features.eventApp.allowed
    },
    typeNameSlug: 'WEBSITE_BLOCK_SPONSORS_2',
    previewImageLink: require('@/assets/img/prefab-previews/sponsors2.jpg')
  })
  .addType({
    dataClass: Sponsors3Element,
    renderer: () => import('./sponsors3/Sponsors3.vue'),
    properties: [
      { type: 'sponsorsSelector', label: 'SPONSORS_SELECTOR', key: 'properties.sponsorsIds' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    isAvailable({ eventRights }) {
      return eventRights.features.eventApp.allowed
    },
    typeNameSlug: 'WEBSITE_BLOCK_SPONSORS_3',
    previewImageLink: require('@/assets/img/prefab-previews/sponsors3.jpg')
  })
  .finish()
