import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PageFit } from '../../../layout/Row/RowElement'
import { PrefabPropertiesBase, PrefabElementBase } from '../../PrefabElementBase'

export class Iframe1Properties extends PrefabPropertiesBase {
  url: string = ''
  height: number = 300
  fullWidth: PageFit = PageFit.Contained
  backgroundColor: string = ''
  style: string = ''
  allow: string = ''
  title: string = ''
}

export default class Iframe1Element extends PrefabElementBase {
  static create({ palette }: ElementCreationContext): Iframe1Element {
    const iframe = new Iframe1Element()

    iframe.properties.backgroundColor = palette.darkestColor.paletteCode

    return iframe
  }

  type = 'prefab-iframe-1' as const

  @Type(() => Iframe1Properties)
  properties: Iframe1Properties = new Iframe1Properties()

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 200
  }

  get prettyName(): string {
    return i18n.t('BLOC_IFRAME')
  }
}
