





























import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'
import DigiFormLayout2Actions from './DigiFormLayout2Actions.vue'
import type { ValidationObserver } from 'vee-validate'

@Component({ components: { DigiFormLayout2Actions } })
export default class DigiFormModal2 extends Vue {
  @Prop({ type: String, required: true }) readonly formTitle!: string
  @Prop({ type: Boolean, default: false }) readonly hasModifications!: boolean
  @Prop({ type: Boolean, default: false }) readonly isLoading!: boolean
  @Prop({ type: Boolean, default: false }) readonly noPadding!: boolean
  @Prop({ type: String, default: 'lg' }) readonly size!: 'lg' | 'sm' | 'xl' | 'md' | 'xs'
  @Prop() readonly submitBtnLabel?: string

  @VModel({ type: Boolean, default: false }) modalVisible!: boolean

  @Ref() readonly observer!: InstanceType<typeof ValidationObserver>

  async onSubmit(): Promise<void> {
    const isValid = await this.observer.validate()
    if (!isValid) {
      return
    }
    this.$emit('submit')
  }

  onCancel() {
    this.modalVisible = false
    this.$emit('cancel')
  }

  onDelete() {
    this.$emit('delete')
  }
}
