











import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    ContactProfileActionsSubMenu,
    // we need dynamic import here to avoid circular dependency : this component import the profile modal...
    ContactPlanningEditor: () =>
      import('@/features/eventApp').then(({ ContactPlanningEditor }) => ContactPlanningEditor)
  }
})
export default class ContactProfileProgrammeSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly title!: string

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
