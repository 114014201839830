import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export type CampaignRouteName = 'event.campaigns'

export const campaignsRoute: ReadonlyFullRouteConfig = [
  {
    path: 'campaigns',
    name: 'event.campaigns',
    component: () => import('@/features/campaigns/views/campaigns.vue'),
    meta: {
      name: 'CAMPAIGNS',
      description: 'FACTORY_CAMPAIGNS_DESC',
      keywords: 'FACTORY_CAMPAIGNS_KEYWORDS',
      iconName: 'mail-send-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['sendCampaigns'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.campaigns,
        redirectName: 'event.settings.inactiveFeatures'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.campaigns?.campaignItems?.length > 0
        }
      }
    }
  },
  {
    path: 'campaigns/edit/sms/:campaignId',
    name: 'event.campaign.sms',
    component: () => import('@/features/campaigns/views/CampaignEditor/CampaignEditor.vue'),
    props: (route) => ({ campaignId: route.params.campaignId, msgMethod: 'sms' }),
    meta: {
      subPageOf: 'event.campaigns',
      renderingData: {
        isContentFullWidth: true
      }
    }
  },
  {
    path: 'campaigns/edit/email/:campaignId',
    name: 'event.campaign.email',
    component: () => import('@/features/campaigns/views/CampaignEditor/CampaignEditor.vue'),
    props: (route) => ({ campaignId: route.params.campaignId, msgMethod: 'email' }),
    meta: {
      subPageOf: 'event.campaigns',
      renderingData: {
        isContentFullWidth: true
      }
    }
  }
] as const
