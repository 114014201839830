import type { PartiallyRequired } from '@/utils/utilityTypes'
import type { RawEditorSettings } from 'tinymce'
import type { Editor } from 'tinymce'

export type TinyMCEMenu = TinyMCEMenuItem | TinyMCENestedMenu | TinyMCESeparator

export interface TinyMCEMenuItem {
  type: 'menuitem'
  text: string
  onAction: () => void
}

export interface TinyMCENestedMenu {
  type: 'nestedmenuitem'
  text: string
  getSubmenuItems: () => TinyMCEMenuItem[]
}

export interface TinyMCESeparator {
  type: 'separator'
  text?: string
}

export type MinimumTinyMceOptions = PartiallyRequired<
  RawEditorSettings,
  | 'theme'
  | 'color_map'
  | 'custom_colors'
  | 'plugins'
  | 'statusbar'
  | 'menubar'
  | 'toolbar1'
  | 'toolbar2'
  | 'contextmenu'
  | 'fontsize_formats'
  | 'extended_valid_elements'
  | 'default_link_target'
>

export function isTextEditorMenuLink(item: TextEditorMenuItem): item is TextEditorMenuLink {
  return item.type === 'link'
}

export type TextEditorMenuLink = {
  type: 'link'
  title: string
  value: string
  onClick: (editor: Editor) => void
}

type TextEditorMenuItem = TextEditorSubMenu | TextEditorMenuLink | TextEditorMenuSeparator

export function isTextEditorSubMenu(item: TextEditorMenuItem): item is TextEditorSubMenu {
  return item.type === 'subMenu'
}

export type TextEditorSubMenu = {
  type: 'subMenu'
  title: string
  items: TextEditorSubMenuItem[]
}

export type TextEditorMenu = {
  tag: string
  label: string
  items: TextEditorMenuItem[]
}

export type TextEditorSubMenuItem = {
  title: string
  value: string
  onClick: (editor: Editor) => void
}

export interface TextEditorMenuSeparator {
  type: 'separator'
  label: string
}

export type TextEditorToolbar = TextEditorMenu[]
export type ModelEvents = 'change' | 'undo' | 'redo'
