import type { Addon } from '@/models/Event/modules/Payment'
import type { MixedSort } from '@/models/commons/sort'
import axios from 'axios'
import type { Ticket } from '@/models/Event/modules/Payment'

export async function createTicket(ticket: Pick<Ticket, 'name'>, eventId: string): Promise<string> {
  const res = await axios.post<{ ticketId: string }>(`/backoffice/events/${eventId}/tickets`, {
    ticket
  })
  return res.data.ticketId
}

export async function updateTicket(ticket: Ticket, eventId: string) {
  await axios.put(`/backoffice/events/${eventId}/tickets/${ticket._id}`, {
    ticket
  })
}

export async function sortTickets(sort: MixedSort, eventId: string) {
  await axios.put(`/backoffice/events/${eventId}/tickets/sort`, {
    sort
  })
}

export async function createTicketGroup({ groupName, eventId }: { groupName: string; eventId: string }) {
  await axios.post(`/backoffice/events/${eventId}/tickets/sort/groups`, {
    groupName
  })
}

export async function updateTicketGroupName({
  groupName,
  groupId,
  eventId
}: {
  groupName: string
  groupId: string
  eventId: string
}) {
  await axios.put(`/backoffice/events/${eventId}/tickets/sort/groups/${groupId}/name`, {
    groupName
  })
}

export async function deleteTicketGroup({ groupId, eventId }: { groupId: string; eventId: string }) {
  await axios.delete(`/backoffice/events/${eventId}/tickets/sort/groups/${groupId}`)
}

export async function createAddon(addon: Pick<Addon, 'name' | 'affiliatedTickets'>, eventId: string): Promise<string> {
  const res = await axios.post<{ addonId: string }>(`/backoffice/events/${eventId}/addons`, {
    addon
  })
  return res.data.addonId
}

export async function updateAddon(addon: Addon, eventId: string) {
  await axios.put(`/backoffice/events/${eventId}/addons/${addon._id}`, {
    addon
  })
}

export async function sortAddons(sort: MixedSort, eventId: string) {
  await axios.put(`/backoffice/events/${eventId}/addons/sort`, {
    sort
  })
}

export async function createAddonGroup({ groupName, eventId }: { groupName: string; eventId: string }) {
  await axios.post(`/backoffice/events/${eventId}/addons/sort/groups`, {
    groupName
  })
}

export async function updateAddonGroupName({
  groupName,
  groupId,
  eventId
}: {
  groupName: string
  groupId: string
  eventId: string
}) {
  await axios.put(`/backoffice/events/${eventId}/addons/sort/groups/${groupId}/name`, {
    groupName
  })
}

export async function deleteAddonGroup({ groupId, eventId }: { groupId: string; eventId: string }) {
  await axios.delete(`/backoffice/events/${eventId}/addons/sort/groups/${groupId}`)
}
