import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import type { MediaProperties } from '../../../types/helperTypes'
import TextMediaElementBase, { TextMediaProperties } from '../TextMediaElementBase'

export class TextImage8Properties extends TextMediaProperties {
  title: string = ''
  titleColor: string = ''
  tagText: string = ''
  tagColor: string = ''
  mediaRight: MediaProperties | null = null
  mediaLeft: MediaProperties | null = null
}

export default class TextMedia8Element extends TextMediaElementBase {
  static create({ mostReadableColor }: ElementCreationContext): TextMedia8Element {
    const textImage = new TextMedia8Element()

    textImage.properties.title = i18n.t('AN_INSPIRING_TITLE')
    textImage.properties.titleColor = mostReadableColor

    textImage.properties.tagText = i18n.t('SECTION_NAME')
    textImage.properties.tagColor = mostReadableColor

    textImage.properties.mainText = new LoremIpsum(
      {
        wordsPerSentence: { min: 2, max: 5 },
        sentencesPerParagraph: { min: 1, max: 3 }
      },
      undefined,
      `\n\n`
    ).generateParagraphs(5)
    textImage.properties.textColor = mostReadableColor

    return textImage
  }
  isFullPrefab: boolean = true
  type = `prefab-text-media-8` as const

  @Type(() => TextImage8Properties)
  properties: TextImage8Properties = new TextImage8Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TEXT_IMAGE8')
  }
}
