import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import store from '@/store'

const guestRegistrationTriggerUtil: TriggerUtil = {
  kind: TriggerKind.GuestRegistration as const,
  label: 'GUEST_REGISTRATION' as const,
  icon: 'checkbox-multiple-line' as const,

  isAllowed(): boolean {
    return !store.getters.isPaidEvent
  }
}

export default guestRegistrationTriggerUtil
