










import { Component, Prop } from 'vue-property-decorator'
import DigiCardItem from '@/components/ui/cardItem/DigiCardItem.vue'
import { DigiLink } from '@/components/ui/actions'
import type { Certificate } from '@/models/Event/modules/Certificate'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { getCertificateUrl } from '@/features/certificate'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton, DigiLink, DigiCardItem }
})
export default class ContactCertificateItem extends Vue {
  @Prop({ required: true }) readonly certificate!: Certificate
  @Prop({ required: true }) readonly guestId!: string

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get url() {
    return getCertificateUrl({
      eventId: this.storeEvent._id,
      guestId: this.guestId,
      certificateId: this.certificate._id
    })
  }
}
