











import { Component, Prop } from 'vue-property-decorator'
import type { Certificate } from '@/models/Event/modules/Certificate'
import { getGuestCertificates } from '@/features/certificate'
import type Guest from '@/models/Guest'
import { joinIfExist } from '@/utils/string'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileCertificatesTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get matchingCertificatesNames(): string[] {
    const certificates = getGuestCertificates(this.guest, this.storeEvent)
    return certificates.map((certificate: Certificate) => certificate.name)
  }

  get firstThreeName(): string {
    return joinIfExist(this.matchingCertificatesNames.slice(0, 3), ', ')
  }

  get otherCertificatesCount(): string {
    const number = this.matchingCertificatesNames.slice(3).length
    return number > 0 ? ` + ${number}` : ''
  }
}
