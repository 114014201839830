import axios from 'axios'
import UAparser from 'ua-parser-js'
import type { Attachment, Attachments, SupportRequest, SupportRequestForm } from './types'

export async function sendSupportRequest(data: SupportRequestForm, eventId?: string): Promise<void> {
  const supportRequest: SupportRequest = makeSupportRequest(data, eventId)
  await axios.post(`backoffice/support/request`, toFormData(supportRequest), {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export async function hasAccessToHighestUserUrgencyLevel(eventId?: string): Promise<boolean> {
  if (!eventId) {
    return false
  }
  return axios
    .get<{ hasAccessToHighestUserUrgencyLevel: boolean }>(
      `/backoffice/support/request/userUrgencyLevel/hasAccess/${eventId}`
    )
    .then((res) => res.data.hasAccessToHighestUserUrgencyLevel)
}
function makeSupportRequest(data: SupportRequestForm, eventId?: string): SupportRequest {
  const supportRequest: SupportRequest = {
    attachments: data.attachments,
    description: data.description,
    preferredFeedbackMethod: data.preferredFeedbackMethod,
    urgencyLevel: data.urgencyLevel,
    userAgent: getBrowserInfo(),
    tag: data.tag,
    eventId: eventId
  }
  return supportRequest
}

function getBrowserInfo(): string {
  const parser = new UAparser()
  const agentOS = parser.getOS()
  const agentBrowser = parser.getBrowser()
  return `OS: ${agentOS.name}(${agentOS.version})  |  Browser : ${agentBrowser.name}(${agentBrowser.version}) | Screen : ${window.screen.width} x ${window.screen.height}`
}

function toFormData(supportRequest: SupportRequest): FormData {
  const formData = new FormData()
  formData.append('description', supportRequest.description)
  formData.append('urgencyLevel', supportRequest.urgencyLevel)
  formData.append('userAgent', supportRequest.userAgent)
  formData.append('preferredFeedbackMethod', supportRequest.preferredFeedbackMethod)
  if (supportRequest.tag !== undefined) {
    formData.append('tag', supportRequest.tag)
  }
  for (const value of supportRequest.attachments) {
    if (value) {
      formData.append('attachments[]', value)
    }
  }
  if (supportRequest.eventId) {
    formData.append('eventId', supportRequest.eventId)
  }
  return formData
}

export function validateAttachment(attachment: Attachment): boolean {
  return attachment ? attachment.size < 3145728 : true
}

export function validateAttachments(attachments: Attachments): boolean {
  return attachments.every(validateAttachment)
}
