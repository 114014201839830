













import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { ContactTicketing } from '@/features/payment'
import ContactProfileActionsSubMenu from '../../ui/ContactProfileActionsSubMenu.vue'
import UtmTracking from './components/UtmTracking.vue'
import RegistrationSource from './components/RegistrationContext.vue'

@Component({
  components: {
    RegistrationSource,
    UtmTracking,
    ContactTicketing,
    ContactProfileActionsSubMenu
  }
})
export default class ContactProfilePaidRegistrationSub extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly title!: string

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
