import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

import type { GuestId } from '@/models/Guest'

export class SpeakersProperties extends PrefabPropertiesBase {
  speakersIds: GuestId[] = []
}

export default abstract class SpeakersElementBase extends PrefabElementBase {
  abstract type: `prefab-speakers-${number}`

  @Type(() => SpeakersProperties)
  properties: SpeakersProperties = new SpeakersProperties()

  addSpeaker(sponsorId: GuestId): void {
    this.properties.speakersIds.push(sponsorId)
  }

  removeSpeaker(speakerIndex: number): void {
    this.properties.speakersIds.splice(speakerIndex, 1)
  }
}
