
























import { Vue, Component, Watch, VModel, Prop } from 'vue-property-decorator'

@Component
export default class RightPanel extends Vue {
  @VModel({ required: true }) isPanelOpen!: boolean

  @Prop({ required: true }) readonly iconName!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly subTitle?: string

  @Watch('$route')
  close(): void {
    this.isPanelOpen = false
    this.$emit('close')
  }
}
