

































import { Vue, Component, VModel, Prop, Ref } from 'vue-property-decorator'

type Size = 'md' | 'lg'

@Component
export default class EditableTitle extends Vue {
  @VModel() title!: string

  @Prop() readonly forbidenValues?: Set<string>
  @Prop({ default: false }) readonly bold?: boolean
  @Prop({ default: 'md' }) readonly size?: Size
  @Prop({ default: false }) readonly disabled?: boolean

  @Ref('input') readonly input!: HTMLInputElement

  localTile: string = ''
  isEditing: boolean = false

  get isNewTitleDuplicated(): boolean {
    if (!this.forbidenValues) {
      return false
    }
    return this.localTile !== this.title && this.forbidenValues.has(this.localTile)
  }

  get inputStyle(): Record<string, string> {
    return {
      width: `${this.localTile.length + 1}ch`
    }
  }

  mounted(): void {
    this.adoptPropAsLocalValue()
  }

  adoptPropAsLocalValue(): void {
    this.localTile = this.title
  }

  initEdition(): void {
    this.isEditing = true
    this.$nextTick(() => {
      this.input.focus()
    })
  }

  edit(): void {
    if (!this.isEditing) {
      return
    }
    if (this.isNewTitleDuplicated || !this.localTile) {
      this.adoptPropAsLocalValue()
    } else {
      this.title = this.localTile
    }
    this.isEditing = false
  }
}
