











import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import type { Property as CssProperty, Properties as CssProperties } from 'csstype'
import { DigiIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiIconButton }
})
export default class ImageFragmentDisplay extends Vue {
  @VModel({ required: true, validator: (value) => value === null || typeof value === 'string' })
  imageUrl!: string | null
  @Prop({ default: 'fill' }) readonly imageFit!: CssProperty.ObjectFit
  @Prop({ default: '50% 50%' }) readonly imagePosition!: CssProperty.ObjectPosition
  @Prop() readonly maxHeightValue!: CssProperty.MaxHeight

  get imageStyle(): CssProperties {
    return {
      objectFit: this.imageFit,
      objectPosition: this.imagePosition,
      maxHeight: `${this.maxHeightValue}px`
    }
  }

  deleteImage() {
    console.warn('There is no API for deleting a non-guest image. Image is probably astray on our servers.')
    this.imageUrl = null
  }
}
