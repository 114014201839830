




















import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component
export default class DigiRangeInput extends Vue {
  @VModel() rangeValue!: number
  @Prop({ default: 0 }) readonly min!: number
  @Prop({ default: 100 }) readonly max!: number
  @Prop({ default: 1 }) readonly step!: number
}
