import type { ReadonlyFullRouteConfig } from '@/core'

export const eventIdentityRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'informations',
    name: 'event.settings.informations',
    component: () => import('./eventInfos/EventInfos.vue'),
    meta: {
      name: 'GENERAL',
      iconName: 'information-line',
      keywords: 'FACTORY_INFOEVENTS_KEYWORDS',
      searchable: true
    }
  },
  {
    path: 'branding',
    name: 'event.settings.branding',
    component: () => import('./branding/EventBranding.vue'),
    meta: {
      name: 'EVENT_BRANDING_PAGE_TITLE',
      iconName: 'information-line',
      keywords: 'FACTORY_INFOEVENTS_KEYWORDS',
      searchable: true
    }
  },
  {
    path: 'front',
    name: 'event.settings.domain',
    component: () => import('./front/FrontSettings.vue'),
    meta: {
      name: 'EVENT_DOMAIN_PAGE_TITLE',
      description: 'EVENT_DOMAIN_PAGE_DESCRIPTION',
      iconName: 'information-line',
      keywords: 'FACTORY_INFOEVENTS_KEYWORDS',
      searchable: true
    }
  }
] as const
