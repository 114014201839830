















import { Component, Ref, Vue } from 'vue-property-decorator'

@Component
export default class ToggleEventMenuButton extends Vue {
  @Ref() readonly toggleButton!: HTMLButtonElement

  showTooltip = false

  click() {
    this.showTooltip = false
    this.$emit('click')
  }
}
