











import { Vue, Component } from 'vue-property-decorator'
import { DigiCardItem } from '@/components/ui/cardItem'

@Component({
  components: { DigiCardItem }
})
export default class FlatItem extends Vue {}
