import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import TextElementBase, { TextProperties } from '../TextElementBase'
export class Text4Item {
  title: string
  text: string

  constructor() {
    this.title = i18n.t('AN_INSPIRING_TITLE')
    this.text = `<div class="mb-2">Labore anim ullamco eiusmod duis occaecat proident irure. Aliquip duis nisi ex proident.</div>

      <a class="see-more" href="#">${i18n.t('READ_MORE')}</a>`
  }
}

export class Text4Properties extends TextProperties {
  title = ''
  titlesColor = ''
  cards: Text4Item[] = []
}

export class Text4Element extends TextElementBase {
  static create({ palette }: ElementCreationContext): Text4Element {
    const element = new Text4Element()
    const darkestColor = palette.darkestColor.paletteCode

    element.properties.title = i18n.t('AN_INSPIRING_TITLE')
    element.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 8, max: 10 },
      sentencesPerParagraph: { min: 1, max: 2 }
    }).generateParagraphs(2)

    element.properties.titlesColor = darkestColor
    element.properties.textColor = darkestColor

    element.properties.cards = [new Text4Item(), new Text4Item(), new Text4Item(), new Text4Item()]

    return element
  }

  type = `prefab-text-4` as const

  @Type(() => Text4Properties)
  properties: Text4Properties = new Text4Properties()

  get prettyName(): string {
    return i18n.t('BLOCK_TEXT')
  }
}
