







































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DigiButton } from '@/components/ui/actions'
import { DigiIconButton } from '@/components/ui/actions'
import type Guest from '@/models/Guest'
import type { GuestField } from '@/models/Event/GuestField'
import { SearchGuests } from '@/features/audience'

@Component({ components: { SearchGuests, DigiButton, DigiIconButton } })
export default class SelectAccompanist extends Vue {
  @Prop({ required: true }) readonly master!: Guest
  @Prop({ required: true }) readonly selectedFieldsData!: GuestField[]

  get searchParams() {
    return {
      searchFields: ['firstname', 'name'],
      filter: {
        andCriterias: [
          {
            property: '_id',
            operator: 'ISNT',
            testedValue: this.master._id
          }
        ],
        orCriterias: []
      }
    }
  }

  get tableFields() {
    return [
      ...this.selectedFieldsData.map((field) => {
        return { key: field.key, label: this.$t(field.name) }
      }),
      { key: 'addBtn', label: '', thStyle: { width: '1px' } }
    ]
  }

  get searchPlaceholder() {
    return `${this.$t('SEARCH_BY')} ${this.$t('FIRSTNAME')}/${this.$t('LASTNAME')}/${this.$t('EMAIL')}`
  }

  get excludedGuestIds() {
    return [this.master._id].concat(this.master.masterRegistrationOf ?? [])
  }

  addAccompanist(slave: Guest) {
    this.$emit('accompanistSelected', slave)
  }

  guestCouldBeSlave(guest: Guest) {
    return (
      !guest.slaveRegistrationOf &&
      ('masterRegistrationOf' in guest === false || guest.masterRegistrationOf?.length === 0) &&
      !this.master.masterRegistrationOf?.includes(guest._id)
    )
  }

  optionFromId(list: { _id: string; value: unknown }[], id: string) {
    return list.find((o) => o._id === id)
  }

  findValueFromKey(list: { _id: string; value: unknown }[], keys: string[]) {
    if (keys) {
      const names = keys.map((key) => {
        return this.optionFromId(list, key)?.value
      })
      return names
    }
  }
}
