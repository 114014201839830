import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export const certificateRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'certificates',
    name: 'certificates',
    component: () => import('./views/CertificatesList/index.vue'),
    meta: {
      name: 'CERTIFICATES',
      keywords: 'CERTIFICATES_KEYWORDS',
      iconName: 'trophy-line',
      description: 'CERTIFICATES_LIST_DESCRIPTION',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.certificates,
        redirectName: 'event.settings.inactiveFeatures'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.certificate?.certificates?.length > 0
        }
      }
    }
  },
  {
    path: 'certificates/:certificateId',
    name: 'certificates.certificate',
    component: () => import('./views/CertificateItem/index.vue'),
    meta: {
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      subPageOf: 'certificates',
      renderingData: {
        isContentFullWidth: false
      }
    }
  }
] as const
