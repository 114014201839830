













import { Component } from 'vue-property-decorator'
import { assertAccount } from '@/services/assertAccount'
import type { Account } from '@/models/Account'
import Vue from 'vue'

@Component({})
export default class HeaderAccountMenu extends Vue {
  get storeAccount(): Account {
    const storeAccount = assertAccount(this.$store.state.account.account)

    return storeAccount
  }

  get text(): string {
    if (this.storeAccount?.firstname?.length > 0 && this.storeAccount?.name?.length > 0) {
      return `${this.storeAccount.firstname} ${this.storeAccount.name}`
    }
    return this.storeAccount?.accountEmail.split('@')[0] ?? ''
  }
}
