import { render, staticRenderFns } from "./DigiImageDisplay.vue?vue&type=template&id=42f94a74&scoped=true"
import script from "./DigiImageDisplay.vue?vue&type=script&lang=ts"
export * from "./DigiImageDisplay.vue?vue&type=script&lang=ts"
import style0 from "./DigiImageDisplay.vue?vue&type=style&index=0&id=42f94a74&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f94a74",
  null
  
)

export default component.exports