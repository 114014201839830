


































import { Component, Prop, Ref } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { joinIfExist } from '@/utils/string'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { getFrontPreviewUrl } from '@/utils/frontPreviewUrl'
import { DigiLink } from '@/components/ui/actions'
import { deleteGuest } from '@/services/guest'
import GuestDeletionModal from '../../GuestDeletionModal.vue'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { hasAccessToFront } from '@/services/front.ts/hasAccessToFront'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { GuestDeletionModal, DigiLink, DigiSimpleIconButton }
})
export default class ContactProfileHeader extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Ref() readonly guestDeletionModal!: GuestDeletionModal

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get fullName(): string {
    return joinIfExist([this.guest.firstname, this.guest.name], ' ')
  }

  get job(): string {
    return joinIfExist([this.guest.job, this.guest.organization], ', ')
  }

  get authenticatedSiteUrlForGuest(): string {
    if (!this.hasWebsite) return ''
    const guestId = this.guest._id
    return getFrontPreviewUrl({ guestId, eventId: this.storeEvent._id })
  }

  showGuestDeletionModal() {
    this.guestDeletionModal.show()
  }

  async deleteGuest(deleteAccompanists: boolean) {
    await deleteGuest(this.storeEvent._id, this.guest._id, deleteAccompanists)
    this.$emit('closeAndReload')
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get hasWebsite() {
    return hasAccessToFront(this.eventRights)
  }
}
