import type { EventId } from '@/models/Event'
import type { EventRights } from '@/models/Event/EventRights'
import type { MessageId } from '@/models/Event/Messages/template'
import type Guest from '@/models/Guest'
import { MessageStatus } from '@/models/Guest/Message'
import { ErrorWithExtras } from '@/utils/errorTypes'
import axios from 'axios'

export interface TestMessageResponse {
  sendingRes: { sentDate: string; nbOfRecipients: number }
  isNewTestGuest: boolean
  guest: Guest
}

export function sendTestMessage(
  eventId: EventId,
  messageId: MessageId,
  destination: string
): Promise<TestMessageResponse> {
  return axios
    .post<TestMessageResponse>(`/backoffice/events/${eventId}/messages/${messageId}/sendTest`, {
      emailOrPhone: destination
    })
    .then((res) => res.data)
}

export async function sendToSomeGuests(eventId: EventId, messageId: MessageId, guestIds: string[]): Promise<void> {
  await axios.post<void>(`/backoffice/events/${eventId}/messages/${messageId}/sendToSomeGuests`, { guestIds })
}

export function getTemplateVar(slug: string, isNotField: boolean = false): string {
  if (!slug) {
    throw new Error('Slug not defined')
  } else {
    if (isNotField) {
      console.log('slug', slug)
      return `*|${slug.toUpperCase()}|*`
    }
    return `*|${slug.split('-').join('').split('_').join('').toUpperCase()}|*`
  }
}

export interface MessageStats {
  nbOpen: number
  nbClick: number
  nbSent: number
  nbError: number
}

export async function getMessageStats(eventId: EventId, messageId: MessageId): Promise<MessageStats> {
  const { data } = await axios.get<MessageStats>(`backoffice/events/${eventId}/messages/${messageId}/stats`)
  return data
}

const slugsForMessageStatuses: Partial<Record<MessageStatus, string>> = {
  [MessageStatus.Sending]: 'MSG_STATUS_PROCESSED',
  [MessageStatus.Error]: 'MSG_STATUS_ERROR',
  [MessageStatus.Clicked]: 'MSG_STATUS_CLICK',
  [MessageStatus.Dropped]: 'MSG_STATUS_DROPPED',
  [MessageStatus.Sent]: 'MSG_STATUS_DELIVERED',
  [MessageStatus.Deferred]: 'MSG_STATUS_DEFERRED',
  [MessageStatus.Bounced]: 'MSG_STATUS_BOUNCE',
  [MessageStatus.Opened]: 'MSG_STATUS_OPEN'
}

const definitionsForMessageStatuses: Partial<Record<MessageStatus, string>> = {
  [MessageStatus.Sending]: 'MSG_STATUS_PROCESSED_DEFINITION',
  [MessageStatus.Error]: 'MSG_STATUS_ERROR_DEFINITION',
  [MessageStatus.Clicked]: 'MSG_STATUS_CLICK_DEFINITION',
  [MessageStatus.Dropped]: 'MSG_STATUS_DROPPED_DEFINITION',
  [MessageStatus.Sent]: 'MSG_STATUS_DELIVERED_DEFINITION',
  [MessageStatus.Deferred]: 'MSG_STATUS_DEFERRED_DEFINITION',
  [MessageStatus.Bounced]: 'MSG_STATUS_BOUNCE_DEFINITION',
  [MessageStatus.Opened]: 'MSG_STATUS_OPEN_DEFINITION'
}

const msgStatusVariants: Partial<Record<MessageStatus, string>> = {
  delivered: 'success',
  open: 'info',
  click: 'primary',
  processed: 'light-success',
  deferred: 'light-success',
  error: 'danger',
  dropped: 'danger',
  bounce: 'danger',
  blocked: 'danger'
}

class NoSuchSlugError extends ErrorWithExtras {
  constructor(messageStatus: MessageStatus) {
    super(`No slug defined for message status ${messageStatus}.`, { messageStatus })
  }
}

export function getSlugForMessageStatus(status: MessageStatus): string {
  const slug = slugsForMessageStatuses[status]

  if (typeof slug === 'string') return slug
  else throw new NoSuchSlugError(status)
}

export function getSlugForMessageStatusDefinition(status: MessageStatus): string {
  const slug = definitionsForMessageStatuses[status]

  if (typeof slug === 'string') return slug
  else throw new NoSuchSlugError(status)
}

export function getVariantForMessageStatus(status: MessageStatus): string | null {
  return msgStatusVariants[status] ?? null
}

export async function getSmsBalance(eventId: string) {
  return axios.get(`/events/${eventId}/smsBalance`, { noCache: false }).then((res) => res.data)
}

export type EmailCustomizationAndVerification = {
  customReplyTo: string | null
  customFromEmailUsername: string | null
  customFromEmailDomainName: string | null
  isCustomDomainVerified: boolean
}

export async function getSenderDomainConfig(eventId: string) {
  return axios
    .get<EmailCustomizationAndVerification>(`/events/${eventId}/messages/settings`, { noCache: false })
    .then((res) => res.data)
}

export async function detachCustomSenderDomain(eventId: string) {
  return axios.delete<void>(`/backoffice/events/${eventId}/messages/sendgridConfiguration`).then((res) => res.data)
}

export const digiteventNoReplyEmailAddress = 'no-reply@event.digitevent.com'

export function getEmailSender(config: EmailCustomizationAndVerification, eventRights: EventRights) {
  if (!eventRights.general.customization.customDomainName) {
    return digiteventNoReplyEmailAddress
  }
  if (!config.customFromEmailDomainName) {
    return digiteventNoReplyEmailAddress
  }
  let from = 'no-reply'
  if (config.customFromEmailUsername) {
    from = config.customFromEmailUsername
  }
  return `${from}@${config.customFromEmailDomainName}`
}
