



















import { Vue, Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { isAnAdditional } from '@/features/registration/multiRegistration'
import MultiRegistrationAdditionnalAttendeeSub from './parts/MultiRegistrationAdditionnalAttendeeSubMenu.vue'
import MultiRegistrationMainAttendeeSub from './parts/MultiRegistrationMainAttendeeSubMenu.vue'
import UtmTracking from '@/features/audience/contactProfile/components/registration/subs/components/UtmTracking.vue'
import RegistrationSource from '@/features/audience/contactProfile/components/registration/subs/components/RegistrationContext.vue'
import ContactProfileActionsSubMenu from '../../../ui/ContactProfileActionsSubMenu.vue'

@Component({
  components: {
    RegistrationSource,
    UtmTracking,
    ContactProfileActionsSubMenu,
    MultiRegistrationMainAttendeeSub,
    MultiRegistrationAdditionnalAttendeeSub
  }
})
export default class ContactProfileMultiRegistrationSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly title!: string

  get isAdditional() {
    return isAnAdditional(this.guest)
  }
}
