

























import { Component, Prop } from 'vue-property-decorator'
import type { AnyAction, SendEmailToAddressAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import { DigiEmailInput } from '@/components/ui/inputs'
import { getMessageNominativeTags, nominativeTagMapper } from '@/features/messages/emailTags/emailNomativeTags'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { NominativeValuesDropdown } from '@/features/messages'
import { WysiwygEditor } from '@/components/features/wysiwyg'

@Component({
  components: {
    WysiwygEditor,
    NominativeValuesDropdown,
    DigiEmailInput
  }
})
export default class EmailToAddressSettings extends Vue {
  @Prop({ required: true }) readonly action!: AnyAction

  icon: 'email' = 'email'

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get messageNominativeValue() {
    return getMessageNominativeTags(this.storeEvent, this.$store.getters.orderedGuestFields)
  }

  get sendEmailToAddressAction(): SendEmailToAddressAction {
    if (this.action.kind !== ActionKind.SendEmailToAddress) {
      throw new Error('')
    }

    return this.action
  }

  get senderName(): string {
    return this.sendEmailToAddressAction.fromName
  }
  set senderName(fromName: string) {
    this.$emit('update', { ...this.action, fromName })
  }

  get emailAddress(): string {
    return this.sendEmailToAddressAction.emailAddress
  }
  set emailAddress(emailAddress: string) {
    this.$emit('update', { ...this.action, emailAddress })
  }

  get emailSubject(): string {
    return this.sendEmailToAddressAction.subject
  }
  set emailSubject(subject: string) {
    this.$emit('update', { ...this.action, subject })
  }

  get emailContent(): string {
    return nominativeTagMapper.mapToEditView({
      value: this.sendEmailToAddressAction.emailContent ?? '',
      guestFields: this.$store.getters.orderedGuestFields
    })
  }
  set emailContent(emailContent: string) {
    this.$emit('update', {
      ...this.action,
      ['emailContent']: nominativeTagMapper.mapToSave({
        value: emailContent,
        guestFields: this.$store.getters.orderedGuestFields
      })
    })
  }

  insertTag(tag: string): void {
    this.emailContent = this.emailContent + tag
  }
}
