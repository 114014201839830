

















import CriteriaDefinition from './CriteriaDefinition.vue'
import { clone } from 'lodash'
import { Vue, Component, VModel } from 'vue-property-decorator'
import Criteria from '@/models/Event/Filter/Criteria'
import { DigiIconButton } from '@/components/ui/actions'

@Component({
  components: {
    DigiIconButton,
    CriteriaDefinition
  }
})
export default class CriteriaType extends Vue {
  @VModel({ required: true }) criterias!: Criteria[]

  validityArray: boolean[] = []

  getFilteredCriterias(): Criteria[] {
    return this.criterias.filter((criteria, index) => index > 0)
  }

  get allValid(): boolean {
    return this.validityArray.every((def) => def === true)
  }

  mounted(): void {
    this.$emit('update:valid', this.allValid)
  }

  addCriteria(): void {
    this.validityArray.push(false)
    this.criterias = this.criterias.concat([new Criteria()])
  }

  changeAtIndex(index: number, newCriteria: Criteria): void {
    const newCriterias = clone(this.criterias)
    newCriterias.splice(index, 1, newCriteria)
    this.criterias = newCriterias
  }

  removeAtIndex(index: number): void {
    this.validityArray.splice(index, 1)
    this.criterias = this.criterias.filter((el, elIdx) => elIdx !== index)
    this.$emit('update:valid', this.allValid)
  }

  updateValidityAtIndex(index: number, isValid: boolean): void {
    this.validityArray.splice(index, 1, isValid)
    this.$emit('update:valid', this.allValid)
  }
}
