import type { EventLangCode } from '@/models/Event/Localized'
import axios from 'axios'
import type { PrivacyConditions } from '@/models/Event/Identity'
import type { EventPresenceType, EventTypology } from '@/core'

interface EventIdentityDTO {
  name?: string
  presenceType?: EventPresenceType
  typology?: EventTypology
  mainLanguage?: EventLangCode
  startDate?: Date
  endDate?: Date
  timezone?: string
  location?: string
  colorPalette?: string[]
  privacyConditions?: PrivacyConditions
}

export async function patchEventIdentity(data: EventIdentityDTO, eventId: string): Promise<void> {
  await axios.patch(`backoffice/events/${eventId}/eventIdentity`, { data })
}
