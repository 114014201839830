import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import SocialsElementBase from '../SocialsElementBase'

export class Socials1Properties extends PrefabPropertiesBase {
  title: string = ''
  titleColor: string = ''
  logosColor: string = ''
  containerBackgroundColor: string = ''
}

export default class Socials1Element extends SocialsElementBase {
  static create({ palette }: ElementCreationContext): Socials1Element {
    const socials = new Socials1Element()

    socials.properties.title = i18n.t('FOLLOW_US')
    socials.properties.containerBackgroundColor = palette.mostVividColor.paletteCode
    socials.properties.titleColor = palette.getMostReadableColor(palette.mostVividColor.color).paletteCode
    socials.properties.logosColor = palette.getMostReadableColor(palette.mostVividColor.color).paletteCode
    return socials
  }

  type = `prefab-socials-1` as const

  @Type(() => Socials1Properties)
  properties: Socials1Properties = new Socials1Properties()

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 200
  }

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SOCIALS')
  }
}
