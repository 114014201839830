






















import { IMAGE_FILE_MIME_TYPES } from '@/utils/constants'
import { FileUpload } from '@/components/features/fileUpload'
import ImageFragmentDisplay from './ImageFragmentDisplay.vue'
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import type { Property as CssProperty } from 'csstype'

@Component({ components: { FileUpload, ImageFragmentDisplay } })
export default class ImageFragment extends Vue {
  @VModel({ required: true, validator: (value) => value === null || typeof value === 'string' })
  imageUrl!: string | null
  @Prop({ default: 'fill' }) readonly imageFit!: CssProperty.ObjectFit
  @Prop({ default: '50% 50%' }) readonly imagePosition!: CssProperty.ObjectPosition
  @Prop() readonly maxHeight?: number
  @Prop() readonly recommendedWidth?: number
  @Prop() readonly recommendedHeight?: number
  @Prop({ default: false }) readonly minimal!: boolean

  private IMAGE_FILE_MIME_TYPES = IMAGE_FILE_MIME_TYPES
}
