import type { GuestFieldType } from '@/models/Event/GuestField'

type OtherIconType =
  | 'filters'
  | 'orderStatus'
  | 'multiRegistration'
  | 'pdf'
  | 'networking'
  | 'campaign'
  | 'codeId'
  | 'programme'
  | 'checkinPoint'
  | 'formSubmission'
  | 'ticketingIndicators'

const iconDictionary: Record<GuestFieldType | OtherIconType, string> = {
  text: 'font-size-2',
  email: 'at-line',
  checkbox: 'checkbox-line',
  phone: 'smartphone-line',
  file: 'upload-2-line',
  list: 'list-check',
  source: 'upload-cloud-2-line',
  invitationStatus: 'checkbox-blank-circle-line',
  checkinPoint: 'checkbox-multiple-line',
  dateWithoutTimezone: 'calendar-line',
  longtext: 'file-list-2-line',
  addedByAccountId: 'add-line',
  number: 'hashtag',
  formSubmission: 'survey-line',
  filters: 'filter-2-line',
  lang: 'global-line',
  unsubscribe: 'mail-forbid-line',
  orderStatus: 'checkbox-blank-circle-line',
  multiRegistration: 'team-line',
  pdf: 'file-pdf-line',
  networking: 'qr-code-line',
  campaign: 'mail-send-line',
  codeId: 'key-2-line',
  programme: 'calendar-line',
  link: 'link',
  ticketingIndicators: 'coupon-line'
}

export type IconType = keyof typeof iconDictionary

export function getIconFromDictionary(type: IconType) {
  return iconDictionary[type]
}
