import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'

import store from '@/store'
import { CountdownElementBase, CountdownProperties } from '../CountdownElementBase'
import type { ElementCreationContext } from '../../../types'

export class Countdown2Properties extends CountdownProperties {
  startingText: string = i18n.t('STARTING_IN')
}

export default class Countdown2Element extends CountdownElementBase {
  static create({ mostReadableColor }: ElementCreationContext): Countdown2Element {
    const event = store.state.event.event

    if (!event) {
      throw new Error('Cannot create countdown element: Element requires event in store, none found.')
    }

    const countdown = new Countdown2Element()

    countdown.properties = {
      ...countdown.properties,
      countdownTextColor: mostReadableColor,
      targetDate: event.eventDate
    }

    return countdown
  }

  type = 'prefab-countdown-2' as const
  isFullPrefab: boolean = true

  @Type(() => Countdown2Properties)
  properties: Countdown2Properties = new Countdown2Properties()

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 60
  }

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_COUNTDOWN')
  }
}
