









import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { handleError } from '@/utils/errorHandling'

interface AccountDTO {
  id: string
  email: string
  lastLogin: Date
}

@Component({})
export default class AccountEmail extends Vue {
  isLoading = false
  error = ''
  details: AccountDTO | null = null

  @Prop({ required: true, type: String })
  readonly eventId!: string

  @Prop({ required: true, type: String })
  readonly accountId!: string

  get email() {
    return this.details?.email ?? ''
  }

  mounted() {
    this.fetchDetails()
  }

  @Watch('accountId')
  async fetchDetails() {
    this.isLoading = true
    this.error = ''
    try {
      this.details = await this.doFetchDetails()
      this.isLoading = false
    } catch (err) {
      this.isLoading = false
      this.error = `${err}`
      console.error(err)
      handleError(err)
    }
  }

  async doFetchDetails(): Promise<AccountDTO | null> {
    const { data } = await axios.get<{ account: AccountDTO | null }>(
      `/events/${this.eventId}/permissions/accounts/${this.accountId}/details`
    )
    const { account } = data
    return account
  }
}
