







import { Vue, Component, Ref } from 'vue-property-decorator'
import { DigiIconButton } from '@/components/ui/actions'
import EventActivationModal from '@/navigation/eventMenu/components/EventActivationModal.vue'

@Component({
  components: { EventActivationModal, DigiIconButton }
})
export default class EventActivationCta extends Vue {
  @Ref() readonly eventActivationModal!: EventActivationModal

  openEventActivationModal() {
    this.eventActivationModal.show()
  }
}
