

















import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { GuestCheckin } from '@/models/Guest'
import { uniq } from 'lodash'
import { timezoneFormat } from '@/lib/timezoneFormat'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { EventIdentity } from '@/core'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileCheckinTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get checkins(): GuestCheckin[] {
    return this.guest.checkins ?? []
  }

  get differentCheckinPoint(): number {
    const checkinPointIds = this.checkins.map((checkin) => checkin.checkinPointId)
    return uniq(checkinPointIds).length
  }

  get lastCheckin(): GuestCheckin | undefined {
    return this.checkins[this.checkins.length - 1]
  }

  get lastCheckinDate() {
    if (!this.lastCheckin) return null
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return timezoneFormat(this.lastCheckin.date, eventIdentity.timezone)
  }
}
