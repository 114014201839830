import CustomFieldBase from '../CustomFieldBase'
import type { LocalizedPrimitive } from '@/models/Event/Localized'
import type { MongoDb } from '@/models/MongoDbTools'
import { CustomFieldType } from '..'

export type ListFieldItemId = MongoDb.ObjectId

export interface UnlimitedListFieldOption {
  _id: ListFieldItemId
  value: string
  translations?: LocalizedPrimitive<string>
  hidden: boolean
}

export interface LimitedListFieldOption extends UnlimitedListFieldOption {
  maxAvailablePlaces: number
}

export interface SegmentedListFieldOption extends UnlimitedListFieldOption {
  segmentIds: string[]
}

export function isSegmentedListFieldOption(option: ListFieldOption): option is SegmentedListFieldOption {
  return 'segmentIds' in option
}

export type ListFieldOption = UnlimitedListFieldOption | LimitedListFieldOption | SegmentedListFieldOption

export default class ListCustomField extends CustomFieldBase {
  type = CustomFieldType.List as const

  maxMultipleSelectList: number = 1
  list: ListFieldOption[] = []

  getItemById(targetId: ListFieldItemId): ListFieldOption {
    const result = this.list.find((i) => i._id === targetId)

    if (result) {
      return result
    } else {
      throw new Error(`No list item found for id ${targetId}.`)
    }
  }
}
