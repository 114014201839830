import { z } from 'zod'

import { amount100 } from './money'

export const transactionProviderType = z.enum(['stripe', 'mangopay', 'cash', 'other'])
export type TransactionProviderType = z.infer<typeof transactionProviderType>

export enum PaymentMethodType {
  Card = 'card',
  CardAmex = 'cardAmex',
  BankWire = 'bankWire',
  Cash = 'cash',
  Other = 'otherPaymentMethod',
  NotChosen = 'notChosen',
  SepaDebit = 'sepaDebit',
  StripeLink = 'stripeLink'
}
export const allPaymentMethodTypes = Object.values(PaymentMethodType)

export enum TransactionStatus {
  Init = 'CREATED',
  Succeeded = 'SUCCEEDED',
  Failed = 'FAILED'
}

export enum TransactionDirection {
  Payin = 'payin',
  Refund = 'refund'
}

export const zTransactionProviderStripe = z
  .object({
    type: z.literal('stripe'),
    checkoutSessionId: z.string().optional(),
    paymentIntentId: z.string().optional(),
    refundId: z.string().optional(),
    stripePaymentMethodType: z.string().optional(),
    chargeId: z.string().optional()
  })
  .refine((p) => p.checkoutSessionId || p.paymentIntentId || p.refundId, {
    message: 'Stripe transaction must have checkoutSessionId, paymentIntentId or refundId'
  })

export const zTransactionProviderMangopay = z.object({
  type: z.literal('mangopay'),
  mangoPayId: z.string(),
  mangopayPaymentMethodType: z.string(),
  refund: z.object({ refundedMangopayId: z.string().optional() }).optional(),
  card: z.object({ SecureModeRedirectURL: z.string().optional() }).optional(),
  bankWire: z
    .object({
      wireReference: z.string(),
      iban: z.string(),
      bic: z.string()
    })
    .optional()
})

export const zTransactionProviderCash = z.object({
  type: z.literal('cash')
})

export const zTransactionProviderOther = z.object({
  type: z.literal('other'),
  transactionReference: z.string().optional(),
  receiptDate: z.date()
})

export const zTransactionProvider = z.union([
  zTransactionProviderStripe,
  zTransactionProviderMangopay,
  zTransactionProviderCash,
  zTransactionProviderOther
])

const zTransactionBase = z.object({
  _id: z.string(),
  direction: z.nativeEnum(TransactionDirection),
  status: z.nativeEnum(TransactionStatus),
  provider: zTransactionProvider,
  methodType: z.nativeEnum(PaymentMethodType),
  creationDate: z.date().optional(),
  paidDate: z.date().optional(),
  /**
   * Fee that was collectible at the time of
   * transaction creation. This is used for tests and debugging.
   */
  feeCollectibleOnCreation100: amount100,
  /**
   * How much fee was already collected on this transaction
   */
  feeCollected100: amount100,
  /**
   * How much money was transferred to the Digitevent platform account
   */
  transferredToPlatformAccount100: amount100,
  lastStatusMessage: z.string().optional(),
  /**
   * Debug information
   */
  logs: z.array(z.string())
})

/**
 * Payin = guest pays 100eur
 *   - 5 euro is transferred to the platform
 *   - 95 euro is transferred to the subaccount
 *
 *   Stripe/mangopay fees are taken later from platform account so we dont put them here in the breakdown
 */
export const zTransactionPayin = zTransactionBase.extend({
  direction: z.literal(TransactionDirection.Payin),
  /**
   * Expected amount of the transaction = how much they should pay
   */
  receivable100: amount100,
  /**
   * Amount that was actually received. In most cases received100 === receivable100
   */
  received100: amount100,
  /**
   * How much money was transferred to the client subaccount
   */
  transferredToOrganizerSubaccount100: amount100,
  /**
   * This is meant for cash and 'other' payment methods.
   * It means that the organizer collected the money himself.
   */
  manuallyCollectedByOrganizer100: amount100
})

/**
 * Consistent type has additional mathematical validation.
 * We have separate validators for checkin consistency because we
 * still want to save the transaction that was performed.
 * We use this separate validator to dectect financial consistency issues.
 */
export const zTransactionPayinConsistent = zTransactionPayin
  .refine(
    (t) =>
      t.received100 ===
      t.transferredToOrganizerSubaccount100 + t.transferredToPlatformAccount100 + t.manuallyCollectedByOrganizer100, //
    {
      message:
        'Inconsistent payin transaction transferredToOrganizerSubaccount100 + transferredToPlatformAccount100 must equal received100'
    }
  )
  .refine(
    (t) => t.transferredToPlatformAccount100 === t.feeCollected100, //
    { message: 'Inconsistent payin transaction transferredToPlatformAccount100 must equal feeCollected100' }
  )
  .refine((t) => !(t.status === TransactionStatus.Succeeded && t.methodType === PaymentMethodType.NotChosen), {
    message: 'Transaction with status Succeeded cannot have methodType NotChosen'
  })

/**
 * Refund. The organizer can decide how much they want to refund.
 * Refund can be partial or full. They can take a fee on their own.
 * Digitevent takes same fee as for payin.
 *
 * An example: original payin was 100eur (of which 95eur went to client and 5eur to digitevent).
 * Organizer initiates 90eur refund (to cover for their costs and our fees).
 * - 90 euro is transferred from client subaccount to guest
 * - 5 euro is transferred from client subaccount to digitevent platform account
 * After that trasaction state of client subaccount is zero.
 */
export const zTransactionRefund = zTransactionBase.extend({
  direction: z.literal(TransactionDirection.Refund),
  refundedTransactionId: z.string(),
  refunded100: amount100,
  refundable100: amount100,
  /**
   * How much money was transferred from the client subaccount to the guest
   */
  transferredFromOrganizerSubaccount100: amount100
})

/**
 * Consistent type has additional mathematical validation.
 * We have separate validators for checkin consistency because we
 * still want to save the transaction that was performed.
 * We use this separate validator to dectect financial consistency issues.
 */
export const zTransactionRefundConsistent = zTransactionRefund
  .refine(
    (t) => t.transferredFromOrganizerSubaccount100 === t.refunded100 + t.feeCollected100, //
    {
      message:
        'Inconsistent refund transaction transferredFromOrganizerSubaccount100 must equal refunded100 plus feeCollected100'
    }
  )
  .refine(
    (t) => t.transferredToPlatformAccount100 === t.feeCollected100, //
    { message: 'In consistent refund transaction transferredToPlatformAccount100 must equal feeCollected100' }
  )

export const zTransaction = z.union([zTransactionPayin, zTransactionRefund])
export const zTransactionConsistent = z.union([zTransactionPayinConsistent, zTransactionRefundConsistent])

export type TransactionProviderStripe = z.infer<typeof zTransactionProviderStripe>
export type TransactionProviderMangopay = z.infer<typeof zTransactionProviderMangopay>
export type TransactionProviderCash = z.infer<typeof zTransactionProviderCash>
export type TransactionProviderOther = z.infer<typeof zTransactionProviderOther>
export type TransactionProvider = z.infer<typeof zTransactionProvider>
export type Transaction<TProvider = TransactionProvider> = z.infer<typeof zTransaction> & { provider: TProvider }
export type TransactionPayin<TProvider = TransactionProvider> = z.infer<typeof zTransactionPayin> & {
  provider: TProvider
}
export type TransactionRefund<TProvider = TransactionProvider> = z.infer<typeof zTransactionRefund> & {
  provider: TProvider
}
