
















import type Filter from '@/models/Event/Filter'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiIconButton }
})
export default class SegmentSelectorItem extends Vue {
  @Prop({ required: true }) readonly filter!: Filter
  @Prop({ required: true }) readonly isSelected!: boolean
  @Prop({ required: true }) readonly isEditable!: boolean
}
