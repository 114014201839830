










import { Vue, Component, Prop } from 'vue-property-decorator'
import type InvitationStatus from '@/models/Guest/InvitationStatus'
import { getStatusColor, getStatusExplanationSlug, getStatusSlug } from '@/utils/invitationStatus'

@Component({})
export default class InvitationStatusBadge extends Vue {
  @Prop({ required: true }) readonly invitationStatus!: InvitationStatus

  get invitationStatusLabel() {
    return this.$t(getStatusSlug(this.invitationStatus))
  }

  get invitationStatusColor() {
    return getStatusColor(this.invitationStatus)
  }

  get invitationStatusExplanation() {
    return this.$t(getStatusExplanationSlug(this.invitationStatus))
  }
}
