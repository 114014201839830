import type { FullRouteConfig } from '@/core'

const routes: FullRouteConfig[] = [
  {
    path: '/',
    name: 'account',
    redirect: 'account/identity',
    component: () => import('./AccountIdentity.vue'),
    children: [
      {
        path: 'identity',
        name: 'account.identity',
        meta: { auth: { allowUnverifiedEmail: true } },
        component: () => import('./Identity/Identity.vue')
      },
      {
        path: 'authentication',
        name: 'account.authentication',
        meta: { auth: { allowUnverifiedEmail: true } },
        component: () => import('./AuthenticationSettings/AuthenticationSettings.vue')
      },
      {
        path: 'access',
        name: 'account.developerAccess',
        component: () => import('./DeveloperAccess/DeveloperAccess.vue')
      },
      {
        path: 'integrations',
        name: 'account.integrations',
        component: () => import('./AccountIntegrations/AccountIntegrations.vue')
      }
    ]
  }
]

export default routes
