






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { parseRichLabel } from '@/utils/translations'

@Component({})
export default class RichLabelFormGroup extends Vue {
  @Prop({ required: true }) readonly label!: string
  @Prop() readonly helpText?: string
  @Prop({ type: Boolean, default: true }) readonly bold!: boolean
  @Prop({ type: Boolean, default: false }) readonly horizontal!: boolean

  get richLabel() {
    return parseRichLabel(this.label)
  }

  get localHelpText() {
    if (this.helpText) return this.helpText
    return this.richLabel.helpText
  }
}
