import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import store from '@/store'
import { i18n } from '@/utils/i18n'

const guestRegistrationTriggerUtil: TriggerUtil = {
  kind: TriggerKind.AccompanistRegistration as const,
  label: 'ACCOMPANIST_REGISTRATION' as const,
  icon: 'checkbox-multiple-line' as const,
  nominativeTags: [
    { title: () => i18n.t('TAG_NAME_MAIN_ATTENDEE_FULLNAME'), value: '*|MAIN_ATTENDEE_FULLNAME|*' },
    { title: () => i18n.t('TAG_NAME_MAIN_ATTENDEE_ORGANIZATION'), value: '*|MAIN_ATTENDEE_ORGANIZATION|*' },
    { title: () => i18n.t('TAG_NAME_MAIN_ATTENDEE_JOB'), value: '*|MAIN_ATTENDEE_JOB|*' },
    { title: () => i18n.t('TAG_NAME_MAIN_ATTENDEE_EMAIL'), value: '*|MAIN_ATTENDEE_EMAIL|*' },
    { title: () => i18n.t('TAG_NAME_MAIN_ATTENDEE_PHONE'), value: '*|MAIN_ATTENDEE_PHONE|*' }
  ] as const,

  isAllowed(): boolean {
    return store.state.event?.rights.features.registration.type === 'multi'
  }
}

export default guestRegistrationTriggerUtil
