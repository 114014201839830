import type { GuestField } from '@/models/Event/GuestField'
import type { Event } from '@/models/Event'
import type { LinkItem } from '@/features/messages/emailTags/types'
import { i18n } from '@/utils/i18n'
import { getTranslatedFieldName } from '@/services/guestFields'

const CODE_ID = '*|CODEID|*'

export function getMessageNominativeTags(
  event: Event,
  orderedGuestFields: GuestField[],
  editFormat: boolean = false
): LinkItem[] {
  const emailFriendlyFields = orderedGuestFields.filter(
    (field: GuestField) => !['unsubscribe', 'invitationStatus', 'source', 'addedByAccountId'].includes(field.type)
  )

  const result = emailFriendlyFields.map((field) => ({
    title: i18n.t(field.name),
    value: editFormat ? `*|${normalizeFieldName(field.name)}|*` : `*|field:${field.key}|*`
  }))

  if (event.modules_data.website.onInvitationOnly.portalMode) {
    result.unshift({
      title: i18n.t('CODE_ID'),
      value: CODE_ID
    })
  }
  return result
}

const guestFieldVariableRegex = /\*\|field:(.*?)\|\*/g

export const nominativeTagMapper = {
  mapToEditView({ value, guestFields }: { value: string; guestFields: GuestField[] }): string {
    return value.replace(guestFieldVariableRegex, (match, fieldKey) => {
      const field = findFieldByKey(guestFields, fieldKey)
      return field ? `*|${normalizeFieldName(field.name)}|*` : match
    })
  },

  mapToReadView({ value, guestFields }: { value: string; guestFields: GuestField[] }): string {
    const getVariableNode = (value: string) => {
      return `<span style="border:1px dashed #ccc; padding: 0px 4px; background: rgba(0, 0, 0, 0.03); border-radius: 3px;">${value}</span>`
    }
    let parsedValue = value.replace(CODE_ID, getVariableNode(i18n.t('CODE_ID')))
    parsedValue = parsedValue.replace(guestFieldVariableRegex, (_, fieldKey) => {
      const field = findFieldByKey(guestFields, fieldKey)
      if (!field) {
        return `<span style="color:red">field ${fieldKey} not found</span>`
      }
      const fieldName = getTranslatedFieldName(field.name)
      return getVariableNode(fieldName)
    })
    return parsedValue
  },

  mapToSave({ value, guestFields }: { value: string; guestFields: GuestField[] }): string {
    return value.replace(/\*\|(.*?)\|\*/g, (match, normalizedFieldName) => {
      const field = guestFields.find((f) => normalizeFieldName(f.name) === normalizedFieldName)
      if (!field) {
        return match
      }
      return `*|field:${field.key}|*`
    })
  }
}

function normalizeFieldName(name: string) {
  return getTranslatedFieldName(name).split(' ').join('_').toUpperCase()
}

const findFieldByKey = (guestFields: GuestField[], key: string) => guestFields.find((f) => f.key === key)
