import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabElementBase, PrefabPropertiesBase } from '../../PrefabElementBase'
import { LoremIpsum } from 'lorem-ipsum'

export class Trombino1Properties extends PrefabPropertiesBase {
  segmentId: string[] = []
  searchFieldsIds: string[] = []
  cardFieldsIds: string[] = []
  innerBkgColor: string = ''
  title: string = ''
  subtitle: string = ''
  displayAvatarInGrayscale: boolean = false
}

export class Trombino1Element extends PrefabElementBase {
  static create({ palette }: ElementCreationContext): Trombino1Element {
    const trombino = new Trombino1Element()
    trombino.properties.innerBkgColor = palette.getMostReadableColor(palette.mostVividColor.color).paletteCode
    trombino.properties.title = i18n.t('AN_INSPIRING_TITLE')
    trombino.properties.subtitle = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 10 },
      sentencesPerParagraph: { min: 1, max: 1 }
    }).generateParagraphs(1)
    trombino.properties.displayAvatarInGrayscale = false

    return trombino
  }

  type = `prefab-trombino-1` as const

  @Type(() => Trombino1Properties)
  properties: Trombino1Properties = new Trombino1Properties()

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 200
  }

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TROMBINO')
  }
}
