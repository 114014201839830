











import { Component, Watch } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { EventMenuSection } from '@/navigation/eventMenu'
import { featureDictionary, getMenuSections } from '@/navigation/featureDictionary'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import EventMenuWebsitePreviewButton from '@/features/website/components/EventMenuWebsitePreviewButton.vue'
import { DigiIconButton } from '@/components/ui/actions'
import EventActivationCta from '@/navigation/eventMenu/components/EventActivationCta.vue'
import BaseMenuSection from '@/navigation/eventMenu/components/menuSection/BaseMenuSection.vue'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import type { EventIdentity } from '@/core'

@Component({
  components: {
    BaseMenuSection,
    EventActivationCta,
    DigiIconButton,
    EventMenuWebsitePreviewButton,
    DigiSimpleIconButton
  }
})
export default class EventMenu extends Vue {
  menuSections: EventMenuSection[] = []

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  async mounted() {
    await this.initMenuSections()
  }

  @Watch('eventRights')
  async initMenuSections() {
    this.menuSections = []
    await this.updateMenuSections()
  }

  get showActivateCta(): boolean {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return eventIdentity.isDemo
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  async updateMenuSections() {
    this.menuSections = await getMenuSections(this.storeEvent._id, this.eventRights, featureDictionary)
  }
}
