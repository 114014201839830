import type { EventLangCode } from '@/models/Event/Localized'
import { LocalizedClass, LocalizedPrimitive } from '@/models/Event/Localized'
import type { PageElementId, PageElementType } from './PageElement'
import { PageElement } from './PageElement'
import { plainToClass, Type, Transform, Expose } from 'class-transformer'
import MainColumnElement from '../layout/MainColumn/MainColumnElement'
import type { ICanBeInNavigation, NavItem, TitleContext } from '@/models/Event/modules/Website/NavData'
import { makeNavItem, NavItemType } from '@/models/Event/modules/Website/NavData'
import Vue from 'vue'
import type { FilterId } from '@/models/Event/Filter'
import { cloneDeep } from 'lodash'
import { ErrorWithExtras } from '@/utils/errorTypes'
import type { ElementCreationContext } from './ElementTypeDictionary'
import { builderDictionary } from '../dictionary'
import { generateIdForElement } from '../../pageElements'
import { isNotEqual } from '@/utils/object'
import { timezoneFormat } from '@/lib/timezoneFormat'

export class PageElementMap implements Record<PageElementId, PageElement> {
  [elementId: string]: PageElement
  main: MainColumnElement = new MainColumnElement()

  static fromPlainObject(obj: Record<PageElementId, Record<string, any>>): PageElementMap {
    const result = new PageElementMap()

    for (const elementId in obj) {
      const objectToConvert: Record<string, any> = obj[elementId]!
      const constructor = builderDictionary.get(objectToConvert.type).dataClass
      const converted = plainToClass<PageElement, Record<string, any>>(constructor, objectToConvert)
      result[elementId] = converted
    }

    return result
  }
}

class DeletionCancelledException extends Error {}

class PageElementNotFoundError extends ErrorWithExtras {
  constructor(elementId: PageElementId) {
    super(`Page element not found.`, { elementId })

    this.elementId = elementId
  }

  elementId: PageElementId
}

class DuplicatePageElementIdError extends ErrorWithExtras {
  constructor(elementId: PageElementId) {
    super(`Page element ID is already in use.`, { elementId })

    this.elementId = elementId
  }

  elementId: PageElementId
}

export class PageBuilderConfig {
  @Expose()
  @Transform(({ value }) => PageElementMap.fromPlainObject(value))
  elements: PageElementMap = new PageElementMap()

  private addElementAtId(id: PageElementId, newElement: PageElement): void {
    if (this.hasElementForId(id)) {
      throw new DuplicatePageElementIdError(id)
    }

    Vue.set(this.elements, id, newElement)
  }

  /**
   * Adds a pre-created page element to the config and assigns an ID to it.
   * @param element Element to be registered in the config.
   * @param suggestedId An element ID to use for the element.
   * @returns The ID that was assigned to the element.
   */
  addElement(element: PageElement, suggestedId?: PageElementId): PageElementId {
    const id: PageElementId = suggestedId ?? generateIdForElement(element)

    this.addElementAtId(id, element)

    return id
  }

  createElement(type: PageElementType, context: Omit<ElementCreationContext, 'pageConfig'>): PageElementId {
    return this.addElement(
      builderDictionary.get(type).dataClass.create({
        pageConfig: this,
        ...context
      })
    )
  }

  hasElementForId(id: PageElementId): boolean {
    return this.elements[id] !== undefined
  }

  get mainElement() {
    return this.elements.main
  }

  /**
   * Attempts to fetch a page element given an ID.
   *
   * Will return null on failure.
   * @param id The ID of the page element to look up.
   * @returns The element that was found, or null if none.
   */
  tryGetElementFromId(id: PageElementId | null): PageElement | null {
    if (id === null) {
      return null
    } else {
      return this.elements[id] ?? null
    }
  }

  /**
   * Retrieves the page element assigned to the specified ID.
   * @throws PageElementNotFoundError if no element was found.
   * @param id The ID of the page element to retrieve.
   * @returns The element that was found.
   */
  getElementFromId(id: PageElementId): PageElement {
    if (this.hasElementForId(id)) {
      return this.elements[id]!
    } else {
      throw new PageElementNotFoundError(id)
    }
  }

  isIdChildOfId(targetChildId: PageElementId, parentId: PageElementId): boolean {
    return this.getElementFromId(parentId).isIdChild(targetChildId)
  }

  isIdDescendantOfID(targetDescendantId: PageElementId, parentId: PageElementId): boolean {
    return this.getElementFromId(parentId).isIdDescendant(targetDescendantId, this)
  }

  getParentIdOfId(targetId: PageElementId): PageElementId | null {
    if (targetId === 'main') {
      return null
    }

    for (const potentialParentId of this.elementIds()) {
      if (this.isIdChildOfId(targetId, potentialParentId)) {
        return potentialParentId
      }
    }

    return null
  }

  isElementIdOrphan(targetId: PageElementId): boolean {
    return this.getParentIdOfId(targetId) === null
  }

  getElementIdDepth(targetId: PageElementId): number {
    const parentId = this.getParentIdOfId(targetId)

    if (parentId === null) return 0
    else return this.getElementIdDepth(parentId) + 1
  }

  isElementIdTopLevel(targetId: PageElementId): boolean {
    return this.isIdChildOfId(targetId, 'main')
  }

  /**
   * Replaces existing element data for a given ID with new element data.
   * @param id Element ID to update with new element data. Must be an existing ID.
   * @param newElement New element data to replace the old data with.
   */
  updateElement(id: PageElementId, newElement: PageElement): void {
    if (this.hasElementForId(id)) {
      this.elements[id] = newElement
    } else {
      throw new PageElementNotFoundError(id)
    }
  }

  deleteElement(targetId: PageElementId): void {
    const deletedElement: PageElement = this.getElementFromId(targetId)

    for (const item of this.elementsAndIds()) {
      item.element.beforeDeleteElement({
        id: item.id,
        deletedId: targetId,
        get elementBeingDeleted() {
          return this.pageConfig.getElementFromId(this.deletedId)
        },
        pageConfig: this,
        get isSelf() {
          return this.id === this.deletedId
        },
        cancelDelete(motive: string = 'Deletion was cancelled by pre-deletion hooks') {
          throw new DeletionCancelledException(motive)
        }
      })
    }

    Vue.delete(this.elements, targetId)

    deletedElement.onDeleteElement({
      deletedId: targetId,
      id: targetId,
      pageConfig: this,
      isSelf: true
    })
    for (const item of this.elementsAndIds()) {
      item.element.onDeleteElement({
        id: item.id,
        deletedId: targetId,
        pageConfig: this,
        isSelf: false
      })
    }
  }

  *elementIds(): Generator<PageElementId> {
    for (const key of Object.keys(this.elements)) {
      if (key in this.elements) {
        yield key
      }
    }
  }

  *elementsAndIds(): Generator<{ id: PageElementId; element: PageElement }> {
    for (const id of this.elementIds()) {
      yield {
        id,
        element: this.getElementFromId(id)
      }
    }
  }

  *orphanedIds(): Generator<PageElementId> {
    for (const id of this.elementIds()) {
      // 'main' is technically always orphan, but also always has an entry point to be displayed.
      // So for the sake of semantics we skip it while iterating over orphans.
      if (id !== 'main' && this.isElementIdOrphan(id)) {
        yield id
      }
    }
  }

  repairOrphanedElements(policy: 'delete' | 'insert'): void {
    for (const id of this.orphanedIds()) {
      switch (policy) {
        case 'delete':
          this.deleteElement(id)
          break
        case 'insert':
          this.mainElement.appendChildId(id)
          break
        default:
          throw new Error('Invalid policy: ' + policy)
      }
    }
  }

  *deadReferences(): Generator<{ from: PageElementId; to: PageElementId }> {
    for (const { element, id: parentId } of this.elementsAndIds()) {
      for (const deadRef of element.deadReferences(this)) {
        yield {
          from: parentId,
          to: deadRef
        }
      }
    }
  }

  clearDeadReferences(): void {
    for (const deadRef of this.deadReferences()) {
      this.getElementFromId(deadRef.from).removeChildId(deadRef.to)
    }
  }

  repairElements(
    options: {
      orphanElementPolicy?: Parameters<PageBuilderConfig['repairOrphanedElements']>[0]
    } = {}
  ): void {
    options = {
      orphanElementPolicy: 'insert',
      ...options
    }

    this.repairOrphanedElements(options.orphanElementPolicy!)
    this.clearDeadReferences()
  }

  get hasAnyElements(): boolean {
    return this.elements.main.children.length > 0
  }
}

export class LocalizedPageBuilderConfig extends LocalizedClass<PageBuilderConfig> {
  constructor() {
    super(PageBuilderConfig)
  }
}

export class PageVersion {
  @Type(() => LocalizedPageBuilderConfig)
  content!: LocalizedPageBuilderConfig
  lastUpdateDate!: Date
  lastUpdateAuthor!: string
  versionId!: string

  getFormattedUpdatedDate(timezone: string) {
    if (!this.lastUpdateDate) return ''
    return timezoneFormat(this.lastUpdateDate, timezone, 'DD/MM/YY HH:mm')
  }
}

export class PageVersions {
  @Type(() => PageVersion)
  draft?: PageVersion
  @Type(() => PageVersion)
  published?: PageVersion
}

type PagePublicationState = 'empty' | 'production' | 'draft' | 'both'

const LOCAL_VERSION_ID = 'draft'

export class Page implements ICanBeInNavigation {
  pageId!: string
  slug!: string

  //#region Nav integration
  get idForNavigation(): NavItem<NavItemType.Page> {
    return makeNavItem(NavItemType.Page, this.pageId)
  }
  getTitleForNav({ lang }: TitleContext, defaultTitle: string = ''): string {
    return this.name[lang] || defaultTitle
  }
  setTitleForNav(newTitle: string, { lang }: TitleContext): void {
    Vue.set(this.name, lang, newTitle)
  }
  //#endregion
  @Type(() => PageVersions)
  versions!: PageVersions
  portPageConfigToLang(fromLang: EventLangCode, toLang: EventLangCode, force?: boolean): void {
    if (this.versions?.draft?.content[fromLang] === undefined) {
      if (this.versions?.draft === undefined) {
        this.versions.draft = new PageVersion()
        this.versions.draft.lastUpdateDate = new Date()
      }
      Vue.set(this.versions.draft.content, fromLang, new PageBuilderConfig())
    }
    if (this.versions?.draft?.content[toLang] === undefined || force) {
      Vue.set(this.versions.draft.content, toLang, cloneDeep(this.versions.draft.content[fromLang]))
    }
  }

  @Type(() => LocalizedPrimitive)
  name: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  makeNameForLang(fromLang: EventLangCode, toLang: EventLangCode) {
    if (this.name[fromLang] && !this.name[toLang]) {
      this.name[toLang] = `${this.name[fromLang]} (${toLang.toUpperCase()})`
    }
  }

  segmentIds: FilterId[] = []

  public getDraftContent(lang: EventLangCode): PageBuilderConfig | null {
    return this.versions.draft!.content[lang] ?? null
  }

  public initDraftVersionIfNeeded(): void {
    if (!this.versions.published && !this.versions.draft) {
      throw new Error('No content available')
    }
    if (!this.versions.draft && this.versions.published) {
      this.versions.draft = new PageVersion()
      this.versions.draft.content = cloneDeep(this.versions.published.content)
      this.versions.draft.lastUpdateDate = new Date()
      this.versions.draft.lastUpdateAuthor = 'Digitevent'
      this.versions.draft.versionId = LOCAL_VERSION_ID
    }
  }

  get draftHasModifications() {
    if (!this.versions.draft) {
      return false
    }
    return isNotEqual(this.versions.draft?.content ?? {}, this.versions.published?.content ?? {})
  }

  get hasVersionToPreview() {
    return this.versions.draft && this.versions.draft.versionId !== LOCAL_VERSION_ID
  }

  get publicationState(): PagePublicationState {
    if (!this.versions.published && !this.versions.draft) {
      return 'empty'
    }
    if (this.versions.published && !this.versions.draft) {
      return 'production'
    }
    if (!this.versions.published && this.versions.draft) {
      return 'draft'
    }
    return 'both'
  }
}
