import type { ReadonlyFullRouteConfig } from '@/core'

export const networkingRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'networking',
    name: 'event.networking',
    component: () => import('./views/NetworkingSettings/NetworkingSettings.vue'),
    meta: {
      name: 'FACTORY_QR_CARDS',
      description: 'FACTORY_QR_CARDS_DESC',
      keywords: 'FACTORY_QR_CARDS_DESC_KEYWORDS',
      iconName: 'qr-code-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
