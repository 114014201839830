import type { ReadonlyFullRouteConfig } from '@/core'

export const inactiveRightsRoute: ReadonlyFullRouteConfig = [
  {
    path: 'inactiveFeatures',
    name: 'event.settings.inactiveFeatures',
    component: () => import('@/features/eventSettings/inactiveRights/views/InactiveFeatures.vue'),
    meta: {
      name: 'FACTORY_INACTIVE_FEATURES',
      description: 'FACTORY_INACTIVE_FEATURES_DESC',
      keywords: 'FACTORY_INACTIVE_FEATURES_KEYWORDS',
      iconName: 'star-smile-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
