




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ContactProfileTab extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly icon!: string
  @Prop({ default: false, type: Boolean }) readonly download!: boolean
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean
  @Prop({}) readonly href?: string

  get component(): string {
    return this.download ? 'a' : 'div'
  }

  get isDisabled(): boolean {
    return this.disabled || (this.download && !this.href)
  }

  get rightIcon(): string {
    if (this.download) {
      return this.href ? 'download-2-line' : ''
    }
    return 'arrow-right-s-line'
  }

  get cssClasses() {
    return {
      'contact-profile-tab--disabled': this.isDisabled
    }
  }
}
