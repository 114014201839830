import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export const exportRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'exports',
    name: 'event.exports',
    component: () => import('@/features/audience/exports/views/ExportsSettings.vue'),
    meta: {
      name: 'FACTORY_EXPORTS',
      keywords: 'FACTORY_EXPORTS_KEYWORDS',
      iconName: 'download-cloud-2-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['data_export'],
        redirectName: 'event.dashboard'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event.exports.length > 0
        }
      }
    }
  }
] as const
