







import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PlanningCheckDate extends Vue {
  @Prop({ required: true }) checkDate!: Date

  get checkDateString() {
    return moment(this.checkDate).format('L LT')
  }
}
