export interface RichLabel {
  label: string
  helpText: string | undefined
}

export function parseRichLabel(translatedLabel: string): RichLabel {
  const [label, helpText] = translatedLabel.split('##')
  if (!label) {
    throw new Error('a label is required')
  }
  return { label, helpText }
}
