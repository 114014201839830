
















import { Vue, Component } from 'vue-property-decorator'
import FormPlaceholderInput from './FormPlaceholderInput.vue'
import FormPlaceholderCta from './FormPlaceholderCta.vue'
import DigiOverlay from '../DigiOverlay.vue'

@Component({
  components: { DigiOverlay, FormPlaceholderCta, FormPlaceholderInput }
})
export default class FormPlaceholder extends Vue {}
