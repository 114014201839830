









import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { countGuestGroupActivities, countGuestMeetings } from '@/features/eventApp'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileProgrammeTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get groupActivityCount(): number {
    if (!this.guest.planning) return 0
    return countGuestGroupActivities(this.guest, this.storeEvent)
  }

  get scheduledMeetingCount(): number {
    if (!this.guest.planning) return 0
    return countGuestMeetings(this.guest, this.storeEvent)
  }

  get meetingToConfirmCount(): number {
    if (!this.guest.planning || !this.guest.planning.unscheduledMeetings) return 0
    return this.guest.planning.unscheduledMeetings.filter((item) => item.status === 'pendingAcceptation').length
  }
}
