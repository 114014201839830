









import { Component, Prop } from 'vue-property-decorator'
import type { SupportRepresentative } from '@/models/Account'
import { getEventSupportRepresentative } from '@/services/events'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import MobileNavItem from '../../MobileNav/MobileNavItem.vue'
import { DigiLink } from '@/components/ui/actions'

@Component({
  components: { DigiLink, MobileNavItem }
})
export default class PremiumSupportCta extends Vue {
  @Prop({ default: 'button' }) variant!: 'button' | 'link'

  supportRepresentative: SupportRepresentative | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get bookingUrl(): string {
    return this.supportRepresentative?.bookingUrl || ''
  }

  async mounted() {
    this.supportRepresentative = await getEventSupportRepresentative(this.storeEvent._id)
  }
}
