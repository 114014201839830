import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import SponsorsElementBase, { SponsorsProperties } from '../SponsorsElementBase'
import { LoremIpsum } from 'lorem-ipsum'

export class Sponsors3Properties extends SponsorsProperties {
  title: string = ''
  mainText: string = ''
  textColor: string = ''

  sponsorsIds: string[] = []
  displayAvatarInGrayscale: boolean = false
}

export default class Sponsors3Element extends SponsorsElementBase {
  actualEditingSlide: number = 0
  static create({ palette }: ElementCreationContext): Sponsors3Element {
    const sponsors = new Sponsors3Element()

    sponsors.properties.title = i18n.t('OUR_SPONSORS')
    sponsors.properties.mainText = new LoremIpsum().generateWords(3)
    sponsors.properties.textColor = palette.darkestColor.paletteCode
    sponsors.properties.sponsorsIds = []
    sponsors.properties.displayAvatarInGrayscale = false

    return sponsors
  }

  type = `prefab-sponsors-3` as const

  @Type(() => Sponsors3Properties)
  properties: Sponsors3Properties = new Sponsors3Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SPONSORS')
  }
}
