







































































import {
  countFilterContacts,
  getSlugForMessageStatusCriteriaValue,
  getSlugForSourceCriteriaValue
} from '@/services/filter'
import CriteriaType from './components/CriteriaType.vue'

import { Component, Prop, Ref } from 'vue-property-decorator'
import type { BModal } from 'bootstrap-vue'

import { isNil, isEmpty, cloneDeep, debounce } from 'lodash'

import type { AnonymousFilter } from '@/models/Event/Filter'
import Filter from '@/models/Event/Filter'
import type Criteria from '@/models/Event/Filter/Criteria'
import { MessageStatusCriteriaValue, SourceCriteriaValue } from '@/models/Event/Filter/Criteria'
import type { CustomFieldId } from '@/models/Event/CustomField'
import { CustomFieldType } from '@/models/Event/CustomField'
import { getPossibleStatuses } from '@/utils/invitationStatus'
import { $enum } from 'ts-enum-util'
import { ErrorWithExtras } from '@/utils/errorTypes'
import type { MongoDb } from '@/models/MongoDbTools'
import { OrderStatus } from '@/models/Guest/Order'
import { humanizeOrderStatus } from '@/services/order'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

import { PaymentMethodType } from '@/models/Guest/Order/Transaction'
import { getEventCollabolators } from '@/services/eventPermissions'
import { DigiButton } from '@/components/ui/actions'
import type {
  Collabolator,
  ExtendedGuestField,
  FieldForFiltering,
  FieldMap,
  FilterBeingCreated,
  OperatorInfo,
  OperatorMap,
  PropertyOptions,
  TypeOperatorsInfo,
  ValueSelectOptions
} from '@/features/audience/segments/components/SegmentsModal/types'
import { isNamedFilter } from '@/features/audience/segments/components/SegmentsModal/types'
import type { EventRights } from '@/models/Event/EventRights'
import { isSegmentOverMaxConditions } from '../../services'
import { isSortGroup } from '@/utils/sort'
import type { SortGroup } from '@/models/commons/sort'
import { assertEventRights } from '@/services/eventRights'
import Vue from 'vue'

const debouncedCountGuests = debounce(function (this: FilterModal) {
  if (this.noCriterias || !this.allCriteriasValid) {
    return
  }

  countFilterContacts(this.$store.getters.eventId, {
    filter: this.filterCopy
  })
    .then((count) => (this.matchingGuestCount = count))
    .finally(() => (this.countingResults = false))
}, 1500)

function getTransactionTypeSlug(
  paymentMethodType:
    | PaymentMethodType.Card
    | PaymentMethodType.CardAmex
    | PaymentMethodType.BankWire
    | PaymentMethodType.Cash
    | PaymentMethodType.Other
): string {
  switch (paymentMethodType) {
    case PaymentMethodType.Card:
      return 'PAYMENT_TYPE_CB'
    case PaymentMethodType.CardAmex:
      return 'PAYMENT_TYPE_CARD_AMEX'
    case PaymentMethodType.BankWire:
      return 'PAYMENT_TYPE_BANKWIRE'
    case PaymentMethodType.Cash:
      return 'PAYMENT_TYPE_CASH'
    case PaymentMethodType.Other:
      return 'PAYMENT_TYPE_OTHERPAYMENT'
  }
}

@Component<FilterModal>({
  components: {
    DigiButton,
    CriteriaType
  },
  provide() {
    return {
      propertyOptions: this.propertyOptions,
      operatorMap: this.operatorMap,
      fieldMap: this.fieldMap
    }
  }
})
export default class FilterModal extends Vue {
  @Prop({ default: '' }) readonly title!: string
  @Prop() readonly nameless!: boolean

  isEditing: boolean = false
  countingResults: boolean = false
  matchingGuestCount: number = 0
  filterCopy: AnonymousFilter | FilterBeingCreated = new Filter()
  andCriteriasValid: boolean | null = null
  orCriteriasValid: boolean | null = null
  originalName: string | null = null
  collabolators: Collabolator[] = []

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  created() {
    this.fetchCollabolators()
  }

  async fetchCollabolators() {
    const eventId = this.storeEvent._id
    this.collabolators = await getEventCollabolators(eventId)
  }

  @Ref() readonly modal!: BModal

  get isSegmentOverMaxConditions(): boolean {
    return isSegmentOverMaxConditions(this.filterCopy, this.eventRights)
  }

  get noCriterias(): boolean {
    return (
      (!this.filterCopy.andCriterias || this.filterCopy.andCriterias.length === 0) &&
      (!this.filterCopy.orCriterias || this.filterCopy.orCriterias.length === 0)
    )
  }

  get nameUnchanged(): boolean {
    if ('name' in this.filterCopy) {
      // There is some kind of name in our filter
      if (isNil(this.originalName)) {
        // If we didn't have a name at the start and still don't have one, we're good
        return isEmpty(this.filterCopy.name)
      } else {
        // If we did have a name originally, check if the one we currently have is the same
        return this.filterCopy.name === this.originalName
      }
    } else {
      // If we don't have a name at all, we probably didn't ever touch it
      return true
    }
  }

  get allCriteriasValid(): boolean {
    return Boolean(this.andCriteriasValid) && Boolean(this.orCriteriasValid)
  }

  get canSave(): boolean {
    return (
      !this.noCriterias &&
      this.allCriteriasValid &&
      (this.nameless || this.isNameValid) &&
      !this.isSegmentOverMaxConditions
    )
  }

  get fieldMap(): FieldMap {
    let result: FieldForFiltering[] = [...this.$store.getters.orderedGuestFields]

    if (this.eventRights.features.registration.type === 'multi') {
      result.push({
        name: 'MULTI_REGISTRATION',
        slug: 'multiRegistration',
        type: 'multiRegistration',
        origin: 'legacy',
        key: 'multiRegistration'
      })
    }

    if (this.eventRights.features.eventApp.allowed) {
      result.push({
        origin: 'programme',
        key: 'planning',
        type: 'programme',
        name: 'PLANNING'
      })

      const programme = this.storeEvent.modules_data.programme

      if (!programme) throw new Error('Programme module is enabled, but programme data is missing.')

      const visioGroupActivities = programme.eventActivities.filter(
        (activity) => activity.kind === 'groupActivity' && activity.hasVisio
      )
      const groupActivityFields: ExtendedGuestField[] = visioGroupActivities.map((activity) => ({
        origin: 'programme',
        key: `CHECKPOINT_${activity._id}`,
        type: 'programmeCheckinField',
        name: `${activity.title}`
      }))

      result = result.concat(groupActivityFields)

      if (this.eventRights.features.eventApp.matchmaking) {
        const matchmakingSessions = programme.eventActivities.filter(
          (activity) => activity.kind === 'matchmakingSession'
        )

        const matchmakingSessionFields: ExtendedGuestField[] = matchmakingSessions.map((session) => ({
          origin: 'programme',
          key: `MATCHMAKING_${session._id}`,
          type: 'matchmaking',
          name: `${session.title}`
        }))

        result = result.concat(matchmakingSessionFields)
      }
    }

    if (this.$store.getters.isPaidEvent) {
      const paymentModule = this.storeEvent.modules_data.payment

      if (!paymentModule) throw new Error('Payment module is enabled, but payment data is missing.')

      result.push({
        key: 'orders.status',
        origin: 'order',
        name: 'ORDER_STATUS',
        type: 'orderStatus'
      })
      result.push({
        key: 'orders.items.ticketId',
        origin: 'order',
        name: 'TICKET_NAME',
        type: 'ticketId'
      })
      result.push({
        key: 'orders.paymentType',
        origin: 'order',
        name: 'PAYMENT_MODE',
        type: 'paymentType'
      })
      if (paymentModule.promoCodes.length) {
        result.push({
          key: 'orders.promoCodeTag',
          origin: 'order',
          name: 'PROMO_CODE',
          type: 'promoCode'
        })
      }
    }

    if (this.eventRights.features.registration.utmTracking) {
      result.push({
        key: 'registrationUrlQuery',
        origin: 'generated',
        name: 'REGISTRATION_QUERY_PARAMS',
        type: 'registrationUrlQuery'
      })
    }

    if (this.eventRights.features.website.allowed) {
      for (const form of this.storeEvent.forms) {
        if (form.isRegistration && this.eventRights.features.registration.mode === 'paid') {
          // Don't offer filtering on registration form in paid events.
          // It doesn't make much sense in that context, the form is only one part of the registration process.
          continue
        }

        result.push({
          type: 'formSubmission',
          name: form.getLocalizedName(this.storeEvent.mainLanguageCode),
          origin: 'generated',
          key: `FORM_${form._id}`
        })
      }
    }

    if (this.eventRights.features.checkin.app) {
      result.push({
        type: 'checkinPoint',
        name: this.$t('SEGMENT_CRITERIA_CHECKIN_ANY'),
        origin: 'generated',
        key: 'CHECKIN_ANY'
      })
      for (const point of this.storeEvent.modules_data.checkin.points) {
        result.push({ type: 'checkinPoint', name: point.name, origin: 'generated', key: `CHECKIN_${point._id}` })
      }
    }

    for (const message of this.storeEvent.messages.templates) {
      result.push({
        key: 'MESSAGE_' + message._id,
        // Arbitrary origin to consistently have an origin on fields
        // Has no semantic value in this context
        origin: 'generated',
        name: message.name,
        type: 'message'
      })
    }

    return result.reduce<FieldMap>((obj, field) => {
      obj[field.key] = field
      return obj
    }, {})
  }

  get propertyOptions(): PropertyOptions {
    const fieldMapArray: FieldForFiltering[] = Object.values(this.fieldMap)

    const regularFields: FieldForFiltering[] = []
    const checkinFields: FieldForFiltering[] = []
    const programmeCheckinFields: FieldForFiltering[] = []
    const matchmakingFields: FieldForFiltering[] = []
    const formFields: FieldForFiltering[] = []
    const campaignFields: FieldForFiltering[] = []
    const paymentFields: FieldForFiltering[] = []
    const otherFields: FieldForFiltering[] = []

    // filter fieldMapArray by categories
    fieldMapArray.forEach((field) => {
      if (
        (field.origin !== 'custom' &&
          (field.type === 'phone' || field.type === 'file' || field.type === 'text' || field.type === 'email')) ||
        (field.origin === 'custom' && !this.fieldHasCategory(field.key))
      )
        regularFields.push(field)
      else if (field.type === 'message' || field.type === 'unsubscribe') campaignFields.push(field)
      else if (field.type === 'checkinPoint') checkinFields.push(field)
      else if (field.type === 'formSubmission') formFields.push(field)
      else if (field.type === 'programmeCheckinField') programmeCheckinFields.push(field)
      else if (field.type === 'matchmaking') matchmakingFields.push(field)
      else if (field.type === 'registrationQueryParams') otherFields.push(field)
      else if (field.origin === 'order') paymentFields.push(field)
      else if (field.origin !== 'custom') {
        if (field.type === 'invitationStatus' && this.$store.getters.isPaidEvent) {
          // @FIXME: This should be the responsibility of the server, the invitationStatus field is generated by the server!
          otherFields.push({
            ...field,
            name: 'PARTICIPATION_STATUS'
          })
        } else {
          otherFields.push(field)
        }
      }
    })

    const categorizedFields: Record<string, FieldForFiltering[]> = {
      FIELDS: regularFields
    }

    // add only the categories we need
    this.customFieldSections.forEach((section) => {
      const sectionName = section.name
      categorizedFields[sectionName] = fieldMapArray.filter(
        (field) => this.fieldHasCategory(field.key) && section.items.includes(field.key)
      )
    })
    categorizedFields.OTHERS_COLUMNS = otherFields
    if (this.eventRights.features.registration.mode === 'paid') {
      categorizedFields.PAYMENT = paymentFields
    }
    if (this.eventRights.features.checkin.app) {
      categorizedFields.CHECKIN_POINTS = checkinFields
    }
    if (this.eventRights.features.eventApp.allowed) {
      categorizedFields.PROGRAMME_VISIO_CHECKIN_POINTS = programmeCheckinFields

      if (this.eventRights.features.eventApp.matchmaking) {
        categorizedFields.MATCHMAKING_SESSIONS = matchmakingFields
      }
    }
    if (this.eventRights.features.website.allowed) {
      categorizedFields.FORMS = formFields
    }
    if (this.eventRights.features.campaigns) {
      categorizedFields.CAMPAIGNS = campaignFields
    }

    const result: PropertyOptions = {}

    for (const category in categorizedFields) {
      result[category] = categorizedFields[category]!.map((item) => ({
        text: item.name,
        value: item.key
      }))
    }
    return result
  }

  /**
   * A map indexed on guest field types.
   * Lets you retrieve all possible operators for a field given its type, with O(1) complexity.
   * For each type, you have an object which uses allowed operators as keys, and each operator
   * contains information on what kind of value may or may not be required after it.
   */
  get operatorMap(): OperatorMap {
    // Common operators for several types
    const textLikeOperators: TypeOperatorsInfo = {
      IS: { valueType: 'string' },
      ISNT: { valueType: 'string' },
      CONTAINS: { valueType: 'string' },
      NOT_CONTAINS: { valueType: 'string' },
      IS_EMPTY: { valueType: null },
      ISNT_EMPTY: { valueType: null }
    }
    // Operators for date fields
    const dateInfo: OperatorInfo = {
      valueType: 'select',
      options: [
        'PRECISE_DATE',
        '-1_YEAR',
        '-1_WEEK',
        '-3_DAYS',
        'YESTERDAY',
        'TODAY',
        'TOMORROW',
        '3_DAYS',
        '1_WEEK',
        '1_YEAR'
      ].map((timePoint) => ({ text: this.$t(timePoint), value: timePoint })),
      showDateInput: (criteria: Criteria) => criteria.testedValue === 'PRECISE_DATE'
    }

    // The final map
    return {
      text: textLikeOperators,
      phone: textLikeOperators,
      email: textLikeOperators,
      longtext: textLikeOperators,
      link: textLikeOperators,
      number: {
        IS: { valueType: 'number' },
        ISNT: { valueType: 'number' },
        GT: { valueType: 'number' },
        LT: { valueType: 'number' },
        IS_EMPTY: { valueType: null },
        ISNT_EMPTY: { valueType: null }
      },
      checkbox: {
        IS_CHECKED: { valueType: null },
        ISNT_CHECKED: { valueType: null }
      },
      unsubscribe: {
        IS_SUBSCRIBE: { valueType: null },
        ISNT_SUBSCRIBE: { valueType: null }
      },
      registrationUrlQuery: {
        CONTAINS: { valueType: 'string' },
        NOT_CONTAINS: { valueType: 'string' },
        IS_EMPTY: { valueType: null },
        ISNT_EMPTY: { valueType: null }
      },
      source: () => {
        const sourceInfo: OperatorInfo = {
          valueType: 'select',
          options: $enum(SourceCriteriaValue)
            .getValues()
            .map((source) => ({
              text: this.$t(getSlugForSourceCriteriaValue(source)),
              value: source as string
            }))
            .concat(
              this.storeEvent.importGuestsSources
                ?.filter((source) => {
                  return Boolean(source.split('::')[1])
                })
                .map((source) => ({
                  text: this.$t('CRITERIA_EXCEL') + ' ' + source.split('::')[1],
                  value: source
                })) || []
            )
        }
        return {
          IS: sourceInfo,
          ISNT: sourceInfo
        }
      },
      multiRegistration: {
        IS_ACCOMPANIST: { valueType: null },
        IS_NOT_ACCOMPANIST: { valueType: null },
        IS_ACCOMPANIED: { valueType: null },
        HAS_NO_ACCOMPANIST: { valueType: null }
      },
      programme: () => {
        if (this.eventRights.features.eventApp.allowed) {
          const programmeOptions = this.storeEvent.modules_data.programme?.eventActivities.map((act) => ({
            value: act._id,
            text: act.title
          }))

          return {
            IS_EMPTY: { valueType: null },
            ISNT_EMPTY: { valueType: null },
            CONTAINS: { valueType: 'select', options: programmeOptions },
            NOT_CONTAINS: { valueType: 'select', options: programmeOptions }
          }
        } else {
          throw new Error('Cannot process programme criteria: No programme data.')
        }
      },
      matchmaking: {
        HAS_CONFIRMED_MEETING: { valueType: null },
        HAS_CANCELED_MEETING: { valueType: null },
        HAS_AWAITING_MEETING: { valueType: null },
        HAS_DECLINED_MEETING: { valueType: null }
      },
      checkinPoint: {
        IS_ARRIVED: { valueType: null },
        ISNT_ARRIVED: { valueType: null }
      },
      programmeCheckinField: {
        IS_ARRIVED: { valueType: null, presentationSlug: 'CONNECTED' },
        ISNT_ARRIVED: { valueType: null, presentationSlug: 'NOT_CONNECTED' }
      },
      invitationStatus: () => {
        const invitationStatusOptions = getPossibleStatuses(this.eventRights).map((status) => ({
          text: this.$t(status.toUpperCase()),
          value: status
        }))
        return {
          IS: { valueType: 'select', options: invitationStatusOptions },
          ISNT: { valueType: 'select', options: invitationStatusOptions }
        }
      },
      dateWithoutTimezone: {
        BEFORE: dateInfo,
        AFTER: dateInfo,
        IS_DATE: dateInfo,
        IS_EMPTY: { valueType: null },
        ISNT_EMPTY: { valueType: null }
      },
      formSubmission: (): TypeOperatorsInfo => {
        const formDateInfo: OperatorInfo = {
          valueType: 'select',
          options: ['-1_WEEK', '-3_DAYS', 'YESTERDAY', 'TODAY', 'PRECISE_DATE'].map((timePoint) => ({
            text: this.$t(timePoint),
            value: timePoint
          })),
          showDateInput: (criteria: Criteria) => criteria.testedValue === 'PRECISE_DATE'
        }

        return {
          SUBMITTED_BEFORE: formDateInfo,
          SUBMITTED_AFTER: formDateInfo,
          SUBMITTED_ON: formDateInfo,
          IS_SUBMITTED: { valueType: null },
          ISNT_SUBMITTED: { valueType: null }
        }
      },
      ticketId: () => {
        const ticketIdInfo: OperatorInfo = {
          valueType: 'select',
          options: this.storeEvent.modules_data.payment.tickets
            .map((ticket) => ({
              text: ticket.name,
              value: ticket._id
            }))
            .concat(
              this.storeEvent.modules_data.payment.addons.map((addon) => ({
                text: addon.name,
                value: addon._id
              }))
            )
        }

        return {
          IS: ticketIdInfo,
          ISNT: ticketIdInfo,
          HAS_A_VALID_TICKET: { valueType: null },
          HAS_NO_VALID_TICKET: { valueType: null },
          HAS_ONLY_CANCELED_TICKETS: { valueType: null }
        }
      },
      paymentType: () => {
        const paymentTypeInfo: OperatorInfo = {
          valueType: 'select',
          options: (
            [
              PaymentMethodType.Card,
              PaymentMethodType.BankWire,
              PaymentMethodType.Cash,
              PaymentMethodType.CardAmex,
              PaymentMethodType.Other
            ] as const
          ).map((transactionType) => ({
            text: this.$t(getTransactionTypeSlug(transactionType)),
            value: transactionType
          }))
        }

        return {
          IS: paymentTypeInfo,
          ISNT: paymentTypeInfo
        }
      },
      promoCode: () => {
        const promoCodeInfos: OperatorInfo = {
          valueType: 'select',
          options: this.storeEvent.modules_data.payment.promoCodes.map((promoCode) => {
            return { text: promoCode.promoCodeTag, value: promoCode.promoCodeTag }
          })
        }
        return {
          IS: promoCodeInfos,
          ISNT: promoCodeInfos,
          IS_EMPTY: { valueType: null },
          ISNT_EMPTY: { valueType: null }
        }
      },
      addedByAccountId: () => {
        const addedByInfo: OperatorInfo = {
          valueType: 'select',
          options: this.collabolators.map((collabolator) => ({
            text: collabolator.email,
            value: collabolator.id
          }))
        }

        return {
          IS: addedByInfo,
          ISNT: addedByInfo
        }
      },
      list: () => {
        const listInfo: OperatorInfo = {
          valueType: 'select',
          options: (criteria: Criteria): { text: string; value: MongoDb.ObjectId }[] => {
            const field = this.fieldMap[criteria.property]

            if (!field)
              throw new ErrorWithExtras('Cannot process list options: Property key is not a known field.', {
                property: criteria.property
              })

            if (field.type === CustomFieldType.List && 'list' in field) {
              return field.list.map((listItem) => ({
                text: listItem.value,
                value: listItem._id!
              }))
            } else {
              throw new Error('Invalid data for list field: Not a list field.')
            }
          }
        }

        return {
          IS: listInfo,
          ISNT: listInfo,
          IS_EMPTY: { valueType: null },
          ISNT_EMPTY: { valueType: null }
        }
      },
      lang: () => {
        const currentLang = this.storeEvent.mainLanguageCode

        const langOptions: ValueSelectOptions = [
          {
            text: this.$t(currentLang),
            value: currentLang
          }
        ]

        if (this.eventRights.features.website.allowed) {
          for (const extraLang of this.storeEvent.modules_data.website.websiteTranslations) {
            langOptions.push({
              text: this.$t(extraLang),
              value: extraLang
            })
          }
        }

        const langInfo: OperatorInfo = {
          valueType: 'select',
          options: langOptions
        }

        return {
          IS: langInfo,
          ISNT: langInfo,
          IS_EMPTY: { valueType: null },
          ISNT_EMPTY: { valueType: null }
        }
      },
      orderStatus: () => {
        const orderStatusInfo: OperatorInfo = {
          valueType: 'select',
          options: $enum(OrderStatus)
            .getValues()
            .map((status) => ({
              text: humanizeOrderStatus(status),
              value: status
            }))
        }

        return {
          IS: orderStatusInfo,
          ISNT: orderStatusInfo,
          IS_EMPTY: { valueType: null },
          ISNT_EMPTY: { valueType: null }
        }
      },
      message: () => {
        const messageStatusInfo: OperatorInfo = {
          valueType: 'select',
          options: $enum(MessageStatusCriteriaValue)
            .getValues()
            .map((status) => ({
              text: this.$t(getSlugForMessageStatusCriteriaValue(status)),
              value: status
            }))
        }

        return {
          HAS_STATUS: messageStatusInfo,
          HASNT_STATUS: messageStatusInfo
        }
      },
      file: {
        IS_EMPTY: { valueType: null },
        ISNT_EMPTY: { valueType: null }
      },
      registrationQueryParams: {}
    }
  }

  get isNameValid() {
    if (this.nameless) {
      return true
    } else {
      // We just want it defined and not empty
      // That's the truthiness rules of a string, so casting to boolean works
      return Boolean('name' in this.filterCopy && this.filterCopy.name)
    }
  }

  get customFieldSections(): SortGroup[] {
    return this.storeEvent.customFieldsSortOrder.filter(
      (item): item is SortGroup => isSortGroup(item) && item.items.length > 0
    )
  }

  openCreationModal(): void {
    this.isEditing = false
    this.resetCopy()
    this.modal.show()
  }

  openDuplicationModal(filter: Filter): void {
    this.isEditing = false
    this.originalName = null
    const filterCopy: FilterBeingCreated = cloneDeep(filter)
    filterCopy.name = ''
    delete filterCopy._id
    this.filterCopy = filterCopy
    this.getMatchingGuestsCount()
    this.modal.show()
  }

  openEditionModal<FilterT extends Filter | AnonymousFilter>(filter: FilterT): void {
    this.isEditing = true
    this.filterCopy = cloneDeep(filter)
    if (isNamedFilter(filter)) {
      this.originalName = filter.name ?? null
    } else {
      this.originalName = null
    }

    this.getMatchingGuestsCount()
    this.modal.show()
  }

  confirm() {
    if (this.isEditing) {
      this.$emit('edit-segment', this.filterCopy as Filter)
    } else {
      this.$emit('create-segment', this.filterCopy as Filter)
    }
    this.dismiss()
  }

  dismiss() {
    this.modal.hide()
  }

  resetCopy() {
    this.filterCopy = new Filter()
    this.originalName = null
  }

  getMatchingGuestsCount() {
    this.countingResults = true
    debouncedCountGuests.apply(this)
  }

  async handleCriteriaChange() {
    // Done on next tick to let watchers update validity
    // Getting rid of this necessity would be good
    // Maybe with even less watchers and more events
    await this.$nextTick()

    if (!this.noCriterias && this.allCriteriasValid) {
      this.getMatchingGuestsCount()
    }
  }

  fieldHasCategory(fieldValue: CustomFieldId) {
    return this.customFieldSections.some((section) => section.items.includes(fieldValue))
  }
}
