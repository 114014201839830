import { ElementTypeDictionary } from '../../types'
import { Cover1Element } from './cover1'
import { Cover2Element } from './cover2'
import { Cover3Element } from './cover3'
import { CoverForm1Element } from '@/features/website/builder/builder/prefabs/cover/coverForm1'

export const coverDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Cover1Element,
    renderer: () => import('./cover1/Cover1.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      {
        type: 'ctaOptions',
        key: 'properties.cta',
        label: 'CTA_OPTIONS'
      },
      {
        type: 'range',
        label: 'HEADER_HEIGHT',
        key: 'properties.minHeight',
        min: 30,
        max: 100,
        default: 80,
        step: 10,
        unit: '%'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COVER_1_TITLE',
    previewImageLink: require('@/assets/img/prefab-previews/cover1.jpg')
  })
  .addType({
    dataClass: Cover2Element,
    renderer: () => import('./cover2/Cover2.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' },
      {
        type: 'range',
        label: 'HEADER_HEIGHT',
        key: 'properties.minHeight',
        min: 40,
        max: 100,
        step: 10,
        default: 80,
        unit: '%'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COVER_2_TITLE',
    previewImageLink: require('@/assets/img/prefab-previews/cover2.jpg')
  })
  .addType({
    dataClass: Cover3Element,
    renderer: () => import('./cover3/PrefabCover3.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' },
      {
        type: 'range',
        label: 'HEADER_HEIGHT',
        key: 'properties.minHeight',
        min: 40,
        max: 100,
        step: 10,
        default: 80,
        unit: '%'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COVER_3_TITLE',
    previewImageLink: require('@/assets/img/prefab-previews/cover3.jpg')
  })
  .addType({
    dataClass: CoverForm1Element,
    renderer: () => import('./coverForm1/PrefabCoverForm1.vue'),
    properties: [
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      {
        type: 'range',
        label: 'HEADER_HEIGHT',
        key: 'properties.minHeight',
        min: 40,
        max: 100,
        step: 10,
        default: 80,
        unit: '%'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COVER_FORM_1_TITLE',
    previewImageLink: require('@/assets/img/prefab-previews/coverForm1.jpg')
  })
  .finish()
