import type { Editor } from 'tinymce'
import type { TextEditorSubMenuItem, TinyMCEMenu, TinyMCENestedMenu, TinyMCESeparator } from './types'
import type { LinkItem } from '@/features/messages/emailTags/types'

export function separator(title: string): TinyMCESeparator {
  const separator: TinyMCESeparator = {
    type: 'separator',
    text: title
  }
  return separator
}

export function menuItemFromLink(link: TextEditorSubMenuItem, editor: Editor): TinyMCEMenu {
  const menuSection: TinyMCEMenu = {
    type: 'menuitem',
    text: link.title,
    onAction: () => {
      link.onClick(editor)
    }
  }
  return menuSection
}

export function subMenu(title: string, links: TextEditorSubMenuItem[], editor: Editor): TinyMCENestedMenu {
  const subMenu: TinyMCENestedMenu = {
    type: 'nestedmenuitem',
    text: title,
    getSubmenuItems: () =>
      links.map((link) => {
        return {
          type: 'menuitem',
          text: link.title,
          onAction: () => {
            editor.insertContent(`<span> <a href="${link.value}">${link.title}</a> </span>`)
          }
        }
      })
  }
  return subMenu
}

export function insertLink(editor: Editor, link: LinkItem) {
  editor.insertContent(`<span> <a href="${link.value}">${link.title}</a> </span>`)
}

export function insertText(editor: Editor, text: string) {
  editor.insertContent(`<span> ${text} </span>`)
}
