import Vue from 'vue'

export type PartiallyPartial<T, PartialKeys extends keyof T> = Omit<T, PartialKeys> & Partial<T>

export type PartiallyRequired<T, RequiredKeys extends keyof T> = T & Required<Pick<T, RequiredKeys>>

export type InferArrayElement<A> = A extends readonly (infer T)[] ? T : never

export type DeepReadonly<T> = T extends (infer R)[]
  ? DeepReadonlyArray<R>
  : T extends Function
  ? T
  : T extends object
  ? DeepReadonlyObject<T>
  : T

export interface DeepReadonlyArray<T> extends ReadonlyArray<DeepReadonly<T>> {}

export type DeepReadonlyObject<T> = {
  readonly [P in keyof T]: DeepReadonly<T[P]>
}

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> }

// @TODO: Remove all usage of this class when we switch to Vue 3
// Utility function to circumvent issue vue/vue#9259
export function doSomeMagicToMakeAnObjectReactiveIGuess(obj: any): void {
  const proto = obj.__proto__

  Object.defineProperty(obj, '__proto__', {
    get() {
      return proto
    },
    set(newValue) {
      proto.__proto__ = newValue
    }
  })

  new Vue({ data: () => ({ obj }) })
}
