











import { Component, Vue } from 'vue-property-decorator'
import { DigiLink } from '@/components/ui/actions'

@Component({
  components: {
    DigiLink
  }
})
export default class ContactSalesSection extends Vue {}
