









import { Vue, Component, Ref } from 'vue-property-decorator'
import { PreviewFrontModal } from '@/components/features/previewFront'

@Component({
  components: { PreviewFrontModal }
})
export default class EventMenuWebsitePreviewButton extends Vue {
  @Ref() readonly previewFrontModal?: PreviewFrontModal

  openModal() {
    this.previewFrontModal?.openModal()
  }
}
