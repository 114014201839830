import Vue from 'vue'
import i18next from 'i18next'
import moment from 'moment'
import VueI18Next from '@panter/vue-i18next'
import HttpBackend from 'i18next-http-backend'

export enum BackofficeLanguage {
  French = 'fr',
  English = 'en',
  Portuguese = 'pt'
}

/**
 * Extracts a primary language subtag from a BCP47/RFC5646 language tag.
 * @param languageTag A BCP47/RFC5646 language tag.
 * @returns The primary language tag.
 */
export function extractPrimaryLanguageSubtag(languageTag: string): string {
  const extractedSubtag = languageTag.split('-')[0]

  if (extractedSubtag) {
    return extractedSubtag
  } else {
    throw new Error(`Invalid language tag: ${languageTag}. Could not extract primary language subtag.`)
  }
}

Vue.use(VueI18Next)
// WARNING, keyseparator and nsseparator have been put here
// to let i18 translate strings with : (colon)
// It can impact things if we use namesacpes someday
i18next.use(HttpBackend).init({
  fallbackLng: 'en',
  // These are just a (terrible) workaround for cases where the default separators appear in user input!
  // @FIXME: Do not keep these indefinitely! Ideally our code should just not translate user input!
  keySeparator: '>',
  nsSeparator: '|',
  backend: {
    loadPath: 'https://s3.eu-west-3.amazonaws.com/digi-translations/digiback/{{lng}}.json'
  }
})

export const i18n: VueI18Next = new VueI18Next(i18next)

// You know what? Every goddamn template in the
// original used a filter for translation.
// So here's a goddamn filter for translation.
// Also I make it "t" and not "translate" because
// otherwise it's verbose af
Vue.filter('t', (slug: string, ...args: any[]) => i18n.t(slug, ...args))

function setI18nLanguage(lang: BackofficeLanguage): Promise<BackofficeLanguage> {
  return i18next.changeLanguage(lang).then(() => {
    document.documentElement.setAttribute('lang', lang)
    return lang
  })
}

export function loadLanguageAsync(lang: BackofficeLanguage): Promise<BackofficeLanguage> {
  return setI18nLanguage(lang).then((lang) => {
    moment.locale(lang)
    return lang
  })
}

export type Localized<T> = {
  [lang in string]?: T
}
