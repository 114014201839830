























import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { DigiIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiIconButton, DigiSimpleIconButton }
})
export default class DigiVerticalMenuSub extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ default: null }) backLabel?: string

  get backLabelValue() {
    return this.backLabel ?? this.$t('DIGI_VERTICAL_MENU_BACK')
  }
}
