









import { Vue, Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { EventRights } from '@/models/Event/EventRights'
import { getStatusSlug } from '@/utils/invitationStatus'
import { isAnAdditional } from '@/features/registration/multiRegistration'

@Component({
  components: {}
})
export default class MultiRegistrationTabContent extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly eventRights!: EventRights

  get invitationStatus() {
    return this.$t(getStatusSlug(this.guest.invitationStatus))
  }

  get isAdditionalAttendee(): boolean {
    return isAnAdditional(this.guest)
  }

  get additionalAttendeeNumber() {
    if (this.guest && Array.isArray(this.guest.masterRegistrationOf)) {
      return this.guest.masterRegistrationOf.length
    } else {
      return 0
    }
  }
}
