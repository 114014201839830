import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

export class CoverProperties extends PrefabPropertiesBase {
  title: string = ''
  mainText: string = ''
  textColor: string = ''

  minHeight: number = 80
}

export default abstract class CoverElementBase extends PrefabElementBase {
  abstract type: `prefab-cover-${string}`
  isFullPrefab: boolean = true

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 1080
  }

  @Type(() => CoverProperties)
  properties: CoverProperties = new CoverProperties()
}
