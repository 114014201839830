import Vue from 'vue'
import Router from 'vue-router'
import { loadLanguageAsync, BackofficeLanguage, extractPrimaryLanguageSubtag } from '@/utils/i18n'
import { handleError } from '@/utils/errorHandling'
import store from '@/store'
import { authenticationRoutes } from '@/views/authentication/routes'
import accountRoutes from '@/views/account/routes'
import eventsRoutes from '@/views/events/routes'

import Meta from 'vue-meta'
import { $enum } from 'ts-enum-util'
import { eventLoaderMiddleware } from '@/navigation/routingExtras'
import { getRoutes } from '@/navigation/featureDictionary/utils'
import type { FullRouteConfig, RouteMetaData } from '@/core'
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout.vue'
import EventLoader from '@/layouts/eventLayout/EventLoader.vue'
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout.vue'
import ComponentsList from '@/views/ComponentsList.vue'
import { featureDictionary } from '@/navigation/featureDictionary'
import { assertAccount } from '@/services/assertAccount'
import Cookies from 'js-cookie'
import { safePushToDataLayer } from '@/views/authentication/gtmTrack'
Vue.use(Router)
Vue.use(Meta)

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/events',
      component: AuthenticatedLayout,
      children: [...eventsRoutes, { path: 'components', component: ComponentsList }]
    },
    {
      // this is a route just to track login success in GTM
      // it has the advantage of being shared for all login methods
      // we then redirect to events
      path: '/loginSuccess',
      name: 'loginSuccess',
      beforeEnter: (to, from, next) => {
        const account = store.state.account.account
        try {
          const assertedAccount = assertAccount(account)
          safePushToDataLayer({ event: 'login', method: assertedAccount.authenticationMethod })
          // we use a cookie because we want to access info in www.digitevent.com too !
          Cookies.set('userId', assertedAccount._id, { domain: '.digitevent.com' })
        } catch (e) {
          console.error('account not asserted', e)
        }
        next('/')
      }
    },
    {
      path: '/account',
      component: AuthenticatedLayout,
      children: [...accountRoutes]
    },
    {
      path: '/event/:id',
      name: 'event',
      component: EventLoader,
      beforeEnter(to, from, next) {
        eventLoaderMiddleware(to, from, next)
      },
      children: getRoutes(featureDictionary) as FullRouteConfig[]
    },
    {
      path: '/',
      redirect: '/events', // Only redirect '/'. If user is unauthenticated there will be 401 and user will be redirected to /login
      name: 'unauthenticatedRoutes',
      component: UnauthenticatedLayout,
      children: [...authenticationRoutes]
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        Vue.notify({
          title: '404',
          text: "You wandered in strange places, didn't you?",
          duration: 10000
        })
        next('/')
      }
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.onError(handleError)
router.afterEach(() => store.commit('SET_LOADING', false))
router.beforeEach(async (to, from, next) => {
  store.commit('SET_LOADING', true)
  const meta = to.meta as RouteMetaData
  if (meta.auth?.allowUnauthenticated !== true) {
    await store.dispatch('ensureAccountLoaded')
  }
  try {
    const language = await getLanguage()
    await loadLanguageAsync(language)
    next()
  } catch (err: any) {
    next(err)
  }
})

async function getLanguage(): Promise<BackofficeLanguage> {
  const account = store.state.account.account
  if (account) {
    return account.language
  }

  return $enum(BackofficeLanguage).asValueOrDefault(
    extractPrimaryLanguageSubtag(navigator.language),
    BackofficeLanguage.English
  )
}
