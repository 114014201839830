












import { Component, Prop } from 'vue-property-decorator'

import { computeReadableTextColor, pickColorWithPalette } from '@/utils/colors'

import { PlainTextFragment } from '../../../fragments'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({ components: { PlainTextFragment } })
export default class PrefabCta extends Vue {
  @Prop({ required: true }) readonly cta!: CtaProperties
  @Prop() readonly noInput!: boolean

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get ctaStyle() {
    const color = pickColorWithPalette(this.cta.color, this.$store.getters.colorPalette)
    return {
      '--main-cta-color': color,
      '--text-color': computeReadableTextColor(color)
    }
  }

  get cssClasses() {
    return {
      'cta--filled': this.cta.mode === 'filled',
      'cta--outlined': this.cta.mode === 'outlined'
    }
  }
}
