






















import { Component, Prop } from 'vue-property-decorator'
import type InvitationStatus from '@/models/Guest/InvitationStatus'
import { getPossibleStatuses, getStatusColor, getStatusSlug } from '@/utils/invitationStatus'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import WithPermission from '@/components/features/WithPermission.vue'
import type Guest from '@/models/Guest'
import { updateGuestInvitationStatus } from '@/features/registration'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'

@Component({
  components: { WithPermission }
})
export default class InvitationStatusDropdown extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ type: Boolean }) readonly disabled!: boolean

  isLoading = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get statusDotColor() {
    return getStatusColor(this.guest.invitationStatus)
  }

  get label() {
    return this.$t(getStatusSlug(this.guest.invitationStatus))
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get invitationStatuses() {
    return getPossibleStatuses(this.eventRights).map((status) => ({
      code: status,
      color: getStatusColor(status),
      label: this.$t(getStatusSlug(status))
    }))
  }

  async onInvitationStatusInput(invitationStatus: InvitationStatus) {
    this.isLoading = true
    try {
      await updateGuestInvitationStatus(this.storeEvent._id, this.guest._id, invitationStatus)
      this.$emit('updated')
    } finally {
      this.isLoading = false
    }
  }
}
