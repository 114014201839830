import store from '@/store'
import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import { i18n } from '@/utils/i18n'

const acceptedMeetingRequestTriggerUtil: TriggerUtil = {
  kind: TriggerKind.AcceptedMeetingRequest as const,
  label: 'ACCEPTED_MEETING_REQUEST' as const,
  icon: 'calendar-check-line' as const,
  nominativeTags: [
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_FULLNAME'), value: '*|MEETING_TARGET_FULLNAME|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_ORGANIZATION'), value: '*|MEETING_TARGET_ORGANIZATION|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_TARGET_JOB'), value: '*|MEETING_TARGET_JOB|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_LOCATION'), value: '*|MEETING_LOCATION|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_DATETIME'), value: '*|MEETING_DATETIME|*' }
  ] as const,

  isAllowed(): boolean {
    return store.state.event?.rights.features.eventApp.matchmaking
  }
}

export default acceptedMeetingRequestTriggerUtil
