import store from '@/store'
import { i18n } from '@/utils/i18n'

export function amount(value: number, currency: string = store.getters.event.modules_data.payment.currency): string {
  if (typeof value !== 'number') return '[???]'
  else
    return new Intl.NumberFormat(i18n.i18next.language, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0
    }).format(value)
}
