













import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { VueTelInput } from 'vue-tel-input'
import type PhoneCustomField from '@/models/Event/CustomField/fieldTypes/Phone'
import { isNil } from 'lodash'

@Component({
  components: { VueTelInput }
})
export default class GuestFieldPhoneInput extends Vue {
  @VModel() fieldValue!: string | null
  @Prop({ required: true }) readonly field!: PhoneCustomField
  isPhoneValid = true

  handlePhoneValidity(payload: Record<string, unknown>): void {
    if (typeof payload.valid === 'boolean') {
      this.isPhoneValid = payload.valid
    } else {
      this.isPhoneValid = !this.fieldValue
    }
    this.$emit('update-validity', this.isPhoneValid)
  }

  get phoneFieldDefaultCountry(): string {
    if (this.field && this.field.type === 'phone') {
      return this.field.preferedCountry
    }
    return ''
  }

  get fieldClass() {
    if (isNil(this.fieldValue)) {
      return null
    }
    return !this.isPhoneValid ? 'invalid' : null
  }

  onInput(newValue: string, phoneObject: Record<string, unknown>) {
    if (phoneObject.valid) {
      this.fieldValue = phoneObject.number as string
    } else if (!newValue) {
      this.fieldValue = null
    }
  }

  mounted() {
    this.$emit('update-validity', this.isPhoneValid)
  }
}
