import type { Module } from 'vuex'
import { cloneDeep } from 'lodash'
import { plainToClass, classToPlain } from 'class-transformer'

import type { SaveEventPatchesResult } from '@/services/events'
import { getEventById, saveEvent } from '@/services/events'
import { getGuestFieldsWithCustomFieldOrder } from '@/services/guestFields'

import messagesModule from './modules/messages'
import exportsModule from './modules/exports'
import rulesModule from './modules/rules'
import formsModule from './modules/forms'
import programmeModule from './modules/programme'
import paymentModule from './modules/payment'

import type { GuestFieldType } from '@/models/Event/GuestField'
import type { GuestField } from '@/models/Event/GuestField'
import type { CustomFieldId } from '@/models/Event/CustomField'
import type CustomField from '@/models/Event/CustomField'
import { CustomFieldType } from '@/models/Event/CustomField'
import type { FilterId } from '@/models/Event/Filter'
import type Filter from '@/models/Event/Filter'

import { Event } from '@/models/Event'

import axios from 'axios'
import { getSiteUrl } from '@/utils/customDomainUtils'

import type { StoreState } from '@/store'
import type { EventRights } from '@/models/Event/EventRights'
import { getEventRights } from '@/services/eventRights'
import type { Addon, Ticket } from '@/models/Event/modules/Payment'

export interface EventState {
  rawEvent: Record<string, any> | null
  event: Event | null
  loading: boolean
  dashboardStats: unknown
  rights: EventRights | null
}

const storeModule: Module<EventState, StoreState> = {
  state: {
    // rawEvent exists to keep a trace of the event state before it was touched by class-transformer
    // It helps to properly log any side-effect class-transformer might've generated, and properly save them
    // If you're confused about this property, see the saveEvent action, or just ask around
    // Don't make the same mistake that was done once before and blindly delete it
    rawEvent: null,
    event: null,
    loading: false,
    dashboardStats: null,
    rights: null
  },
  getters: {
    hasEvent(state): boolean {
      return Boolean(state.event)
    },
    event(state): Event | null {
      return state.event
    },
    eventId(state): string | undefined {
      return state.event?._id
    },
    isPaidEvent(state): boolean {
      return state.rights?.features.registration.mode === 'paid'
    },
    siteUrl(state): string | null {
      if (state.event && state.rights) {
        return getSiteUrl({ event: state.event, eventRights: state.rights })
      } else return null
    },
    guestFieldKeyMap(state): Record<string, GuestField> {
      if (state.event) {
        return state.event.guestFields.reduce<Record<string, GuestField>>((result, field) => {
          result[field.key] = field
          return result
        }, {})
      } else {
        return {}
      }
    },
    orderedGuestFields(state): GuestField[] {
      if (state.event) return getGuestFieldsWithCustomFieldOrder(state.event)
      else return []
    },
    customFieldIdMap(state): Record<string, CustomField> {
      if (state.event) {
        return state.event.customFields.reduce<Record<CustomFieldId, CustomField>>((result, field) => {
          result[field._id] = field
          return result
        }, {})
      } else return {}
    },
    filterIdMap(state): Record<string, Filter> {
      if (state.event) {
        return state.event.filters.reduce<Record<FilterId, Filter>>((result, filter) => {
          result[filter._id] = filter
          return result
        }, {})
      } else return {}
    },
    exportFriendlyFields(state: EventState, getters) {
      if (state.event) {
        const exportFriendlyLegacyTypes: GuestFieldType[] = [
          CustomFieldType.Text,
          CustomFieldType.Email,
          CustomFieldType.Phone,
          CustomFieldType.File
        ]

        return getters.orderedGuestFields.filter(
          (field: GuestField) => field.origin === 'custom' || exportFriendlyLegacyTypes.includes(field.type)
        )
      } else return []
    },
    tryFilterById(state): (id: FilterId) => Filter {
      return (id: FilterId) => {
        if (!state.event) throw new Error('Tried to fetch filter while no event is loaded')
        else return state.event.getFilterById(id)
      }
    },
    ticketsMap(state): Record<string, Ticket> {
      if (state.rights?.features.registration.mode === 'paid') {
        const ticketsMap: Record<string, Ticket> = {}
        for (const ticket of state.event?.modules_data.payment.tickets ?? []) {
          ticketsMap[ticket._id] = ticket
        }

        return ticketsMap
      }

      return {}
    },
    addonsMap(state): Record<string, Addon> {
      if (state.rights?.features.registration.mode === 'paid') {
        const addonsMap: Record<string, Addon> = {}

        for (const addon of state.event?.modules_data.payment.addons ?? []) {
          addonsMap[addon._id] = addon
        }

        return addonsMap
      }

      return {}
    },
    addonsByTicket(state): Record<string, string[]> {
      if (state.rights?.features.registration.mode === 'paid') {
        const addonsByTicket: Record<string, string[]> = {}
        for (const ticket of state.event?.modules_data.payment.tickets ?? []) {
          addonsByTicket[ticket._id] =
            state.event?.modules_data.payment.addons
              .filter((addon) => addon.affiliatedTickets.includes(ticket._id) && addon.enable)
              .map((addon) => addon._id) ?? []
        }

        return addonsByTicket
      }

      return {}
    }
  },
  actions: {
    setEvent({ commit }, newEvent: Event) {
      commit('SET_EVENT', newEvent)
      return newEvent
    },
    async loadEventById({ state: { event }, commit, dispatch }, payload): Promise<Event> {
      payload = {
        id: null,
        skipCache: false,
        noHTTPCache: false,
        ...payload
      }
      if (!payload.skipCache && event && event._id === payload.id) {
        return Promise.resolve(event)
      }
      const newEvent = await getEventById(payload.id, payload.noHTTPCache)
      await dispatch('getEventRights', payload.id)
      commit('SET_EVENT', newEvent)
      commit('SET_DASHBOARD_STATS', null)
      return newEvent
    },
    reloadCurrentEvent({ state: { event }, dispatch }): Promise<unknown> {
      if (event) {
        return dispatch('loadEventById', { id: event._id, skipCache: true, noHTTPCache: true })
      }

      throw new Error('Cannot reload current event: There is no current event.')
    },

    async deleteEventCheckinPoint({ state: { event }, commit }, checkinPointId): Promise<void> {
      if (!event) throw new Error('Cannot delete checkin point: No event loaded.')

      const { data: updatedEvent } = await axios.delete<Event>(
        `backoffice/events/${event._id}/checkinPoint/${checkinPointId}`
      )

      commit('SET_EVENT', updatedEvent)
    },
    async saveEvent(store, event): Promise<SaveEventPatchesResult> {
      if (!store.state.rawEvent) {
        throw new Error('Cannot request event save while store has no event.')
      }

      const res = await saveEvent(store.state.rawEvent, classToPlain(event))
      if ('newEvent' in res) {
        // @TODO: Maybe apply patches instead? Since we have them?
        store.commit('SET_EVENT', res.newEvent)
      }
      return res
    },
    postEventMutation(store, mutator): Promise<unknown> {
      const result = mutator(cloneDeep(store.state.event))

      if (result === undefined) {
        throw new Error('Mutator does not return modified event!')
      }

      return store.dispatch('saveEvent', result)
    },
    async getEventRights({ commit }, eventId: string) {
      const eventRights = await getEventRights(eventId)
      commit('SET_EVENT_RIGHTS', eventRights)
    }
  },
  mutations: {
    SET_EVENT(state, event): void {
      if (!event) {
        throw new Error('Mutation SET_EVENT did not receive an event.')
      }

      if (event instanceof Event) {
        state.event = event
        state.rawEvent = classToPlain(event)
      } else {
        state.rawEvent = event
        state.event = plainToClass(Event, event)
      }
    },
    REMOVE_EVENT(state): void {
      state.event = null
      state.rawEvent = null
    },
    SET_DASHBOARD_STATS(state, stats): void {
      state.dashboardStats = stats
    },
    SET_EVENT_RIGHTS(state, eventRights: EventRights) {
      state.rights = eventRights
    }
  },
  modules: { paymentModule, rulesModule, messagesModule, exportsModule, forms: formsModule, programmeModule }
}

export default storeModule
