




import { Component, Vue } from 'vue-property-decorator'
import HeaderNavItem from '@/layouts/components/Header/components/HeaderNavItem.vue'
import type { Account } from '@/models/Account'
import { DIGITEVENT_DOCUMENTATION_LINK } from './support'

@Component({
  components: {
    HeaderNavItem
  }
})
export default class DocumentationLink extends Vue {
  documentationLink = DIGITEVENT_DOCUMENTATION_LINK

  get isFrenchLanguageAccount(): boolean {
    const account = this.$store.state.account.account as Account
    return account.language === 'fr'
  }
}
