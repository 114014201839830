import type { PageElementId } from './builder/types'
import type { PageElement } from './builder/types'
import type { ColorPalette } from '@/models/Event/Identity/ColorPalette'
import type { Properties as CssProperties } from 'csstype'

export function generateIdForElement(element: PageElement): PageElementId {
  return `${new Date().valueOf()}_${Math.random().toString(36).substr(2, 9)}-${element.type}`
}

type PaddingOrMargin = 'padding' | 'margin'

interface GetStyleFromBoxOptions {
  palette?: ColorPalette
  paddingOrMargin?: PaddingOrMargin
}

export function getStyleObjectFromBoxProperties(
  box: PageElement['box'],
  colorPalette: ColorPalette,
  options: GetStyleFromBoxOptions = {}
): CssProperties {
  if (!box) return {}

  const palette: ColorPalette = options.palette ?? colorPalette

  const fullOptions: Required<GetStyleFromBoxOptions> = {
    palette,
    paddingOrMargin: options.paddingOrMargin ?? 'padding'
  }
  const result: CssProperties = {
    [fullOptions.paddingOrMargin]: box.paddingPx?.map((n) => `${n}px`).join(' ')
  }

  return result
}
