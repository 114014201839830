













import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { getFormById } from '@/features/surveys'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileSurveysTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get submittedFormsCount(): number {
    if (!this.guest.submittedForms) return 0
    let result = 0
    this.guest.submittedForms.forEach((submittedForm) => {
      const form = getFormById(this.storeEvent.forms, submittedForm.formId)
      if (!form) throw new Error(`Form ${submittedForm.formId} not found`)
      if (!form.isRegistration) result++
    })
    return result
  }
}
