













import type { EventRights } from '@/models/Event/EventRights'
import { Component, Prop } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { isAllowedToCreateNewSegment } from '@/features/audience/segments/services'

@Component
export default class SegmentSelectorEditButtons extends Vue {
  @Prop({ required: true }) readonly eventRights!: EventRights

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get isAllowedToCreateNewSegment(): boolean {
    return isAllowedToCreateNewSegment(this.storeEvent.filters.length, this.eventRights)
  }
}
