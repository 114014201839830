import store from '@/store'
import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import { i18n } from '@/utils/i18n'

const targetReceivesMeetingRequestTriggerUtil: TriggerUtil = {
  kind: TriggerKind.TargetReceivesMeetingRequest as const,
  label: 'TARGET_RECEIVES_MEETING_REQUEST' as const,
  icon: 'chat-history-line' as const,
  nominativeTags: [
    { title: () => i18n.t('TAG_NAME_MEETING_APPLICANT_FULLNAME'), value: '*|MEETING_APPLICANT_FULLNAME|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_APPLICANT_ORGANIZATION'), value: '*|MEETING_APPLICANT_ORGANIZATION|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_APPLICANT_JOB'), value: '*|MEETING_APPLICANT_JOB|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_APPLICANT_MOTIVATION'), value: '*|MEETING_APPLICANT_MOTIVATION|*' }
  ] as const,
  isAllowed(): boolean {
    return store.state.event?.rights.features.eventApp.matchmaking
  }
}

export default targetReceivesMeetingRequestTriggerUtil
