









































import { Component, Watch } from 'vue-property-decorator'
import type { Route, RouteRecord } from 'vue-router'
import { EventMenu } from '@/navigation/eventMenu'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import DigiHeader from '@/layouts/components/Header/DigiHeader.vue'
import MobileNav from '@/layouts/components/MobileNav/MobileNav.vue'
import MobileNavItem from '@/layouts/components/MobileNav/MobileNavItem.vue'
import HeaderEventAbstract from '@/layouts/eventLayout/parts/HeaderEventAbstract.vue'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import PremiumSupportCta from '@/layouts/components/Header/components/PremiumSupportCta.vue'
import EventPageLayout from './parts/EventPageLayout/index.vue'
import ToggleEventMenuButton from '@/layouts/eventLayout/parts/ToggleEventMenuButton.vue'
import { DigiSpinner } from '@/components/ui'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import type { EventIdentity } from '@/core'
import { SupportRequestPanel } from '@/features/support'

@Component({
  components: {
    DigiSpinner,
    ToggleEventMenuButton,
    PremiumSupportCta,
    DigiSimpleIconButton,
    HeaderEventAbstract,
    MobileNavItem,
    MobileNav,
    DigiHeader,
    EventMenu,
    ProductChangesPanel: () => import('@/layouts/components/ProductChangesPanel/index.vue'),
    EventPageLayout,
    SupportRequestPanel
  }
})
export default class EventLayout extends Vue {
  isNavOpen = false
  isEventMenuOpen = true

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get loading(): boolean {
    return Boolean(this.$store.getters.isLoading)
  }

  get eventName(): string {
    const eventIdentity: EventIdentity = this.$store.state.eventIdentity
    return eventIdentity.name
  }

  toggleNav(isOpen: boolean) {
    this.isNavOpen = isOpen
  }

  toggleEventMenu() {
    this.isEventMenuOpen = !this.isEventMenuOpen
  }

  @Watch('$route')
  onRouteChanged() {
    this.isNavOpen = false
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get hasPremiumSupport(): boolean {
    return this.eventRights.services.nbOfCsMeetings > 0
  }

  get matchedRouteWithWrappingRenderingData(): RouteRecord | Route {
    for (let i = this.$route.matched.length - 1; i > 0; i--) {
      const route = this.$route.matched[i]! // This component would theoretically not be rendered if no route was matched
      if (route.meta?.renderingData?.doNotWrap) {
        return route
      }
    }
    return this.$route
  }

  get shouldShowEventMenu(): boolean {
    return !this.matchedRouteWithWrappingRenderingData.meta?.renderingData?.hideEventMenu
  }

  get wrapperElement(): string {
    return this.matchedRouteWithWrappingRenderingData.meta?.renderingData?.doNotWrap ? 'div' : 'EventPageLayout'
  }

  get cssClasses() {
    return {
      'event-layout--menu-close': !this.isEventMenuOpen,
      'event-layout--menu-hidden': !this.shouldShowEventMenu
    }
  }

  created() {
    this.$root.$on('eventmenu::close', () => {
      this.isEventMenuOpen = false
    })
  }
}
