type ParsedFormItemLabel = {
  label: string
  description: string | null
  helpLink: string | null
}

export function parseFormItemLabel(label: string): ParsedFormItemLabel {
  const regex = /(?<label>[^#?]+)(?:##(?<description>[^?]+))?(?:\?\?(?<link>.+))?/g
  const matches = regex.exec(label)
  if (!matches || !matches.groups || !matches.groups.label) return { label, description: null, helpLink: null }
  return {
    label: matches.groups.label,
    description: matches.groups.description || null,
    helpLink: matches.groups.link || null
  }
}
