import { validateEmail } from '@/utils/validation'
import type { AnyAction, SendEmailToAddressAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import type { ActionUtil } from '../../../RuleEdition/types'
import Settings from './Settings.vue'

function isSendEmailToAdressAction(action: AnyAction): action is SendEmailToAddressAction {
  if (action.kind !== ActionKind.SendEmailToAddress) {
    throw new Error('SendEmailToAdressActionUtil can only manage action of kind SendEmailToAdress')
  }

  return true
}

const sendEmailToAddressActionUtil: ActionUtil = {
  kind: ActionKind.SendEmailToAddress as const,
  label: 'NOTIFY_EMAIL_ADDRESS' as const,
  icon: 'mail-line' as const,
  settings: Settings,

  validator(action: AnyAction): boolean {
    if (isSendEmailToAdressAction(action)) {
      const isSubjectValid = action.subject?.length <= 78
      const isFromNameValid = action.fromName?.length <= 78
      return (
        Boolean(action.emailAddress && action.emailContent) &&
        isFromNameValid &&
        isSubjectValid &&
        validateEmail(action.emailAddress)
      )
    } else {
      return false
    }
  }
}

export default sendEmailToAddressActionUtil
