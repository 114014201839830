import Meeting from './ScheduledItem/Meeting'

abstract class UnscheduledMeetingBase extends Meeting {
  abstract status: string
}

export enum UnscheduledMeetingStatus {
  PendingAcceptation = 'pendingAcceptation',
  Declined = 'declined'
}

export class PendingMeeting extends UnscheduledMeetingBase {
  status = UnscheduledMeetingStatus.PendingAcceptation as const
}

export class DeclinedMeeting extends UnscheduledMeetingBase {
  status = UnscheduledMeetingStatus.Declined as const
  declineReason!: string
}

export type UnscheduledMeeting = PendingMeeting | DeclinedMeeting
