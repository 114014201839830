import { ElementTypeDictionary } from '../../types'
import Text1Element from './text1/Text1Element'
import Text2Element from './text2/Text2Element'
import Text3Element from './text3/Text3Element'
import { Text4Element } from './text4/Text4Element'
import Text5Element from './text5/Text5Element'

export const textDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Text2Element,
    renderer: () => import('./text2/Text2.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_2',
    previewImageLink: require('@/assets/img/prefab-previews/text2.jpg')
  })
  .addType({
    dataClass: Text3Element,
    renderer: () => import('./text3/Text3.vue'),
    properties: [{ type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_3',
    previewImageLink: require('@/assets/img/prefab-previews/text3.jpg')
  })
  .addType({
    dataClass: Text1Element,
    renderer: () => import('./text1/Text1.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_1',
    previewImageLink: require('@/assets/img/prefab-previews/text1.jpg')
  })
  .addType({
    dataClass: Text5Element,
    renderer: () => import('./text5/Text5.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_5',
    previewImageLink: require('@/assets/img/prefab-previews/text5.jpg')
  })
  .addType({
    dataClass: Text4Element,
    renderer: () => import('./text4/PrefabText4.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titlesColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_4',
    previewImageLink: require('@/assets/img/prefab-previews/text4.jpg')
  })
  .finish()
