import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import SponsorsElementBase, { SponsorsProperties } from '../SponsorsElementBase'
import type { CtaMode } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class Sponsors2Properties extends SponsorsProperties {
  title: string = ''
  textColor: string = ''
  ctaColor: string = ''
  ctaMode: CtaMode = 'filled'
  displayAvatarInGrayscale: boolean = false
}

export default class Sponsors2Element extends SponsorsElementBase {
  static create({ palette }: ElementCreationContext): Sponsors2Element {
    const sponsors = new Sponsors2Element()

    sponsors.properties.title = i18n.t('OUR_SPONSORS')
    sponsors.properties.textColor = palette.darkestColor.paletteCode
    sponsors.properties.ctaColor = palette.darkestColor.paletteCode
    sponsors.properties.displayAvatarInGrayscale = false

    return sponsors
  }

  type = `prefab-sponsors-2` as const

  @Type(() => Sponsors2Properties)
  properties: Sponsors2Properties = new Sponsors2Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SPONSORS')
  }
}
