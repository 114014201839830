



















import { Component } from 'vue-property-decorator'
import ContactEditForm from './ContactForm.vue'
import type Guest from '@/models/Guest'
import InvitationStatus from '@/models/Guest/InvitationStatus'
import ContactForm from './ContactForm.vue'
import { createGuestAndNotify } from './service'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactForm, ContactEditForm }
})
export default class ContactCreationModal extends Vue {
  isOpen = false
  guest: Partial<Guest> = {}
  isDuplicateEmail = false
  isSubmitting = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  async save(newGuest: Partial<Guest>): Promise<void> {
    this.isSubmitting = true
    try {
      await createGuestAndNotify(newGuest, this.storeEvent._id)
      this.onCreated()
    } catch (err: any) {
      if (err.isAxiosError && err.response?.data.slug === 'SERVERR_DUPLICATE_EMAIL') {
        this.isDuplicateEmail = true
      } else throw err
    } finally {
      this.isSubmitting = false
    }
  }

  show() {
    this.isOpen = true
  }

  hide() {
    this.isOpen = false
  }

  onCreated() {
    this.$emit('created')
    this.hide()
  }

  mounted() {
    if (this.isPaidEvent) {
      this.guest.invitationStatus ??= InvitationStatus.NoStatus
    }
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get isPaidEvent(): boolean {
    return this.eventRights.features.registration.mode === 'paid'
  }
}
