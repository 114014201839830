import type { LeadQualificationCriterion } from '@/models/Event/modules/Networking'
import type Guest from '@/models/Guest'

export function findQualificationCriterionById(
  id: string,
  criteria: LeadQualificationCriterion[]
): LeadQualificationCriterion | undefined {
  return criteria.find((criterion) => criterion._id === id)
}

export function getLeadExportUrl({ eventId, guestId }: { eventId: string; guestId: string }): string {
  return `${process.env.VUE_APP_API_URL}/events/${eventId}/exportLeadsToXls/guests/${guestId}.xls`
}

export function getLeadsCount(guest: Guest): number {
  if (!guest.networking) return 0
  return guest.networking.scannedGuests?.length || 0
}
