



















import { Vue, Component, Prop } from 'vue-property-decorator'
import ListCardLayout from '@/DigiCard.vue'
import { DigiButton } from '@/components/ui/actions'

@Component({ components: { DigiButton, ListCardLayout } })
export default class DigiFormLayout2Actions extends Vue {
  @Prop({ default: false }) readonly saveDisabled!: boolean
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly isLoading!: boolean
  @Prop({ default: '' }) readonly submitBtnLabel!: string
}
