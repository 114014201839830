import type { LocalizedPrimitive } from '@/models/Event/Localized'
import axios from 'axios'

export async function updateRegistrationCtaSettings(
  eventId: string,
  ctaSettings: { registrationCtaBtnLabel: LocalizedPrimitive<string>; showHeaderRegistrationCta: boolean }
) {
  await axios.put(`/events/${eventId}/website/registrationCtaSettings`, {
    registrationCtaSettings: ctaSettings
  })
}
