








import { Component, Prop, Vue } from 'vue-property-decorator'
import { ContactAdditionalAttendeesTable } from '@/features/registration/multiRegistration'
import type Guest from '@/models/Guest'
import { InvitationStatusDropdown } from '@/features/registration'

@Component({
  components: { InvitationStatusDropdown, ContactAdditionalAttendeesTable }
})
export default class MultiRegistrationMainAttendeeSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
}
