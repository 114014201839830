




















import { ContactProfileModal } from '@/features/audience/contactProfile'
import type Guest from '@/models/Guest'
import { joinIfExist } from '@/utils/string'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({ components: { ContactProfileModal } })
export default class SpeakerCard extends Vue {
  @Prop({ required: true }) readonly speaker!: Guest

  @Ref('contactProfileModal') readonly contactProfileModal!: ContactProfileModal

  joinIfExist(elements: (string | undefined)[], separator?: string) {
    return joinIfExist(elements, separator)
  }

  openProfileModal() {
    this.contactProfileModal.loadGuestFromIdAndOpenModal(this.speaker._id)
  }
}
