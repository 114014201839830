























import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiIconButton }
})
export default class MultiSelectorHeading extends Vue {
  @Prop({ required: true }) readonly titleRemixIcon!: string
  @Prop({ required: true }) readonly buttonRemixIcon!: string
  @Prop({ required: true }) readonly buttonLabel!: string
  @Prop({ required: true }) readonly buttonDisabled!: boolean
}
