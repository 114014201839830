









import { Vue, Component } from 'vue-property-decorator'
import { DigiSpinner } from '@/components/ui'

@Component({
  components: { DigiSpinner }
})
export default class UnauthenticatedLayout extends Vue {
  get loading(): boolean {
    return Boolean(this.$store.getters.isLoading)
  }
}
