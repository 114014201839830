import type { Sort } from '@/models/commons/sort'
import type { PDFBadgeVariant } from '@/models/Event/modules/PDFBadge'

export class PDFBadgeStore {
  private static instance: PDFBadgeStore | null = null
  public variants: PDFBadgeVariant[] = []
  public sort: Sort = []
  public isLoading: boolean = false
  private _activeVariantId: string | null = null
  private _hasUnsavedModifications: boolean = false

  private constructor() {}

  public static init(): void {
    if (this.instance) {
      return
    }
    this.instance = new PDFBadgeStore()
  }

  public static getInstance(): PDFBadgeStore {
    if (!this.instance) {
      throw new Error('PDF badge store not initialized')
    }
    return this.instance
  }

  static destroyInstance(): void {
    this.instance = null
  }

  public get activeVariantId(): string | null {
    return this._activeVariantId
  }

  public get hasUnsavedModifications(): boolean {
    return this._hasUnsavedModifications
  }

  public setActiveVariantId(id: string | null): void {
    this._activeVariantId = id
  }

  public setHasUnsavedModifications(value: boolean): void {
    this._hasUnsavedModifications = value
  }
}
