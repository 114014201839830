import type { EventId } from '@/models/Event'
import type { Event } from '@/models/Event'
import type { FormId } from '@/models/Event/Form'
import type { Operation } from 'fast-json-patch'
import { i18n } from '@/utils/i18n'
import Vue from 'vue'
import axios from 'axios'

export async function deleteOne(formId: FormId, eventId: EventId): Promise<Event> {
  const res = await axios.delete<Event>(`/backoffice/events/${eventId}/forms/${formId}`)
  Vue.notify({
    text: i18n.t('MODIFICATIONS_SAVED'),
    type: 'success'
  })
  return res.data
}

export async function patchElements(patch: Operation[], formId: FormId, eventId: EventId): Promise<Event> {
  const res = await axios.patch<Event>(`/backoffice/events/${eventId}/forms/${formId}/elements`, patch)
  Vue.notify({
    text: i18n.t('MODIFICATIONS_SAVED'),
    type: 'success'
  })
  return res.data
}
