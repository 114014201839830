
















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import type { ColorVariant, Size } from './types'

@Component
export default class DigiButton extends Vue {
  @Prop({ default: 'primary' }) readonly variant!: ColorVariant
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly isLoading!: boolean
  @Prop({ default: 'md' }) readonly size!: Size
  @Prop({ default: 'button' }) readonly type!: 'button' | 'submit' | 'reset'
  @Prop({ default: false }) readonly block!: boolean
  @Prop({ default: 0 }) readonly tabindex!: number
  @Prop({ default: '' }) readonly form!: string

  @Ref() readonly button!: HTMLButtonElement

  onClick(): void {
    this.$emit('click')
  }

  get cssClasses() {
    return {
      [`digi-btn--${this.variant}`]: true,
      [`digi-btn--${this.size}`]: true,
      'digi-btn--block': this.block
    }
  }

  focus(): void {
    this.button.focus()
  }
}
