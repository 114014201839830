




































import type { BModal } from 'bootstrap-vue'
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import VideoUploader from './VideoUploader.vue'

@Component({
  components: {
    VideoUploader
  }
})
export default class AddVideoModal extends Vue {
  @Prop({ type: Boolean }) readonly needLabel!: boolean
  @Ref() readonly modal!: BModal

  private codeId: string = ''
  private codeIdState: boolean | null = null
  private name: string = ''
  private label: string = ''

  get labelState(): boolean | null {
    return this.label == '' ? false : true
  }

  get videoLabel(): string {
    return this.label
  }

  set videoLabel(value: string) {
    this.label = value
  }

  get platformName(): string {
    return this.name
  }

  set platformName(value: string) {
    this.name = value
  }

  get videoCodeId(): string {
    return this.codeId
  }

  set videoCodeId(value: string) {
    this.codeId = value
  }

  get videoCodeState(): boolean | null {
    return this.codeIdState
  }

  set videoCodeState(value: boolean | null) {
    this.codeIdState = value
  }

  get canAddVideo(): boolean {
    if (this.needLabel) {
      return Boolean(this.labelState) && Boolean(this.codeIdState)
    }
    return Boolean(this.codeIdState)
  }

  reset(): void {
    this.codeId = ''
    this.codeIdState = null
    this.name = ''
    this.label = ''
  }

  finishAdding(): void {
    this.$emit('add', {
      videoCode: this.codeId,
      videoPlatform: this.name,
      label: this.label
    })
  }

  show(): void {
    this.modal.show()
  }

  hide(): void {
    this.modal.hide()
  }
}
