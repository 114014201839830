







import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class DigiBurgerMenu extends Vue {
  @Prop({ default: false }) readonly forceOpen!: boolean
  isOpen: boolean = false

  @Watch('forceOpen')
  onForceOpenChanged() {
    this.isOpen = this.forceOpen
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen
    this.$emit('toggle', this.isOpen)
  }

  get classes() {
    return {
      'burger-menu--open': this.isOpen
    }
  }
}
