import type { LinkDestination } from '@/features/website/builder/builder/customBlocks/helperTypes'

export type CtaMode = 'filled' | 'outlined'

export interface CtaProperties {
  label: string
  color: string
  destination: LinkDestination
  mode: CtaMode
}

export function defaultCtaProperties(): CtaProperties {
  return {
    label: '',
    color: '',
    destination: {
      type: 'maincta'
    },
    mode: 'filled'
  }
}
