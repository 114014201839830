






import { Component, Prop } from 'vue-property-decorator'
import { timezoneFormat } from '@/lib/timezoneFormat'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'
import type { NamedSubmittedForm } from './type'
import SubmittedSurveyItem from './components/SubmittedSurveyItem.vue'
import { getFormById } from '@/features/surveys'
import DigiVerticalMenuSub from '@/components/ui/verticalMenu/parts/DigiVerticalMenuSub.vue'
import { DigiIconLink } from '@/components/ui/actions'
import type Guest from '@/models/Guest'
import type { EventIdentity } from '@/core'

@Component({
  components: { DigiIconLink, DigiVerticalMenuSub, SubmittedSurveyItem }
})
export default class ContactSurveys extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get forms(): NamedSubmittedForm[] {
    if (!this.guest.submittedForms) return []
    const surveys = this.guest.submittedForms?.map((submittedForm) => {
      const form = getFormById(this.storeEvent.forms, submittedForm.formId)
      if (!form) throw new Error(`Form ${submittedForm.formId} not found`)
      if (form.isRegistration) return undefined
      const eventIdentity: EventIdentity = this.$store.state.eventIdentity
      return {
        formName: form.getLocalizedName(this.storeEvent.mainLanguageCode),
        formId: form._id,
        userAgent: submittedForm.userAgent,
        formattedDate: timezoneFormat(submittedForm.lastSubmissionDate, eventIdentity.timezone, 'DD/MM/YYYY - HH:mm')
      }
    })
    return surveys.filter((survey) => survey !== undefined) as NamedSubmittedForm[]
  }
}
