




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DigiSpinner extends Vue {
  @Prop({ default: 'md', validator: (value: string) => ['sm', 'md', 'lg'].includes(value) })
  readonly size!: string

  @Prop({ default: 'dark', validator: (value: string) => ['dark', 'light', 'white'].includes(value) })
  readonly variant!: string
}
