import type { FullRouteConfig } from '@/core'
import type { RouteMetaData } from '@/core'
import { UnauthenticatedError } from '@/utils/errorTypes'
import type { AxiosError, AxiosInstance } from 'axios'
import type VueRouter from 'vue-router'

export const authenticationRoutes: FullRouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    meta: { auth: { allowUnauthenticated: true, allowUnverifiedEmail: true } },
    component: () => import('./Login/Login.vue')
  },
  {
    path: '/login/sso',
    name: 'login.sso',
    meta: { auth: { allowUnauthenticated: true, allowUnverifiedEmail: true } },
    component: () => import('./LoginSSO/LoginSSO.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: { auth: { allowUnauthenticated: true, allowUnverifiedEmail: true } },
    component: () => import('./SignUp/SignUp.vue')
  },
  {
    path: '/passwordResetRequest',
    name: 'pwdresetrequest',
    meta: { auth: { allowUnauthenticated: true, allowUnverifiedEmail: true } },
    component: () => import('./PasswordResetRequest/PasswordResetRequest.vue')
  },
  {
    path: '/passwordReset',
    name: 'pwdreset',
    meta: { auth: { allowUnauthenticated: true, allowUnverifiedEmail: true } },
    component: () => import('./PasswordReset/PasswordReset.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./Logout/Logout.vue'),
    meta: {
      auth: { allowUnverifiedEmail: true },
      renderingData: {
        noHeader: true
      }
    }
  },
  {
    path: '/emailVerification',
    name: 'emailVerification',
    component: () => import('./EmailVerification/EmailVerification.vue'),
    meta: {
      auth: { allowUnverifiedEmail: true },
      renderingData: {
        noHeader: true
      }
    }
  }
]

export function setupAxiosRedirectToLoginOnUnauthorizedRequests(instance: AxiosInstance, router: VueRouter) {
  function handleUnauthorizedError(error: AxiosError) {
    const currentMeta = router.currentRoute.meta as RouteMetaData | undefined
    if (isEmailNotVerifiedError(error)) {
      if (currentMeta?.auth?.allowUnverifiedEmail !== true) {
        console.warn('Received 401 error, redirecting to email verification', error)
        router.push({ name: 'emailVerification' }).catch(console.warn)
      }
      return Promise.reject(new UnauthenticatedError('emailNotVerified'))
    }
    if (currentMeta?.auth?.allowUnauthenticated !== true) {
      console.warn('Received 401 error, redirecting to login', error)
      router.push({ name: 'login' }).catch(console.warn)
    }
    return Promise.reject(new UnauthenticatedError('loggedOut'))
  }

  instance.interceptors.response.use(
    (response: any) => response,
    (error) => {
      if (isUnauthorizedError(error)) {
        return handleUnauthorizedError(error)
      }
      return Promise.reject(error)
    }
  )
}

export function isUnauthorizedError(error: AxiosError | any): boolean {
  return error?.response?.status === 401
}

export function isEmailNotVerifiedError(error: AxiosError | any): boolean {
  return error?.response?.status === 401 && error?.response?.data?.slug === 'SERVERR_EMAIL_NOT_VERIFIED'
}
