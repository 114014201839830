import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export const sponsorsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'sponsors',
    name: 'event.website.sponsors',
    component: () => import('./views/list/SponsorsListView.vue'),
    meta: {
      name: 'SPONSORS',
      iconName: 'award-line',
      keywords: 'FACTORY_SPONSORS_KEYWORDS',
      hideOnInactiveFeatures: true,
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      },

      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.programme.sponsors.length
        }
      }
    }
  },

  {
    path: 'sponsors/:sponsorId',
    name: 'event.website.sponsors.edition',
    component: () => import('./views/edit/SponsorEditionView.vue'),
    meta: {
      subPageOf: 'event.website.sponsors'
    }
  }
]
