

















import { Vue, Component } from 'vue-property-decorator'
import type { RouteMetaData } from '@/core'
import type { Route } from 'vue-router'

@Component({
  components: {
    BackButton: () => import('@/components/features/BackButton.vue'),
    EventPageHeader: () => import('@/layouts/eventLayout/parts/EventPageLayout/parts/EventPageHeader.vue'),
    SidePanelContainer: () => import('@/layouts/components/sidePanel/SidePanelContainer/SidePanelContainer.vue')
  }
})
export default class EventPageLayout extends Vue {
  get cssClasses() {
    return {
      'event-page-layout--full-width': this.isFullWidth,
      'event-page-layout--limited-width': !this.isFullWidth,
      'event-page-layout--builder-container': this.$route.name === 'website.websiteBuilder.page'
    }
  }

  get pageMeta(): RouteMetaData {
    return this.$route.meta!
  }

  get showPageHeader(): boolean {
    return this.pageMeta.renderingData?.shouldDisplayPageHeader ?? Boolean(this.pageMeta.name)
  }

  get parentRouteName(): Route['name'] {
    return this.pageMeta.subPageOf
  }

  get isSubPage(): boolean {
    return Boolean(this.parentRouteName)
  }

  get parentPageName(): string | null {
    if (this.parentRouteName) {
      const { route } = this.$router.resolve({ name: this.parentRouteName })
      return route.meta?.name
    }
    return null
  }

  get isFullWidth(): boolean {
    return Boolean(this.pageMeta?.renderingData?.isContentFullWidth)
  }
}
