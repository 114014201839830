import { contactListRoutes } from './contactList/routes'
import { guestFieldsRoutes } from './guestFields/routes'
import { importRoutes } from './import/routes'
import { exportRoutes } from './exports/routes'
import { segmentRoutes } from './segments/routes'
import type { ReadonlyFullRouteConfig } from '@/core'

export const audienceRoutes: ReadonlyFullRouteConfig = [
  ...contactListRoutes,
  ...guestFieldsRoutes,
  ...segmentRoutes,
  ...importRoutes,
  ...exportRoutes
] as const
