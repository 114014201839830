













import { VideoPlatform } from '../../types/helperTypes'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class VideoIdInput extends Vue {
  @Prop() readonly videoPlatforms!: Record<VideoPlatform, { logoPath: string; validator: (value: string) => boolean }>
  @Prop() readonly chosenPlatform!: VideoPlatform

  tempVideoCode: string | null = null

  get codeVideoState(): boolean | null {
    return !this.tempVideoCode ? null : this.videoPlatforms[this.chosenPlatform].validator(this.tempVideoCode)
  }

  get videoCodeLabel(): string {
    return this.chosenPlatform == VideoPlatform.Custom ? 'VIDEO_URL' : 'VIDEO_CODE'
  }

  videoCodeHandler() {
    this.$emit('video-id-inserted', { code: this.tempVideoCode, state: this.codeVideoState })
  }
}
