import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import TextElementBase, { TextProperties } from '../TextElementBase'

export class Text3Properties extends TextProperties {}

export default class TextElement3 extends TextElementBase {
  static create({ palette }: ElementCreationContext): TextElement3 {
    const text = new TextElement3()
    const darkestColor = palette.darkestColor

    text.properties.textColor = darkestColor.paletteCode

    return text
  }

  type = `prefab-text-3` as const

  @Type(() => Text3Properties)
  properties: Text3Properties = new Text3Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_STRONG_TEXT')
  }
}
