import { LayoutElementBase } from '../LayoutElementBase'
import type { PageElementId, DeleteContext, BeforeDeleteContext } from '../../types/PageElement'
import type { PageBuilderConfig } from '../../types/Page'

export abstract class LinearLayoutElementBase extends LayoutElementBase {
  children: PageElementId[] = [];

  *allDirectChildrenIds() {
    yield* super.allDirectChildrenIds()
    yield* this.children
  }

  canHaveChildren = true

  appendChildId(id: PageElementId) {
    this.children.push(id)
  }

  removeChildId(targetId: PageElementId) {
    const oldLength = this.children.length

    this.children = this.children.filter((childId) => childId !== targetId)

    return this.children.length !== oldLength || super.removeChildId(targetId)
  }

  /**
   * Duplicates a child of this element and places the copy after to the original.
   * @param targetId The ID of the child element to duplicate.
   * @param pageConfig A pageConfig to use to manipulate elements within.
   * @returns The ID of the duplicate.
   */
  duplicateChildElement(targetId: PageElementId, pageConfig: PageBuilderConfig): PageElementId {
    const indexToDuplicate: number = this.children.indexOf(targetId)

    if (indexToDuplicate > -1) {
      const newChildren: PageElementId[] = this.children.slice()
      const newChildId: PageElementId = pageConfig.getElementFromId(targetId).duplicate(pageConfig)
      newChildren.splice(indexToDuplicate + 1, 0, newChildId)
      this.children = newChildren
      return newChildId
    } else {
      throw new Error(`Cannot duplicate child element ${targetId}: Target is not a direct child element.`)
    }
  }

  beforeDeleteElement(context: BeforeDeleteContext) {
    super.beforeDeleteElement(context)

    if (context.isSelf) {
      for (const childId of this.children) {
        context.pageConfig.deleteElement(childId)
      }
    }
  }

  onDeleteElement(context: DeleteContext) {
    super.onDeleteElement(context)

    if (this.children.includes(context.deletedId)) {
      this.children = this.children.filter((childId) => childId !== context.deletedId)
    }
  }

  duplicate(pageConfig: PageBuilderConfig): PageElementId {
    const duplicateId: PageElementId = super.duplicate(pageConfig)
    const duplicate = pageConfig.getElementFromId(duplicateId)

    if (duplicate instanceof LinearLayoutElementBase) {
      duplicate.children = this.children.map((childId) => {
        return pageConfig.getElementFromId(childId).duplicate(pageConfig)
      })

      return duplicateId
    } else {
      throw new Error('Unexpected: Duplicate is not of the correct type.')
    }
  }
}
