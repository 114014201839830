






















































































import { DigiDatePicker } from '@/components/ui/inputs'
import { i18n } from '@/utils/i18n'

import type { CriteriaValue, Operator } from '@/models/Event/Filter/Criteria'
import Criteria from '@/models/Event/Filter/Criteria'

import { Vue, Component, Inject, VModel, Watch, Emit } from 'vue-property-decorator'
import { plainToClass } from 'class-transformer'
import type {
  FieldForFiltering,
  FieldMap,
  OperatorInfo,
  OperatorMap,
  PropertyOptions,
  TypeOperatorsInfo,
  ValueSelectOptions
} from '../types'
import { DigiSimpleIconButton } from '@/components/ui/actions'

const dateConfig: unknown = {
  locale: i18n.i18next.language,
  format: 'L',
  useCurrent: false,
  showTodayButton: true,
  showClear: true
}

type CriteriaWithNothing = Pick<Criteria, never>
type CriteriaWithJustAProperty = Pick<Criteria, 'property'>

type PossiblyIncompleteCriteria = CriteriaWithNothing | CriteriaWithJustAProperty | Criteria

@Component({
  components: {
    DigiSimpleIconButton,
    DigiDatePicker
  }
})
export default class CriteriaDefinition extends Vue {
  @Inject() readonly propertyOptions!: PropertyOptions
  @Inject() readonly operatorMap!: OperatorMap
  @Inject() readonly fieldMap!: FieldMap

  @VModel({ required: true }) criteria!: PossiblyIncompleteCriteria

  get dateConfig(): unknown {
    return dateConfig
  }

  get currentField(): FieldForFiltering | null {
    if (!this.property) return null
    else if (this.fieldMap[this.property]) {
      return this.fieldMap[this.property]!
    } else {
      throw new Error(`Filter contains reference to unknown field, keyed "${this.property}"`)
    }
  }

  get currentOperatorMap(): TypeOperatorsInfo | null {
    if (!this.currentField) return null
    else {
      const result = this.operatorMap[this.currentField.type]

      if (!result) {
        throw new Error(`Operators have not been defined for fields of type ${this.currentField.type}`)
      } else if (typeof result === 'function') {
        return result()
      } else if (typeof result === 'object') {
        return result
      } else {
        throw new Error(`Operators for fields of type ${this.currentField.type} are ill-formed`)
      }
    }
  }

  get operatorOptions(): { text: string; value: Operator }[] | null {
    if (!this.currentOperatorMap) return null

    const options: { text: string; value: Operator }[] = []

    for (const [operator, operatorInfo] of Object.entries(this.currentOperatorMap)) {
      options.push({
        text: this.$t(operatorInfo.presentationSlug ?? operator),
        value: operator as Operator
      })
    }

    return options
  }

  get needsTestedValue(): boolean {
    if (this.currentOperatorInfo) {
      return this.currentOperatorInfo.valueType !== null
    } else {
      return false
    }
  }

  get property(): string | null {
    if ('property' in this.criteria) {
      return this.criteria.property
    } else {
      return null
    }
  }
  set property(newProperty: string | null) {
    if (newProperty === null) {
      throw new Error('Cannot set criteria property to null.')
    } else {
      this.updateCriteria({
        property: newProperty
      })
    }
  }

  get isPropertyValid(): boolean {
    if (this.property) {
      return this.property in this.fieldMap
    } else {
      return false
    }
  }

  get operator(): Operator | null {
    if ('operator' in this.criteria) {
      return this.criteria.operator
    } else {
      return null
    }
  }
  set operator(newOperator: Operator | null) {
    if (!this.property) {
      throw new Error('Cannot set operator if property is not set.')
    } else if (!newOperator) {
      throw new Error('Cannot set operator to null.')
    } else {
      this.updateCriteria({
        property: this.property,
        operator: newOperator
      })
    }
  }

  get isOperatorValid(): boolean {
    if (this.currentOperatorMap && this.operator) {
      return this.operator in this.currentOperatorMap
    } else {
      return false
    }
  }

  get currentOperatorInfo(): OperatorInfo | null {
    if (!this.currentOperatorMap || !this.isOperatorValid) {
      return null
    } else {
      if (this.operator! in this.currentOperatorMap) {
        return this.currentOperatorMap[this.operator!]!
      } else {
        throw new Error(
          `No operator information available for operator ${this.operator} with property ${this.property}.`
        )
      }
    }
  }

  get selectOptions(): ValueSelectOptions | null {
    if (this.currentOperatorInfo && 'options' in this.currentOperatorInfo) {
      if (Array.isArray(this.currentOperatorInfo.options)) {
        return this.currentOperatorInfo.options
      } else if (typeof this.currentOperatorInfo.options === 'function') {
        // Note: Cast to Criteria is acceptable as it is not possible to have operator info without a valid property and operator
        // However, a more proper approach would be appreciated
        return this.currentOperatorInfo.options(this.criteria as Criteria)
      } else {
        throw new Error(`Value options for criteria on field ${this.property} are ill-formed`)
      }
    } else {
      return null
    }
  }

  get valueIsRequired(): boolean {
    if (!this.currentOperatorInfo) return false
    else return this.currentOperatorInfo.valueType !== null
  }

  get testedValue(): CriteriaValue | null {
    if ('testedValue' in this.criteria) {
      return this.criteria.testedValue ?? null
    } else {
      return null
    }
  }
  set testedValue(newValue: CriteriaValue | null) {
    if (!this.property || !this.operator) {
      throw new Error('Cannot set tested value before property and operator are set.')
    } else if (newValue === null) {
      throw new Error('Cannot set tested value to null.')
    } else {
      this.updateCriteria({
        property: this.property,
        operator: this.operator,
        testedValue: newValue
      })
    }
  }

  get isValueValid(): boolean {
    if (this.valueIsRequired === false) {
      return 'testedValue' in this.criteria === false || this.testedValue === null
    } else {
      if (this.currentOperatorInfo) {
        switch (this.currentOperatorInfo.valueType) {
          case 'select':
            if (this.selectOptions === null) {
              throw new Error(
                `Value type for "${this.property} -> ${this.operator}" is a select but no options were provided`
              )
            } else {
              return this.selectOptions.some(({ value }) => value === this.testedValue)
            }
          case 'string':
            return Boolean(this.testedValue)
          case 'number':
            return typeof this.testedValue === 'number' && !isNaN(this.testedValue)
          default:
            throw new Error(`No validation routine for testedValue of type ${this.currentOperatorInfo.valueType}`)
        }
      } else {
        throw new Error('Value is required, but information about its type is missing.')
      }
    }
  }

  get dateIsRequired(): boolean {
    if (this.currentOperatorInfo) {
      if ('showDateInput' in this.currentOperatorInfo === false) {
        return false
      } else if (typeof this.currentOperatorInfo.showDateInput === 'function') {
        // Again, acceptable cast because currentOperatorInfo cannot be set on an incomplete criteria
        // Would still be cool to have a more sound approach
        return this.currentOperatorInfo.showDateInput(this.criteria as Criteria)
      } else if (typeof this.currentOperatorInfo.showDateInput === 'boolean') {
        return this.currentOperatorInfo.showDateInput
      } else {
        throw new Error(
          `Malformed information for operator ${this.operator} of field ${this.property} : showDateInput is ill-formed`
        )
      }
    } else {
      return false
    }
  }

  get testedDate(): Date | null {
    if ('testedDate' in this.criteria) return this.criteria.testedDate ?? null
    else return null
  }
  set testedDate(newDate: Date | null) {
    if (this.property === null || this.operator === null || this.testedValue === null) {
      throw new Error('Cannot set tested date on incomplete criteria.')
    } else if (newDate === null) {
      throw new Error('Cannot set tested date to null.')
    } else {
      if (typeof newDate === 'string') newDate = new Date(newDate)

      this.updateCriteria({
        property: this.property,
        operator: this.operator,
        testedValue: this.testedValue,
        testedDate: newDate
      })
    }
  }

  get isDateValid(): boolean {
    if (this.dateIsRequired) {
      return this.testedDate instanceof Date && !isNaN(this.testedDate.valueOf())
    } else {
      return 'testedDate' in this.criteria === false || this.testedDate === null
    }
  }

  get validationClassDate() {
    if (this.isDateValid) {
      return 'is-valid'
    } else if (this.isDateValid === false) {
      return 'is-invalid'
    } else {
      return ''
    }
  }

  get isLineValid() {
    return this.isPropertyValid && this.isOperatorValid && this.isValueValid && this.isDateValid
  }

  @Emit('update:valid')
  @Watch('isLineValid', { immediate: true })
  onValidityChanged(valid: boolean) {
    return valid
  }

  updateCriteria(newCriteria: CriteriaWithJustAProperty | Criteria) {
    this.criteria = plainToClass(Criteria, newCriteria)
  }

  @Emit('remove')
  removeCriteria() {}
}
