












import { Vue, Component, Prop } from 'vue-property-decorator'

import type Guest from '@/models/Guest'
import { classToPlain } from 'class-transformer'
import ContactProfileSimpleSubMenu from '../ui/ContactProfileSimpleSubMenu.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

@Component({
  components: { ContactProfileSimpleSubMenu, VueJsonPretty }
})
export default class ContactProfileDebugSubMenu extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get plainNewGuest() {
    return classToPlain(this.guest)
  }
}
