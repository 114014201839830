























































import { Component, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment-timezone'

import type { TimeSlot } from '@/features/eventApp'
import { getAvailableTimeSlotsForMeeting } from '@/features/eventApp'

import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import axios from 'axios'

import type Guest from '@/models/Guest'
import { DigiButton } from '@/components/ui/actions'
import { SearchGuests } from '@/features/audience'

interface TimeSlotOption {
  disabled?: boolean
  text: string
  value?: TimeSlot
}

interface RdvSessionOption {
  text: string
  value: string
}

@Component({
  components: {
    DigiButton,
    SearchGuests
  }
})
export default class RdvForm extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  isSubmitting = false
  pickedSession: string | null = null
  pickedGuest: Guest | null = null
  isFetchingSlots = false
  timeSlots: TimeSlot[] = []
  pickedTimeSlot: TimeSlot | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  @Watch('canRequestTimes')
  async onCanRequestTimesChanged(canRequest: boolean): Promise<void> {
    if (canRequest) {
      await this.fetchPossibleTimes()
    }
  }

  @Watch('canPickTimeSlot')
  onCanPickTimeSlotChanged(canPick: boolean): void {
    if (!canPick) {
      this.pickedTimeSlot = null
    }
  }

  get rdvSessionOptions(): RdvSessionOption[] {
    const programme = this.storeEvent.modules_data.programme

    if (!programme) throw Error('Undefined programme')

    return programme.eventActivities
      .filter((act) => act.kind === 'matchmakingSession')
      .map((act) => ({
        text: act.title,
        value: act._id
      }))
  }
  get excludedGuestIds(): string[] {
    return [this.guest._id]
  }

  get canRequestTimes(): boolean {
    return this.pickedSession !== null && this.pickedGuest !== null
  }

  get availableSlotsAreKnown(): boolean {
    return this.canRequestTimes && !this.isFetchingSlots
  }

  get canPickTimeSlot(): boolean {
    return this.availableSlotsAreKnown && this.timeSlots.length > 0
  }

  get timeSlotOptions(): TimeSlotOption[] {
    if (this.availableSlotsAreKnown) {
      if (this.timeSlots.length) {
        const timeSlotsOptions = this.timeSlots.map((slot) => ({
          value: slot,
          text: moment(slot.start).format('l LT')
        }))

        return [
          {
            disabled: true,
            text: this.$t('PICK_A_TIME_SLOT')
          },
          ...timeSlotsOptions
        ]
      } else {
        return [
          {
            text: this.$t('NO_MEETING_SLOT_AVAILABLE'),
            disabled: true
          }
        ]
      }
    } else {
      return []
    }
  }

  get searchPlaceholder(): string {
    return `${this.$t('SEARCH_BY')} ${this.$t('FIRSTNAME')}/${this.$t('LASTNAME')}`
  }

  pickGuest(guest: Guest): void {
    this.pickedGuest = guest
  }

  async fetchPossibleTimes(): Promise<void> {
    if (!this.pickedGuest) throw Error('No picked guest')
    if (!this.pickedSession) throw Error('No picked session')

    this.isFetchingSlots = true

    try {
      this.timeSlots = await getAvailableTimeSlotsForMeeting(this.guest._id, this.pickedGuest._id, this.pickedSession)
    } finally {
      this.isFetchingSlots = false
    }
  }

  async submit(): Promise<void> {
    if (!this.pickedGuest) throw Error('No picked guest')
    if (!this.pickedTimeSlot) throw Error('No picked time slot')

    this.isSubmitting = true

    try {
      this.$emit(
        'done',
        (
          await axios.post(
            `/backoffice/events/${this.storeEvent._id}/matchmaking/${this.pickedSession}/scheduleMeeting`,
            {
              startDate: this.pickedTimeSlot.start,
              applicantId: this.guest._id,
              targetId: this.pickedGuest._id
            }
          )
        ).data
      )
    } finally {
      this.isSubmitting = false
    }
  }
}
