import i18next from 'i18next'
import type { MomentInput } from 'moment'
import moment from 'moment'

export function prettyDate(value: MomentInput | null | undefined, format: string = 'L'): string {
  if (!value) return ''
  return moment(value).locale(i18next.language).format(format)
}

export default prettyDate
