




import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningDateRange from '../PlanningDateRange.vue'
import type { GroupActivity } from '@/models/Event/modules/Programme'

@Component({ components: { PlanningDateRange } })
export default class GroupActivityDateRange extends Vue {
  @Prop({ required: true }) readonly groupActivitySession!: GroupActivity

  get dateRangeString(): string {
    const startDateString: string = moment(this.groupActivitySession.dateRange[0]).format('LT')
    const endDateString = moment(this.groupActivitySession.dateRange[1]).format('LT')
    return `${startDateString} - ${endDateString}`
  }
}
