import { ElementTypeDictionary } from '../../types'
import Media1Element from './media1/Media1Element'
import Media2Element from './media2/Media2Element'
import Media3Element from './media3/Media3Element'
import Media4Element from './media4/Media4Element'
import Media5Element from './media5/Media5Element'
import Media6Element from './media6/Media6Element'

import SliderElement from './slider/SliderElement'
import GalleryElement from './gallery/GalleryElement'
import type { RadioButtonImageOption } from '@/features/website/builder/components/ControlPanel/components/PageBlocks/ElementPropertyItem/properties/RadioButtonProperty/index.vue'

const galleryRadioOptions: RadioButtonImageOption[] = [
  { value: '4x2', imageUrl: require('@/assets/img/gallery-prefab/4x2.png'), type: 'image' },
  { value: '1-2x2', imageUrl: require('@/assets/img/gallery-prefab/1-2x2.png'), type: 'image' },
  { value: '1-2', imageUrl: require('@/assets/img/gallery-prefab/1-2.png'), type: 'image' },
  { value: '2x3-1-2', imageUrl: require('@/assets/img/gallery-prefab/2x3-1-2.png'), type: 'image' },
  { value: '1-2x2-2x4', imageUrl: require('@/assets/img/gallery-prefab/1-2x2-2x4.png'), type: 'image' },
  { value: 'fantasy', imageUrl: require('@/assets/img/gallery-prefab/fantasy.png'), type: 'image' }
]
export const mediaDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Media1Element,
    renderer: () => import('./media1/Media1.vue'),
    properties: [],
    typeNameSlug: 'WEBSITE_BLOCK_IMAGES1',
    previewImageLink: require('@/assets/img/prefab-previews/images1.jpg')
  })
  .addType({
    dataClass: Media2Element,
    renderer: () => import('./media2/Media2.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagTextColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.mainTextColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_IMAGES2',
    previewImageLink: require('@/assets/img/prefab-previews/images2.jpg')
  })
  .addType({
    dataClass: Media3Element,
    renderer: () => import('./media3/Media3.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagTextColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.mainTextColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_IMAGES3',
    previewImageLink: require('@/assets/img/prefab-previews/images3.jpg')
  })
  .addType({
    dataClass: Media4Element,
    renderer: () => import('./media4/Media4.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'BLOCK_VIDEO',
    previewImageLink: require('@/assets/img/prefab-previews/video.jpg')
  })
  .addType({
    dataClass: Media6Element,
    renderer: () => import('./media6/Media6.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'BLOCK_VIDEO2',
    previewImageLink: require('@/assets/img/prefab-previews/video2.jpg')
  })
  .addType({
    dataClass: Media5Element,
    renderer: () => import('./media5/Media5.vue'),
    properties: [],
    typeNameSlug: 'WEBSITE_BLOCK_IMAGES5',
    previewImageLink: require('@/assets/img/prefab-previews/images5.jpg')
  })
  .addType({
    dataClass: SliderElement,
    renderer: () => import('./slider/Slider.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'orderedimages', label: 'SLIDER_IMAGES', key: 'properties.imageSliders' },
      { type: 'boolean', label: 'SLIDER_AUTOPLAY', key: 'properties.isAutoplay' }
    ],
    typeNameSlug: 'BLOCK_SLIDER',
    previewImageLink: require('@/assets/img/prefab-previews/slider.jpg')
  })
  .addType({
    dataClass: GalleryElement,
    renderer: () => import('./gallery/index.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' },
      { type: 'radiobutton', label: 'GALLERY_TEMPLATE', key: 'properties.layout', options: galleryRadioOptions }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_GALLERY',
    previewImageLink: require('@/assets/img/prefab-previews/gallery.png')
  })
  .finish()
