









































import { Component, Prop } from 'vue-property-decorator'
import type { AnyAction, SendSMStoPhoneNumberAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import { VueTelInput } from 'vue-tel-input'
import { SMS_SENDER_CHARACTER_LIMIT, PREFERRED_COUNTRIES } from '@/utils/constants'
import { containsOnlyLettersAndSpaces } from '@/utils/validation'
import SmsCostChecker from '@/components/features/SmsCostChecker.vue'
import SmsInvalidCharsChecker from '@/components/features/SmsInvalidCharsChecker.vue'
import { NominativeValuesDropdown } from '@/features/messages'
import SmsInput from './SmsInput.vue'
import Vue from 'vue'

@Component({ components: { NominativeValuesDropdown, SmsInvalidCharsChecker, SmsCostChecker, VueTelInput, SmsInput } })
export default class SendSmsToPhoneNumberSettings extends Vue {
  @Prop({ required: true }) readonly action!: AnyAction

  icon = 'smartphone'
  preferredCountries = PREFERRED_COUNTRIES

  get sendSmsToPhoneNumberAction(): SendSMStoPhoneNumberAction {
    if (this.action.kind !== ActionKind.SendSMSToPhoneNumber) {
      throw new Error('Unexpected: SendSmsToPhoneNumberSettings can only manage actions of kind SendSMSToPhoneNumber')
    }

    return this.action
  }

  get phoneNumber(): string {
    return this.sendSmsToPhoneNumberAction.phoneNumber
  }
  set phoneNumber(phoneNumber: string) {
    this.$emit('update', { ...this.action, phoneNumber })
  }

  get senderName(): string {
    return this.sendSmsToPhoneNumberAction.senderName
  }
  set senderName(senderName: string) {
    this.$emit('update', { ...this.action, senderName })
  }

  get senderNameValidity(): false | null {
    const senderNameLength = this.senderName ? this.senderName.length : 0

    if (senderNameLength === 0 || senderNameLength > SMS_SENDER_CHARACTER_LIMIT || !this.containsOnlyLettersAndSpaces) {
      return false
    } else {
      return null
    }
  }

  get containsOnlyLettersAndSpaces() {
    return containsOnlyLettersAndSpaces(this.senderName)
  }

  get senderNameError(): string | null {
    const senderNameLength = this.senderName ? this.senderName.length : 0

    if (senderNameLength > SMS_SENDER_CHARACTER_LIMIT) {
      return this.$t('EXPEDITOR_NAME_TOO_LONG')
    } else if (!this.containsOnlyLettersAndSpaces) {
      return this.$t('SMS_TITLE_ERROR_LETTERS_ONLY')
    } else if (senderNameLength === 0) {
      return this.$t('REQUIRED')
    } else return null
  }

  get content(): string {
    return this.sendSmsToPhoneNumberAction.content ?? ''
  }
  set content(content: string) {
    this.$emit('update', { ...this.action, content })
  }

  get useUSC2EncodingFormat(): boolean {
    return this.sendSmsToPhoneNumberAction.useUSC2EncodingFormat ?? false
  }
  set useUSC2EncodingFormat(useUSC2EncodingFormat: boolean) {
    this.$emit('update', { ...this.action, useUSC2EncodingFormat })
  }

  insertTag(tag: string) {
    this.content = this.content + tag
  }
}
