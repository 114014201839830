






import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SmsCostChecker extends Vue {
  @Prop({ required: true }) readonly content!: string
  @Prop({ default: false }) readonly useUSC2EncodingFormat!: boolean

  get smsCost(): number {
    if (!this.content) {
      return 1
    }
    return Math.ceil(this.content.length / (this.useUSC2EncodingFormat ? 70 : 140))
  }

  @Watch('smsCost')
  onSmsCostChange(newValue: number, oldValue: number): void {
    if (newValue > oldValue) {
      this.$bvModal.msgBoxOk(this.$t('SMS_COST_INCREASE', { nb: this.smsCost }), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'default',
        okTitle: this.$t('OK'),
        centered: true
      })
    }
  }
}
