import Campaign from './Campaign'
import { Type } from 'class-transformer'
import type { MixedSort } from '@/models/commons/sort'

export default class CampaignsModule {
  @Type(() => Campaign)
  campaignItems: Campaign[] = []

  sort!: MixedSort
}
