















import { Vue, Component, VModel, Ref, Prop } from 'vue-property-decorator'
import { validateEmail } from '@/utils/validation'

@Component
export default class DigiEmailInput extends Vue {
  @VModel({ required: true }) email!: string

  @Prop({ default: 'md' }) readonly size!: 'sm' | 'md' | 'lg'
  @Prop({ default: '' }) readonly placeholder!: string
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: null }) readonly state!: false | null
  @Prop({ default: false }) readonly required!: boolean
  @Prop({ default: false }) readonly noState!: boolean
  @Prop({ default: false }) readonly autocomplete!: boolean

  @Ref() input!: HTMLInputElement

  isValid: boolean = true

  get computedState(): false | null {
    if (this.noState) {
      return null
    }
    if (!this.isValid) {
      return false
    }
    return this.state
  }

  validate(value: string): void {
    // reset custom validation
    this.input.setCustomValidity('')
    // check vanilla validation
    if (!this.checkValidity()) {
      this.isValid = false
    }
    // if vanilla validation pass, do custom validation
    else if (!validateEmail(value)) {
      this.input.setCustomValidity(this.$t('INVALID_EMAIL_CUSTOM_MESSAGE'))
      this.isValid = false
    }
    // if all validations pass, email is valid
    else {
      this.input.setCustomValidity('')
      this.isValid = true
    }
  }

  // Expose checkValidity api to the parent
  public checkValidity(): boolean {
    return this.input.checkValidity()
  }
}
