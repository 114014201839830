import { captureException } from '@sentry/browser'

type DataLayerItem = {
  event: string
  [key: string]: any
}
export function safePushToDataLayer(dataLayerItem: DataLayerItem) {
  try {
    window.dataLayer?.push(dataLayerItem)
    console.info('pushed to dataLayer', dataLayerItem)
  } catch (e) {
    console.log('failed to track', e)
    captureException(e)
  }
}
