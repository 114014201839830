export interface DigiError extends Error {
  errorId: number
  status: number
  slug: string | null
  /**
   * Record<langCode, translation>
   */
  localized: Record<string, string>
  criticity: number
  // and maybe context but only in local so don't use it please
}

/**
 * Augmented error class that allows to pass extra information for Sentry reporting.
 */
export class ErrorWithExtras extends Error {
  constructor(message: string, extras: Record<string, any>) {
    super(message)
    this.extras = extras
  }

  extras: Record<string, any>
}

export class UnauthenticatedError extends Error {
  constructor(public readonly kind: 'loggedOut' | 'emailNotVerified') {
    super(`Unauthorized: ${kind}`)
  }
}
