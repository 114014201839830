import type { StoreState } from '@/store'
import { createExport } from '@/services/exports'
import type { Module } from 'vuex'

const storeModule: Module<undefined, StoreState> = {
  actions: {
    generateExport({ rootState }, exportData) {
      if (rootState.event.event) {
        return createExport(rootState.event.event._id, exportData)
      } else {
        throw new Error('Cannot generate export: No event loaded.')
      }
    }
  }
}

export default storeModule
