import type { MessageId as MessageTemplateId } from '@/models/Event/Messages/template'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'

export enum MessageStatus {
  Error = 'error',
  Clicked = 'click',
  Sending = 'processed',
  Dropped = 'dropped',
  Sent = 'delivered',
  Deferred = 'deferred',
  Bounced = 'bounce',
  Blocked = 'blocked',
  Opened = 'open'
}

export class MessageActivity {
  event!: MessageStatus
  @TransformDateStringToDateObject()
  eventDate!: Date
  details?: string
}

export default class GuestMessage {
  messageId!: MessageTemplateId
  thirdPartMsgId!: string
  state!: MessageStatus
  @TransformDateStringToDateObject()
  eventDate!: Date
  state_details?: string
  sentTo?: string
  testSending?: boolean
  @Type(() => MessageActivity)
  activities!: MessageActivity[]
}
