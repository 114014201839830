import type { BackofficeLanguage } from '@/utils/i18n'
import axios from 'axios'

export interface PasswordLoginCredentials {
  email: string
  password: string
  utm?: Record<string, unknown>
}

export function login(credentials: PasswordLoginCredentials) {
  return axios.post('auth/login', credentials).then((res) => res.data)
}

export function logout(): Promise<void> {
  return axios.post<void>('auth/logout').then((res) => res.data)
}

export interface SignUpCredentials {
  accountEmail: string
  password: string
  language: BackofficeLanguage
  timezone: string | null
  utm?: Record<string, unknown>
}

export function signUp(credentials: SignUpCredentials) {
  return axios.post('account', credentials).then((res) => res.data)
}
