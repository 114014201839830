import { render, staticRenderFns } from "./PrefabCover3.vue?vue&type=template&id=0b8f6a0e&scoped=true"
import script from "./PrefabCover3.vue?vue&type=script&lang=ts"
export * from "./PrefabCover3.vue?vue&type=script&lang=ts"
import style0 from "./PrefabCover3.vue?vue&type=style&index=0&id=0b8f6a0e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8f6a0e",
  null
  
)

export default component.exports