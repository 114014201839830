import type { ElementCreationContext } from '../../../types/ElementTypeDictionary'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'

import type { ImageMediaProperties } from '../../../types/helperTypes'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export type GalleryLayout = '4x2' | '1-2x2' | '1-2' | '2x3-1-2' | '1-2x2-2x4' | 'fantasy'

const galleryLayoutsItemsNumber: Record<GalleryLayout, number> = {
  '4x2': 8,
  '1-2x2': 5,
  '1-2': 3,
  '2x3-1-2': 9,
  '1-2x2-2x4': 13,
  fantasy: 11
}

export type GalleryImage = Array<ImageMediaProperties | null>

export class GalleryProperties extends PrefabPropertiesBase {
  title: string = ''
  titleColor: string = ''
  mainText: string = ''
  textColor: string = ''
  cta: CtaProperties = defaultCtaProperties()
  layout: GalleryLayout | null = null
  images: GalleryImage[] = []
}

export default class GalleryElement extends MediaElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): GalleryElement {
    const gallery = new GalleryElement()

    gallery.properties.title = i18n.t('AN_INSPIRING_TITLE')

    gallery.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)

    gallery.properties.cta.label = i18n.t('REGISTRATION_CTA_BTN_LABEL')

    const darkestColor = palette.darkestColor

    gallery.properties.titleColor = mostReadableColor
    gallery.properties.textColor = mostReadableColor
    gallery.properties.cta.color = darkestColor.paletteCode

    gallery.setCorrectImageNumber()

    return gallery
  }

  type = `prefab-gallery` as const

  @Type(() => GalleryProperties)
  properties: GalleryProperties = new GalleryProperties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_GALLERY')
  }

  setCorrectImageNumber(): void {
    if (!this.properties.layout) return
    const imagesNumber = galleryLayoutsItemsNumber[this.properties.layout]
    if (this.properties.images.length < imagesNumber) {
      const numberNeeded = imagesNumber - this.properties.images.length
      this.properties.images.push(...Array(numberNeeded).fill(null))
      return
    }
    if (this.properties.images.length > imagesNumber) {
      this.properties.images = this.properties.images.slice(0, imagesNumber)
      return
    }
  }
}
