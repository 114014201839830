import store from '@/store'
import type { ReadonlyFullRouteConfig } from '@/core'

export const sessionsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'programme',
    name: 'event.website.programme',
    component: () => import('./views/ActivityList.vue'),
    meta: {
      name: 'PROGRAMME',
      description: 'PROGRAMME_INTRO_DESCRIPTION',
      keywords: 'FACTORY_PROGRAMME_KEYWORDS',
      iconName: 'calendar-event-line',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      },
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.programme.eventActivities.length > 0
        }
      }
    }
  },
  {
    path: 'programme/matchmakingSessions/:activityId',
    name: 'event.website.programme.matchmakingSessions.edition',
    component: () => import('./views/MatchmakingSessions/MatchmakingSessionView/index.vue'),
    meta: {
      subPageOf: 'event.website.programme'
    }
  },
  {
    path: 'programme/groupActivities/:activityId',
    name: 'event.website.programme.groupActivities.edition',
    component: () => import('./views/GroupActivities/GroupActivityEdition/GroupActivityEdition.vue'),
    meta: {
      subPageOf: 'event.website.programme'
    }
  }
] as const
