import { cashOutToOrganizer } from '@/services/order'
import type { StoreState } from '@/store'
import type { Module } from 'vuex'

const storeModule: Module<undefined, StoreState> = {
  actions: {
    async cashOutToOrganizer(ctx) {
      const event = await cashOutToOrganizer(ctx.rootGetters.eventId)
      ctx.commit('SET_EVENT', event)
    }
  }
}

export default storeModule
