import type { ReadonlyFullRouteConfig } from '@/core'

export const generalRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'websiteSettings',
    name: 'event.website.websitesettings',
    component: () => import('./GeneralWebsiteSettings.vue'),
    meta: {
      name: 'SITE_SETTINGS',
      iconName: 'settings-3-line',
      keywords: 'FACTORY_SITE_SETTINGS_KEYWORDS',
      hideOnInactiveFeatures: true,
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.website.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  }
]
