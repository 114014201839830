








import { Vue, Component, Prop } from 'vue-property-decorator'
import type { ColorVariant, LinkTargetType } from './types'
import type { OutlineColorVariant } from './types'
import type { RawLocation } from 'vue-router'

@Component
export default class DigiSimpleIconButton extends Vue {
  @Prop({ required: true }) readonly remixIcon!: string
  @Prop({ required: true }) readonly variant!: Omit<ColorVariant, 'link'> | Omit<OutlineColorVariant, 'outline-link'>
  @Prop({ default: 'md' }) readonly size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly pressed!: boolean
  @Prop({}) readonly stopPropagation!: boolean
  @Prop({}) readonly to?: RawLocation
  @Prop({}) readonly href?: string
  @Prop({}) readonly target?: LinkTargetType

  get component() {
    if (this.to) return 'router-link'
    if (this.href) return 'a'
    return 'button'
  }

  get buttonClass() {
    return {
      [`btn-transparent--${this.variant}`]: true,
      [`btn-transparent--${this.size}`]: true,
      'btn-transparent--pressed': this.pressed
    }
  }

  get props() {
    switch (this.component) {
      case 'button':
        return {
          type: 'button',
          disabled: this.disabled
        }
      case 'a':
        return {
          href: this.href,
          target: this.target,
          ['aria-disabled']: this.disabled
        }
      case 'router-link':
        return {
          to: this.to,
          target: this.target,
          ['aria-disabled']: this.disabled
        }
      default:
        return {}
    }
  }

  onClick(e: MouseEvent) {
    if (this.stopPropagation) e.stopPropagation()
    this.$emit('click', e)
  }
}
