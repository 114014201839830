


















import { Component, Prop } from 'vue-property-decorator'
import type { UnscheduledMeeting } from '@/models/Guest/Planning/UnscheduledMeeting'
import type { EventActivityId, MatchmakingSession } from '@/models/Event/modules/Programme'
import type { Programme } from '@/models/Event/modules/Programme'
import { uniq } from 'lodash'
import MeetingRequestListing from './MeetingRequestListing.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({ components: { MeetingRequestListing } })
export default class MeetingRequestListGrouper extends Vue {
  @Prop({ required: true }) readonly requests!: UnscheduledMeeting[]

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get programme(): Programme {
    return this.storeEvent.modules_data.programme
  }

  get hasMultipleMatchmakingSessions(): boolean {
    return Array.from(this.programme.matchMakingSessions()).length > 1
  }

  get usedSessions(): MatchmakingSession[] {
    return uniq(this.requests.map((req) => req.eventActivityId)).map((sessionId) =>
      this.programme.getMatchmakingSessionById(sessionId)
    )
  }

  getRequestsForSessionId(sessionId: EventActivityId): UnscheduledMeeting[] {
    return this.requests.filter((req) => req.eventActivityId === sessionId)
  }
}
