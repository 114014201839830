import type { QuoteElementCtor } from './quote'
import { quoteDictionary } from './quote'
import type { AssistanceElementCtor } from './assistance'
import { assistanceDictionary } from './assistance'
import type { TextElementCtor } from './text'
import { textDictionary } from './text'
import type { SponsorsElementCtor } from './sponsors'
import { sponsorsDictionary } from './sponsors'
import type { SpeakersElementCtor } from './speakers'
import { speakersDictionary } from './speakers'
import type { SocialsElementCtor } from './socials'
import { socialsDictionary } from './socials'
import type { CoverElementCtor } from './cover'
import { coverDictionary } from './cover'
import type { ActionElementCtor } from './action'
import { actionDictionary } from './action'
import type { MapElementCtor } from './map'
import { mapDictionary } from './map'
import type { MediaElementCtor } from './media'
import { mediaDictionary } from './media'
import type { CountdownElementCtor } from './countdown'
import { countdownDictionary } from './countdown'
import type { TextInfiniteScrollElementCtor } from './textInfiniteScroll'
import { textInfiniteScrollDictionary } from './textInfiniteScroll'
import type { PageElementType } from '../types/PageElement'
import type { TextMediaElementCtor } from './textMedia'
import { textMediaDictionary } from './textMedia'
import type { KeyFiguresElementCtor } from './keyFigures'
import { keyFiguresDictionary } from './keyFigures'
import type { IframeCtor } from './iframe'
import { iframeDictionary } from './iframe'
import type { ElementTypeDictionary } from '../types'
import type { TrombinoElementCtor } from './trombinoscope'
import { trombinoDictionary } from './trombinoscope'
import type { ProgrammeElementCtor } from './programme'
import { programmeDictionary } from './programme'

export enum PrefabCategory {
  Cover = 'cover',
  Text = 'text',
  TextAndMedia = 'text-media',
  Media = 'media',
  Assistance = 'assistance',
  Quote = 'quote',
  Socials = 'socials',
  Sponsors = 'sponsors',
  Map = 'map',
  Speakers = 'speakers',
  Countdown = 'countdown',
  Action = 'action',
  TextInfiniteScroll = 'text-infinite-scroll',
  KeyFigures = 'key-figures',
  Trombinoscope = 'trombinoscope',
  Programme = 'programme',
  Iframe = 'iframe'
}

export type PrefabElementCtor =
  | QuoteElementCtor
  | AssistanceElementCtor
  | TextMediaElementCtor
  | TextElementCtor
  | SponsorsElementCtor
  | SocialsElementCtor
  | CoverElementCtor
  | SpeakersElementCtor
  | MapElementCtor
  | MediaElementCtor
  | CountdownElementCtor
  | TextInfiniteScrollElementCtor
  | KeyFiguresElementCtor
  | IframeCtor
  | TrombinoElementCtor
  | ProgrammeElementCtor
  | ActionElementCtor

export type PrefabElement = InstanceType<PrefabElementCtor>

export type PrefabElementType = PageElementType<PrefabElement>
export const prefabTypesByCategory: {
  [Category in PrefabCategory]: ElementTypeDictionary
} = {
  [PrefabCategory.Cover]: coverDictionary,
  [PrefabCategory.Assistance]: assistanceDictionary,
  [PrefabCategory.Quote]: quoteDictionary,
  [PrefabCategory.Socials]: socialsDictionary,
  [PrefabCategory.Sponsors]: sponsorsDictionary,
  [PrefabCategory.Text]: textDictionary,
  [PrefabCategory.TextAndMedia]: textMediaDictionary,
  [PrefabCategory.Map]: mapDictionary,
  [PrefabCategory.Media]: mediaDictionary,
  [PrefabCategory.Speakers]: speakersDictionary,
  [PrefabCategory.Countdown]: countdownDictionary,
  [PrefabCategory.KeyFigures]: keyFiguresDictionary,
  [PrefabCategory.TextInfiniteScroll]: textInfiniteScrollDictionary,
  [PrefabCategory.Iframe]: iframeDictionary,
  [PrefabCategory.Trombinoscope]: trombinoDictionary,
  [PrefabCategory.Programme]: programmeDictionary,
  [PrefabCategory.Action]: actionDictionary
}
