import type { MongoDb } from '@/models/MongoDbTools'

export type SponsorId = MongoDb.ObjectId

export interface Attachment {
  url: string
  title?: string
}

export interface SocialNetworks {
  facebook?: string
  linkedin?: string
  instagram?: string
  twitter?: string
  youtube?: string
}

export default class Sponsor {
  _id!: SponsorId
  logo: string
  name: string
  description?: string
  ctaLink?: string
  speakers?: MongoDb.ObjectId[]
  attachments?: Attachment[]
  socialNetworks?: SocialNetworks
  cover: SponsorCover = {
    mediaType: 'embedVideo',
    embedVideoUrl: null,
    imageUrl: null
  }

  constructor(logo: string = '', name: string = '', description?: string, ctaLink?: string) {
    this.logo = logo
    this.name = name
    this.description = description
    this.ctaLink = ctaLink
  }
}

interface SponsorCover {
  mediaType: SponsorCoverMediaType
  embedVideoUrl: string | null
  imageUrl: string | null
}

export type SponsorCoverMediaType = 'image' | 'embedVideo'
