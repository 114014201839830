import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'
export class KeyFigure {
  value = ''
  label = ''
}
export class KeyFiguresProperties extends PrefabPropertiesBase {
  tag = ''
  tagColor = ''

  title = ''
  titleColor = ''

  mainText = ''
  mainTextColor = ''

  keyFigureColor = ''
  keyFigureLabelColor = ''

  @Type(() => KeyFigure)
  keyFigures: KeyFigure[] = [new KeyFigure(), new KeyFigure(), new KeyFigure(), new KeyFigure(), new KeyFigure()]
}

export default abstract class KeyFiguresElementBase extends PrefabElementBase {
  // @ts-ignore
  abstract type: `prefab-key-figures-${number}`
  isFullPrefab: boolean = false

  @Type(() => KeyFiguresProperties)
  properties: KeyFiguresProperties = new KeyFiguresProperties()
}
