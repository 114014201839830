import type { MessageTemplate } from '@/models/Event/Messages/template'
import type { MessageId } from '@/models/Event/Messages/template/Base'
import type { CampaignId } from '@/models/Event/modules/Campaigns/Campaign'
import type { StoreState } from '@/store'
import type { Module } from 'vuex'

const storeModule: Module<undefined, StoreState> = {
  getters: {
    messagesMap(state, getters, rootState) {
      if (rootState.event.event) {
        return rootState.event.event.messages.templates.reduce<Record<MessageId, MessageTemplate>>((acc, message) => {
          acc[message._id] = message
          return acc
        }, {})
      }

      return {}
    },
    getMessage(state, getters) {
      return (id: string) => getters.messagesMap[id]
    },
    getCampaignById(state, getters, rootState) {
      return (campaignId: CampaignId) => {
        if (!rootState.event.event) throw new Error('Cannot get campaign: No event loaded.')
        else {
          return rootState.event.event!.modules_data.campaigns!.campaignItems.find(
            (campaign) => campaign._id === campaignId
          )
        }
      }
    }
  }
}

export default storeModule
