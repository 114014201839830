






import { Component, Prop, Vue } from 'vue-property-decorator'
import type { MessageStatus } from '@/models/Guest/Message'
import { getSlugForMessageStatus, getVariantForMessageStatus } from '@/features/messages/services/messages'

@Component
export default class MessageStatusBadge extends Vue {
  @Prop() readonly status!: MessageStatus

  get statusSlug(): string {
    return getSlugForMessageStatus(this.status)
  }

  get badgeClass(): string | null {
    return getVariantForMessageStatus(this.status)
  }
}
