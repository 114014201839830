










import { Vue, Component, Prop } from 'vue-property-decorator'
import MenuItem from '@/navigation/eventMenu/components/MenuItem.vue'
import type { EventMenuItem, EventMenuSection } from '@/navigation/eventMenu'
import type { Location } from 'vue-router'
import { isEventMenuItemLink } from '@/navigation/eventMenu/types'

@Component({
  components: { MenuItem }
})
export default class LinkMenuSection extends Vue {
  @Prop({ required: true }) readonly section!: EventMenuSection

  get firstItem(): EventMenuItem | undefined {
    return this.section.items[0]
  }

  get location(): Location | undefined {
    return this.firstItem && isEventMenuItemLink(this.firstItem)
      ? {
          name: this.firstItem.routeName
        }
      : undefined
  }
}
