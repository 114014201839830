import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'

export class CountdownProperties extends PrefabPropertiesBase {
  @TransformDateStringToDateObject()
  targetDate: Date = new Date()
  countdownTextColor: string = ''
}

export abstract class CountdownElementBase extends PrefabElementBase {
  abstract type: `prefab-countdown-${number}`

  @Type(() => CountdownProperties)
  properties: CountdownProperties = new CountdownProperties()
}
