import type Guest from '@/models/Guest'
import type { Sort } from '@/models/commons/sort'
import { doesGuestMatchFilterId } from '@/services/filter'
import type { Event } from '@/models/Event'
import { mustFindVariantById } from './mustFindVariantById'
import type { PDFBadgeVariant } from '@/models/Event/modules/PDFBadge'
import type { EventRights } from '@/models/Event/EventRights'

export function findBadgeMatchingGuest({
  variants,
  sort,
  guest,
  event,
  eventRights
}: {
  variants: PDFBadgeVariant[]
  sort: Sort
  guest: Guest
  event: Event
  eventRights: EventRights
}): PDFBadgeVariant | null {
  const isGuestConfirmed =
    guest.invitationStatus === 'confirmed' ||
    (eventRights.features.registration.mode === 'paid' && guest.tickets?.some((t) => !t.canceled))
  if (!isGuestConfirmed) {
    return null
  }
  for (const variantId of sort) {
    const variant = mustFindVariantById(variantId, variants)
    if (!variant.isEnabled) {
      continue
    }
    if (!variant.segmentId) {
      return variant
    }
    const isMatching = doesGuestMatchFilterId(guest, event, variant.segmentId)
    if (isMatching) {
      return variant
    }
  }
  return null
}
