import type { MomentInput } from 'moment'
import moment from 'moment'
import i18next from 'i18next'

export function fromNow(date: NonNullable<MomentInput>): string {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  return moment(date).locale(i18next.language).fromNow()
}

export default fromNow
