































import { Vue, Component, Prop } from 'vue-property-decorator'
import MobileNavItem from '@/layouts/components/MobileNav/MobileNavItem.vue'
import DigitIconButton from '@/components/ui/actions/DigiIconButton.vue'

@Component({
  components: { DigitIconButton, MobileNavItem }
})
export default class MobileNav extends Vue {
  @Prop({ required: true }) readonly isOpen!: boolean

  get classes() {
    return {
      'mobile-nav--open': this.isOpen
    }
  }
}
