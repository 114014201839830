














import type { VideoProperties } from '../../types/helperTypes'
import { Component, Prop } from 'vue-property-decorator'

import VideoDisplayFragment from './VideoDisplayFragment.vue'
import { DigiButton } from '@/components/ui/actions'
import Vue from 'vue'

@Component({
  components: {
    DigiButton,
    VideoDisplayFragment
  }
})
export default class VideoFragment extends Vue {
  @Prop({ type: Object }) readonly video!: VideoProperties

  tempVideoCode: string | null = null

  get isVideoSet(): boolean {
    return Boolean(this.video.videoPlatform) && Boolean(this.video.videoCode)
  }
}
