import { extractIndexFromPaletteCode, makePaletteCodeForIndex } from '@/utils/colors'
import { doSomeMagicToMakeAnObjectReactiveIGuess } from '@/utils/utilityTypes'
import Color from 'color'

export interface PaletteLookupResult {
  color: Color
  index: number
  hex: string
  paletteCode: string
}

export class ColorPalette extends Array<string> {
  constructor() {
    super()

    doSomeMagicToMakeAnObjectReactiveIGuess(this)
  }

  private prepareLookupResultForIndex(index: number): PaletteLookupResult {
    if (typeof this[index] !== 'string') throw new Error('Cannot build palette lookup output: Index out of range.')

    return {
      index: index,
      hex: this[index]!,
      get paletteCode() {
        return makePaletteCodeForIndex(index)
      },
      get color() {
        return new Color(this.hex)
      }
    }
  }

  pickColor(colorString: string | null): string {
    if (colorString === null) {
      return 'transparent'
    } else {
      const index = extractIndexFromPaletteCode(colorString)

      if (index === null) {
        return colorString
      } else {
        const colorAtIndex = this[index]

        if (colorAtIndex) {
          return colorAtIndex
        } else {
          return 'transparent'
        }
      }
    }
  }

  /**
   * Given a comparator, find the color in the palette that best fits its criterias.
   * @param comparator Comparator function, same that would be used for `Array.prototype.sort`.
   *
   * Return a negative value if the second color is a better fit, >=0 otherwise.
   * @returns The color that best fits the given comparator function.
   */
  getBestColor(comparator: (c1: Color, c2: Color) => number): PaletteLookupResult {
    if (this.length === 0) throw new Error('Cannot perform color comparison in palette: Palette is empty.')
    if (this.length === 1) {
      return this.prepareLookupResultForIndex(0)
    } else {
      let bestIndex: number = 0

      for (let currentIndex = 1; currentIndex < this.length; ++currentIndex) {
        const comparison = comparator(new Color(this[bestIndex]), new Color(this[currentIndex]))
        if (comparison < 0) bestIndex = currentIndex
      }

      return this.prepareLookupResultForIndex(bestIndex)
    }
  }

  get lightestColor(): PaletteLookupResult {
    return this.getBestColor((c1, c2) => c1.lightness() - c2.lightness())
  }

  get darkestColor(): PaletteLookupResult {
    return this.getBestColor((c1, c2) => c2.lightness() - c1.lightness())
  }

  /**
   * Gets a color that is farthest from grayscale, evaluated with HSV saturation and value.
   */
  get mostVividColor(): PaletteLookupResult {
    return this.getBestColor((c1, c2) => c1.saturationv() * c1.value() - c2.saturationv() * c2.value())
  }

  /**
   * Gets the color that is closest to a perfect 50% grey. Puts an emphasis on the color being desaturated.
   */
  get mostGrayishColor(): PaletteLookupResult {
    const grey = new Color('grey')

    const distanceFromGrey = (c: Color): number => {
      return c.contrast(grey) * Math.max(1, c.saturationl()) * Math.max(1, c.saturationv())
    }

    return this.getBestColor((c1, c2) => {
      return distanceFromGrey(c2) - distanceFromGrey(c1)
    })
  }

  /**
   * Gets the color that is closest to a target color.
   */
  getClosestColor(targetColor: Color): PaletteLookupResult {
    return this.getBestColor((c1, c2) => {
      return targetColor.contrast(c2) - targetColor.contrast(c1)
    })
  }

  /**
   * Given a background color, finds the color in the palette that is most readable in contrast.
   *
   * This uses WCAG contrast level metrics as a fitness mesurement, followed by contrast evaluation as a secondary metric.
   * @param backgroundColor The color that will be used a background for the queried color.
   * @returns The most readable color for the given background color.
   */
  getMostReadableColor(backgroundColor: Color): PaletteLookupResult {
    return this.getBestColor((c1, c2) => {
      const c1Level = backgroundColor.level(c1)
      const c2Level = backgroundColor.level(c2)
      const levelComparison = c1Level.localeCompare(c2Level)

      if (levelComparison !== 0) {
        return levelComparison
      } else {
        return backgroundColor.contrast(c1) - backgroundColor.contrast(c2)
      }
    })
  }

  getMostReadableFromString(backgroundColor: string) {
    const paletteColor = this.pickColor(backgroundColor)
    return this.getMostReadableColor(new Color(paletteColor))
  }
}
