import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import type { MediaProperties } from '../../../types/helperTypes'
import TextMediaElementBase, { TextMediaProperties } from '../TextMediaElementBase'

export class TextMedia5Properties extends TextMediaProperties {
  title: string = ''
  titleColor: string = ''
  tagText: string = ''
  tagColor: string = ''
  media: MediaProperties | null = null
}

export default class TextMedia5Element extends TextMediaElementBase {
  static create({ palette }: ElementCreationContext): TextMedia5Element {
    const textImage = new TextMedia5Element()

    const darkestColor = palette.darkestColor

    textImage.properties.title = i18n.t('AN_INSPIRING_TITLE')
    textImage.properties.titleColor = darkestColor.paletteCode

    textImage.properties.tagText = i18n.t('SECTION_NAME')
    textImage.properties.tagColor = darkestColor.paletteCode

    textImage.properties.mainText = new LoremIpsum(
      {
        wordsPerSentence: { min: 2, max: 5 },
        sentencesPerParagraph: { min: 2, max: 3 }
      },
      undefined,
      `\n\n`
    ).generateParagraphs(5)
    textImage.properties.textColor = darkestColor.paletteCode

    return textImage
  }

  type = `prefab-text-media-5` as const

  @Type(() => TextMedia5Properties)
  properties: TextMedia5Properties = new TextMedia5Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TEXT_IMAGE5')
  }
}
