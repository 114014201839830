import { Type } from 'class-transformer'
import type { GuestFieldKey } from '../../GuestField'
import { LocalizedPrimitive } from '../../Localized'
import type { FormId } from '../../Form'
import MasterRule from './MasterRule'

export default class MultiRegistrationModule {
  slaveFormId!: FormId
  @Type(() => LocalizedPrimitive)
  infoHtmlMyRegistration: LocalizedPrimitive<string> = new LocalizedPrimitive<string>()
  @Type(() => MasterRule)
  masterRules: MasterRule[] = []
  inheritedFields: GuestFieldKey[] = []
  disallowAccompanistDeletion!: boolean
}
