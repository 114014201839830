

















import { Component, Prop } from 'vue-property-decorator'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import type Guest from '@/models/Guest'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'
import { assertEventRights } from '@/services/eventRights'
import type { EventRights } from '@/models/Event/EventRights'

@Component({
  components: { DigiFormGroup }
})
export default class UtmTracking extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const rights = this.$store.state.event.rights as EventRights
    assertEventRights(rights)
    return rights
  }

  get isRegistrationQueryParamsActivated(): boolean {
    return this.eventRights.features.registration.utmTracking
  }

  get registrationUrlQueryValues(): string[] | null {
    if (!this.guest.registrationUrlQuery) {
      return null
    }
    return this.guest.registrationUrlQuery.split('&').map((query: string) => query.split('=').join(' = '))
  }
}
