































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class StepCard extends Vue {
  @Prop({ required: true }) readonly icon!: string
  @Prop({ required: true }) readonly title!: string
  @Prop({ default: '' }) readonly description!: string
  @Prop() readonly isValid!: boolean
}
