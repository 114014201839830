import axios from 'axios'
import type { Page } from '@/features/website/builder/builder/types'
import type { LocalizedPageBuilderConfig } from '@/features/website/builder/builder/types'

export async function updatePageContent({
  eventId,
  pageId,
  content,
  environment
}: {
  eventId: string
  pageId: string
  content: LocalizedPageBuilderConfig
  environment: 'draft' | 'published'
}) {
  await axios.put(`/events/${eventId}/site/page/${pageId}/${environment}/content`, content)
}

export async function createPage(eventId: string, page: { name: string; segmentIds: string[] }) {
  const { data } = await axios.post<{ pageId: string }>(`/events/${eventId}/site/pages`, page)
  return data
}

export async function getWebsitePages(eventId: string): Promise<Page[]> {
  const { data } = await axios.get<{ pages: Page[] }>(`/backoffice/events/${eventId}/site/pages`)
  return data.pages
}

export async function updatePageInfos(
  eventId: string,
  pageId: string,
  infos: Partial<Pick<Page, 'segmentIds' | 'name' | 'slug'>>
) {
  await axios.patch(`/backoffice/events/${eventId}/site/pages/${pageId}`, infos)
}

export async function duplicatePage(eventId: string, pageId: string): Promise<{ duplicatedPageId: string }> {
  const { data } = await axios.put<{ duplicatedPageId: string }>(
    `/backoffice/events/${eventId}/site/pages/duplicate/${pageId}`
  )
  return data
}

export async function deletePage(eventId: string, pageId: string) {
  await axios.delete(`/backoffice/events/${eventId}/site/pages/${pageId}`)
}

export async function flushPageDraft(eventId: string, pageId: string) {
  await axios.delete(`/events/${eventId}/site/page/${pageId}/content/draft`)
}

export async function portEventPagesToLang(eventId: string, fromLang: string, toLang: string) {
  await axios.post(`/backoffice/events/${eventId}/site/pages/port/${fromLang}/to/${toLang}`)
}
