import store from '@/store'
import { CHECKIN_TRIGGER_KIND } from './checkinRules/checkinRuleTriggers'
import type Rule from '@/models/Event/RulesData/Rule'
import type { ReadonlyFullRouteConfig } from '@/core'

export const checkinRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'application',
    name: 'checkin.settings.application',
    component: () => import('./app/views/CheckinAppSettings.vue'),
    meta: {
      name: 'CHECKIN_APP',
      keywords: 'FACTORY_CHECKIN_KEYWORDS',
      iconName: 'tablet-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'accessPass',
    name: 'event.accessPass',
    component: () => import('./accessPass/views/AccessPassConfiguration.vue'),
    meta: {
      name: 'FACTORY_ACCESS_PASS',
      keywords: 'FACTORY_ACCESS_PASS_KEYWORDS',
      iconName: 'qr-code-line',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.checkin.accessPass,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  },
  {
    path: 'pdfBadge',
    name: 'checkin.settings.pdfBadge',
    component: () => import('./pdfBadge/views/PDFBadge/index.vue'),
    meta: {
      name: 'FACTORY_BADGE_PDF',
      keywords: 'FACTORY_BADGES_PDF_KEYWORDS',
      iconName: 'profile-line',
      description: 'PDFBADGE_PAGE_DESCRIPTION',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.pdfBadges,
        redirectName: 'event.settings.inactiveFeatures'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.pdfBadge?.variants?.length > 0
        }
      }
    }
  },
  {
    path: 'checkinStickerBadge',
    name: 'checkin.settings.checkinStickerBadge',
    component: () => import('@/features/checkin/checkinStickerBadge/CheckinStickerBadge.vue'),
    meta: {
      name: 'FACTORY_BADGES',
      iconName: 'printer-line',
      description: 'FACTORY_BADGES_DESC',
      keywords: 'FACTORY_BADGES_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.checkin.printBadge,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  },
  {
    path: 'checkin/rules',
    name: 'checkin.rules',
    component: () => import('./checkinRules/CheckinRulesListView.vue'),
    meta: {
      name: 'FACTORY_RULES_LIST_CHECKIN',
      iconName: 'printer-line',
      keywords: 'FACTORY_RULES_CHECKIN_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.checkin.app,
        redirectName: 'event.settings.inactiveFeatures'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event.rules.ruleItems.filter((rule: Rule) => CHECKIN_TRIGGER_KIND.includes(rule.trigger.kind))
              .length > 0
          )
        }
      }
    }
  },
  {
    path: 'checkin/rules/edition/:ruleId?',
    name: 'checkin.rules.edition',
    component: () => import('./checkinRules/CheckinRulesEditionView.vue'),
    meta: {
      subPageOf: 'checkin.rules'
    }
  }
] as const
