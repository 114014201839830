import { ElementTypeDictionary } from '../../types'
import Map1Element from './map1/Map1Element'

export const mapDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Map1Element,
    renderer: () => import('./map1/Map1.vue'),
    properties: [
      {
        key: 'properties.marker.address',
        type: 'plaintext',
        label: 'LOCATION'
      },
      {
        key: 'properties.titleColor',
        type: 'color',
        label: 'TITLE_COLOR'
      },
      {
        key: 'properties.textColor',
        type: 'color',
        label: 'TEXT_COLOR'
      }
    ],
    typeNameSlug: 'BLOCK_MAP',
    previewImageLink: require('@/assets/img/prefab-previews/map.jpg')
  })
  .finish()
