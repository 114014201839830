
















import type { VideoPlatform } from '../../types/helperTypes'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class PlatformChooser extends Vue {
  @Prop() readonly videoPlatforms!: Record<VideoPlatform, { logoPath: string }>

  choosePlatform(platformName: string): void {
    this.$emit('platform-chosen', platformName)
  }
}
