import type { EventRights } from '@/models/Event/EventRights'

export type RegistrationType = 'paid' | 'single' | 'multi'

export function getRegistrationType(eventRights: EventRights): RegistrationType {
  if (eventRights.features.registration.mode === 'paid') {
    return 'paid'
  }
  if (eventRights.features.registration.type === 'multi') {
    return 'multi'
  }
  return 'single'
}
