import type Guest from '@/models/Guest'
import { validateGuestId } from '@/utils/validation'

export function isAnAdditional(guest: Guest): guest is Guest & { slaveRegistrationOf: string } {
  return guest.slaveRegistrationOf !== undefined && validateGuestId(guest.slaveRegistrationOf)
}

export function isAMain(guest: Guest): boolean {
  return Array.isArray(guest.masterRegistrationOf) && guest.masterRegistrationOf.length > 0
}
