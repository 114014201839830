import UAparser from 'ua-parser-js'
import { joinIfExist } from '@/utils/string'

const parser = new UAparser()

export function getClientUserAgent(): string {
  const agentOS = parser.getOS()
  const agentBrowser = parser.getBrowser()

  return `OS: ${agentOS.name}(${agentOS.version})  |  Browser : ${agentBrowser.name}(${agentBrowser.version}) | Screen : ${window.screen.width} x ${window.screen.height}`
}

export function formatUserAgent(userAgent: string): string {
  const parsedUserAgent = parser.setUA(userAgent).getResult()
  const browser = joinIfExist([parsedUserAgent.browser.name, parsedUserAgent.browser.version], ' ')
  const os = joinIfExist([parsedUserAgent.os.name, parsedUserAgent.os.version], ' ')
  if (!browser && !os) return userAgent
  return joinIfExist([browser, os], ' | ')
}
