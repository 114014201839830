








































































































import { snakeCase } from 'lodash'
import { prettyDate } from '@/lib/prettyDate'
import { getSlugForMessageStatusCriteriaValue, getSlugForSourceCriteriaValue } from '@/services/filter'

import { Vue, Component, Prop } from 'vue-property-decorator'
import type Filter from '@/models/Event/Filter'
import type { Event } from '@/models/Event'
import type { CriteriaProperty, MessageStatusCriteriaValue, SourceCriteriaValue } from '@/models/Event/Filter/Criteria'
import type Criteria from '@/models/Event/Filter/Criteria'
import type { MessageId } from '@/models/Event/Messages/template'
import type { MessageTemplate } from '@/models/Event/Messages/template'
import type ListCustomField from '@/models/Event/CustomField/fieldTypes/List'
import type { MongoDb } from '@/models/MongoDbTools'
import type { Activity, EventActivityId } from '@/models/Event/modules/Programme'
import { ErrorWithExtras } from '@/utils/errorTypes'
import type { Ticket, Addon } from '@/models/Event/modules/Payment'
import type { FormId } from '@/models/Event/Form'
import type { GuestField } from '@/models/Event/GuestField'
import { AccountEmail } from '@/components/features/account'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'

@Component({
  components: { AccountEmail },
  filters: {
    prettyDate
  }
})
export default class FilterCriterias extends Vue {
  @Prop({ required: false }) readonly filter?: Filter
  @Prop({ required: false }) readonly filters?: Filter[]
  @Prop() readonly showNames!: boolean
  @Prop() readonly canDelete!: boolean

  get event(): Event {
    return this.$store.getters.event
  }

  get eventId(): string {
    return this.event._id
  }

  get filterArray(): Filter[] {
    return [...(this.filter ? [this.filter] : []), ...(this.filters || [])]
  }

  get criteriaTypes(): (keyof Filter)[] {
    return ['andCriterias', 'orCriterias']
  }

  get allMessages(): MessageTemplate[] {
    return this.event.messages.templates
  }

  get listFieldsItemMap(): Record<MongoDb.ObjectId, string> {
    // Take all custom fields
    return (
      this.event.customFields
        // Filter to only list fields
        .filter((field): field is ListCustomField => field.type === 'list')
        // Create an object by accumulation over all fields
        .reduce<Record<MongoDb.ObjectId, string>>(
          (fieldAccumulator, field) =>
            // For each list
            field.list
              // Fill the parent accumulator by accumulation of the list's items
              .reduce((nestedFieldAccumulator, item) => {
                nestedFieldAccumulator[item._id] = item.value
                return nestedFieldAccumulator
              }, fieldAccumulator),
          {}
        )
    )
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get ticketAndAddonNameMap(): Record<MongoDb.ObjectId, string> {
    if (this.eventRights.features.registration.mode !== 'paid') return {}
    const ticketAndAddons: (Ticket | Addon)[] = this.event.modules_data.payment.tickets
    return ticketAndAddons
      .concat(this.event.modules_data.payment.addons)
      .reduce<Record<MongoDb.ObjectId, string>>((accumulator, ticket) => {
        accumulator[ticket._id] = ticket.name
        return accumulator
      }, {})
  }

  formatAsTranslationSlug(value: unknown): string {
    return snakeCase(String(value)).toUpperCase()
  }

  getPlanningActivityFromId(id: EventActivityId): Activity {
    if (!this.eventRights.features.eventApp.allowed) {
      throw new Error('Programme module is not enabled.')
    }
    return this.event.modules_data.programme.getActivityById(id)
  }

  deleteFilter(filter: Filter): void {
    if (this.filter) {
      this.$emit('update:filter', null)
    } else if (this.filters !== undefined) {
      const index = this.filters.findIndex((filterFromArr) => filterFromArr._id === filter._id)
      this.filters.splice(index, 1)
      this.$emit('update:filters', this.filters)
    }
  }

  getPropertyNameByKey(key: CriteriaProperty): string | null {
    {
      const guestField: GuestField | undefined = this.event.guestFields.find((f) => f.key === key)
      if (guestField && guestField.key !== 'invitationStatus') {
        return guestField.name
      }
    }

    if (key === 'planning') {
      return 'PLANNING'
    } else if (key.includes('MESSAGE_')) {
      const msgId: MessageId = key.split('_')[1]!
      const message: MessageTemplate | undefined = this.allMessages.find((m) => m._id === msgId)
      return message ? message.name : `(${msgId} not found)`
    } else if (key.includes('FORM_')) {
      const formId: FormId = key.split('_')[1]!
      return this.event.getFormById(formId).getLocalizedName(this.event.mainLanguageCode)
    } else if (key.startsWith('CHECKIN_')) {
      const pointId: FormId = key.split('_')[1]!
      const checkinModule = this.event.modules_data.checkin
      if (checkinModule) {
        if (pointId === 'ANY') return this.$t('SEGMENT_CRITERIA_CHECKIN_ANY')
        return checkinModule.getCheckinPointById(pointId).name
      } else {
        throw new ErrorWithExtras(
          'Filter contains a reference to a check-in point, but the event does not have checkin module data.',
          {
            pointId
          }
        )
      }
    } else if (key === 'orders.items.ticketId') {
      return 'TICKET_NAME'
    } else if (key === 'orders.status') {
      return 'ORDER_STATUS'
    } else if (key === 'orders.paymentType') {
      return 'PAYMENT_TYPE'
    } else if (key === 'orders.promoCodeTag') {
      return 'PROMO_CODE_NAME'
    } else if (key === 'invitationStatus') {
      if (this.$store.getters.isPaidEvent) {
        return 'PARTICIPATION_STATUS'
      } else {
        return 'INVITATION_STATUS'
      }
    } else if (key === 'registrationUrlQuery') {
      return 'REGISTRATION_QUERY_PARAMS'
    } else {
      return null
    }
  }

  criteriaIsWebsiteCheckpoint(criteria: Criteria): boolean {
    return criteria.property.startsWith('CHECKPOINT_')
  }

  getOperatorText(criteria: Criteria): string {
    if (this.criteriaIsWebsiteCheckpoint(criteria)) {
      switch (criteria.operator) {
        case 'IS_ARRIVED':
          return this.$t('CONNECTED')
        case 'ISNT_ARRIVED':
          return this.$t('NOT_CONNECTED')
      }
    }

    return this.$t(criteria.operator)
  }

  criteriaUsesPreciseDate(criteria: Criteria): boolean {
    // @TODO: Find a way to avoid the magic string check.
    return criteria.testedValue === 'PRECISE_DATE' && !!criteria.testedDate
  }

  hasBothTypesOfCriteria(filter: Filter): boolean {
    return filter.orCriterias && filter.orCriterias.length > 0 && filter.andCriterias && filter.andCriterias.length > 0
  }

  valueAsArray(value: unknown): unknown[] {
    if (Array.isArray(value)) return value
    else return [value]
  }

  getSlugForMessageStatusCriteriaValue(status: MessageStatusCriteriaValue): string {
    return getSlugForMessageStatusCriteriaValue(status)
  }

  getSlugForSourceCriteriaValue(value: SourceCriteriaValue): string {
    if (value.split('::')[1]) {
      return this.$t('CRITERIA_EXCEL') + ' ' + value.split('::')[1]
    }
    return getSlugForSourceCriteriaValue(value)
  }
}
