import type { ReadonlyFullRouteConfig } from '@/core'

export const dashboardRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'dashboard',
    name: 'event.dashboard',
    component: () => import('./view/DashboardView.vue'),
    meta: {
      name: 'DASHBOARD',
      iconName: 'dashboard-3-line',
      keywords: 'FACTORY_DASHBOARD_KEYWORDS',
      searchable: true,
      renderingData: {
        isContentFullWidth: true,
        shouldDisplayPageHeader: false
      }
    }
  }
] as const
