import store from '@/store'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import type { TriggerUtil } from '../../RuleEdition/types'

const checkinAnyTriggerUtil: TriggerUtil = {
  kind: TriggerKind.CheckinAny as const,
  label: 'CHECKIN_ARRIVED_ANY' as const,
  icon: 'pin-distance-line' as const,

  isAllowed(): boolean {
    return store.state.event?.rights.features.checkin.app
  }
}

export default checkinAnyTriggerUtil
