

























import type * as CSS from 'csstype'

import type { VideoPlatform } from '../../types/helperTypes'
import { Component, Prop } from 'vue-property-decorator'
import { DigiIconButton } from '@/components/ui/actions'
import { getIframeSrc } from '@/features/website/builder/builder/getIframeSrc'
import Vue from 'vue'

@Component({
  components: { DigiIconButton }
})
export default class VideoDisplayFragment extends Vue {
  @Prop() readonly maxHeightValue!: CSS.Property.MaxHeight
  @Prop() readonly videoPlatform?: VideoPlatform
  @Prop() readonly videoCode!: string

  get maxHeight() {
    return {
      '--limited-height': `${this.maxHeightValue}px`
    }
  }

  get videoSrc() {
    if (!this.videoPlatform) return ''

    return getIframeSrc(this.videoPlatform, this.videoCode)
  }
}
