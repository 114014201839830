import { cloneDeep, isEqual as lodashIsEqual } from 'lodash'

export function getObjectDiff<T>(originalObj: T, obj: T): Partial<T> {
  const modifications: Partial<T> = cloneDeep(obj)
  for (const key in originalObj) {
    const newValue = obj[key]
    const originalValue = originalObj[key]
    const isNotModified = lodashIsEqual(newValue, originalValue)
    if (isNotModified) {
      delete modifications[key]
    }
  }
  return modifications
}

export function isEqual<T extends {}>(object1: T, object2: T) {
  return lodashIsEqual(object1, object2)
}

export function isNotEqual<T extends {}>(object1: T, object2: T): boolean {
  return !lodashIsEqual(object1, object2)
}
