




import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import type CheckboxCustomField from '@/models/Event/CustomField/fieldTypes/Checkbox'

@Component({})
export default class GuestFieldBooleanInput extends Vue {
  @Prop({ required: true }) readonly field!: CheckboxCustomField
  @VModel() readonly fieldValue!: boolean
}
