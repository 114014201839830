/**
 * Always returns a number in `00.00` format (even if the decimals equal to zero).
 */
export function roundTwoDecimals(number: number): number {
  return Math.round(number * 100) / 100
}

export function add(...numbers: number[]): number {
  let total = 0
  for (const number of numbers) {
    total += number
  }
  return roundTwoDecimals(total)
}
