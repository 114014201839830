import axios from 'axios'
import type { SocialLinks, SocialNetwork } from '@/models/Event/Identity'

export async function patchEventSocialsLink(socialsLinks: Partial<SocialLinks>, eventId: string): Promise<void> {
  await axios.patch(`backoffice/events/${eventId}/organizerIdentity/socialLinks`, { data: socialsLinks })
}

export function getActiveSocialNetworks(socialsLinks: SocialLinks): SocialLinks {
  return Object.fromEntries(Object.entries(socialsLinks).filter(([, value]) => value))
}

export function getActiveSocialNetworksNames(socialsLinks: SocialLinks): SocialNetwork[] {
  return Object.keys(getActiveSocialNetworks(socialsLinks)) as SocialNetwork[]
}
