import type { EventIdentity } from '@/core'
import { doIHavePermission } from '@/services/eventPermissions'

export const DIGITEVENT_DOCUMENTATION_LINK =
  'https://wearedigitevent.notion.site/80fa983b0a444973a78ce1145860dc21?v=19c74797c7a84dc09e1bc8ce2fda7b64' as const

export function canContactSales(eventIdentity: EventIdentity | undefined): boolean {
  const isOutsideEvent = !eventIdentity
  const isDemo = eventIdentity?.isDemo === true
  return isOutsideEvent || isDemo
}

export async function hasOpenTicketPermission(eventId?: string): Promise<boolean> {
  if (!eventId) {
    return true
  }
  return await doIHavePermission(eventId, 'openSupportTicket')
}
