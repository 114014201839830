import InvitationStatus from '@/models/Guest/InvitationStatus'
import type { EventRights } from '@/models/Event/EventRights'

const statusColors: Record<InvitationStatus, string> = {
  [InvitationStatus.NoStatus]: 'blue',
  [InvitationStatus.Confirmed]: '#60E2A9',
  [InvitationStatus.Invited]: '#FFDC61',
  [InvitationStatus.Declined]: '#FF6A79',
  [InvitationStatus.Blacklisted]: 'black',
  [InvitationStatus.Active]: '#60E2A9'
}

const statusSlugs: Record<InvitationStatus, string> = {
  [InvitationStatus.NoStatus]: 'NO_STATUS',
  [InvitationStatus.Confirmed]: 'CONFIRMED',
  [InvitationStatus.Invited]: 'INVITED',
  [InvitationStatus.Declined]: 'DECLINED',
  [InvitationStatus.Blacklisted]: 'BLACKLISTED',
  [InvitationStatus.Active]: 'ACTIVE'
}

const statusExplanationSlugs: Record<InvitationStatus, string> = {
  [InvitationStatus.NoStatus]: 'NO_STATUS_EXPLANATION',
  [InvitationStatus.Confirmed]: 'CONFIRMED_EXPLANATION',
  [InvitationStatus.Invited]: 'INVITED_EXPLANATION',
  [InvitationStatus.Declined]: 'DECLINED_EXPLANATION',
  [InvitationStatus.Blacklisted]: 'BLACKLISTED_EXPLANATION',
  [InvitationStatus.Active]: ''
}

const statusIcons: Record<InvitationStatus, string> = {
  [InvitationStatus.NoStatus]: 'focus-2-line',
  [InvitationStatus.Confirmed]: 'user-follow-line',
  [InvitationStatus.Invited]: 'mail-send-line',
  [InvitationStatus.Declined]: 'user-unfollow-line',
  [InvitationStatus.Blacklisted]: 'close-circle-line',
  [InvitationStatus.Active]: ''
}

export function getPossibleStatuses(eventRights: EventRights): InvitationStatus[] {
  if (eventRights.features.registration.mode === 'paid') {
    return [InvitationStatus.Invited, InvitationStatus.Declined, InvitationStatus.NoStatus]
  } else {
    return [
      InvitationStatus.NoStatus,
      InvitationStatus.Confirmed,
      InvitationStatus.Invited,
      InvitationStatus.Declined,
      InvitationStatus.Blacklisted
    ]
  }
}

export function getStatusColor(status: InvitationStatus): string {
  return statusColors[status]
}
export function getStatusSlug(status: InvitationStatus): string {
  return statusSlugs[status]
}
export function getStatusExplanationSlug(status: InvitationStatus): string {
  return statusExplanationSlugs[status]
}
export function getStatusIcon(status: InvitationStatus): string {
  return statusIcons[status]
}
