
























import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import type { IconType } from '@/services/iconLibrary'
import { getIconFromDictionary } from '@/services/iconLibrary'

@Component({})
export default class MultiSelectorItem extends Vue {
  @Prop({ required: true }) readonly iconLibraryKey!: IconType
  @Prop({ required: true }) readonly text!: string
  @Prop({ default: false }) readonly required!: boolean
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({}) readonly disabledTooltipLabel?: string
  @Prop({ default: true }) readonly draggable!: boolean
  @Prop({ default: false }) readonly locked!: boolean
  @Prop({ default: 'add-line' }) readonly hintRemixIcon!: string
  @Prop({ required: true }) readonly hintText!: string

  @Emit('click')
  onClick() {
    return undefined
  }

  get remixIcon(): string {
    return getIconFromDictionary(this.iconLibraryKey)
  }
}
