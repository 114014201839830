import type { AxiosResponse } from 'axios'
import Vue from 'vue'
import { i18n } from '@/utils/i18n'
import type { GuestId } from '@/models/Guest'
import type Guest from '@/models/Guest'
import type { OrderId } from '@/models/Guest/Order'
import type Order from '@/models/Guest/Order'
import { OrderStatus } from '@/models/Guest/Order'
import type { EventId } from '@/models/Event'
import type { Transaction } from '@/models/Guest/Order/Transaction'
import { PaymentMethodType, TransactionStatus, transactionProviderType } from '@/models/Guest/Order/Transaction'
import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { z } from 'zod'
import type { Amount100 } from '@/models/Guest/Order/money'
import { amount100 } from '@/models/Guest/Order/money'

export interface ItemInCreationAddon {
  addonId: string
  quantity: number
}

export interface ItemInCreationBase {
  ticketId: string
  addons: ItemInCreationAddon[]
}
export interface PaymentSummary {
  other: number
  bankWire: number
  cb: number
  cash: number
  refunded: number
  totalPaid: number
  remainsToPay: number
  balance: number
  paidDate: Date | null
}

export interface OrderDetails {
  preDiscountWithoutVAT: number
  postDiscountWithoutVAT: number
  preDiscountWithVAT: number
  postDiscountWithVAT: number
  totalDeductibleForCerfa: number
  VATAmount: number
  totalAmount: number
  tickets: any[]
}

export interface OrderData {
  orderDetails: OrderDetails
  paymentSummary: PaymentSummary
}

export function getOrderById(guest: Guest, orderId: OrderId): Order {
  if (!guest) throw new Error('Guest not found')

  const order = guest.orders.find((order) => {
    return order._id === orderId
  })

  if (order) {
    return order
  } else {
    throw new Error(`orderId ${orderId} not found at guest ${guest._id}`)
  }
}

export async function getOrderData(eventId: EventId, guestId: GuestId, orderId: OrderId): Promise<OrderData> {
  return (await axios.get<OrderData>(`/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}`)).data
}

export async function updateMangoTransaction(
  eventId: EventId,
  guestId: GuestId,
  orderId: OrderId,
  transactionId: string
) {
  const res = await axios.put<{ guest: Guest; orderId: OrderId }>(
    `/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/transaction/${transactionId}/updateStatus`
  )
  Vue.notify({ text: i18n.t('STATUS_UPDATED'), type: 'success' })
  return res.data
}

export async function manualSyncTransaction(
  eventId: EventId,
  guestId: GuestId,
  orderId: OrderId,
  transactionId: string
): Promise<Guest> {
  const response = await axios.put<Guest>(
    `/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/transaction/${transactionId}/manualSync`
  )

  return response.data
}

export interface CreditNotePayload {
  amountWithoutVat: number
  amountVatIncluded: number
  description: string
  organizerComment?: string
}

export async function createCreditNote({
  eventId,
  guestId,
  orderId,
  creditNote
}: {
  eventId: string
  guestId: string
  orderId: string
  creditNote: CreditNotePayload
}): Promise<void> {
  await axios.post<Guest>(`/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/creditNote`, {
    creditNote
  })
}

export async function updateCreditNote({
  eventId,
  guestId,
  orderId,
  creditNote
}: {
  eventId: string
  guestId: string
  orderId: string
  creditNote: CreditNotePayload
}): Promise<void> {
  await axios.patch<Guest>(`/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/creditNote`, {
    creditNote
  })
}

export async function deleteCreditNote(guestId: string, eventId: string, orderId: string): Promise<void> {
  await axios.delete<Guest>(`/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/creditNote`)
}

interface OrderDtoAddon {
  addonId: string
  quantity: number
}

interface OrderDtoItem {
  guestId?: string
  guestData?: Record<string, unknown>
  ticketId: string
  addons: OrderDtoAddon[]
}
export interface OrderDto {
  tickets: OrderDtoItem[]
}
export async function calcOrderAmount(eventId: EventId, order: OrderDto, promoCodeTag: string | null): Promise<number> {
  const requestConfig: AxiosRequestConfig = {
    method: 'GET',
    url: `/backoffice/events/${eventId}/calcOrderAmount`,
    params: { order }
  }

  if (promoCodeTag) {
    requestConfig.params.promoCodeTag = promoCodeTag
  }

  const res = await axios.request<{ orderAmount: number }>(requestConfig)
  return res.data.orderAmount
}

export async function removePayment(
  eventId: EventId,
  guest: Guest,
  orderId: OrderId,
  transactionId: string
): Promise<Guest> {
  const response: AxiosResponse = await axios.put<{ guest: Guest; orderId: OrderId }>(
    `/backoffice/events/${eventId}/guest/${guest._id}/order/${orderId}/transaction/${transactionId}/delete`
  )
  await Vue.notify({
    text: i18n.t('STATUS_UPDATED'),
    type: 'success'
  })
  return response.data
}

export async function createOrder(
  eventId: EventId,
  guestId: GuestId,
  orderToInit: OrderDto,
  promoCodeTag: string | null
): Promise<{ guest: Guest; orderId: OrderId }> {
  const requestConfig: AxiosRequestConfig = {
    method: 'post',
    url: `/backoffice/events/${eventId}/guest/${guestId}/order`,
    data: {
      orderToInit
    }
  }

  if (promoCodeTag) {
    requestConfig.data.promoCodeTag = promoCodeTag
  }

  const res = await axios.request<{ guest: Guest; orderId: OrderId }>(requestConfig)
  return res.data
}

export async function cancelOrder(guest: Guest, order: Order): Promise<Guest> {
  let recordCashRes
  try {
    recordCashRes = await axios.put<Guest>(
      `/backoffice/events/${guest.parentEvent}/guest/${guest._id}/order/${order._id}/cancel`,
      {}
    )
  } catch (err: any) {
    if (err) {
      Vue.notify({ text: err.response.data.message, type: 'error' })
    }
    throw err
  }
  Vue.notify({ text: i18n.t('ORDER_CANCELED'), type: 'success' })
  return recordCashRes.data
}
export async function toggleGuestTicketValidity(
  guestId: string,
  eventId: string,
  ticketItemId: string,
  newStatus: 'canceled' | 'active'
) {
  const updateTicketRes = await axios.put<Guest>(
    `/backoffice/events/${eventId}/guest/${guestId}/tickets/${ticketItemId}`,
    {
      newTicketStatus: newStatus
    }
  )
  return updateTicketRes.data
}
export interface CashRecordPayload {
  paidDate: Date
  amount: number
}
export async function recordCashPayment(
  eventId: string,
  guestId: string,
  orderId: string,
  payload: CashRecordPayload
): Promise<Guest> {
  const recordPaymentRes = await axios.put<Guest>(
    `/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/recordPayment/cash`,
    payload
  )
  return recordPaymentRes.data
}
export interface OtherPaymentPayload {
  paidDate: Date
  amount: number
  transactionReference?: string
  receiptDate?: Date
}
export async function recordOtherPayment(
  eventId: string,
  guestId: string,
  orderId: string,
  payload: OtherPaymentPayload
) {
  const recordPaymentRes = await axios.put<Guest>(
    `/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/recordPayment/other`,
    payload
  )
  return recordPaymentRes.data
}

export async function refundMpTransaction({
  eventId,
  guestId,
  orderId,
  transactionId,
  refundAmount100,
  vatRate,
  organizerComment
}: {
  eventId: string
  guestId: string
  orderId: string
  transactionId: string
  refundAmount100: Amount100
  vatRate: number
  organizerComment: string
}) {
  const res = await axios.put<Guest>(
    `/backoffice/events/${eventId}/guest/${guestId}/order/${orderId}/transaction/${transactionId}/refund`,
    { refundAmount100, vatRate, organizerComment }
  )
  return res.data
}

export async function getRefundFeePreview({
  eventId,
  transactionAmount100,
  refundAmount100,
  methodType
}: {
  eventId: string
  transactionAmount100: number
  refundAmount100: number
  methodType: string
}): Promise<RefundFeePreview> {
  return axios
    .get(`/backoffice/events/${eventId}/transaction/refund/fee/preview`, {
      params: {
        transactionAmount100,
        refundAmount100,
        methodType
      }
    })
    .then((res) => res.data)
}

export type RefundFeePreview = {
  transactionFee100: Amount100
  refundFee100: Amount100
  total100: Amount100
}

export async function cashOutToOrganizer(eventId: EventId) {
  const res = await axios.post<{ event: Event }>(`/backoffice/events/${eventId}/cashout`)
  return res.data
}

export function humanizeOrderStatus(status: OrderStatus): string {
  return i18n.t(orderStatusUi[status].slug)
}
interface groupItem {
  _id: string
  sold: number
}
export type TicketStats = {
  nbTicketHolders: number
  ticketStats: groupItem[]
  addonStats: groupItem[]
}
export async function getDashboardTicketStats(eventId: EventId, ignoreCache: boolean) {
  const res = await axios.get<TicketStats>(
    `/backoffice/events/${eventId}/dashboardTicketStats?ignoreCache=${ignoreCache}`
  )
  return res.data
}

export const zTransactionStat = z.object({
  // Dimensions
  status: z.nativeEnum(TransactionStatus),
  provider: transactionProviderType,
  methodType: z.nativeEnum(PaymentMethodType),
  // Stats
  nbTransactions: z.number().int().nonnegative(),
  nbGuests: z.number().int().nonnegative(),
  feeCollected100: amount100,
  transferredToPlatformAccount100: amount100,
  transferredToOrganizerSubaccount100: amount100,
  transferredFromOrganizerSubaccount100: amount100,
  /**
   * This is meant for cash and 'other' payment methods.
   * It means that the organizer collected the money himself.
   */
  manuallyCollectedByOrganizer100: amount100,
  receivable100: amount100,
  received100: amount100,
  refunded100: amount100,
  refundable100: amount100
})

export type TransactionStat = z.infer<typeof zTransactionStat>

export interface TransactionAndOrderStatBase {
  nbGuests: number
  totalAmount: number
}

export function getDashboardOrderStats(eventId: EventId, ignoreCache: boolean) {
  return axios
    .get<Record<OrderStatus, TransactionAndOrderStatBase>>(
      `/backoffice/events/${eventId}/dashboardOrderStats?ignoreCache=${ignoreCache}`
    )
    .then((res) => {
      return res.data
    })
}

export function getDashboardTransactionStats(eventId: EventId, ignoreCache: boolean) {
  return axios
    .get<TransactionStat[]>(`/backoffice/events/${eventId}/dashboardTransactionStats?ignoreCache=${ignoreCache}`)
    .then((res) => {
      return res.data
    })
}

export interface DashboardRecentOrder {
  guestId: string
  orderId: string
  status: OrderStatus
  lastChangeDate: string
  firstname?: string
  lastname: string
}
export async function getDashboardRecentOrders(eventId: EventId, ignoreCache: boolean) {
  const res = await axios.get<DashboardRecentOrder[]>(
    `/backoffice/events/${eventId}/dashboardRecentOrders?ignoreCache=${ignoreCache}`
  )
  return res.data
}

interface OrderStatusUi {
  variant: string
  icon: string
  slug: string
  explanation: string
}

export const orderStatusUi: Record<OrderStatus, OrderStatusUi> = {
  [OrderStatus.Paid]: {
    variant: 'success',
    icon: 'shopping-cart-line',
    slug: 'ORDERSTATUS_PAID',
    explanation: 'ORDERSTATUS_PAID_EXPLANATION'
  },
  [OrderStatus.ToRefund]: {
    variant: 'dark',
    icon: 'refund-2-line',
    slug: 'ORDERSTATUS_TO_REFUND',
    explanation: 'ORDERS_STATUS_TO_REFUND_EXPLANATION'
  },
  [OrderStatus.InProgress]: {
    variant: 'warning',
    icon: 'timer-line',
    slug: 'ORDERSTATUS_IN_PROGRESS',
    explanation: 'ORDERS_STATUS_IN_PROGRESS_EXPLANATION'
  },
  [OrderStatus.Canceled]: {
    variant: 'light',
    icon: 'close-circle-line',
    slug: 'ORDERSTATUS_CANCELED',
    explanation: 'ORDERS_STATUS_CANCELED_EXPLANATION'
  },
  [OrderStatus.Refunded]: {
    variant: 'success',
    icon: 'refund-2-line',
    slug: 'ORDERSTATUS_REFUNDED',
    explanation: 'ORDERS_STATUS_REFUNDED_EXPLANATION'
  },
  [OrderStatus.Failed]: {
    variant: 'danger',
    icon: 'error-warning-line',
    slug: 'ORDERSTATUS_FAILED',
    explanation: 'ORDERS_STATUS_FAILED_EXPLANATION'
  }
}

export const transactionTypesUi: Record<PaymentMethodType, { slug: string; icon: string }> = {
  bankWire: { slug: 'BANK_WIRE', icon: 'bank-line' },
  card: { slug: 'CREDIT_CARD', icon: 'bank-card-line' },
  cardAmex: { slug: 'CREDIT_CARD_AMEX', icon: 'bank-card-line' },
  cash: { slug: 'CASH', icon: 'hand-coin-line' },
  otherPaymentMethod: { slug: 'AUTHORIZE_OTHER_PAYMENT_METHOD', icon: 'edit-2-line' },
  sepaDebit: { slug: 'SEPA_DEBIT', icon: 'bank-line' },
  stripeLink: { slug: 'STRIPE_LINK', icon: 'hand-coin-line' },
  notChosen: { slug: 'TRANSACTION_TYPE_NOT_CHOSEN', icon: 'question-mark-circle-line' }
}

export function getTransactionTypeSlug(transaction: Transaction): string {
  if (transaction.methodType === 'notChosen') {
    return 'TRANSACTION_TYPE_NOT_CHOSEN'
  }
  if (transaction.direction === 'refund') {
    return 'A_REFUND'
  }
  return transactionTypesUi[transaction.methodType].slug
}

export function getTransactionTypeIcon(transaction: Transaction): string {
  if (transaction.methodType === 'notChosen') {
    return 'question-mark-circle-line'
  }
  if (transaction.direction === 'refund') {
    return 'refund-2-line'
  }
  return transactionTypesUi[transaction.methodType].icon
}

export interface PromoCodeStat {
  promoCode: string
  count: number
}

export function getPromoCodeStats(eventId: EventId) {
  return axios.get<PromoCodeStat[]>(`/backoffice/events/${eventId}/ticketing/promoCodes/stats`).then((res) => res.data)
}
