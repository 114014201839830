














import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { Certificate } from '@/models/Event/modules/Certificate'
import type Guest from '@/models/Guest'
import { Component, Prop } from 'vue-property-decorator'
import { DigiLink } from '@/components/ui/actions'
import { getGuestCertificates } from '@/features/certificate'
import ContactCertificateItem from './ContactCertificateItem.vue'

@Component({
  components: { ContactCertificateItem, DigiLink }
})
export default class ContactCertificates extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get matchingCertificates(): Certificate[] {
    return getGuestCertificates(this.guest, this.storeEvent)
  }

  get hasCertificate(): boolean {
    return this.matchingCertificates.length > 0
  }

  get guestId() {
    return this.guest._id
  }
}
