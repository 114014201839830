










import type Guest from '@/models/Guest'
import { Component, Prop } from 'vue-property-decorator'
import { ContactCertificates } from '@/features/certificate'
import ContactProfileActionsSubMenu from '../ui/ContactProfileActionsSubMenu.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileActionsSubMenu, ContactCertificates }
})
export default class ContactProfileCertificatesSubMenu extends Vue {
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }
}
