




import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import type MeetingSchedule from '@/models/Guest/Planning/ScheduledItem/Meeting'
import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningDateRange from '../PlanningDateRange.vue'

@Component({ components: { PlanningDateRange } })
export default class MeetingDateRange extends Vue {
  @Prop({ required: true }) readonly meeting!: MeetingSchedule
  @Prop({ required: true }) readonly matchMakingSession!: MatchmakingSession

  get dateRangeString(): string {
    const startDateString: string = moment(this.meeting.dateRange[0]).format('LT')
    const endDateString = moment(this.meeting.dateRange[1])
      .subtract(this.matchMakingSession.includedBreakDuration, 'minutes')
      .format('LT')
    return `${startDateString} - ${endDateString}`
  }
}
