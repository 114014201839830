















import { Vue, Component, Prop } from 'vue-property-decorator'
import MenuItem from '@/navigation/eventMenu/components/MenuItem.vue'
import type { EventMenuSection } from '@/navigation/eventMenu'
import BaseMenuSection from './BaseMenuSection.vue'

@Component({
  components: { BaseMenuSection, MenuItem }
})
export default class ListMenuSection extends Vue {
  @Prop({ required: true }) readonly section!: EventMenuSection
  @Prop({ required: true }) readonly isActive!: boolean
  isOpenLocally = false

  get classes() {
    return {
      'menu-section--expanded': this.isOpenLocally
    }
  }

  toggle() {
    this.isOpenLocally = !this.isOpenLocally
  }

  close() {
    this.isOpenLocally = false
  }

  open() {
    this.isOpenLocally = true
  }
}
