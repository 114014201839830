















import { Vue, Component, Prop } from 'vue-property-decorator'
import type { NamedSubmittedForm } from '../type'
import { DigiCardItem } from '@/components/ui/cardItem'
import { DigiLink } from '@/components/ui/actions'
import { formatUserAgent } from '@/utils/userAgent'

@Component({
  components: { DigiLink, DigiCardItem }
})
export default class SubmittedSurveyItem extends Vue {
  @Prop({ required: true }) readonly submittedForm!: NamedSubmittedForm

  get formattedUserAgent() {
    return formatUserAgent(this.submittedForm.userAgent)
  }
}
