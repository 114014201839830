import { ElementTypeDictionary } from '../../types'
import { Trombino1Element } from './trombino1'
import type { GuestField, GuestFieldType } from '@/models/Event/GuestField'
import { CustomFieldType } from '@/models/Event/CustomField'
import type { GuestFieldKey } from '@/models/Event/GuestField'

const TYPES_ELIGIBLE_FOR_TROMBINO: GuestFieldType[] = [
  CustomFieldType.Checkbox,
  CustomFieldType.List,
  CustomFieldType.Text
]

const NON_ELIGIBLE_FIELD_KEYS_FOR_DISPLAY: GuestFieldKey[] = [
  'source',
  'invitationStatus',
  'unsubscribe',
  'addedByAccountId'
]

const REQUIRED_FIELD_KEYS_FOR_DISPLAY: GuestFieldKey[] = ['firstname', 'name', 'photo', 'job', 'organization']

function filterFieldsForSearch(guestField: GuestField): boolean {
  return TYPES_ELIGIBLE_FOR_TROMBINO.includes(guestField.type)
}

function filterFieldsForDisplay(guestField: GuestField): boolean {
  return !NON_ELIGIBLE_FIELD_KEYS_FOR_DISPLAY.includes(guestField.key)
}

export const trombinoDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Trombino1Element,
    renderer: () => import('./trombino1/PrefabTrombino1.vue'),
    isAvailable: ({ eventRights }) => eventRights.features.website.trombinoPrefab,
    properties: [
      { type: 'segmentSelector', label: 'TROMBINO_SEGMENTS_LABEL', key: 'properties.segmentId' },
      {
        type: 'fieldSelector',
        label: 'TROMBINO_SEARCH_FIELDS_LABEL',
        key: 'properties.searchFieldsIds',
        filter: filterFieldsForSearch
      },
      {
        type: 'fieldSelector',
        label: 'TROMBINO_CARD_FIELDS_LABEL',
        key: 'properties.cardFieldsIds',
        filter: filterFieldsForDisplay,
        requiredFields: REQUIRED_FIELD_KEYS_FOR_DISPLAY,
        canOrderFields: true
      },
      {
        type: 'color',
        label: 'TROMBINO_INNER_BKG_COLOR',
        key: 'properties.innerBkgColor',
        toContrastWith: 'box.backgroundColor'
      },
      { type: 'boolean', label: 'IMAGE_GRAYSCALE', key: 'properties.displayAvatarInGrayscale' }
    ],
    typeNameSlug: 'WEBSITE_BLOCKS_TROMBINO',
    previewImageLink: require('@/assets/img/prefab-previews/trombino-1.jpg')
  })
  .finish()
