






import { Vue, Component, Ref } from 'vue-property-decorator'

@Component
export default class DigiModal extends Vue {
  @Ref() readonly modal!: HTMLDialogElement

  open() {
    this.modal.showModal()
    this.removeFocus()
  }

  close() {
    this.modal.close()
  }

  removeFocus() {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement && this.modal.contains(activeElement)) {
      activeElement.blur()
    }
  }
}
