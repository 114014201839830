import type { ReadonlyFullRouteConfig } from '@/core'

export const legalEntityRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'legalInformations',
    name: 'event.settings.legalinformations',
    component: () => import('@/features/eventSettings/legalEntity/EditLegalEntityView.vue'),
    meta: {
      name: 'COMPANY_INFORMATIONS',
      iconName: 'building-4-line',
      keywords: 'FACTORY_LEGAL_INFORMATIONS_KEYWORDS',
      searchable: true
    }
  }
] as const
