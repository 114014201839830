











import TinyEditor from '@tinymce/tinymce-vue'
import type { RawEditorSettings } from 'tinymce'
import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TinyEditor
  }
})
export default class SlightlyRichTextFragment extends Vue {
  @VModel({ required: true }) textContent!: string
  @Prop({ type: Boolean }) readonly disabled!: boolean
  @Prop() placeholder: string | undefined

  // Tinymce-vue is not well typed and does not offer a type for instances of itself
  @Ref() readonly tinyEditor!: Vue

  beingDestroyed: boolean = false

  get computedPlaceholder(): string {
    if (typeof this.placeholder === 'string') {
      return this.placeholder
    } else {
      return this.$t('FORM_TEXTAREA_PLACEHOLDER')
    }
  }

  // @TODO: Refactor this into the tinymce options util maybe.
  get tinyMceOptions(): RawEditorSettings {
    const getTinymceLanguage = () => {
      switch (this.$store.getters.userLang) {
        case 'fr':
          return 'fr_FR'
        case 'pt':
          return 'pt_BR'
        default:
          return undefined
      }
    }

    const getPlugins = () => {
      return 'autolink paste link lists'
    }

    const options: RawEditorSettings = {
      inline: true,
      language: getTinymceLanguage(),
      skin_url: '/js/skins/ui/oxide',
      icons_url: `/js/icons/default/icons.js`,
      theme: 'silver',
      plugins: getPlugins(),
      statusbar: false,
      menubar: false,
      toolbar1: 'undo redo | bold italic underline | removeformat | bullist numlist | link',
      contextmenu: 'selectall | cut copy paste | link',
      fontsize_formats: '9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 22px 24px 26px 36px 40px',
      default_link_target: '_blank',
      placeholder: this.computedPlaceholder,
      paste_as_text: true
    }

    if (options.language !== undefined) {
      options.language_url = `/tinymce_translations/${options.language}.js`
    }

    return options
  }

  handleEditorInput(newText: string): void {
    if (this.beingDestroyed) {
      console.warn('Received editor input during component destruction. Ignoring.')
    } else {
      this.textContent = newText
      // Workaround for issue tinymce/tinymce-vue#57
      this.tinyEditor.editor.save()
    }
  }

  beforeDestroy(): void {
    this.beingDestroyed = true
  }
}
