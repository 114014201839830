import checkin from './checkin'
import registration from './registration'
import orders from './orders'
import matchmaking from './matchmaking'
import survey from './survey'
import type { TriggerUtil } from '../RuleEdition/types'
import type { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'

const triggerUtils: Record<TriggerKind, TriggerUtil> = {
  ...checkin,
  ...registration,
  ...orders,
  ...matchmaking,
  ...survey
}

export default triggerUtils
