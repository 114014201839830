import { Type } from 'class-transformer'
import type { DeleteContext } from '../../types/PageElement'
import ContentElementBase from '../ContentElementBase'
import { i18n } from '@/utils/i18n'
import { TextAlignment } from '../helperTypes'

import type { LinkDestination } from '../helperTypes'
import { NullLinkDestination } from '../helperTypes'

export class ImageProperties {
  imageUrl: string | null = null
  linkTo: LinkDestination = new NullLinkDestination()
  altText: string = ''
}

export class PositionedImageProperties extends ImageProperties {
  align: TextAlignment = TextAlignment.Center
  useWidthAsPercent: boolean = false
  widthPercent: number = 100

  get fullWidth(): boolean {
    return this.useWidthAsPercent && this.widthPercent === 100
  }
  set fullWidth(value: boolean) {
    if (value !== undefined) {
      this.useWidthAsPercent = value

      if (value === true) {
        this.widthPercent = 100
      }
    }
  }
}

export default class ImageElement extends ContentElementBase {
  static create(): ImageElement {
    return new ImageElement()
  }

  type: 'content-image' = 'content-image'

  @Type(() => PositionedImageProperties)
  properties = new PositionedImageProperties()

  prettyName = i18n.t('BLOCK_IMAGE')

  onDeleteElement(context: DeleteContext) {
    if (context.isSelf && this.properties.imageUrl !== null) {
      console.warn(`Deleting image element ${context.id} while it contains an image.
      The image file may not have been deleted from the servers.`)
    }
  }
}
