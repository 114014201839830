











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LockingOverlay extends Vue {
  @Prop({ default: false }) readonly isLoading!: boolean
  @Prop({ required: true }) readonly explanation!: string
}
