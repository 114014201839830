import { sessionsRoutes } from './sessions'
import { sponsorsRoutes } from './sponsors'
import { programmeSettingsRoutes } from './settings'
import type { ReadonlyFullRouteConfig } from '@/core'
import { networkingRoutes } from './networking'

export const programmeRoutes: ReadonlyFullRouteConfig = [
  ...sessionsRoutes,
  ...sponsorsRoutes,
  ...programmeSettingsRoutes,
  ...networkingRoutes
]
