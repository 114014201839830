import type { MeetingStatus } from '../../../../services/matchmakingSession'

export enum TableFieldKey {
  Date = 'date',
  Time = 'time',
  Applicant = 'applicant',
  Target = 'target',
  Location = 'location',
  Edition = 'edition'
}

export type SortableFieldKey = TableFieldKey.Date

export interface SortingOptions {
  key: SortableFieldKey
  descending: boolean
}

export const MEETING_STATUS_SLUGS: Record<MeetingStatus, string> = {
  upcoming: 'MEETING_STATUS_UPCOMING',
  ended: 'MEETING_STATUS_ENDED',
  endedChecked: 'MEETING_STATUS_ENDED_CHECKED',
  inProgress: 'MEETING_STATUS_ONGOING',
  inProgressChecked: 'MEETING_STATUS_ONGOING_CHECKED',
  awaitingConnection: 'MEETING_STATUS_AWAITING_CONNECTION',
  unhonored: 'MEETING_STATUS_UNHONORED',
  canceled: 'MEETING_STATUS_CANCELED',
  pendingAcceptation: 'MEETING_STATUS_PENDING_ACCEPTATION',
  declined: 'MEETING_STATUS_DECLINED'
}

export const MEETING_STATUS_DESCRIPTION_SLUGS: Record<MeetingStatus, string> = {
  upcoming: 'MEETING_STATUS_UPCOMING_DESCRIPTION',
  ended: 'MEETING_STATUS_ENDED_DESCRIPTION',
  endedChecked: 'MEETING_STATUS_ENDED_CHECKED_DESCRIPTION',
  inProgress: 'MEETING_STATUS_ONGOING_DESCRIPTION',
  inProgressChecked: 'MEETING_STATUS_ONGOING_CHECKED_DESCRIPTION',
  awaitingConnection: 'MEETING_STATUS_AWAITING_CONNECTION_DESCRIPTION',
  unhonored: 'MEETING_STATUS_UNHONORED_DESCRIPTION',
  canceled: 'MEETING_STATUS_CANCELED_DESCRIPTION',
  pendingAcceptation: 'MEETING_STATUS_PENDING_ACCEPTATION_DESCRIPTION',
  declined: 'MEETING_STATUS_DECLINED_DESCRIPTION'
}

export const MEETINGS_PER_PAGE = 25
