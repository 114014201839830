








































import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { Quote2ItemProperties } from '../Quote2Element'
import { PlainTextFragment, ImageFragment, SlightlyRichTextFragment } from '../../../../fragments'
import AvatarUpload from '@/components/features/fileUpload/AvatarUpload.vue'
import { DigiDeleteButton } from '@/components/ui/actions'
import { cloneDeep } from 'lodash'

@Component({
  components: { SlightlyRichTextFragment, DigiDeleteButton, AvatarUpload, ImageFragment, PlainTextFragment }
})
export default class Quote2Item extends Vue {
  @Prop({ required: true }) readonly quote!: Quote2ItemProperties
  @Prop({ required: true }) readonly textColor!: string
  @Prop({ required: true }) readonly backgroundColor!: string
  @Prop({ required: true }) readonly grayscale!: boolean

  get blockStyle() {
    return {
      ['--quote-text-color']: this.textColor,
      ['--quote-bkg-color']: this.backgroundColor
    }
  }
  acceptedFiles = 'image/jpeg, image/png, image/jpg'
  localQuote: Quote2ItemProperties = new Quote2ItemProperties()

  @Watch('quote')
  onQuoteChanged() {
    this.localQuote = cloneDeep(this.quote)
  }

  @Emit('input')
  emitQuoteChanged() {
    return this.localQuote
  }

  mounted() {
    this.localQuote = this.quote
  }
}
