import type { EventIdentity } from '@/core'
import type { EventTypology } from '@/core'
import { zEventIdentity } from '@/core/EventIdentity'
import type { EventId } from '@/models/Event'
import axios from 'axios'

export const typologyNames: Record<EventTypology, string> = {
  internalEvent: 'TYPOLOGY_INTERNAL_EVENT',
  externalEvent: 'TYPOLOGY_EXTERNAL_EVENT',
  assembly: 'TYPOLOGY_ASSEMBLY',
  b2bevent: 'TYPOLOGY_B2B_EVENT',
  other: 'TYPOLOGY_OTHER_EVENT'
} as const

export async function getEventIdentityById(eventId: EventId, noHTTPCache: boolean = false): Promise<EventIdentity> {
  const res = await axios.get<unknown>(`events/${eventId}/identity`, { noCache: noHTTPCache })
  return zEventIdentity.parse(res.data)
}
