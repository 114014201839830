import { ElementTypeDictionary } from '../../types'
import Countdown1Element from './countdown1/Countdown1Element'
import Countdown2Element from './countdown2/Countdown2Element'

export const countdownDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Countdown1Element,
    renderer: () => import('./countdown1/Countdown1.vue'),
    properties: [
      {
        type: 'date',
        label: 'DATE',
        key: 'properties.targetDate'
      },
      {
        type: 'color',
        label: 'COUNTDOWN_BACKGROUND_COLOR',
        key: 'properties.countdownBackgroundColor',
        canBeTransparent: true
      },
      {
        type: 'color',
        label: 'TEXT_COLOR',
        key: 'properties.countdownTextColor',
        toContrastWith: 'properties.countdownBackgroundColor'
      },
      { type: 'color', label: 'WEBSITE_BLOCK_OUTER_TEXT_COLOR', key: 'properties.outerTextColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COUNTDOWN_1',
    previewImageLink: require('@/assets/img/prefab-previews/countdown.jpg')
  })
  .addType({
    dataClass: Countdown2Element,
    renderer: () => import('./countdown2/Countdown2.vue'),
    properties: [
      {
        type: 'date',
        label: 'DATE',
        key: 'properties.targetDate'
      },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.countdownTextColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_COUNTDOWN_2',
    previewImageLink: require('@/assets/img/prefab-previews/countdown2.jpg')
  })
  .finish()
