import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import CoverElementBase, { CoverProperties } from '../CoverElementBase'
import store from '@/store'
import moment from 'moment'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class Cover2Properties extends CoverProperties {
  tagText: string = ''
  tagColor: string = ''
  cta: CtaProperties = defaultCtaProperties()
}

export class Cover2Element extends CoverElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): Cover2Element {
    const cover = new Cover2Element()

    cover.properties.title = store.state.event.event?.label || i18n.t('AN_INSPIRING_TITLE')
    cover.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)
    cover.properties.tagText = moment(store.state.event.event?.eventDate).format('YYYY-MM-DD') || i18n.t('SECTION_NAME')
    cover.properties.cta.label = i18n.t('REGISTRATION_CTA_BTN_LABEL')

    cover.properties.textColor = mostReadableColor
    cover.properties.tagColor = mostReadableColor
    cover.properties.cta.color = palette.darkestColor.paletteCode

    return cover
  }

  type = `prefab-cover-2` as const

  @Type(() => Cover2Properties)
  properties: Cover2Properties = new Cover2Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_COVER')
  }
}
