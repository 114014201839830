













import { VideoPlatform } from '../../types/helperTypes'
import { isValidUrl } from '@/utils/validation'
import { Component, Vue } from 'vue-property-decorator'
import PlatformChooser from './PlatformChooser.vue'
import VideoIdInput from './VideoIdInput.vue'

export interface IPlatformSettings {
  logoPath: string
  validator?: (value: string) => boolean
  codeInputHelptext: string
}

@Component({
  components: {
    PlatformChooser,
    VideoIdInput
  }
})
export default class VideoUploader extends Vue {
  private platformName: VideoPlatform | null = null
  VIDEO_PLATEFORMS_SETTINGS: Record<VideoPlatform, IPlatformSettings> = {
    [VideoPlatform.Youtube]: {
      logoPath: require('@/assets/logos/youtube-red.svg'),
      validator: (value: string) => /^[a-zA-Z0-9_-]{11}$/.test(value),
      codeInputHelptext: 'YOUTUBE_VIDEO_CODE_HELP'
    },
    [VideoPlatform.Vimeo]: {
      logoPath: require('@/assets/logos/vimeo.svg'),
      validator: (value: string) => /^[0-9]*$/.test(value),
      codeInputHelptext: 'VIMEO_VIDEO_CODE_HELP'
    },
    [VideoPlatform.Custom]: {
      logoPath: require('@/assets/logos/link.svg'),
      validator: (value: string) => isValidUrl(value),
      codeInputHelptext: 'CUSTOM_VIDEO_URL_HELP'
    }
  }

  get videoPlatforms() {
    return this.VIDEO_PLATEFORMS_SETTINGS
  }

  get chosenPlatform(): VideoPlatform | null {
    return this.platformName
  }

  setChosenPlatform(value: VideoPlatform | null) {
    this.platformName = value
    this.$emit('platform-name', this.platformName)
  }
}
