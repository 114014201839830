






import { Vue, Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { getStatusSlug } from '@/utils/invitationStatus'
import type { EventRights } from '@/models/Event/EventRights'
import InvitationStatusBadge from '../components/InvitationStatusBadge.vue'

@Component({
  components: { InvitationStatusBadge }
})
export default class SingleRegistrationTabContent extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Prop({ required: true }) readonly eventRights!: EventRights

  get invitationStatus() {
    return this.$t(getStatusSlug(this.guest.invitationStatus))
  }
}
