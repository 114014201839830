import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import CoverElementBase, { CoverProperties } from '../CoverElementBase'

import store from '@/store'

import moment from 'moment'

class CoverForm1Fact {
  number: string = ''
  text: string = ''
}

export class CoverForm1Properties extends CoverProperties {
  tagText: string = ''
  formIntro: string = ''
  facts: CoverForm1Fact[] = []
}

export class CoverForm1Element extends CoverElementBase {
  static create({ mostReadableColor }: ElementCreationContext): CoverForm1Element {
    const cover = new CoverForm1Element()

    cover.properties.title = store.state.event.event?.label || i18n.t('AN_INSPIRING_TITLE')
    cover.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(1)
    cover.properties.tagText = moment(store.state.event.event?.eventDate).format('YYYY-MM-DD') || i18n.t('SECTION_NAME')
    cover.properties.textColor = mostReadableColor
    cover.properties.formIntro = i18n.t('COVER_FORM_1_INTRO_PLACEHOLDER')
    cover.properties.facts = [
      {
        number: '1',
        text: new LoremIpsum().generateWords(1)
      },
      {
        number: '2',
        text: new LoremIpsum().generateWords(1)
      }
    ]

    return cover
  }

  type = `prefab-cover-form-1` as const

  @Type(() => CoverForm1Properties)
  properties: CoverForm1Properties = new CoverForm1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_COVER')
  }
}
