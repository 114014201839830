import { ElementTypeDictionary } from '../../types'
import Iframe1Element from './iframe1/Iframe1Element'

export const iframeDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Iframe1Element,
    renderer: () => import('./iframe1/Iframe1.vue'),
    properties: [
      {
        key: 'properties.url',
        type: 'iframeurl',
        label: 'BLOCK_IFRAME_FIELD_URL'
      },
      {
        key: 'properties.height',
        type: 'range',
        label: 'BLOCK_IFRAME_FIELD_HEIGHT',
        min: 100,
        max: 1000,
        step: 1,
        unit: 'px'
      },
      {
        key: 'properties.fullWidth',
        type: 'pagefit',
        label: 'BLOCK_IFRAME_FIELD_FULL_WIDTH'
      },
      {
        type: 'collapsegroup',
        label: 'BLOCK_IFRAME_SECTION_DEVELOPER',
        content: [
          {
            key: 'properties.style',
            type: 'plaintext',
            label: 'BLOCK_IFRAME_FIELD_STYLE'
          },
          {
            key: 'properties.allow',
            type: 'plaintext',
            label: 'BLOCK_IFRAME_FIELD_ALLOW'
          },
          {
            key: 'properties.title',
            type: 'plaintext',
            label: 'BLOCK_IFRAME_FIELD_TITLE'
          }
        ]
      }
    ],
    typeNameSlug: 'BLOC_IFRAME',
    previewImageLink: require('@/assets/img/prefab-previews/iframe.jpg')
  })
  .finish()
