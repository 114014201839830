











import { DigiLabelWithDescriptionLayout } from '@/components/ui/form/formLayouts'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { extend as addValidatorRule } from 'vee-validate'
import FormGroupItem from './DigiFormGroupItem.vue'
import type { DigiFormGroupItem } from './types'

@Component({
  components: {
    FormGroupItem,
    DigiLabelWithDescriptionLayout
  }
})
export default class DigiFormGroup extends Vue {
  @Prop({ required: true }) readonly formItems!: DigiFormGroupItem[]
  @Prop({ default: null }) readonly header!: string | null
  @Prop({ default: false }) readonly small!: boolean

  get itemStyle() {
    return {
      '--max-form-group-column': this.small ? '1fr' : undefined
    }
  }

  shouldDisplayItem(item: DigiFormGroupItem): boolean {
    return item.shouldDisplay ?? true
  }

  get displayedFormItems(): DigiFormGroupItem[] {
    return this.formItems.filter((item) => this.shouldDisplayItem(item))
  }

  created(): void {
    for (const item of this.formItems) {
      if (item.customValidationRule) {
        addValidatorRule(`rule_${item.key}`, {
          validate: (value) => item.customValidationRule!(value)
        })
      }
    }
  }
}
