import store from '@/store'
import type { AnyAction, SendEmailToGuestAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import type { ActionUtil } from '@/features/rules/RuleEdition/types'
import Settings from './Settings.vue'
import type { Event } from '@/models/Event'

function isSendEmailToGuestAction(action: AnyAction): action is SendEmailToGuestAction {
  if (action.kind !== ActionKind.SendEmailToGuest) {
    throw new Error('SendEmailToAdressActionUtil can only manage action of kind SendEmailToGuest')
  }

  return true
}

const sendEmailToGuestActionUtil: ActionUtil = {
  kind: ActionKind.SendEmailToGuest as const,
  label: 'SEND_EMAIL_TO_GUEST' as const,
  icon: 'mail-line' as const,
  settings: Settings,

  validator(action: AnyAction): boolean {
    if (isSendEmailToGuestAction(action)) {
      const event: Event | undefined = store.state.event?.event

      return Boolean(
        action.messageTemplateId &&
          event?.messages.templates.some((template) => template._id === action.messageTemplateId)
      )
    } else {
      return false
    }
  }
}

export default sendEmailToGuestActionUtil
