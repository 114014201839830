













import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class DigiDeleteButton extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: false }) readonly fill!: boolean

  get classes() {
    return {
      'delete-btn--fill': this.fill
    }
  }
}
