import type { EventRights } from '@/models/Event/EventRights'
import axios from 'axios'

export async function getEventRights(eventId: string, noCache = false): Promise<EventRights> {
  const response = await axios.get<EventRights>(`/events/${eventId}/eventRights`, { noCache })
  return response.data
}

export function assertEventRights(maybeEventRights: EventRights | null): asserts maybeEventRights is EventRights {
  if (!maybeEventRights) {
    throw new Error('Event rights not defined')
  }
}
