import type { EventId } from '@/models/Event'
import type { Segmentable, SignatureReportOptions, GuestListOptions, OrderListOptions } from '@/models/Event/Export'
import { ExportKind } from '@/models/Event/Export'
import type { GuestFieldKey } from '@/models/Event/GuestField'
import type { EventActivityId } from '@/models/Event/modules/Programme'
import type { BackofficeLanguage } from '@/utils/i18n'
import axios from 'axios'

interface BaseExportCreation {
  kind: ExportKind
}

export class QRCodeExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.QRCode
  segmentIds: string[] = []
}

export class PDFBadgeExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.PDFBadge
  segmentIds: string[] = []
}

export class CerfaExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.Cerfa
  segmentIds: string[] = []
}

export class InvoiceExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.Invoice
  segmentIds: string[] = []
}

export class ImageFileExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.CustomFile
  documentKey: GuestFieldKey
  segmentIds: string[] = []

  constructor(key: GuestFieldKey) {
    this.documentKey = key
  }
}

export class CreditNoteExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.CreditNote
  segmentIds: string[] = []
}
export class OrderListExportCreation implements BaseExportCreation, OrderListOptions {
  readonly kind = ExportKind.OrderList
  language: BackofficeLanguage
  constructor(lang: BackofficeLanguage) {
    this.language = lang
  }
}

export class GuestListExportCreation implements BaseExportCreation, Segmentable, GuestListOptions {
  readonly kind = ExportKind.GuestList
  fields: string[] = ['firstname', 'name', 'guestemail', 'mobilephone']
  language: BackofficeLanguage
  includeCampaigns: boolean = false
  segmentIds: string[] = []

  constructor(lang: BackofficeLanguage) {
    this.language = lang
  }
}

export class SignatureReportExportCreation implements BaseExportCreation, Segmentable, SignatureReportOptions {
  readonly kind = ExportKind.SignatureReport
  fields: string[] = ['firstname', 'name']
  checkinPoints: string[] = []
  language: BackofficeLanguage
  title: string = ''
  subtitle: string = ''
  repeatHeader: boolean = false
  segmentIds: string[] = []

  constructor(lang: BackofficeLanguage, title: string, checkinPoints: string[]) {
    this.title = title
    this.language = lang
    this.checkinPoints = checkinPoints
  }
}

export class MatchmakingPlanningExportCreation implements BaseExportCreation {
  readonly kind = ExportKind.MatchmakingPlanning
  sessionId: EventActivityId | null = null
}

export class CertificateExportCreation implements BaseExportCreation, Segmentable {
  readonly kind = ExportKind.Certificate
  documentKey?: string
  segmentId!: string | null
  segmentIds: string[] = []
}

export type ExportCreationData =
  | QRCodeExportCreation
  | PDFBadgeExportCreation
  | CerfaExportCreation
  | InvoiceExportCreation
  | ImageFileExportCreation
  | CreditNoteExportCreation
  | GuestListExportCreation
  | SignatureReportExportCreation
  | MatchmakingPlanningExportCreation
  | OrderListExportCreation
  | CertificateExportCreation

export function isSegmentableExportCreation(
  data: ExportCreationData
): data is Extract<ExportCreationData, Segmentable> {
  return [
    ExportKind.QRCode,
    ExportKind.PDFBadge,
    ExportKind.Cerfa,
    ExportKind.Invoice,
    ExportKind.CustomFile,
    ExportKind.CreditNote,
    ExportKind.GuestList,
    ExportKind.SignatureReport
  ].includes(data.kind)
}

export async function createExport(eventId: EventId, exportData: ExportCreationData): Promise<void> {
  await axios.post<void>(`backoffice/export/${eventId}`, exportData)
}
