import type { MomentInput } from 'moment-timezone'
import moment from 'moment-timezone'

export function timezoneFormat(
  value: MomentInput | null | undefined,
  timezone: string = 'Europe/Paris',
  format: string = 'DD/MM HH:mm'
): string {
  if (value) {
    return moment(value).tz(timezone).format(format)
  } else {
    return ''
  }
}
