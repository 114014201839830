import type { EventRights } from '@/models/Event/EventRights'

export const websiteMenuSection = {
  key: 'website',
  titleSlug: 'EVENT_MENU_WEBSITE_TITLE',
  iconName: 'mac-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_WEBSITE_BUILDER_LINK',
      routeName: 'event.website.websiteBuilder',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.website.allowed
    },
    {
      titleSlug: 'EVENT_MENU_WEBSITE_TRANSLATIONS_LINK',
      routeName: 'event.website.translations',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.general.customization.multiLang && eventRights.features.website.allowed
    },
    {
      titleSlug: 'EVENT_MENU_WEBSITE_SETTINGS_LINK',
      routeName: 'event.website.websitesettings',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.website.allowed
    },
    {
      titleSlug: 'EVENT_MENU_WEBSITE_SETTINGS_LINK',
      component: () => import('./components/EventMenuWebsitePreviewButton.vue'),
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.website.allowed
    }
  ]
} as const
