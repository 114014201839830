





























import { Component, Prop, Vue } from 'vue-property-decorator'
import StepCard from '../StepCard.vue'
import triggerUtils from '../../../triggerUtils'
import { checkIfItemIsAllowed } from '../../..//service'
import type { AnyTrigger } from '@/models/Event/RulesData/Rule/Trigger'
import {
  AcceptedMeetingRequestTrigger,
  AccompanistRegistrationTrigger,
  CanceledMeetingTrigger,
  CheckinAnyCheckpointTrigger,
  CheckinExactCheckpointTrigger,
  DeclinedMeetingRequestTrigger,
  GuestRegistrationTrigger,
  OrderInitTrigger,
  OrderPaidTrigger,
  TicketGrantTrigger,
  ParticipationDeclineTrigger,
  RescheduledMeetingRequestTrigger,
  SurveySubmissionTrigger,
  TargetReceivesMeetingRequestTrigger,
  TriggerKind
} from '@/models/Event/RulesData/Rule/Trigger'
import type { SelectorMetaData, StepInfo, TriggerUtil } from '../../types'

@Component({
  components: {
    StepCard,
    GenericSelector: () => import('../GenericSelector.vue'),
    RuleEditionItem: () => import('../RuleEditionItem.vue')
  }
})
export default class TriggerStep extends Vue {
  @Prop({ required: true }) readonly trigger!: AnyTrigger | null
  @Prop({ required: true }) readonly isValid!: Boolean
  @Prop({ required: true }) readonly availableTriggers!: TriggerKind[]

  get availableTriggersMetaData(): SelectorMetaData[] {
    const availableTriggers: TriggerKind[] = Object.values(TriggerKind).filter(
      (triggerKind) => checkIfItemIsAllowed(triggerUtils[triggerKind]) && this.availableTriggers.includes(triggerKind)
    )

    return availableTriggers.map((triggerKind) => {
      const triggerUtil = triggerUtils[triggerKind]

      return {
        kind: triggerKind,
        label: triggerUtil.label,
        icon: triggerUtil.icon
      }
    })
  }

  get selectedTriggerUtil(): TriggerUtil | null {
    if (this.trigger) {
      return triggerUtils[this.trigger.kind]
    } else {
      return null
    }
  }

  get triggerStepInfos(): StepInfo {
    if (this.selectedTriggerUtil) {
      return {
        description: this.$t('RULES_TRIGGER_DESCRIPTION'),
        title: this.$t(this.selectedTriggerUtil.label),
        icon: this.selectedTriggerUtil.icon
      }
    } else {
      return { title: this.$t('RULES_TRIGGER_DESCRIPTION'), icon: 'flashlight-line' }
    }
  }

  showContactSalesModal(): void {
    this.$bvModal.msgBoxOk(
      [
        this.$createElement('div', {
          domProps: {
            innerHTML: this.$t('CANNOT_GO_TO_SECTION')
          }
        })
      ],
      {
        okVariant: 'default',
        okTitle: this.$t('OK'),
        centered: true
      }
    )
  }

  onTriggerSelectHandler(selectedKind: TriggerKind): void {
    const newTrigger = this.createTrigger(selectedKind)

    triggerUtils[newTrigger.kind].onSelection?.(newTrigger)
    this.$emit('update:trigger', newTrigger)
  }

  createTrigger(triggerKind: TriggerKind): AnyTrigger {
    switch (triggerKind) {
      case TriggerKind.ParticipationDecline:
        return new ParticipationDeclineTrigger()
      case TriggerKind.OrderInit:
        return new OrderInitTrigger()
      case TriggerKind.OrderPaid:
        return new OrderPaidTrigger()
      case TriggerKind.TicketGrant:
        return new TicketGrantTrigger()
      case TriggerKind.AcceptedMeetingRequest:
        return new AcceptedMeetingRequestTrigger()
      case TriggerKind.TargetReceivesMeetingRequest:
        return new TargetReceivesMeetingRequestTrigger()
      case TriggerKind.DeclinedMeetingRequest:
        return new DeclinedMeetingRequestTrigger()
      case TriggerKind.CanceledMeeting:
        return new CanceledMeetingTrigger()
      case TriggerKind.CheckinAny:
        return new CheckinAnyCheckpointTrigger()
      case TriggerKind.CheckinExact:
        return new CheckinExactCheckpointTrigger()
      case TriggerKind.SurveySubmission:
        return new SurveySubmissionTrigger()
      case TriggerKind.GuestRegistration:
        return new GuestRegistrationTrigger()
      case TriggerKind.AccompanistRegistration:
        return new AccompanistRegistrationTrigger()
      case TriggerKind.RescheduledMeetingRequest:
        return new RescheduledMeetingRequestTrigger()
      default:
        throw new Error(`Unexpected: Trigger of type ${triggerKind} is not manage`)
    }
  }
}
