import axios from 'axios'

import type Filter from '@/models/Event/Filter'

import type { SegmentCreation } from './types'
import type { MixedSort } from '@/models/commons/sort'

export async function createSegment(segment: SegmentCreation, eventId: string): Promise<string> {
  const res = await axios.post(`/backoffice/events/${eventId}/segments`, { segment })
  return res.data
}

export async function editSegment(segment: Filter, eventId: string): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/segments/${segment._id}`, { segment })
}

export async function deleteSegment(segmentId: string, eventId: string): Promise<void> {
  await axios.delete(`/backoffice/events/${eventId}/segments/${segmentId}`)
}

export async function sortSegments(sort: MixedSort, eventId: string): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/segmentsSort`, { sort })
}

export async function createSegmentGroup(groupName: string, eventId: string): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/segmentsSort/groups`, { groupName })
}

export async function updateGroupName(groupName: string, groupId: string, eventId: string): Promise<void> {
  await axios.patch(`/backoffice/events/${eventId}/segmentsSort/groups/${groupId}`, { groupName })
}

export async function deleteGroup(groupId: string, eventId: string): Promise<void> {
  await axios.delete(`/backoffice/events/${eventId}/segmentsSort/groups/${groupId}`)
}

export async function getGuestCountMatchingSegment(eventId: string, segment: Omit<Filter, '_id'>): Promise<number> {
  const res = await axios.get(`backoffice/events/${eventId}/countMatchingGuests`, { params: { filter: segment } })
  return res.data
}
