








import { Vue, Component, Prop } from 'vue-property-decorator'
import RichLabelFormGroup from '@/components/ui/form/RichLabelFormGroup.vue'

@Component({
  components: { RichLabelFormGroup }
})
export default class DigiVerticalMenuSettingsSection extends Vue {
  @Prop({ required: true }) readonly label!: string
  @Prop({ type: Boolean, default: false }) readonly horizontal!: boolean
}
