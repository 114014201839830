























import { DigiButton } from '@/components/ui/actions'
import { Vue, Component, Ref } from 'vue-property-decorator'
import type { Event } from '@/models/Event'
import AttachmentInputs from './AttachmentInputs.vue'
import SuccessMessage from './SuccessMessage.vue'
import UserUrgencyLevelInput from './UserUrgencyLevelInput.vue'
import PreferredFeedbackMethodInput from './PreferredFeedbackMethodInput.vue'
import TagInput from './TagInput.vue'
import DescriptionInput from './DescriptionInput.vue'
import BestAnswer from './BestAnswer.vue'
import type { Attachments, SupportRequestForm } from './types'
import { zSupportRequestForm } from './types'
import { sendSupportRequest, validateAttachments } from './supportRequest'
import { mustFindTagMetaData } from './tagMetaData'
import type { Account } from '@/models/Account'

@Component({
  components: {
    AttachmentInputs,
    DescriptionInput,
    DigiButton,
    BestAnswer,
    PreferredFeedbackMethodInput,
    SuccessMessage,
    TagInput,
    UserUrgencyLevelInput
  }
})
export default class SupportRequestFormSection extends Vue {
  supportRequestForm: Partial<SupportRequestForm> & { attachments: Attachments } = {
    attachments: [null, null, null]
  }
  isLoading: boolean = false
  isRequestSent: boolean = false
  ignoreBestAnswer: boolean = false

  @Ref() readonly userUrgencyLevelInput!: UserUrgencyLevelInput

  get bestAnswerUrl(): string {
    const tagMetaData = this.supportRequestForm.tag ? mustFindTagMetaData(this.supportRequestForm.tag) : undefined
    if (!tagMetaData) return ''
    const account = this.$store.state.account.account as Account
    return tagMetaData.documentationUrl[account.language] || tagMetaData.documentationUrl['en'] || ''
  }

  get showBestAnswer(): boolean {
    return Boolean(this.bestAnswerUrl) && !this.ignoreBestAnswer
  }

  get showRestOfForm(): boolean {
    let mustShow = true
    if (this.storeEvent && !this.supportRequestForm.tag) mustShow = false
    if (this.showBestAnswer) mustShow = false
    return mustShow
  }

  get storeEvent(): Event | null {
    if (this.$store.state.event.event) {
      return this.$store.state.event.event
    }
    return null
  }

  get isFormValid(): boolean {
    return validateAttachments(this.supportRequestForm.attachments) && this.userUrgencyLevelInput?.isValid
  }

  async sendSupportRequest(): Promise<void> {
    if (!this.isFormValid) {
      return
    }
    this.isLoading = true
    try {
      const validatedSupportRequestForm = zSupportRequestForm.parse(this.supportRequestForm)
      await sendSupportRequest(validatedSupportRequestForm, this.storeEvent?._id)
      this.isRequestSent = true
      this.$emit('support-request-sent')
      this.$notify({
        text: this.$t('REQUEST_SENT'),
        type: 'success'
      })
    } finally {
      this.isLoading = false
    }
  }

  reset(): void {
    if (this.isRequestSent) {
      this.isRequestSent = false
      this.supportRequestForm = {
        attachments: [null, null, null]
      }
      this.ignoreBestAnswer = false
    }
  }
}
