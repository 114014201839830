












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class FileTypeInfo extends Vue {
  @Prop({ required: true }) readonly acceptedExt!: string
  @Prop({ required: true }) readonly maxSizeText!: string
  @Prop({ required: true }) readonly recommendedWidth!: number | null
  @Prop({ required: true }) readonly recommendedHeight!: number | null
  @Prop({ default: false }) readonly hideSupportLink!: boolean

  get recommendedSize(): string {
    if (this.recommendedWidth && !this.recommendedHeight) {
      const roundedRecommendedWidth = Math.ceil(this.recommendedWidth)

      return this.$t('MAXIMUM_RECOMMENDED_WIDTH', { x: roundedRecommendedWidth })
    }

    if (!this.recommendedWidth && this.recommendedHeight) {
      const roundedRecommendedHeight = Math.ceil(this.recommendedHeight)

      return this.$t('MAXIMUM_RECOMMENDED_HEIGHT', { x: roundedRecommendedHeight })
    }

    if (this.recommendedWidth && this.recommendedHeight) {
      const roundedRecommendedWidth = Math.ceil(this.recommendedWidth)
      const roundedRecommendedHeight = Math.ceil(this.recommendedHeight)

      return this.$t('MAXIMUM_RECOMMENDED_SIZE', { x: roundedRecommendedWidth, y: roundedRecommendedHeight })
    }

    return ''
  }
}
