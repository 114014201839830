















import { Vue, Component, VModel, Prop, Watch } from 'vue-property-decorator'
import type LinkCustomField from '@/models/Event/CustomField/fieldTypes/Link'
import { isValidUrl } from '@/utils/validation'
import { isNil } from 'lodash'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton }
})
export default class GuestFieldLinkInput extends Vue {
  @VModel() readonly fieldValue!: string
  @Prop({ required: true }) readonly field!: LinkCustomField

  get showPreviewButton() {
    return this.fieldValue && isValidUrl(this.fieldValue)
  }

  get fieldState() {
    if (isNil(this.fieldValue)) {
      return null
    } else if (typeof this.fieldValue === 'string') {
      return isValidUrl(this.fieldValue)
    } else {
      return false
    }
  }

  @Watch('fieldState')
  onFieldStateChange() {
    this.$emit('update-validity', this.fieldState !== false)
  }
}
