import ContentElementBase from '../ContentElementBase'
import type { Border } from '../helperTypes'
import { BorderStyle } from '../helperTypes'
import { i18n } from '@/utils/i18n'

export default class DividerElement extends ContentElementBase {
  static create(): DividerElement {
    return new DividerElement()
  }

  type: 'content-divider' = 'content-divider'

  properties: Border = {
    size: 3,
    style: BorderStyle.Solid,
    color: '#000000'
  }

  prettyName = i18n.t('BLOCK_DIVIDER')
}
