import type { EventMenuItem, EventMenuSection } from 'src/navigation/eventMenu'
import type { FeatureDictionary } from './types'
import { hasMenuSection } from './types'
import { listMyPermissions } from '@/services/eventPermissions'
import type { ReadonlyFullRouteConfig } from '@/core'
import type { EventRights } from '@/models/Event/EventRights'

export async function getMenuSections(
  eventId: string,
  eventRights: EventRights,
  featureDictionary: FeatureDictionary
): Promise<EventMenuSection[]> {
  const featureWithMenuSection = featureDictionary.filter(hasMenuSection)
  const result: EventMenuSection[] = []

  for (const feature of featureWithMenuSection) {
    const items = await filterAvailableMenuSectionItem(eventId, eventRights, feature.eventMenuSection.items)
    if (items.length > 0) {
      result.push({
        ...feature.eventMenuSection,
        items
      })
    }
  }

  return result
}

async function filterAvailableMenuSectionItem(
  eventId: string,
  eventRights: EventRights,
  items: readonly EventMenuItem[]
): Promise<EventMenuItem[]> {
  const availableItems: EventMenuItem[] = []
  for (const item of items) {
    const isAllowedInEvent = checkItemAllowedInEvent(eventRights, item)
    const hasRequiredPermission = await checkItemHasRequiredPermission(eventId, item)
    if (isAllowedInEvent && hasRequiredPermission) availableItems.push(item)
  }
  return availableItems
}

export function checkItemAllowedInEvent(eventRights: EventRights, item: EventMenuItem): boolean {
  if ('isAllowedInEvent' in item) {
    return item.isAllowedInEvent(eventRights)
  }
  return true
}

export async function checkItemHasRequiredPermission(eventId: string, item: EventMenuItem): Promise<boolean> {
  if ('requiredPermissions' in item) {
    return await hasRequiredPermission(eventId, item)
  }
  return true
}

export function getRoutes(featureDictionary: FeatureDictionary): ReadonlyFullRouteConfig {
  return featureDictionary.map((feature) => feature.routes).flat()
}

export async function hasRequiredPermission(eventId: string, menuItem: EventMenuItem): Promise<boolean> {
  const requiredPermissions = menuItem.requiredPermissions
  if (requiredPermissions.length === 0) return true
  const permissions = await listMyPermissions(eventId)
  return requiredPermissions.every((permission) => permissions.includes(permission))
}
