import type { AnyAction, SendSMStoPhoneNumberAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import type { ActionUtil } from '../../../RuleEdition/types'
import Settings from './Settings.vue'
import { SMS_SENDER_CHARACTER_LIMIT } from '@/utils/constants'

function isSendSMSToPhoneNumberAction(action: AnyAction): action is SendSMStoPhoneNumberAction {
  if (action.kind !== ActionKind.SendSMSToPhoneNumber) {
    throw new Error('SendSMSToPhoneNumberActionUtil can only manage action of kind SendSMSToPhoneNumber')
  }

  return true
}

const sendSMSToPhoneNumberActionUtil: ActionUtil = {
  kind: ActionKind.SendSMSToPhoneNumber as const,
  label: 'NOTIFY_PHONE_NUMBER' as const,
  icon: 'smartphone-line' as const,
  settings: Settings,

  validator(action: AnyAction): boolean {
    if (isSendSMSToPhoneNumberAction(action)) {
      return Boolean(
        action.phoneNumber &&
          action.senderName &&
          action.senderName.length <= SMS_SENDER_CHARACTER_LIMIT &&
          action.content
      )
    } else {
      return false
    }
  }
}

export default sendSMSToPhoneNumberActionUtil
