









































import type * as CSS from 'csstype'
import type { MediaProperties, VideoProperties } from '../types/helperTypes'
import { Component, Prop, Ref, VModel, Vue } from 'vue-property-decorator'
import { FileUpload } from '@/components/features/fileUpload'
import type { Property as CssProperty } from 'csstype'
import { IMAGE_FILE_MIME_TYPES } from '@/utils/constants'
import ImageFragmentDisplay from './Image/ImageFragmentDisplay.vue'
import { VideoDisplayFragment } from './Video'
import AddVideoModal from './Video/AddVideoModal.vue'
import { UploadMediaItem } from '@/components/features/fileUpload'

@Component({
  components: { FileUpload, ImageFragmentDisplay, VideoDisplayFragment, AddVideoModal, UploadMediaItem }
})
export default class MediaFragment extends Vue {
  @VModel({ required: true }) media!: MediaProperties | null
  @Prop({ default: 'fill' }) readonly imageFit!: CssProperty.ObjectFit
  @Prop({ default: '50% 50%' }) readonly imagePosition!: CssProperty.ObjectPosition
  @Prop() readonly maxHeight!: number
  @Prop() readonly recommendedWidth?: number
  @Prop() readonly recommendedHeight?: number

  @Ref() readonly addVideoModal!: AddVideoModal

  private IMAGE_FILE_MIME_TYPES = IMAGE_FILE_MIME_TYPES

  get imageUrl(): string | null {
    if (this.media?.type !== 'image') return null

    return this.media.imageUrl
  }
  set imageUrl(newUrl: string | null) {
    if (typeof newUrl === 'string') {
      this.media = {
        type: 'image',
        imageUrl: newUrl
      }
    } else {
      this.media = null
    }
  }

  get videoProperties(): VideoProperties | null {
    if (this.media?.type !== 'video') return null

    return {
      videoCode: this.media.videoCode,
      videoPlatform: this.media.videoPlatform
    }
  }
  set videoProperties(newVideo: VideoProperties | null) {
    if (newVideo) {
      this.media = {
        type: 'video',
        videoCode: newVideo.videoCode,
        videoPlatform: newVideo.videoPlatform
      }
    } else {
      this.media = null
    }
  }

  get isVideoSet(): boolean {
    return Boolean(this.videoProperties?.videoPlatform) && Boolean(this.videoProperties?.videoCode)
  }

  get hasMedia(): boolean {
    return !!this.media
  }

  get mediaIsImage(): boolean {
    return this.media?.type === 'image'
  }

  get mediaIsVideo(): boolean {
    return this.media?.type === 'video'
  }

  get maxHeightStyle(): CSS.Properties {
    return {
      maxHeight: `${this.maxHeight}px`
    }
  }

  addVideo(video: VideoProperties) {
    this.videoProperties = video
  }

  showAddVideoModal(): void {
    this.addVideoModal.show()
  }

  resetVideo(): void {
    this.videoProperties = null
  }
}
