import store from '@/store'
import { i18n } from '@/utils/i18n'
import type { RawEditorSettings } from 'tinymce'
import { menuItemFromLink, separator, subMenu } from './components'
import type { MinimumTinyMceOptions, TextEditorToolbar } from './types'
import { isTextEditorMenuLink, isTextEditorSubMenu } from './types'
import type { ColorPalette } from '@/models/Event/Identity/ColorPalette'

const getTinymceLanguage = () => {
  switch (store.getters.userLang) {
    case 'fr':
      return 'fr_FR'
    case 'pt':
      return 'pt_BR'
    default:
      return undefined
  }
}

export function getTinyMceOptions(
  colorPalette: ColorPalette,
  {
    inline = false,
    height = 200
  }: {
    inline?: boolean
    height?: number
  } = {}
): MinimumTinyMceOptions {
  const getColorPalette = (): string[] => {
    return colorPalette.reduce<string[]>((acc, color) => acc.concat([color, color]), [])
  }

  const options: MinimumTinyMceOptions = {
    inline,
    height,
    language: getTinymceLanguage(),
    theme: 'silver',
    color_map: getColorPalette(),
    custom_colors: true,
    plugins: 'advlist autolink lists image insertdatetime media table paste link charmap searchreplace code',
    statusbar: false,
    menubar: false,
    toolbar1: 'undo redo | bold italic underline fontsizeselect styleselect',
    toolbar2:
      'forecolor backcolor | removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | link | code',
    contextmenu: 'selectall | cut copy paste | link | searchreplace',
    fontsize_formats: '9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 22px 24px 26px 36px 40px',
    style_formats: [
      { title: 'Heading 1', format: 'h1' },
      { title: 'Heading 2', format: 'h2' },
      { title: 'Heading 3', format: 'h3' },
      { title: 'Paragraph', format: 'p' }
    ],
    extended_valid_elements: 'iframe[src|style|scrolling|class|width|height|name|align|allowfullscreen|allow]',
    default_link_target: '_blank',
    paste_as_text: true
  }

  if (options.language !== undefined) {
    options.language_url = `/tinymce_translations/${options.language}.js`
  }

  return options
}

export function getCompleteTInyMceOptions(
  {
    addAutoFontSize,
    addFontFormat,
    thirdToolbar,
    addFontSelect,
    inline,
    height,
    forceRootStyle,
    rawEncoding,
    blurHandler
  }: {
    addAutoFontSize: boolean
    addFontFormat: boolean
    addFontSelect: boolean
    height: number
    inline: boolean
    forceRootStyle: string
    thirdToolbar?: TextEditorToolbar
    rawEncoding?: boolean
    blurHandler?: () => void
  } = {
    addFontFormat: false,
    addAutoFontSize: false,
    addFontSelect: false,
    forceRootStyle: '',
    inline: false,
    rawEncoding: false,
    height: 200
  },
  colorPalette: ColorPalette
): MinimumTinyMceOptions {
  const options = getTinyMceOptions(colorPalette, { inline, height })
  options.auto_focus = 'content'

  if (rawEncoding) {
    options.entity_encoding = 'raw'
  }

  if (addFontSelect) {
    const toolbar1Groups = options.toolbar1.split('|')
    toolbar1Groups[1] += ' fontselect'
    options.toolbar1 = toolbar1Groups.join()
  }

  if (addAutoFontSize) {
    options.fontsize_formats = 'auto=inherit ' + options.fontsize_formats
  }
  if (addFontFormat) {
    options.font_formats = `${i18n.t('THEME_FONT')}=inherit;`
  }

  if (forceRootStyle.length > 0) {
    options.forced_root_block_attrs = {
      style: forceRootStyle
    }
  }

  if (thirdToolbar && thirdToolbar.length > 0) {
    options.toolbar3 = thirdToolbar
      .map((menu) => {
        return menu.tag
      })
      .join(' | ')

    options.setup = (editor) => {
      if (blurHandler) {
        editor.on('blur', blurHandler)
      }
      if (!thirdToolbar) return
      thirdToolbar.forEach((menu) => {
        editor.ui.registry.addMenuButton(menu.tag, {
          text: menu.label,
          fetch: (callback) => {
            const entries = menu.items.map((item) => {
              if (!item.type) throw new Error('Menu item type is required', item)
              if (isTextEditorSubMenu(item)) {
                return subMenu(item.title, item.items, editor)
              } else if (isTextEditorMenuLink(item)) {
                return menuItemFromLink(item, editor)
              } else {
                return separator(item.label)
              }
            })
            callback(entries)
          }
        })
      })
    }
  }

  return options
}

export function getSimpleTinyMceOptions(inline: boolean = false): RawEditorSettings {
  const options: RawEditorSettings = {
    inline,
    language: getTinymceLanguage(),
    skin_url: '/js/skins/ui/oxide',
    icons_url: `/js/icons/default/icons.js`,
    theme: 'silver',
    statusbar: false,
    menubar: false,
    toolbar1: 'undo redo | bold italic underline',
    plugins: 'paste',
    paste_as_text: true
  }
  if (options.language !== undefined) {
    options.language_url = `/tinymce_translations/${options.language}.js`
  }

  return options
}
