







import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class PlanningLocation extends Vue {
  @Prop({ required: true }) readonly location!: string
}
