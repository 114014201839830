import store from '@/store'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import type { TriggerUtil } from '../../RuleEdition/types'

const ticketGrantTriggerUtil: TriggerUtil = {
  kind: TriggerKind.TicketGrant as const,
  label: 'RULES_TICKET_GRANT_LABEL' as const,
  icon: 'coupon-line' as const,

  isAllowed(): boolean {
    return store.getters.isPaidEvent
  }
}

export default ticketGrantTriggerUtil
