




import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import type NumberCustomField from '@/models/Event/CustomField/fieldTypes/Number'
import { isNil } from 'lodash'

@Component({})
export default class GuestFieldNumberInput extends Vue {
  @VModel() readonly fieldValue!: number
  @Prop({ required: true }) readonly field!: NumberCustomField

  get fieldState(): boolean | null {
    if (isNil(this.fieldValue)) {
      return null
    } else if (typeof this.fieldValue === 'number') {
      return !isNaN(this.fieldValue)
    }
    return false
  }
}
