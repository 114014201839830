import { render, staticRenderFns } from "./MultiSelectorHeading.vue?vue&type=template&id=3e5b2aa6&scoped=true"
import script from "./MultiSelectorHeading.vue?vue&type=script&lang=ts"
export * from "./MultiSelectorHeading.vue?vue&type=script&lang=ts"
import style0 from "./MultiSelectorHeading.vue?vue&type=style&index=0&id=3e5b2aa6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5b2aa6",
  null
  
)

export default component.exports