





























import moment from 'moment'
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'

@Component
export default class DigiDateTimePicker extends Vue {
  @VModel({ required: true, type: Date }) dateValue!: Date
  @Prop({ required: true }) readonly timezone!: string
  @Prop() readonly minDate?: Date
  @Prop() readonly maxDate?: Date
  @Prop({ default: false }) readonly isDatePickerDisabled!: boolean

  get localTimezone(): string {
    return moment.tz.guess()
  }

  get shouldMentionTimezone(): boolean {
    return this.localTimezone !== this.timezone
  }

  get valueAsMomentWithTimezone() {
    return moment(this.dateValue).tz(this.timezone)
  }

  get date(): string {
    return this.valueAsMomentWithTimezone.format('YYYY-MM-DD')
  }

  set date(newDate: string) {
    this.dateValue = moment.tz(`${newDate}T${this.time}`, this.timezone).toDate()
  }

  get time(): string {
    return this.valueAsMomentWithTimezone.format('HH:mm:ss')
  }

  set time(newTime: string) {
    this.dateValue = moment.tz(`${this.date}T${newTime}`, this.timezone).toDate()
  }
}
