













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

type Variant = 'light' | 'danger' | 'warning'

@Component
export default class DigiTitleCard extends Vue {
  @Prop({ required: true }) readonly remixIcon!: string
  @Prop({ default: 'light' }) readonly variant!: Variant

  get classes() {
    return {
      [`digi-title-card--${this.variant}`]: true
    }
  }
}
