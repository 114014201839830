import type { EventId } from '@/models/Event'
import type { GuestFieldKey } from '@/models/Event/GuestField'
import type { GuestId } from '@/models/Guest'
import axios from 'axios'

export interface S3PresignedInfo {
  signature: Record<string, string>
  postEndpoint: string
  finalUrl: string
}

export function deleteGuestImage(eventId: EventId, guestId: GuestId, fieldId: GuestFieldKey) {
  return axios.delete(`/backoffice/events/${eventId}/guest/${guestId}/image/${fieldId}`)
}

async function getPresignedPost(
  file: File,
  whatFor: string,
  eventId: string | undefined,
  guestId: string | undefined,
  fieldId: string | undefined
): Promise<S3PresignedInfo> {
  const presignedInfoRes = await axios.get<S3PresignedInfo>(`/backoffice/signedUrl`, {
    params: {
      filename: file.name,
      whatFor,
      eventId,
      guestId,
      fieldId
    }
  })
  return presignedInfoRes.data
}

async function uploadUsingPresignedInfo(
  file: File,
  { signature, postEndpoint, finalUrl }: S3PresignedInfo
): Promise<string> {
  const form = new FormData()
  Object.entries(signature).forEach(([key, value]) => form.append(key, value))
  form.append('file', file)
  await axios.post(postEndpoint, form, { withCredentials: false })
  return finalUrl
}

export async function uploadFile(
  file: File,
  whatFor: string,
  eventId: string | undefined,
  guestId: string | undefined,
  fieldId: string | undefined
): Promise<string> {
  const presignedInfo = await getPresignedPost(file, whatFor, eventId, guestId, fieldId)
  const fileUrl = await uploadUsingPresignedInfo(file, presignedInfo)
  return fileUrl
}
