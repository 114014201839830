




import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningDateRange from '../PlanningDateRange.vue'
import type Unavailability from '@/models/Guest/Planning/ScheduledItem/Unavailability'

@Component({ components: { PlanningDateRange } })
export default class UnavailabilityDateRange extends Vue {
  @Prop({ required: true }) readonly unavailability!: Unavailability

  get dateRangeString(): string {
    const startDateString: string = moment(this.unavailability.dateRange[0]).format('LT')
    const endDateString = moment(this.unavailability.dateRange[1]).format('LT')
    return `${startDateString} - ${endDateString}`
  }
}
