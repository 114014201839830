import type { Route } from 'vue-router/types/router'
import type { ReadonlyFullRouteConfig } from '@/core'
export const rulesRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'rules/:ruleId?/edit/sms/:messageId',
    name: 'event.rules.message.sms',
    component: () => import('./views/MessageEditor.vue'),
    props: (route: Route) => ({ messageId: route.params.messageId, ruleId: route.params.ruleId, msgMethod: 'sms' }),
    meta: {
      renderingData: {
        isContentFullWidth: false
      }
    }
  },
  {
    path: 'rules/:ruleId?/edit/email/:messageId',
    name: 'event.rules.message.email',
    component: () => import('./views/MessageEditor.vue'),
    props: (route: Route) => ({ messageId: route.params.messageId, msgMethod: 'email' }),
    meta: {
      renderingData: {
        isContentFullWidth: false
      }
    }
  }
] as const
