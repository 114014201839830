













import { Component, Prop } from 'vue-property-decorator'

import { DigiTipCard } from '@/components/ui'
import { DigiButton } from '@/components/ui/actions'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({ components: { DigiTipCard, DigiButton } })
export default class DiscardableTipCard extends Vue {
  @Prop({ required: true }) readonly storagePrefix!: string

  isIgnored: boolean | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get showTip(): boolean {
    return this.isIgnored === false
  }

  get localStorageKey(): string {
    return `${this.storagePrefix}-${this.storeEvent._id}-tip-disabled`
  }

  mounted(): void {
    this.setIsIgnored()
  }

  setIsIgnored(): void {
    const value = localStorage.getItem(this.localStorageKey)
    this.isIgnored = value === null ? false : true
  }

  ignoreTip(): void {
    localStorage.setItem(this.localStorageKey, '')
    this.isIgnored = true
  }
}
