








import { Vue, Component, VModel } from 'vue-property-decorator'
import { DigiIconButton } from '@/components/ui/actions'
import type { AddAccompanistMethod } from './types'

@Component({ components: { DigiIconButton } })
export default class MethodSelection extends Vue {
  @VModel({ required: true }) method!: AddAccompanistMethod
}
