import type { AnonymousFilter } from '@/models/Event/Filter'
import type Filter from '@/models/Event/Filter'
import type { GuestField, GuestFieldOrigin } from '@/models/Event/GuestField'
import type { Operator } from '@/models/Event/Filter/Criteria'
import type Criteria from '@/models/Event/Filter/Criteria'
import type { PartiallyPartial } from '@/utils/utilityTypes'

export type ExtendedGuestFieldType =
  | 'programme'
  | 'programmeCheckinField'
  | 'matchmaking'
  | 'orderStatus'
  | 'ticketId'
  | 'paymentType'
  | 'promoCode'
  | 'registrationUrlQuery'
  | 'message'
  | 'registrationQueryParams'
  | 'multiRegistration'
  | 'checkinPoint'
  | 'formSubmission'

export interface ExtendedGuestField {
  name: string
  slug?: string
  type: ExtendedGuestFieldType
  origin: GuestFieldOrigin | 'programme' | 'order' | 'generated'
  key: string
}

export type FieldForFiltering = GuestField | ExtendedGuestField
export type FieldMap = Record<string, FieldForFiltering>
type FieldTypeForFiltering = FieldForFiltering['type']

interface OperatorInfoBase {
  presentationSlug?: string
  valueType: string | null
  showDateInput?: boolean | ((criteria: Criteria) => boolean)
}

export type ValueSelectOptions = {
  value: any
  text: string
}[]

interface SelectOperatorInfo extends OperatorInfoBase {
  valueType: 'select'
  options: ValueSelectOptions | ((criteria: Criteria) => ValueSelectOptions)
}

interface BasicOperatorInfo extends OperatorInfoBase {
  valueType: null | 'string' | 'number'
}

export type OperatorInfo = SelectOperatorInfo | BasicOperatorInfo
export type TypeOperatorsInfo = Partial<Record<Operator, OperatorInfo>>
export type OperatorMap = {
  [type in FieldTypeForFiltering]: TypeOperatorsInfo | (() => TypeOperatorsInfo)
}
export type PropertyOptions = Record<string, { text: string; value: string }[]>
export type FilterBeingCreated = PartiallyPartial<Filter, '_id'>
export type EditPayloadFromInput<FilterT extends Filter | AnonymousFilter> = FilterT extends Filter
  ? Filter
  : AnonymousFilter
export type AnonymousPromisePayload = AnonymousFilter | EditPayloadFromInput<AnonymousFilter>
export type NamedPromisePayload = FilterBeingCreated | EditPayloadFromInput<Filter>

export function isNamedFilter(filter: Filter | AnonymousFilter): filter is Filter {
  return 'name' in filter
}

export interface Collabolator {
  id: string
  email: string
}
