var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DigiVerticalMenu',{staticClass:"contact-profile-menu",attrs:{"items":_vm.menuItems,"initial-opened-sub-key":_vm.initialOpenedTab},on:{"sub-menu-opened":_vm.setActiveTabQueryParam,"sub-menu-closed":_vm.removeActiveTabQueryParam},scopedSlots:_vm._u([{key:"tab(edit)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}})]}},{key:"sub(edit)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileEditSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu,"saved":_vm.closeAndReload}})]}},{key:"tab(singleRegistration)",fn:function(ref){
var openSubMenu = ref.openSubMenu;
var item = ref.item;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_c('SingleRegistrationTabContent',{attrs:{"guest":_vm.guestData,"event-rights":_vm.eventRights}})],1)]}},{key:"sub(singleRegistration)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileSingleRegistrationSubMenu',{attrs:{"guest":_vm.guestData,"event-rights":_vm.eventRights,"title":item.label},on:{"close":closeSubMenu,"updated":function($event){return _vm.reload('singleRegistration')}}})]}},{key:"tab(multiRegistration)",fn:function(ref){
var openSubMenu = ref.openSubMenu;
var item = ref.item;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_c('MultiRegistrationTabContent',{attrs:{"guest":_vm.guestData,"event-rights":_vm.eventRights}})],1)]}},{key:"sub(multiRegistration)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileMultiRegistrationSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu,"updated":function($event){return _vm.reload('multiRegistration')}}})]}},{key:"tab(paidRegistration)",fn:function(ref){
var openSubMenu = ref.openSubMenu;
var item = ref.item;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_c('PaidRegistrationTabContent',{attrs:{"guest":_vm.guestData}})],1)]}},{key:"sub(paidRegistration)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfilePaidRegistrationSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu,"updated":function($event){return _vm.reload('paidRegistration')}}})]}},{key:"tab(accessPass)",fn:function(ref){
var item = ref.item;
return [_c('ContactProfileAccessPassTab',{attrs:{"item":item,"guest":_vm.guestData}})]}},{key:"tab(programme)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileProgrammeTabContent',{attrs:{"guest":_vm.guestData,"item":item},on:{"click":openSubMenu}})]}},{key:"sub(programme)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileProgrammeSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu,"updated":function($event){return _vm.reload('programme')}}})]}},{key:"tab(pdfBadge)",fn:function(ref){
var item = ref.item;
return [_c('ContactProfilePdfBadgeTab',{attrs:{"item":item,"guest":_vm.guestData}})]}},{key:"tab(checkins)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileCheckinTabContent',{attrs:{"guest":_vm.guestData,"item":item},on:{"click":openSubMenu}})]}},{key:"sub(checkins)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileCheckinSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu}})]}},{key:"tab(certificate)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileCertificatesTabContent',{attrs:{"guest":_vm.guestData,"item":item},on:{"click":openSubMenu}})]}},{key:"sub(certificate)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileCertificatesSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu}})]}},{key:"tab(networking)",fn:function(ref){
var item = ref.item;
return [_c('ContactProfileNetworkingTab',{attrs:{"guest":_vm.guestData,"item":item}})]}},{key:"tab(surveys)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileSurveysTabContent',{attrs:{"guest":_vm.guestData,"item":item},on:{"click":openSubMenu}})]}},{key:"sub(surveys)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileSurveysSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu}})]}},{key:"tab(segments)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileSegmentsTabContent',{attrs:{"guest":_vm.guestData,"item":item},on:{"click":openSubMenu}})]}},{key:"sub(segments)",fn:function(ref){
var item = ref.item;
var closeSubMenu = ref.closeSubMenu;
return [_c('ContactProfileSegmentsSubMenu',{attrs:{"guest":_vm.guestData,"title":item.label},on:{"close":closeSubMenu,"hide":function($event){return _vm.$emit('close')}}})]}},{key:"tab(messages)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_c('ContactProfileMessagesTabContent',{attrs:{"guest":_vm.guestData}})],1)]}},{key:"content(messages)",fn:function(){return [_c('ContactProfileMessagesSubMenu',{attrs:{"messages-sent":_vm.guestData.messages,"guest-identity":_vm.guestData},on:{"updated":function($event){return _vm.reload('messages')}}})]},proxy:true},{key:"tab(history)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_c('ContactProfileHistoryTabContent',{attrs:{"guest":_vm.guestData,"history":_vm.history}})],1)]}},{key:"content(history)",fn:function(){return [_c('ContactProfileHistorySubMenu',{attrs:{"guest":_vm.guestData,"history":_vm.history}})]},proxy:true},{key:"content(debug)",fn:function(){return [_c('ContactProfileDebugSubMenu',{attrs:{"guest":_vm.guestData}})]},proxy:true},{key:"tab(debug)",fn:function(ref){
var item = ref.item;
var openSubMenu = ref.openSubMenu;
return [_c('ContactProfileTab',{attrs:{"icon":item.icon,"title":item.label},on:{"click":openSubMenu}},[_vm._v(" "+_vm._s(_vm.$t('CONTACT_PROFILE_DEBUG_TAB_CONTENT'))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }