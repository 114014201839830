















import { DigiIconButton } from '@/components/ui/actions'
import type { GuestField } from '@/models/Event/GuestField'
import type Guest from '@/models/Guest'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AddAccompanistModal from './AddAccompanistModal/AddAccompanistModal.vue'

@Component({ components: { DigiIconButton, AddAccompanistModal } })
export default class AddAccompanistButton extends Vue {
  @Prop({ required: true }) readonly master!: Guest
  @Prop({ required: true }) readonly selectedFieldsData!: GuestField[]

  showModal: boolean = false
}
