






import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class DigiCardItem extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
}
