import { ElementTypeDictionary } from '../../types'
import TextMedia2Element from './textMedia2/TextMedia2Element'
import TextMedia7Element from './textMedia7/TextMedia7Element'
import TextMedia3Element from './textMedia3/TextMedia3Element'
import TextMedia4Element from './textMedia4/TextMedia4Element'
import TextMedia5Element from './textMedia5/TextMedia5Element'
import TextMedia6Element from './textMedia6/TextMedia6Element'
import TextMedia1Element from './textMedia1/TextMedia1Element'
import TextMedia8Element from './textMedia8/TextMedia8Element'

export const textMediaDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: TextMedia1Element,
    renderer: () => import('./textMedia1/TextMedia1.vue'),
    properties: [
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      {
        type: 'color',
        label: 'FRAME_TITLE_COLOR',
        key: 'properties.frameTitleColor',
        toContrastWith: 'properties.frameBackgroundColor'
      },
      { type: 'color', label: 'FRAME_BACKGROUND_COLOR', key: 'properties.frameBackgroundColor' },
      {
        type: 'color',
        label: 'TEXT_COLOR',
        key: 'properties.textColor',
        toContrastWith: 'properties.frameBackgroundColor'
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_1',
    previewImageLink: require('@/assets/img/prefab-previews/textImg1.jpg')
  })
  .addType({
    dataClass: TextMedia2Element,
    renderer: () => import('./textMedia2/TextMedia2.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_2',
    previewImageLink: require('@/assets/img/prefab-previews/textImg2.jpg')
  })
  .addType({
    dataClass: TextMedia3Element,
    renderer: () => import('./textMedia3/TextMedia3.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_3',
    previewImageLink: require('@/assets/img/prefab-previews/textImg3.jpg')
  })
  .addType({
    dataClass: TextMedia4Element,
    renderer: () => import('./textMedia4/TextMedia4.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' },
      { type: 'ctaOptions', label: 'CTA_OPTIONS', key: 'properties.cta' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_4',
    previewImageLink: require('@/assets/img/prefab-previews/textImg4.jpg')
  })
  .addType({
    dataClass: TextMedia5Element,
    renderer: () => import('./textMedia5/TextMedia5.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_5',
    previewImageLink: require('@/assets/img/prefab-previews/textImg5.jpg')
  })
  .addType({
    dataClass: TextMedia6Element,
    renderer: () => import('./textMedia6/TextMedia6.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_6',
    previewImageLink: require('@/assets/img/prefab-previews/textImg6.jpg')
  })
  .addType({
    dataClass: TextMedia7Element,
    renderer: () => import('./textMedia7/TextMedia7.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_7',
    previewImageLink: require('@/assets/img/prefab-previews/textImg7.jpg')
  })
  .addType({
    dataClass: TextMedia8Element,
    renderer: () => import('./textMedia8/TextMedia8.vue'),
    properties: [
      { type: 'color', label: 'TAG_COLOR', key: 'properties.tagColor' },
      { type: 'color', label: 'TITLE_COLOR', key: 'properties.titleColor' },
      { type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_IMAGE_8',
    previewImageLink: require('@/assets/img/prefab-previews/textImg8.jpg')
  })
  .finish()
