import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'

import type { MediaProperties } from '../../../types/helperTypes'
import TextMediaElementBase, { TextMediaProperties } from '../TextMediaElementBase'
import type { CtaProperties } from '@/features/website/builder/builder/prefabs/components/cta'
import { defaultCtaProperties } from '@/features/website/builder/builder/prefabs/components/cta/types'

export class TextMedia4Properties extends TextMediaProperties {
  title: string = ''
  titleColor: string = ''
  tagText: string = ''
  tagColor: string = ''
  media: MediaProperties | null = null
  cta: CtaProperties = defaultCtaProperties()
}

export default class TextMedia4Element extends TextMediaElementBase {
  static create({ palette, mostReadableColor }: ElementCreationContext): TextMedia4Element {
    const textImage = new TextMedia4Element()

    textImage.properties.title = i18n.t('AN_INSPIRING_TITLE')
    textImage.properties.titleColor = mostReadableColor

    textImage.properties.tagText = i18n.t('SECTION_NAME')
    textImage.properties.tagColor = mostReadableColor

    textImage.properties.mainText = new LoremIpsum(
      {
        wordsPerSentence: { min: 2, max: 5 },
        sentencesPerParagraph: { min: 1, max: 3 }
      },
      undefined,
      `\n\n`
    ).generateParagraphs(5)
    textImage.properties.textColor = mostReadableColor

    textImage.properties.cta.label = i18n.t('REGISTRATION_CTA_BTN_LABEL')
    textImage.properties.cta.color = palette.darkestColor.paletteCode

    return textImage
  }

  type = `prefab-text-media-4` as const

  @Type(() => TextMedia4Properties)
  properties: TextMedia4Properties = new TextMedia4Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TEXT_IMAGE4')
  }
}
