import store from '@/store'
import type Form from '@/models/Event/Form'
import type Rule from '@/models/Event/RulesData/Rule'
import { SURVEY_TRIGGER_KIND } from './surveyTriggers'
import type { ReadonlyFullRouteConfig } from '@/core'

export const surveysRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'surveys',
    name: 'event.website.surveys',
    component: () => import('./views/SurveysList.vue'),
    meta: {
      name: 'SURVEYS',
      iconName: 'file-list-2-line',
      hideOnInactiveFeatures: false,
      keywords: 'FACTORY_SURVEYS_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.website.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          const slaveFormId = store.getters.event?.modules_data.multiRegistration?.slaveFormId
          return store.getters.event?.forms.some((form: Form) => !form.isRegistration && form._id !== slaveFormId)
        }
      }
    }
  },
  {
    path: 'surveys/edit/:formId',
    name: 'event.website.surveys.edition',
    component: () => import('./views/SurveyEdition.vue'),
    meta: {
      subPageOf: 'event.website.surveys'
    }
  },
  {
    path: 'surveys/rules',
    name: 'event.website.surveys.rules',
    component: () => import('./views/SurveyRulesView.vue'),
    meta: {
      name: 'FACTORY_SURVEY_RULES_LIST',
      iconName: 'user-add-line',
      keywords: 'FACTORY_SINGLE_REGISTRATION_RULES_KEYWORDS',
      searchable: true,
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event.rules.ruleItems.filter((rule: Rule) => SURVEY_TRIGGER_KIND.includes(rule.trigger.kind))
              .length > 0
          )
        }
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.website.allowed,
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'surveys/rules/edition/:ruleId?',
    name: 'event.website.surveys.rules.edition',
    component: () => import('./views/SurveyRulesEditionView.vue'),
    meta: {
      subPageOf: 'event.website.surveys.rules',
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.website.allowed,
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
