import { Type } from 'class-transformer'
import ContentElementBase from '../ContentElementBase'
import { i18n } from '@/utils/i18n'
import { MapMarker } from '../../types/helperTypes'

class MapProperties {
  @Type(() => MapMarker)
  marker = new MapMarker()
}

export default class MapElement extends ContentElementBase {
  static create(): MapElement {
    return new MapElement()
  }

  type = 'content-map' as const
  prettyName = i18n.t('BLOCK_MAP')

  @Type(() => MapProperties)
  properties = new MapProperties()
}
