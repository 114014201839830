










































































import { Vue, Component } from 'vue-property-decorator'
import { DigiButton } from '@/components/ui/actions'
import { DigiLink } from '@/components/ui/actions'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton, DigiLink, DigiButton }
})
export default class ComponentsList extends Vue {}
