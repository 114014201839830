import type { MongoDb } from '@/models/MongoDbTools'
import { Type } from 'class-transformer'
import type { FilterId } from '../../Filter'
import type { GuestFieldKey } from '@/models/Event/GuestField'

export type CheckinPointId = MongoDb.ObjectId

export class CheckinPoint {
  _id!: CheckinPointId
  name!: string
  segmentIds!: FilterId[]
  signatureEnabled: boolean = false
  signature?: {
    fieldId: string
    clauseEnabled: boolean
    clause?: string
  }
}

export default class CheckinModule {
  allowAccompanists?: boolean

  checkinBkgFile?: string

  displayedFields: GuestFieldKey[] = []
  canAddExtraGuest: boolean = true
  extraGuestFields: GuestFieldKey[] = []
  editableFields: GuestFieldKey[] = []
  searchFields: GuestFieldKey[] = []

  @Type(() => CheckinPoint)
  points: CheckinPoint[] = []

  tryGetCheckinPointById(id: CheckinPointId): CheckinPoint | null {
    return this.points.find((point) => point._id === id) ?? null
  }

  getCheckinPointById(id: CheckinPointId): CheckinPoint {
    const result = this.tryGetCheckinPointById(id)

    if (!result) throw new Error(`No checkin point found for id ${id}.`)

    return result
  }

  mainColor: string = ''

  rateLimits?: number

  secretCheckinId: string = ''

  logo?: string
}
