export const audienceMenuSection = {
  key: 'audience',
  titleSlug: 'EVENT_MENU_AUDIENCE_TITLE',
  iconName: 'contacts-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_CONTACTS_LINK',
      routeName: 'contacts',
      requiredPermissions: ['guestList_read']
    },
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_SEGMENTS_LINK',
      routeName: 'event.filters'
    },
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_CUSTOM_FIELDS_LINK',
      routeName: 'event.settings.guestfields',
      requiredPermissions: ['configEvent']
    },
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_IMPORT_LINK',
      routeName: 'event.import',
      requiredPermissions: ['guestList_import']
    },
    {
      titleSlug: 'EVENT_MENU_AUDIENCE_EXPORT_LINK',
      routeName: 'event.exports',
      requiredPermissions: ['data_export']
    }
  ]
} as const
