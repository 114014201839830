import { render, staticRenderFns } from "./HeaderNavItem.vue?vue&type=template&id=61efc9fc&scoped=true"
import script from "./HeaderNavItem.vue?vue&type=script&lang=ts"
export * from "./HeaderNavItem.vue?vue&type=script&lang=ts"
import style0 from "./HeaderNavItem.vue?vue&type=style&index=0&id=61efc9fc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61efc9fc",
  null
  
)

export default component.exports