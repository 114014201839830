









import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { doesGuestMatchFilter } from '@/services/filter'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { SingleSegmentBadge } from '@/components/features/multipleSegmentsBadge'

@Component({
  components: { SingleSegmentBadge, ContactProfileTab }
})
export default class ContactProfileSegmentsTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get segments() {
    return this.storeEvent.filters.filter((filter) => doesGuestMatchFilter(this.guest, filter))
  }

  get firstThreeSegmentsName(): string[] {
    return this.segments.slice(0, 3).map((segment) => segment.name)
  }

  get otherSegmentsCount(): number {
    return this.segments.slice(3).length
  }
}
