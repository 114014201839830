









import type MeetingSchedule from '@/models/Guest/Planning/ScheduledItem/Meeting'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MeetingCancelBadge extends Vue {
  @Prop({ required: true }) readonly meeting!: MeetingSchedule
}
