import type { SponsorId } from '@/models/Event/modules/Programme/Sponsor'
import type Sponsor from '@/models/Event/modules/Programme/Sponsor'
import type { StoreState } from '@/store'
import type { Module } from 'vuex'

const programmeModule: Module<undefined, StoreState> = {
  getters: {
    sponsorsMap(state, getters, rootState) {
      const sponsorMap: Record<SponsorId, Sponsor> = {}
      const programme = rootState.event.event?.modules_data.programme
      if (programme?.sponsors) {
        for (const sponsor of programme.sponsors) {
          sponsorMap[sponsor._id] = sponsor
        }
      }
      return sponsorMap
    }
  }
}

export default programmeModule
