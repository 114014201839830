import type { MongoDb } from '@/models/MongoDbTools'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import { Type } from 'class-transformer'
import type { FilterId } from '../../Filter'
import type { GuestFieldKey } from '../../GuestField'
import type { MessageId } from '../../Messages/template/Base'

export type CampaignId = MongoDb.ObjectId

export class CampaignHistoryPoint {
  _id!: string
  @TransformDateStringToDateObject()
  sentDate!: Date
  nbOfRecipients!: number
}

class SendingSchedule {
  @TransformDateStringToDateObject()
  sendAt!: Date
  done!: boolean
}

export default class Campaign {
  _id!: CampaignId

  isBeingSent!: boolean

  messageTemplateId!: MessageId
  atField!: GuestFieldKey
  archived?: boolean
  segmentsIds!: FilterId[]

  @Type(() => SendingSchedule)
  sendingSchedule?: SendingSchedule

  @Type(() => CampaignHistoryPoint)
  sendingHistory?: CampaignHistoryPoint[]
}
