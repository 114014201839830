import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'

export class Media5Properties extends PrefabPropertiesBase {
  imageUrls: string[] = ['', '', '']
}

export default class Media5Element extends MediaElementBase {
  static create(): Media5Element {
    const media5 = new Media5Element()

    return media5
  }

  type = `prefab-media-5` as const

  @Type(() => Media5Properties)
  properties: Media5Properties = new Media5Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_IMAGES3')
  }
}
