import type { MixedSort } from '@/models/commons/sort'
import type Campaign from '@/models/Event/modules/Campaigns/Campaign'
import type { CampaignId } from '@/models/Event/modules/Campaigns/Campaign'
import axios from 'axios'
import type { CampaignStats, EmailCreation, EmailDuplication, SMSCreation, SMSDuplication } from './types'
import type { EventId } from '@/models/Event'

export async function createEmailCampaign(eventId: string, data: EmailCreation): Promise<string> {
  const res = await axios.post<string>(
    `/backoffice/events/${eventId}/campaigns`,
    {
      data
    },
    {
      params: {
        type: 'email'
      }
    }
  )
  return res.data
}

export async function duplicateEmailCampaign(
  campaignId: string,
  data: EmailDuplication,
  eventId: string
): Promise<string> {
  const res = await axios.post<string>(
    `/backoffice/events/${eventId}/campaigns/${campaignId}/duplicate`,
    {
      data
    },
    {
      params: {
        type: 'email'
      }
    }
  )
  return res.data
}

export async function createSMSCampaign(eventId: string, data: SMSCreation): Promise<string> {
  const res = await axios.post<string>(
    `/backoffice/events/${eventId}/campaigns`,
    {
      data
    },
    {
      params: {
        type: 'sms'
      }
    }
  )
  return res.data
}

export async function duplicateSMSCampaign(campaignId: string, data: SMSDuplication, eventId: string): Promise<string> {
  const res = await axios.post<string>(
    `/backoffice/events/${eventId}/campaigns/${campaignId}/duplicate`,
    {
      data
    },
    {
      params: {
        type: 'sms'
      }
    }
  )
  return res.data
}

export async function deleteCampaign(eventId: string, campaignId: string): Promise<void> {
  await axios.delete(`/backoffice/events/${eventId}/campaigns/${campaignId}`)
}

export async function sortCampaigns(sort: MixedSort, eventId: string): Promise<void> {
  await axios.put(`backoffice/events/${eventId}/campaigns/sort`, { sort })
}

export async function addCampaignGroup(groupName: string, eventId: string): Promise<void> {
  await axios.post<MixedSort>(`backoffice/events/${eventId}/campaigns/group`, { groupName })
}

export async function deleteCampaignGroup(groupId: string, eventId: string): Promise<void> {
  await axios.delete<MixedSort>(`backoffice/events/${eventId}/campaigns/groups/${groupId}`)
}

export async function updateGroupName(groupId: string, groupName: string, eventId: string) {
  await axios.patch(`backoffice/events/${eventId}/campaigns/groups/${groupId}/name`, {
    groupName
  })
}

export async function getCampaignsStat(eventId: string): Promise<Record<string, CampaignStats>> {
  const res = await axios.get(`backoffice/events/${eventId}/campaigns/stats`)
  return res.data
}

export async function archiveCampaign(campaignId: string, eventId: string): Promise<void> {
  await axios.post<Campaign[]>(`backoffice/events/${eventId}/campaigns/${campaignId}/archive`)
}

export async function unarchiveCampaign(campaignId: string, eventId: string): Promise<void> {
  await axios.post<Campaign[]>(`backoffice/events/${eventId}/campaigns/${campaignId}/unarchive`)
}

export async function preSendingCount(eventId: EventId, campaignId: CampaignId): Promise<number> {
  const { data: count } = await axios.get<number>(
    `backoffice/events/${eventId}/campaigns/${campaignId}/countCampaignRecipients`
  )
  return count
}

export async function sendCampaign(eventId: EventId, campaignId: CampaignId, guestsLength: number): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/campaigns/${campaignId}/sendCampaign`, {
    guestsLength
  })
}

export async function scheduleCampaign(eventId: EventId, campaignId: CampaignId, sendAt: Date): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/campaigns/${campaignId}/schedule`, {
    sendAt
  })
}

export async function unscheduleCampaign(eventId: EventId, campaignId: CampaignId): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/campaigns/${campaignId}/unschedule`)
}

export async function updateGuestCampaignSubscriptionStatus(eventId: string, guestId: string, unsubscribe: boolean) {
  await axios.post(`/backoffice/events/${eventId}/guests/${guestId}/campaignSubscription`, { unsubscribe })
}
