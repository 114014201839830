












import { Vue, Component, Ref, VModel, Prop } from 'vue-property-decorator'
import type { RawEditorSettings } from 'tinymce'
import type { ModelEvents } from './tinyMce/types'
import type { TextEditorToolbar } from './tinyMce/types'
import { getSimpleTinyMceOptions } from '@/components/features/wysiwyg/tinyMce/tinyMceOptions'
import FixedTinyMceEditor from '@/components/features/wysiwyg/FixedTinyMceEditor.vue'

@Component({
  components: { FixedTinyMceEditor }
})
export default class SimpleWysiwygEditor extends Vue {
  @Ref() readonly tinyEditor!: FixedTinyMceEditor
  @VModel({ required: true }) editorValue!: string

  @Prop() readonly init!: RawEditorSettings
  /**
   * See FixedTinyMceEditor.vue for more info
   */
  @Prop({ default: 0 }) readonly timeToWaitMilliseconds!: number
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: () => ['change', 'undo', 'redo'] }) readonly modelEvents!: ModelEvents[]
  @Prop({ default: () => [] }) readonly toolbar!: TextEditorToolbar
  @Prop({ default: false }) readonly inline!: boolean

  get editorOptions() {
    return getSimpleTinyMceOptions(this.inline)
  }

  save() {
    this.tinyEditor.save()
  }
}
