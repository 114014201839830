import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import type { EventId } from '@/models/Event'
import type { DateRange, EventActivityId } from '@/models/Event/modules/Programme'
import type { GuestId } from '@/models/Guest'
import store from '@/store'
import { UnscheduledMeetingStatus } from '@/models/Guest/Planning/UnscheduledMeeting'
import type { ScheduledItemId } from '@/models/Guest/Planning/ScheduledItem/Base'
import type ScheduledItem from '@/models/Guest/Planning/ScheduledItem'

/**
 * Status strings used only in context of matchmaking session stats.
 * This enum is never used directly in DB, and only reflect arbitrary states dependant on other concrete data.
 */
export enum ScheduledMeetingStatus {
  Upcoming = 'upcoming',
  Ended = 'ended',
  EndedChecked = 'endedChecked',
  InProgress = 'inProgress',
  InProgressChecked = 'inProgressChecked',
  AwaitingConnection = 'awaitingConnection',
  Unhonored = 'unhonored',
  Canceled = 'canceled'
}

export type MeetingStatus = ScheduledMeetingStatus | UnscheduledMeetingStatus

export function isScheduledMeetingStatus(status: string): status is ScheduledMeetingStatus {
  return Object.values(ScheduledMeetingStatus).includes(status as ScheduledMeetingStatus)
}

function isUnscheduledMeetingStatus(status: string): status is UnscheduledMeetingStatus {
  return Object.values(UnscheduledMeetingStatus).includes(status as UnscheduledMeetingStatus)
}

export function isMeetingStatus(status: string): status is MeetingStatus {
  return isScheduledMeetingStatus(status) || isUnscheduledMeetingStatus(status)
}

export interface ParticipantData {
  _id: GuestId
  name: string
  firstname?: string
  organization?: string
}

export interface ScheduledMeetingParticipant extends ParticipantData {
  planning: {
    scheduledItems: ScheduledItem[]
  }
}

interface MeetingDataBase {
  _id: ScheduledItemId
}

export interface ScheduledMeetingData extends MeetingDataBase {
  isCanceled: boolean
  location: string | null
  dateRange: DateRange
  count: number
  applicant: ScheduledMeetingParticipant
  target: ScheduledMeetingParticipant
}

export interface UnscheduledMeetingData extends MeetingDataBase {
  applicant: ParticipantData
  target: ParticipantData
}

export type MatchmakingSessionStats = Record<MeetingStatus, number>

interface GetMeetingsResponseBase {
  meetingCount: number
}

export interface ScheduledMeetingsResponse extends GetMeetingsResponseBase {
  meetings: ScheduledMeetingData[]
}

export interface UnscheduledMeetingsResponse extends GetMeetingsResponseBase {
  meetings: UnscheduledMeetingData[]
}

export async function fetchMatchmakingSessionStats(
  eventId: EventId,
  sessionId: EventActivityId,
  isOnSiteSession: boolean,
  loadFromCache: boolean = true
): Promise<MatchmakingSessionStats> {
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: `backoffice/${eventId}/matchmaking/${sessionId}/getStats`,
    params: {
      isOnSiteSession,
      loadFromCache
    }
  }
  const res = await axios.request<MatchmakingSessionStats>(axiosOptions)

  return res.data
}

export async function fetchScheduledMeetings(
  eventId: EventId,
  sessionId: EventActivityId,
  status: MeetingStatus,
  page: number,
  limit: number,
  sortKey: string,
  descending: boolean
): Promise<ScheduledMeetingsResponse> {
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: `backoffice/${eventId}/matchmaking/${sessionId}/getScheduledMeetingsByStatus`,
    params: {
      status,
      page,
      limit,
      sortKey,
      descending
    }
  }
  const res = await axios.request<ScheduledMeetingsResponse>(axiosOptions)

  return res.data
}

export async function fetchUnscheduledMeetings(
  eventId: EventId,
  sessionId: EventActivityId,
  status: MeetingStatus,
  page: number,
  limit: number
): Promise<UnscheduledMeetingsResponse> {
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: `backoffice/${eventId}/matchmaking/${sessionId}/getUnscheduledMeetingsByStatus`,
    params: {
      status,
      page,
      limit
    }
  }
  const res = await axios.request<UnscheduledMeetingsResponse>(axiosOptions)

  return res.data
}

export interface TimeSlot {
  start: string
  end: string
}

export async function getAvailableTimeSlotsForMeeting(
  applicantId: GuestId,
  targetId: GuestId,
  matchmakingSessionId: EventActivityId
): Promise<TimeSlot[]> {
  if (!store.state.event.event) throw new Error('Event not loaded in store, cannot evaluate time slots.')

  return (
    await axios.get<{ meetingPossibleSlots: TimeSlot[] }>(
      `/backoffice/events/${store.state.event.event._id}/matchmakingSession/${matchmakingSessionId}/applicant/${applicantId}/target/${targetId}/possibleSlots`
    )
  ).data.meetingPossibleSlots
}

export async function cancelMeeting(eventId: EventId, meetingId: EventActivityId): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/planning/meeting/${meetingId}/cancelScheduledMeeting`)
}

export async function removeNonMeetingScheduledItem(
  eventId: string,
  guestId: string,
  scheduledItemId: string
): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/guest/${guestId}/planning/scheduledItem/${scheduledItemId}/remove`)
}
