





import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class RemixIcon extends Vue {
  @Prop({ required: true }) readonly name!: string
  @Prop({ default: '1x' }) readonly size!: string
}
