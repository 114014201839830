import { render, staticRenderFns } from "./ContactProfileSegmentsSubMenu.vue?vue&type=template&id=4ead7027&scoped=true"
import script from "./ContactProfileSegmentsSubMenu.vue?vue&type=script&lang=ts"
export * from "./ContactProfileSegmentsSubMenu.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ead7027",
  null
  
)

export default component.exports