import type { Module } from 'vuex'
import type { StoreState } from '../../'
import type { EventIdentity } from '@/core'
import { EventLangCode } from '@/models/Event/Localized'
import { getEventIdentityById } from '@/services/eventIdentity'
import moment from 'moment'
import { ColorPalette } from '@/models/Event/Identity/ColorPalette'
import { patchEventIdentity } from '@/features/eventSettings/eventIdentity/patchEventIdentity'

const storeModule: Module<EventIdentity, StoreState> = {
  state: {
    id: '',
    name: '',
    presenceType: 'onsite',
    typology: 'internalEvent',
    startDate: new Date(),
    endDate: new Date(),
    timezone: '',
    mainLanguage: EventLangCode.English,
    location: '',
    ownerId: '',
    logo: '',
    colorPalette: [],
    creationDate: new Date(),
    isDemo: true,
    roundingLevel: 0,
    font: { kind: 'basic', family: 'Arial', size: 12 }
  },
  getters: {
    eventIdentity(state): EventIdentity | null {
      return state
    },

    colorPalette(state): ColorPalette {
      const colorPalette = new ColorPalette()
      colorPalette.push(...state.colorPalette)

      return colorPalette
    },

    isEventEnded(state): boolean {
      return moment().isAfter(moment(state.endDate).endOf('day'))
    }
  },
  actions: {
    async loadEventIdentityById({ commit }, payload): Promise<EventIdentity> {
      const eventIdentity = await getEventIdentityById(payload.id, payload.noHTTPCache)
      commit('SET_EVENT_IDENTITY', eventIdentity)

      return eventIdentity
    },
    reloadCurrentEventIdentity({ state, dispatch }): Promise<unknown> {
      if (state) {
        return dispatch('loadEventIdentityById', { id: state.id, skipCache: true, noHTTPCache: true })
      }

      throw new Error('Cannot reload current event identity: There is no current event identity.')
    },
    async saveEventIdentity({ state, commit }, eventIdentity: EventIdentity): Promise<void> {
      await patchEventIdentity(eventIdentity, state.id)
      commit('SET_EVENT_IDENTITY', eventIdentity)
    }
  },
  mutations: {
    SET_EVENT_IDENTITY(state, eventIdentity): void {
      if (!eventIdentity) {
        throw new Error('Mutation SET_EVENT_IDENTITY did not receive an event identity.')
      }

      state.id = eventIdentity.id
      state.name = eventIdentity.name
      state.presenceType = eventIdentity.presenceType
      state.typology = eventIdentity.typology
      state.startDate = eventIdentity.startDate
      state.endDate = eventIdentity.endDate
      state.timezone = eventIdentity.timezone
      state.mainLanguage = eventIdentity.mainLanguage
      state.location = eventIdentity.location
      state.ownerId = eventIdentity.ownerId
      state.logo = eventIdentity.logo
      state.colorPalette = eventIdentity.colorPalette
      state.creationDate = eventIdentity.creationDate
      state.isDemo = eventIdentity.isDemo
      state.roundingLevel = eventIdentity.roundingLevel
      state.font = eventIdentity.font
    }
  }
}

export default storeModule
