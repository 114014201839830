import moment from 'moment-timezone'

export function getDate(date: Date, tz: string): string {
  return moment(date).tz(tz).format('DD/MM/YYYY')
}
export function getDateAndHour(date: Date, tz: string): string {
  return moment(date).tz(tz).format('DD/MM/YYYY - HH:mm')
}

export function getDateAndHourForFile(date: Date): string {
  return moment(date).format('DD-MM-YYYY_HH-mm-ss')
}

export function isBefore(date1: Date, date2: Date): boolean {
  return moment(date1).isBefore(date2)
}

export function addMinutes(date: Date, minutes: number): Date {
  return moment(date).add(minutes, 'minutes').toDate()
}
