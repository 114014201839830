import type { ReadonlyFullRouteConfig } from '@/core'

export const guestFieldsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'guestFields',
    name: 'event.settings.guestfields',
    component: () => import('./views/GuestFields/GuestFields.vue'),
    meta: {
      name: 'FACTORY_CUSTOM_FIELDS',
      keywords: 'FACTORY_CUSTOM_FIELDS_KEYWORDS',
      iconName: 'align-left',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
