import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'
import type { SponsorId } from '@/models/Event/modules/Programme/Sponsor'

export class SponsorsProperties extends PrefabPropertiesBase {
  sponsorsIds: SponsorId[] = []
}

export default abstract class SponsorsElementBase extends PrefabElementBase {
  abstract type: `prefab-sponsors-${number}`

  @Type(() => SponsorsProperties)
  properties: SponsorsProperties = new SponsorsProperties()

  addSponsor(sponsorId: SponsorId): void {
    this.properties.sponsorsIds.push(sponsorId)
  }

  removeSponsor(sponsorIndex: number): void {
    this.properties.sponsorsIds.splice(sponsorIndex, 1)
  }
}
