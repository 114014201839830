




import { Vue, Component, Prop, VModel, Ref } from 'vue-property-decorator'
import { DigiEmailInput } from '@/components/ui/inputs'
import type EmailCustomField from '@/models/Event/CustomField/fieldTypes/Email'

@Component({
  components: { DigiEmailInput }
})
export default class GuestFieldEmailInput extends Vue {
  @VModel() readonly fieldValue!: string
  @Prop({ required: true }) readonly field!: EmailCustomField
  @Ref() readonly input!: DigiEmailInput
}
