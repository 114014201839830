import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import SpeakersElementBase, { SpeakersProperties } from '../SpeakersElementBase'

export class Speakers1Properties extends SpeakersProperties {
  title: string = ''
  subtitle: string = ''
  textColor: string = ''
  itemBgColor: string = ''
  displayAvatarInGrayscale: boolean = false
}

export default class Speakers1Element extends SpeakersElementBase {
  static create({ palette }: ElementCreationContext): Speakers1Element {
    const speakers = new Speakers1Element()

    speakers.properties.title = i18n.t('BACK_SPEAKERS')
    speakers.properties.subtitle = ''
    speakers.properties.textColor = palette.darkestColor.paletteCode
    speakers.properties.itemBgColor = palette.darkestColor.paletteCode
    speakers.properties.displayAvatarInGrayscale = false

    return speakers
  }

  type = `prefab-speakers-1` as const

  @Type(() => Speakers1Properties)
  properties: Speakers1Properties = new Speakers1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SPEAKERS')
  }
}
