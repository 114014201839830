import { checkinMenuSection } from './checkinMenuSection'
import { checkinRoutes } from './checkinRoutes'
import type { DeepReadonly } from '@/utils/utilityTypes'
import type { FullRouteConfig } from '@/core'

export const checkinFeature = {
  name: 'checkin',
  routes: checkinRoutes as DeepReadonly<FullRouteConfig>[],
  eventMenuSection: checkinMenuSection
} as const
