import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import SpeakersElementBase, { SpeakersProperties } from '../SpeakersElementBase'

export class Speakers2Properties extends SpeakersProperties {
  title: string = ''
  subtitle: string = ''
  textColor: string = ''
  itemBgColor: string = ''
  displayAvatarInGrayscale: boolean = false
}

export default class Speakers2Element extends SpeakersElementBase {
  static create({ palette }: ElementCreationContext): Speakers2Element {
    const speakers = new Speakers2Element()

    speakers.properties.title = i18n.t('BACK_SPEAKERS')
    speakers.properties.subtitle = ''
    speakers.properties.textColor = palette.darkestColor.paletteCode
    speakers.properties.itemBgColor = palette.darkestColor.paletteCode
    speakers.properties.displayAvatarInGrayscale = false

    return speakers
  }

  type = `prefab-speakers-2` as const

  @Type(() => Speakers2Properties)
  properties: Speakers2Properties = new Speakers2Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_SPEAKERS')
  }
}
