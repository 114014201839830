import Settings from './Settings.vue'
import store from '@/store'
import type { AnyAction, PostWebhookAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import { isValidUrl } from '@/utils/validation'
import type { ActionUtil } from '../../RuleEdition/types'

function isPostWebhookAction(action: AnyAction): action is PostWebhookAction {
  if (action.kind !== ActionKind.PostWebhook) {
    throw new Error('PostWebhookActionUtil can only manage action of kind PostWebhook')
  }

  return true
}

const postWebhookActionUtil: ActionUtil = {
  kind: ActionKind.PostWebhook as const,
  label: 'SEND_WEBHOOK' as const,
  icon: 'code-line' as const,
  settings: Settings,

  validator(action: AnyAction): boolean {
    if (isPostWebhookAction(action)) {
      return isValidUrl(action.url)
    } else {
      return false
    }
  },

  isAllowed(): boolean {
    return Boolean(store.state.account.account?.hasEnabledApi)
  }
}

export default postWebhookActionUtil
