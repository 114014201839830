











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class UnsubscribeInput extends Vue {
  @Prop({ required: true }) readonly unsubscribed!: boolean | undefined
  @Prop({ type: Boolean }) readonly disabled!: boolean
}
