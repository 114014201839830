


















import { Component, Prop } from 'vue-property-decorator'

import { DigiDateTimeRangePicker } from '@/components/ui/inputs'

import type Guest from '@/models/Guest'
import type { DateRange } from '@/models/Event/modules/Programme'

import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import axios from 'axios'
import { DigiButton } from '@/components/ui/actions'

interface FormattedFormData {
  startDate: Date
  endDate: Date
  comment: string
}

@Component({
  components: { DigiButton, DigiDateTimeRangePicker }
})
export default class UnavailabilityForm extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  submitting = false
  range: DateRange | [] = []
  comment: string = ''

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get formIsValid(): boolean {
    return !!(this.range[0] && this.range[1])
  }

  get formattedFormData(): FormattedFormData | null {
    const [startDate, endDate] = this.range

    if (!startDate || !endDate) {
      return null
    }

    return {
      startDate,
      endDate,
      comment: this.comment
    }
  }

  async submit(): Promise<void> {
    if (!this.formIsValid || !this.formattedFormData) {
      throw Error('Undefined date range')
    }

    this.submitting = true

    try {
      this.$emit(
        'done',
        (
          await axios.post(
            `/backoffice/events/${this.storeEvent._id}/guests/${this.guest._id}/planning/addUnavailability`,
            this.formattedFormData
          )
        ).data
      )
    } finally {
      this.submitting = false
    }
  }
}
