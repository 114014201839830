




import { Vue, Component, Prop } from 'vue-property-decorator'
import DigiSimpleIconButton from '@/components/ui/actions/DigiSimpleIconButton.vue'

@Component({
  components: { DigiSimpleIconButton }
})
export default class DigiCopyButton extends Vue {
  @Prop({ default: false }) readonly disabled!: DigiSimpleIconButton['disabled']
  @Prop({ required: false }) readonly size?: DigiSimpleIconButton['size']
  @Prop({ required: true }) readonly text!: string

  iconName: 'file-copy-line' | 'check-fill' = 'file-copy-line'

  onClick() {
    navigator.clipboard.writeText(this.text)

    this.iconName = 'check-fill'
    setTimeout(() => {
      this.iconName = 'file-copy-line'
    }, 1000)
  }
}
