import { Transform } from 'class-transformer'

function makeTwoWayTransformDecorator<PlainT, TransformedT>(
  toClass: (value: PlainT) => TransformedT,
  toPlain: (value: TransformedT) => PlainT | null
): PropertyDecorator {
  return function (target, propertyKey) {
    if (typeof propertyKey === 'symbol')
      throw new Error('Cannot make two-way transform decorator for a property under symbol key.')

    // Do not run transformations if the value is undefined.
    // This poses the caveat of skipping transformation functions tthat take undefined into account.
    // @TODO: Look into that.
    // Should be fine for more normal use cases.
    Transform(({ value }) => (value !== undefined ? toClass(value) : value), { toClassOnly: true })(target, propertyKey)
    Transform(({ value }) => (value !== undefined ? toPlain(value) : value), {
      toPlainOnly: true
    })(target, propertyKey)
  }
}

function makeTwoWayTransformDecoratorForArray<PlainT, TransformedT>(
  toClass: (value: PlainT) => TransformedT,
  toPlain: (value: TransformedT) => PlainT
): PropertyDecorator {
  return makeTwoWayTransformDecorator<PlainT[], TransformedT[]>(
    (plainValues: PlainT[]) => plainValues.map(toClass),
    (transformedValues: TransformedT[]) => transformedValues.map(toPlain)
  )
}

export function TransformDateStringToDateObject(): PropertyDecorator {
  return makeTwoWayTransformDecorator<string, Date>(
    (dateString: string) => {
      if (dateString === null || dateString === undefined) return dateString

      return new Date(dateString)
    },
    (date: Date | null) => {
      if (date === null) return null
      return date.toISOString()
    }
  )
}

export function TransformArrayOfDateStringsToDateObjects(): PropertyDecorator {
  return makeTwoWayTransformDecoratorForArray<string, Date>(
    (dateString: string) => new Date(dateString),
    (date: Date) => date.toISOString()
  )
}

export function Transform2dArrayOfDateStringsToDateObjects(): PropertyDecorator {
  return makeTwoWayTransformDecoratorForArray<string[], Date[]>(
    (dateStrings: string[]) => dateStrings.map((dateString) => new Date(dateString)),
    (dates: Date[]) => dates.map((date) => date.toISOString())
  )
}
