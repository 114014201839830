import type { EventMenuSection } from '@/navigation/eventMenu'
import type { FullRouteConfig } from '@/core'
import type { DeepReadonly } from '@/utils/utilityTypes'

export interface BaseDigiFeature {
  readonly name: string
  readonly routes: readonly DeepReadonly<FullRouteConfig>[]
}

export interface DigiFeatureWithMenu extends BaseDigiFeature {
  readonly eventMenuSection: EventMenuSection
}

export type DigiFeature = BaseDigiFeature | DigiFeatureWithMenu

export function hasMenuSection(feature: DigiFeature): feature is DigiFeatureWithMenu {
  return 'eventMenuSection' in feature
}

export type FeatureDictionary = readonly DigiFeature[]
