


















































































import type { MomentInput } from 'moment-timezone'
import moment from 'moment-timezone'
import { getGuestById } from '@/services/guest'
import { DigiSpinner } from '@/components/ui'
import UnavailabilityForm from './UnavailabilityForm.vue'
import ActivityForm from './ActivityForm.vue'
import RdvForm from './RdvForm.vue'
import MeetingRequests from './MeetingRequests/MeetingRequests.vue'
import { Component, Prop, Ref } from 'vue-property-decorator'
import type { GuestId } from '@/models/Guest'
import type Guest from '@/models/Guest'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import type { PendingMeeting } from '@/models/Guest/Planning/UnscheduledMeeting'
import type { BModal } from 'bootstrap-vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { GuestPlanningItem } from '@/features/eventApp'
import { computeGuestPlanning } from '@/features/eventApp'
import { DigiIconButton } from '@/components/ui/actions'
import PlanningItem from './PlanningItem/PlanningItem.vue'

enum CreatingType {
  MEETING = 'meeting',
  GROUP_ACTIVTY = 'group_activity',
  UNAVAILABILITY = 'unavailability'
}

@Component({
  components: {
    DigiIconButton,
    DigiSpinner,
    UnavailabilityForm,
    ActivityForm,
    RdvForm,
    MeetingRequests,
    PlanningItem
  },
  filters: {
    timeAgo(isoDate: MomentInput) {
      return moment(isoDate).fromNow()
    }
  }
})
export default class ContactPlanningEditor extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Ref() readonly addModal!: BModal

  creatingType: CreatingType | null = null
  reloadingGuestData: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get matchmakingSessions(): MatchmakingSession[] {
    return Array.from(this.storeEvent.modules_data.programme.matchMakingSessions())
  }

  get planningData(): GuestPlanningItem[] {
    return computeGuestPlanning(this.guest, this.storeEvent)
  }

  get planningDataByDate(): { date: string; planning: GuestPlanningItem[] }[] {
    const groups = new Map<string, GuestPlanningItem[]>()

    this.planningData.forEach((plan) => {
      const date: string = moment(plan.dateRange[0]).format('YYYY-MM-DDT12:00:00Z')

      if (groups.has(date)) {
        groups.get(date)!.push(plan)
      } else {
        groups.set(date, [plan])
      }
    })

    return Array.from(groups.entries())
      .sort(([date1], [date2]) => date1.localeCompare(date2))
      .map(([date, planning]) => ({
        date: moment(date).format('l'),
        planning: planning.sort((planItem1, planItem2) =>
          moment(planItem1.dateRange[0]).diff(moment(planItem2.dateRange[0]))
        )
      }))
  }

  get unscheduledMeetings() {
    return this.guest.planning?.unscheduledMeetings ?? []
  }

  get hasAnyUnscheduledMeeting(): boolean {
    return this.unscheduledMeetings.length > 0
  }

  get hasAnythingScheduled(): boolean {
    return this.planningData.length > 0
  }

  get hasEmptyPlanning() {
    return !this.hasAnyUnscheduledMeeting && !this.hasAnythingScheduled
  }

  get modalTitle(): string {
    switch (this.creatingType) {
      case CreatingType.MEETING:
        return this.$t('REGISTER_MATCHMAKING_SESSION')

      case CreatingType.GROUP_ACTIVTY:
        return this.$t('REGISTER_GROUP_ACTIVITY')

      case CreatingType.UNAVAILABILITY:
        return this.$t('UNAVAILABILITY')

      default:
        return ''
    }
  }

  async enforceReloadOfGuests(...guestIds: GuestId[]): Promise<void> {
    if (this.reloadingGuestData === true) {
      console.warn(
        'Multiple reloads of guest data are being requested simultaneously. This will likely not break but may cause some UI weirdness.'
      )
    }

    this.reloadingGuestData = true

    try {
      await Promise.all(
        guestIds.map(async (guestId) => {
          this.emitGuestUpdate(await getGuestById(this.storeEvent._id, guestId))
        })
      )
    } finally {
      this.reloadingGuestData = false
    }
  }

  emitGuestUpdate(guest: Guest): void {
    this.$emit('update:guest', guest)
  }

  async deletePlanItem(planningItem: GuestPlanningItem): Promise<void> {
    if (planningItem.kind === 'meeting') {
      if (!this.guest.planning) throw new Error('Cannot delete meeting: guest has no planning data.')

      const scheduledItem = this.guest.planning.scheduledItems.find((item) => item._id === planningItem.scheduledItemId)

      if (!scheduledItem || scheduledItem.kind !== 'meeting') {
        throw new Error('Could not find scheduled meeting with the specified ID.')
      }

      await this.enforceReloadOfGuests(scheduledItem.applicant._guest, scheduledItem.target._guest)
    } else {
      await this.enforceReloadOfGuests(this.guest._id)
    }
  }

  async onPlanItemRescheduled(planningItem: GuestPlanningItem) {
    if (!this.guest.planning) throw new Error('Cannot delete meeting: guest has no planning data.')

    if (planningItem.scheduledItemId) {
      const scheduledItem = this.guest.planning.scheduledItems.find((item) => item._id === planningItem.scheduledItemId)

      if (scheduledItem) {
        if (scheduledItem.kind === 'meeting') {
          await this.enforceReloadOfGuests(scheduledItem.applicant._guest, scheduledItem.target._guest)
        } else {
          await this.enforceReloadOfGuests(this.guest._id)
        }
      } else {
        throw new Error('Plan item references nonexistent schedule item ID.')
      }
    }
  }

  async onMeetingAccepted(request: PendingMeeting) {
    await this.enforceReloadOfGuests(request.applicant._guest, request.target._guest)
  }

  async handleAdditionDone(payload: { guest: Guest }) {
    const newGuest: Guest = payload.guest
    let hasPropagatedNewGuest = false

    if (newGuest) {
      this.emitGuestUpdate(newGuest)
      hasPropagatedNewGuest = true
    }

    const wasAddingRdv: boolean = this.creatingType === 'meeting'

    this.closeAdditionModal()

    await this.$nextTick()

    if (!hasPropagatedNewGuest) {
      await this.enforceReloadOfGuests(this.guest._id)
    }

    if (wasAddingRdv) {
      if (!this.guest.planning)
        throw new Error('Unexpected behavior: Somehow added something to the schedule without planning data.')

      const scheduledItem = this.guest.planning.scheduledItems[this.guest.planning.scheduledItems.length - 1]

      if (!scheduledItem) throw new Error('Unexpected behavior: A scheduled item was added, but could not be located.')

      if (scheduledItem.kind === 'meeting') {
        await this.enforceReloadOfGuests(scheduledItem.target._guest)
      }
    }
  }

  closeAdditionModal() {
    this.addModal.hide()
  }
}
