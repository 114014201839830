
















import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { Component, Vue, VModel } from 'vue-property-decorator'

import type { Tag, TagCategory } from './types'
import { tagCategoryMetaDataList, tagMetaDataList } from './tagMetaData'

@Component
export default class TagInput extends Vue {
  @VModel() tag!: Tag | undefined

  selectedCategory: TagCategory | null = null

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get tagCategoryOptions(): { value: TagCategory; text: string }[] {
    return tagCategoryMetaDataList
      .filter((categoryMetaData) => categoryMetaData.predicate(this.eventRights))
      .map((categoryMetaData) => ({ value: categoryMetaData.value, text: this.$t(categoryMetaData.translationSlug) }))
  }

  get tagOptions(): { value: Tag; text: string }[] {
    if (!this.selectedCategory || this.selectedCategory === 'Certificates') {
      return []
    }
    return tagMetaDataList
      .filter(
        (tagMetaData) => tagMetaData.category === this.selectedCategory && tagMetaData.predicate(this.eventRights)
      )
      .map((tagMetaData) => ({
        value: tagMetaData.value,
        text: this.$t(tagMetaData.translationSlug)
      }))
  }

  setSelectedCategory(selectedCategory: TagCategory | null): void {
    this.selectedCategory = selectedCategory
    this.tag = undefined
    if (selectedCategory === 'Certificates') {
      this.tag = 'Certificates'
    }
  }
}
