



































import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import DigiCardItemInformation from './DigiCardItemInformation.vue'

@Component({
  components: {
    DigiCardItemInformation
  }
})
export default class DigiDraggableInformationCard extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ required: true }) readonly title!: string
  @Prop({ required: false }) readonly editLink!: any
  @Prop({ default: true }) readonly enableDrag!: boolean
}
