import type ColumnElement from './Column/ColumnElement'
import type { LinearLayoutElementBase } from './LinearLayout/LinearLayoutElementBase'
import type MainColumnElement from './MainColumn/MainColumnElement'
import type RowElement from './Row/RowElement'

export type LayoutElementCtor = typeof ColumnElement | typeof MainColumnElement | typeof RowElement

type LayoutElement = InstanceType<LayoutElementCtor>

export type LinearLayoutElement = Extract<LayoutElement, LinearLayoutElementBase>

export default LayoutElement

export enum VerticalAlignment {
  Top = 'top',
  Centered = 'centered',
  Bottom = 'bottom'
}
