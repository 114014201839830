import { ErrorWithExtras } from '@/utils/errorTypes'
import type { MessageTemplate, MessageId } from './template'
import type { MailBranding } from '@/models/Event/Messages/MailBranding'

export default class EventMessagesData {
  templates: MessageTemplate[] = []
  mailBranding!: MailBranding
  isSenderDomainVerified?: boolean
  sendgridConfig?: {
    sendingDomainId?: string
    sendgridUser: string
  }
  emailSendingDomain?: string
  defaultEmailSender?: string
  replyTo?: string

  getTemplateById(id: MessageId): MessageTemplate {
    const result = this.templates.find((template) => template._id === id)

    if (result) {
      return result
    } else {
      throw new ErrorWithExtras('Message template not found', { templateId: id })
    }
  }
}
