import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'

const participationDeclineTriggerUtil: TriggerUtil = {
  kind: TriggerKind.ParticipationDecline as const,
  label: 'PARTICIPATION_DECLINE' as const,
  icon: 'thumb-down-line' as const
}

export default participationDeclineTriggerUtil
