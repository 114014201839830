import { websiteBuilderRoutes } from './builder/routes'
import { generalRoutes } from './generalSettings/routes'
import type { ReadonlyFullRouteConfig } from '@/core'

export const websiteRoutes: ReadonlyFullRouteConfig = [
  ...websiteBuilderRoutes,
  ...generalRoutes,
  {
    path: 'website/translations',
    name: 'event.website.translations',
    component: () => import('./translations/views/TranslationsView.vue'),
    meta: {
      name: 'FACTORY_MULTI_LANGUAGE',
      description: 'MULTI_LANGUAGE_DESC',
      iconName: 'translate-2',
      keywords: 'FACTORY_MULTI_LANGUAGE_KEYWORDS',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.general.customization.multiLang,
        redirectName: 'event.settings.inactiveFeatures'
      },
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      }
    }
  }
] as const
