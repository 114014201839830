













import { Vue, Component, Prop } from 'vue-property-decorator'
import { InvitationStatusDropdown } from '@/features/registration'
import type Guest from '@/models/Guest'
import { getStatusExplanationSlug } from '@/utils/invitationStatus'

@Component({
  components: { InvitationStatusDropdown }
})
export default class RegistrationStatusEditor extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get invitationStatusExplanation() {
    return this.$t(getStatusExplanationSlug(this.guest.invitationStatus))
  }
}
