import type { MongoDb } from '@/models/MongoDbTools'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'

export type ScheduledItemId = MongoDb.ObjectId

export default abstract class ScheduledItemBase {
  _id!: ScheduledItemId
  abstract kind: string
}

export abstract class ScheduledItemFromProgrammeBase extends ScheduledItemBase {
  @TransformDateStringToDateObject()
  creationDate!: Date
  eventActivityId!: MongoDb.ObjectId
}
