import type { LayoutElementCtor } from '../layout/types'
import type { ContentElementCtor } from '../customBlocks/type'
import type { PrefabElementCtor } from '../prefabs/types'
import { Exclude, Type } from 'class-transformer'
import type { PageBuilderConfig } from './Page'
import { cloneDeep } from 'lodash'

export type PageElementId = string

export type Padding = number[]

export type PrefabBackgroundType = 'color' | 'image'

export type PrefabBackgroundImageConfig = {
  url: string
  hasParallax: boolean
  brightness100: number
}

export type BackgroundImageRecommendedSize = {
  width: number
  height: number
}

export class BoxProperties {
  paddingPx?: Padding = [0, 0]
  backgroundType: PrefabBackgroundType = 'color'
  backgroundColor: string | null = null
  backgroundImage: PrefabBackgroundImageConfig | null = null
}

export interface DeleteContext {
  id: PageElementId
  deletedId: PageElementId
  pageConfig: PageBuilderConfig
  isSelf: boolean
}

export interface BeforeDeleteContext extends DeleteContext {
  elementBeingDeleted: PageElement
  cancelDelete: (motive?: string) => never
}

export abstract class PageElementBase {
  //#region Basic properties most child classes will fill
  abstract type: string
  @Type(() => BoxProperties)
  box: BoxProperties
  properties?: Record<string, any>
  //#endregion

  //#region Presentational fluff
  @Exclude()
  /**
   * @deprecated not used anymore, the name is in the dictionary
   */
  abstract prettyName: string
  //#endregion

  @Exclude()
  canHaveBackgroundImage = true

  @Exclude()
  backgroundImageRecommendedDimensions: BackgroundImageRecommendedSize = {
    width: 1920,
    height: 450
  }

  constructor() {
    this.box ??= new BoxProperties()
  }

  duplicate(pageConfig: PageBuilderConfig): PageElementId {
    // @FIXME: Remove this ugly cast and crate a proper type checker for concrete PageElement types.
    return pageConfig.addElement(cloneDeep(this as unknown as PageElement))
  }

  //#region Methods to run through the element's descendants
  *allDirectChildrenIds(): Generator<PageElementId, void> {}
  get directChildrenCount() {
    let total = 0
    for (const _ of this.allDirectChildrenIds()) {
      ++total
    }
    return total
  }
  *allDescendantIdsBreadthFirst(pageConfig: PageBuilderConfig): Generator<PageElementId> {
    const descendantIterators = new Array<Generator<PageElementId>>()

    for (const directChildId of this.allDirectChildrenIds()) {
      descendantIterators.push(pageConfig.getElementFromId(directChildId).allDirectChildrenIds())

      yield directChildId
    }

    while (descendantIterators.length > 0) {
      for (const descendantId of descendantIterators.shift()!) {
        yield descendantId
        descendantIterators.push(pageConfig.getElementFromId(descendantId).allDirectChildrenIds())
      }
    }
  }
  *deadReferences(pageConfig: PageBuilderConfig) {
    for (const childId of this.allDirectChildrenIds()) {
      if (pageConfig.hasElementForId(childId) === false) {
        yield childId
      }
    }
  }
  //#endregion

  //#region Methods to check for descendance
  get hasAnyChildren(): boolean {
    return this.allDirectChildrenIds().next().done === false
  }
  isIdChild(targetId: PageElementId) {
    for (const childId of this.allDirectChildrenIds()) {
      if (targetId === childId) return true
    }
    return false
  }
  isIdDescendant(targetId: PageElementId, pageConfig: PageBuilderConfig) {
    for (const childId of this.allDescendantIdsBreadthFirst(pageConfig)) {
      if (targetId === childId) return true
    }
    return false
  }
  @Exclude()
  abstract canHaveChildren: boolean
  //#endregion

  //#region Methods to manage descendance
  /**
   * Removes an ID from the element's direct children.
   *
   * @remarks This isn't deletion, it only detaches the child.
   * @param _ The page element ID to detach from this element.
   * @returns True if the child ID was removed, false otherwise.
   */
  removeChildId(_: PageElementId): boolean {
    return false
  }
  //#endregion

  //#region Page management event hooks
  beforeDeleteElement(context: BeforeDeleteContext): void {
    void context
  }
  onDeleteElement(context: DeleteContext): void {
    void context
  }
  //#endregion
}

export type PageElementCtor = ContentElementCtor | LayoutElementCtor | PrefabElementCtor

export type PageElement = InstanceType<PageElementCtor>

export type PageElementType<ElementType extends PageElement = PageElement> = ElementType['type']

export type PageElementOfType<ElementType extends PageElementType> = Extract<PageElement, { type: ElementType }>
