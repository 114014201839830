import type { EventRights } from '@/models/Event/EventRights'
export const programmeMenuSection = {
  key: 'programme',
  titleSlug: 'EVENT_MENU_PROGRAMME_TITLE',
  iconName: 'apps-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_PROGRAMME_SESSIONS_LINK',
      routeName: 'event.website.programme',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.allowed
    },
    {
      titleSlug: 'EVENT_MENU_PROGRAMME_SPONSORS_LINK',
      routeName: 'event.website.sponsors',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.allowed
    },
    {
      titleSlug: 'EVENT_MENU_PROGRAMME_NETWORKING_LINK',
      routeName: 'event.networking',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.networking
    },
    {
      titleSlug: 'EVENT_MENU_EVENT_APP_SETTINGS_LINK',
      routeName: 'programme.branding',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.allowed
    },
    {
      titleSlug: 'EVENT_MENU_CHAT_SETTINGS_LINK',
      routeName: 'programme.chat',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.chat
    },
    {
      titleSlug: 'EVENT_MENU_PROGRAMME_RULES_LINK',
      routeName: 'programme.rules',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) =>
        eventRights.features.eventApp.allowed && eventRights.features.eventApp.matchmaking
    },
    {
      titleSlug: 'EVENT_MENU_PROGRAMME_SETTINGS_LINK',
      routeName: 'programme.settings',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.allowed
    },
    {
      titleSlug: '',
      component: () => import('./components/EventMenuEventAppPreviewButton.vue'),
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.eventApp.allowed
    }
  ]
} as const
