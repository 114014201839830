import { render, staticRenderFns } from "./StepCard.vue?vue&type=template&id=9bda23f8&scoped=true&functional=true"
import script from "./StepCard.vue?vue&type=script&lang=ts"
export * from "./StepCard.vue?vue&type=script&lang=ts"
import style0 from "./StepCard.vue?vue&type=style&index=0&id=9bda23f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "9bda23f8",
  null
  
)

export default component.exports