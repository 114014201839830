
























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DigiImageDisplay extends Vue {
  @Prop() readonly image?: string | null
  @Prop() readonly height?: number
  @Prop() readonly width?: number
  @Prop({ default: 'unset' }) readonly color!: string

  get modalId(): string {
    return `ImgDisplay-${this.$uuid}`
  }
}
