import ContentElementBase from '../ContentElementBase'
import { LoremIpsum } from 'lorem-ipsum'
import { i18n } from '@/utils/i18n'
import { capitalize } from 'lodash'

export default class TextElement extends ContentElementBase {
  static create(): TextElement {
    const newElement = new TextElement()

    const title: HTMLHeadingElement = document.createElement('h1')
    title.innerText = capitalize(new LoremIpsum().generateWords(2))

    const paragraph: HTMLParagraphElement = document.createElement('p')
    paragraph.innerText = new LoremIpsum().generateParagraphs(1)

    const containingDiv: HTMLDivElement = document.createElement('div')
    containingDiv.appendChild(title)
    containingDiv.appendChild(paragraph)

    newElement.properties.text = containingDiv.innerHTML

    return newElement
  }

  type: 'content-text' = 'content-text'
  properties: { text: string } = { text: '' }
  prettyName = i18n.t('BLOCK_TEXT')
}
