

















































import { isSegmentedListFieldOption, type LimitedListFieldOption, type ListFieldOption } from '@/models/Event/CustomField/fieldTypes/List'
import type ListCustomField from '@/models/Event/CustomField/fieldTypes/List'
import type Guest from '@/models/Guest'
import type { MongoDb } from '@/models/MongoDbTools'
import type { FieldStatItem } from '@/services/guestFields'
import { fetchFieldStats } from '@/services/guestFields'
import { assertEvent } from '@/services/storeEvent'
import { ErrorWithExtras } from '@/utils/errorTypes'
import { matchSomeFilters } from '@digitevent/filterutils'
import { isNil } from 'lodash'
import { Vue, Component, VModel, Prop, Ref } from 'vue-property-decorator'
import type { VueSelectComputed, VueSelectData, VueSelectMethods, VueSelectProps } from 'vue-select'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import type { CombinedVueInstance } from 'vue/types/vue'
import type {Event} from '@/models/Event'

@Component({
  components: {
    VSelect
  }
})
export default class ListFieldInput extends Vue {
  @VModel({ validator: (value) => Array.isArray(value) || isNil(value) }) fieldValue!:
    | MongoDb.ObjectId[]
    | null
    | undefined
  @Prop({ required: true }) readonly field!: ListCustomField
  @Prop({ type: Boolean, default: null }) readonly state!: boolean | null
  @Prop({ required: false }) readonly guest?: Partial<Guest>

  usageData: Record<MongoDb.ObjectId, number> | null = null
  displaySelectionQuotaAlert = false
  // For some reason, vue-select has typing but they don't include the type of a VueSelect instance.
  // So this type is what should technically be the missing type.
  @Ref() readonly dropdown!: CombinedVueInstance<
    Vue,
    VueSelectData,
    VueSelectMethods,
    VueSelectComputed,
    VueSelectProps
  >

  get safeValue(): MongoDb.ObjectId[] {
    if (Array.isArray(this.fieldValue)) {
      return this.fieldValue
    } else if (isNil(this.fieldValue)) {
      return []
    } else {
      throw new ErrorWithExtras('Invalid value for list field.', { value: this.fieldValue })
    }
  }

  get options(): ListFieldOption[] {
    return this.field ? this.field.list : []
  }

  get maxOptions(): number {
    return this.field.maxMultipleSelectList || 1
  }

  get isMultiSelect(): boolean {
    return this.maxOptions > 1
  }

  get isAnyLimited(): boolean {
    return this.options.some((opt) => this.isLimited(opt))
  }

  get isAtMaxCapacity(): boolean {
    return this.safeValue.length >= this.maxOptions
  }

  get hasSelectedTooManyOptions(): boolean {
    return this.safeValue.length > this.maxOptions
  }

  get mustLoadUsage(): boolean {
    return this.isAnyLimited
  }

  get displaySelectionQuota(): boolean {
    return this.maxOptions > 1 || this.safeValue.length > this.maxOptions
  }

  get optionsFromSafeValue(): ListFieldOption[] {
    return this.safeValue.map((id) => this.optionFromId(id))
  }

  get classFromState(): string | null {
    switch (this.state) {
      case true:
        return 'valid'
      case false:
        return 'invalid'
      case null:
      default:
        return null
    }
  }


  get storeEvent(): Event {
    const storeEvent = assertEvent(this.$store.state.event.event)
    return storeEvent
  }

  async handleSearchFocus(): Promise<void> {
    if (this.mustLoadUsage) {
      await this.loadUsageData()
    }
  }

  async loadUsageData(): Promise<void> {
    if (this.field._id === undefined) {
      throw new Error('Unable to load usage data for list field: Field has no assigned ID.')
    }

    this.usageData = null
    const stats: FieldStatItem[] = await fetchFieldStats(this.$store.getters.eventId, this.field._id)
    this.usageData = Object.fromEntries(stats.map((stat) => [stat._id, stat.count]))
  }

  optionFromId(id: MongoDb.ObjectId): ListFieldOption {
    const foundOption: ListFieldOption | undefined = this.options.find((o) => o._id === id)

    if (foundOption) {
      return foundOption
    } else {
      throw new Error(`Unrecognized list option ID: ${id}`)
    }
  }

  isLimited(option: ListFieldOption): option is LimitedListFieldOption {
    return 'maxAvailablePlaces' in option
  }

  getItemsLeft(option: ListFieldOption): number | undefined {
    if (!this.isLimited(option)) {
      return Infinity
    } else if (!this.usageData) {
      return undefined
    } else {
      if (option._id === undefined) {
        return undefined
      } else {
        return option.maxAvailablePlaces - (this.usageData[option._id] ?? 0)
      }
    }
  }

  getTextForItemsLeft(option: ListFieldOption): string {
    const result = this.getItemsLeft(option)

    return result === undefined ? '-' : result.toString()
  }

  hasSelectedOptionOverQuota(options: ListFieldOption[]): boolean {
    return options.some((option) => {
      const leftItems = this.getItemsLeft(option)
      if (Number(leftItems) <= 0) {
        return true
      }
      return false
    })
  }

  isSelectable(option: ListFieldOption): boolean {
    if (this.isSelected(option)) {
      return false
    }
    return true
  }

  isEligible(option: ListFieldOption): boolean {
    if (this.guest && isSegmentedListFieldOption(option)){
        return matchSomeFilters(option.segmentIds.map((id) => this.storeEvent.getFilterById(id)), this.guest)
    }
    return true
  }

  isSelected({ _id: id }: ListFieldOption): boolean {
    if (id) {
      return this.safeValue.includes(id)
    } else {
      // Obviously, an option that does not have an ID cannot be selected
      return false
    }
  }

  handleInput(newValue: ListFieldOption[] | null): void {
    if (newValue === null) {
      this.$emit('input', null)
    } else {
      this.fieldValue = newValue.map((opt) => {
        if (opt._id) {
          return opt._id
        } else {
          throw new Error(`Cannot select option ${opt.value}: Option has no ID.`)
        }
      })

      this.displaySelectionQuotaAlert = this.hasSelectedOptionOverQuota(newValue)
    }
  }
}
