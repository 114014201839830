import type { EventRights } from '@/models/Event/EventRights'
import { hasAccessToFront } from '@/services/front.ts/hasAccessToFront'

export const eventSettingsMenuSection = {
  key: 'eventSettings',
  titleSlug: 'EVENT_MENU_SETTINGS_TITLE',
  iconName: 'settings-5-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_SETTINGS_GENERAL_LINK',
      routeName: 'event.settings.informations',
      requiredPermissions: ['configEvent']
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_BRANDING_LINK',
      routeName: 'event.settings.branding',
      requiredPermissions: ['configEvent']
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_DOMAIN_LINK',
      routeName: 'event.settings.domain',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => hasAccessToFront(eventRights)
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_ORGANIZER_LINK',
      routeName: 'event.settings.legalinformations',
      requiredPermissions: ['configEvent']
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_MESSAGE_LINK',
      routeName: 'event.settings.messageSettings',
      requiredPermissions: ['configEvent']
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_PERMISSIONS_LINK',
      routeName: 'event.settings.permissions',
      requiredPermissions: ['writeRoles']
    },
    {
      titleSlug: 'EVENT_MENU_SETTINGS_INACTIVE_RIGHT_LINK',
      routeName: 'event.settings.inactiveFeatures',
      requiredPermissions: ['configEvent']
    }
  ]
} as const
