import Rule from '@/models/Event/RulesData/Rule'
import type { StoreState } from '@/store'
import type { Module } from 'vuex'

interface RulesState {
  cachedRule: Rule
}

const storeModule: Module<RulesState, StoreState> = {
  state: {
    cachedRule: new Rule()
  },
  mutations: {
    SET_CACHED_RULE(state, newRule) {
      state.cachedRule = newRule
    },
    RESET_CACHED_RULE(state) {
      state.cachedRule = new Rule()
    }
  }
}

export default storeModule
