

























import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { DigiButton, DigiIconButton } from '@/components/ui/actions'
import DigiModal from './DigiModal.vue'
import axios from 'axios'

function isLocalizedError(error: unknown): error is { response: { data: { localized: Record<string, string> } } } {
  const { ...t } = error as object
  if (!axios.isAxiosError(error)) {
    return false
  }

  if (error.response && error.response.data) {
    return 'localized' in error.response.data
  }

  return false
}

@Component({
  components: { DigiButton, DigiIconButton, DigiModal }
})
export default class DigiDeleteConfirmationModal extends Vue {
  @Ref() readonly modal!: DigiModal
  @Prop({ type: String }) readonly message!: string
  @Prop({ required: true }) readonly action!: () => Promise<void>

  errorMessage = ''
  isLoading = false

  async onConfirm() {
    this.errorMessage = ''
    this.isLoading = true
    try {
      await this.action()
      this.close()
    } catch (error: unknown) {
      if (isLocalizedError(error)) {
        const locale = this.$i18n.i18next.language as 'fr' | 'en' | 'pt'

        this.errorMessage = error.response.data.localized[locale] || this.$t('AN_ERROR_OCCURED')
        return
      }

      this.errorMessage = this.$t('AN_ERROR_OCCURED')
    } finally {
      this.isLoading = false
    }
  }

  open() {
    this.modal.open()
  }

  close() {
    this.modal.close()
  }
}
