import type { EventRights } from '@/models/Event/EventRights'

export const paymentMenuSection = {
  key: 'payment',
  titleSlug: 'EVENT_MENU_PAYMENT_TITLE',
  iconName: 'coupon-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_PAYMENT_TICKETS_LINK',
      routeName: 'payment.settings.ticketsAndAddons',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.registration.mode === 'paid'
    },
    {
      titleSlug: 'EVENT_MENU_PAYMENT_PROMOCODE_LINK',
      routeName: 'payment.settings.promocodes',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.registration.mode === 'paid'
    },
    {
      titleSlug: 'EVENT_MENU_PAYMENT_CASHOUTS_LINK',
      routeName: 'payment.settings.recoverFunds',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.registration.mode === 'paid'
    },
    {
      titleSlug: 'EVENT_MENU_PAYMENT_SETTINGS_LINK',
      routeName: 'payment.settings.pricing',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.registration.mode === 'paid'
    },
    {
      titleSlug: 'EVENT_MENU_PAYMENT_RULES_LINK',
      routeName: 'payment.rules',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => eventRights.features.registration.mode === 'paid'
    }
  ]
} as const
