









import { Component, Prop } from 'vue-property-decorator'
import type GuestMessage from '@/models/Guest/Message'
import MessageHistory from './components/MessageHistory/index.vue'
import type Guest from '@/models/Guest'
import MessageHistoryContainer from './components/MessageHistoryContainer.vue'
import UnsubscribeInput from './components/UnsubscribeInput.vue'
import ContactProfileSimpleSubMenu from '../../ui/ContactProfileSimpleSubMenu.vue'
import { updateGuestCampaignSubscriptionStatus } from '@/features/campaigns'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { UnsubscribeInput, MessageHistoryContainer, MessageHistory, ContactProfileSimpleSubMenu }
})
export default class ContactProfileMessagesSubMenu extends Vue {
  @Prop({ required: true }) readonly messagesSent!: GuestMessage[]
  @Prop({ required: true }) readonly guestIdentity!: Guest
  loading: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  async onSubscribeStatusChange() {
    this.loading = true
    try {
      await updateGuestCampaignSubscriptionStatus(
        this.storeEvent._id,
        this.guestIdentity._id,
        !this.guestIdentity.unsubscribe
      )
      this.$emit('updated')
    } finally {
      this.loading = false
    }
  }
}
