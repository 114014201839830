import type { Route } from 'vue-router'
import { EventLangCode } from '@/models/Event/Localized'
import { BackofficeLanguage } from '@/utils/i18n'
import { $enum } from 'ts-enum-util'

export function getLangFromRoute(eventLanguageCode: EventLangCode, route: Route): EventLangCode {
  if (route.params.lang) {
    return $enum(EventLangCode).asValueOrThrow(route.params.lang)
  } else {
    return eventLanguageCode
  }
}

export function getBackOfficeLangFromRoute(eventLanguageCode: BackofficeLanguage, route: Route) {
  if (route.params.lang) {
    return $enum(BackofficeLanguage).asValueOrThrow(route.params.lang)
  } else {
    return eventLanguageCode
  }
}

export function getAllEditableLanguages({
  mainLanguage,
  translations
}: {
  mainLanguage: EventLangCode
  translations: EventLangCode[]
}) {
  return [mainLanguage, ...translations]
}
