import type { AxiosInstance } from 'axios'
import { AxiosConcurrentCache } from './axios-concurrent-cache'

function setBaseUrl(instance: AxiosInstance): void {
  instance.defaults.baseURL = process.env.VUE_APP_API_URL
}

function useCredentials(instance: AxiosInstance): void {
  instance.defaults.withCredentials = true
}

export function setupAxiosInstance(instance: AxiosInstance): void {
  const cache = new AxiosConcurrentCache({ ttlMs: 1500 /* ms */ })
  cache.installOnAxios(instance)
  setBaseUrl(instance)
  useCredentials(instance)
}
