









































import { Component, Prop, Vue } from 'vue-property-decorator'

import { duplicateRule } from '../service'

import type Rule from '@/models/Event/RulesData/Rule'
import WithPermission from '@/components/features/WithPermission.vue'
import type { Location } from 'vue-router'
import { DigiLink } from '@/components/ui/actions'
import { DigiDraggableInformationCard } from '@/components/ui/cardItem'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: {
    DigiLink,
    CustomRule: () => import('./components/CustomRule.vue'),
    DigiIntroLayout: () => import('@/components/ui/form/formLayouts/DigiIntroLayout.vue'),
    WithPermission,
    DigiDraggableInformationCard,
    DigiSimpleIconButton
  }
})
export default class RulesList extends Vue {
  @Prop({ required: true }) readonly introTitle!: string
  @Prop({ required: true }) readonly rules!: Rule[]
  @Prop({ required: true }) readonly ruleEditionRoute!: Location
  isRequestPending: boolean = false

  get isBusy(): boolean {
    return this.isRequestPending
  }

  get rulesSortedByStatus(): Rule[] {
    return this.rules.sort((a, b) => {
      if (a.isEnabled === b.isEnabled) return 0
      else if (a.isEnabled) return -1
      else return 1
    })
  }

  async duplicateRule(rule: Rule): Promise<void> {
    this.isRequestPending = true
    try {
      const { ruleId } = await duplicateRule(rule._id)
      await this.$store.dispatch('reloadCurrentEvent')
      this.$router.push({ name: this.ruleEditionRoute.name, params: { ruleId } })
    } finally {
      this.isRequestPending = false
    }
  }
}
