import { ElementTypeDictionary } from '../../types'
import Contact1Element from './contact1/Contact1Element'
import Faq1Element from './faq1/Faq1Element'

export const assistanceDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: Faq1Element,
    renderer: () => import('./faq1/Faq1.vue'),
    properties: [
      { type: 'color', label: 'WEBSITE_BLOCK_OUTER_TEXT_COLOR', key: 'properties.outerTextColor' },
      {
        type: 'color',
        label: 'QUESTIONS_TEXT_COLOR',
        key: 'properties.questionColor',
        toContrastWith: 'properties.questionBackgroundColor'
      },
      {
        type: 'color',
        label: 'QUESTIONS_BACKGROUND_COLOR',
        key: 'properties.questionBackgroundColor',
        canBeTransparent: true
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_FAQ',
    previewImageLink: require(`@/assets/img/prefab-previews/faq.jpg`)
  })
  .addType({
    dataClass: Contact1Element,
    renderer: () => import('./contact1/Contact1.vue'),
    properties: [
      {
        type: 'color',
        label: 'TEXT_COLOR',
        key: 'properties.textColor'
      },
      {
        type: 'color',
        label: 'MAIN_BLOCK_COLOR',
        key: 'properties.mainBlockColor',
        toContrastWith: 'box.backgroundColor'
      },
      {
        type: 'image',
        label: 'MAIN_BLOCK_IMAGE',
        key: 'properties.mainBlockImg',
        recommendedWidth: 900,
        recommendedHeight: 600
      }
    ],
    typeNameSlug: 'WEBSITE_BLOCK_CONTACT',
    previewImageLink: require(`@/assets/img/prefab-previews/contact1.jpg`)
  })
  .finish()
