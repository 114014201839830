export class MapMarker {
  address: string | null = null
}

export enum VideoPlatform {
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Custom = 'custom'
}

export class VideoProperties {
  videoCode: string = ''
  videoPlatform: VideoPlatform | null = null
}

export class ImageMediaProperties {
  type = 'image' as const
  imageUrl: string = ''
}

export class VideoMediaProperties extends VideoProperties {
  type = 'video' as const
}

export type MediaProperties = ImageMediaProperties | VideoMediaProperties

export type MediaType = MediaProperties['type']
