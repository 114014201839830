























import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class GuestDeletionModal extends Vue {
  @Prop({ type: Number, default: 1 }) readonly selectedGuestsCount!: number

  deleteAccompanists: boolean = false
  showModal: boolean = false

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get hasMultiRegistrationEnabled(): boolean {
    return this.eventRights.features.registration.type === 'multi'
  }

  get confirmDeleteText(): string {
    return this.$t('GUEST_DELETION_MODAL', { nb: this.selectedGuestsCount })
  }

  show(): void {
    this.showModal = true
  }

  resetDeleteAccompanist(): void {
    this.deleteAccompanists = false
  }

  confirmDelete(): void {
    this.$emit('confirmDelete', this.deleteAccompanists)
  }
}
