












import { Vue, Component, Prop } from 'vue-property-decorator'
import type { IconType } from '@/services/iconLibrary'
import { getIconFromDictionary } from '@/services/iconLibrary'

@Component
export default class DigiIconItem extends Vue {
  @Prop() readonly type!: IconType
  @Prop() readonly text!: string
  @Prop() readonly iconOnly!: boolean
  @Prop() readonly textOnly!: boolean

  get iconName(): string | null {
    return getIconFromDictionary(this.type) ?? null
  }

  get hasIconForType(): boolean {
    return this.iconName !== null
  }

  get computedText(): string {
    return this.text || this.$t(this.type.toUpperCase())
  }
}
