








import { Component, Emit } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { getMessageNominativeTags } from '@/features/messages/emailTags/emailNomativeTags'

@Component
export default class NominativeValuesDropdown extends Vue {
  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  @Emit('insert')
  emitInsert(slug: string): string {
    return slug
  }

  get messageNominativeValue() {
    return getMessageNominativeTags(this.storeEvent, this.$store.getters.orderedGuestFields)
  }
}
