import { Page } from '@/features/website/builder'
import { getWebsitePages } from '@/digiteventApi'
import { plainToClass } from 'class-transformer'

export class WebsitePageStore {
  private static instance?: WebsitePageStore
  private pages: Page[] = []
  private eventId: string = ''
  public isLoading = false

  public static initPageStore(eventId: string): WebsitePageStore {
    if (this.instance) {
      return this.instance
    }
    this.instance = new WebsitePageStore()
    this.instance.eventId = eventId
    return this.instance
  }

  public static getInstance(): WebsitePageStore {
    if (!this.instance) {
      throw new Error('WebsitePageStore not initialized')
    }
    return this.instance
  }

  public static destroyInstance(): void {
    this.instance = undefined
  }

  public async fetchPages(eventId?: string): Promise<Page[]> {
    this.isLoading = true
    if (eventId) this.eventId = eventId
    try {
      const pages = await getWebsitePages(this.eventId)
      this.pages = plainToClass(Page, pages)
      return this.pages
    } finally {
      this.isLoading = false
    }
  }

  public get pageList(): Page[] {
    return this.pages
  }

  public getPageById(pageId: string): Page {
    const page = this.pages.find((p) => p.pageId === pageId)
    if (!page) {
      throw new Error(`Page with id ${pageId} not found`)
    }
    return page
  }
}
