import type { MongoDb } from '@/models/MongoDbTools'
import { Type } from 'class-transformer'
import Criteria from './Criteria'

export type FilterId = MongoDb.ObjectId

export class AnonymousFilter {
  @Type(() => Criteria)
  andCriterias: Criteria[] = []
  @Type(() => Criteria)
  orCriterias: Criteria[] = []
}

export default class Filter extends AnonymousFilter {
  _id!: FilterId
  name!: string
  defaultOrigin?: 'confirmed'
}
