import store from '@/store'
import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'

const orderInitTriggerUtil: TriggerUtil = {
  kind: TriggerKind.OrderInit as const,
  label: 'ORDER_INIT' as const,
  icon: 'shopping-basket-2-line' as const,

  isAllowed(): boolean {
    return store.getters.isPaidEvent
  }
}

export default orderInitTriggerUtil
