























import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

import type { ValidationObserver } from 'vee-validate'
import { DigiButton } from '@/components/ui/actions'

@Component({
  components: { DigiButton }
})
export default class DigiFormLayout extends Vue {
  @Prop() readonly autocomplete!: boolean
  @Prop() readonly disabled!: boolean
  @Prop() readonly centeredButton!: boolean
  @Prop({ default: true }) readonly displaySaveButton!: boolean
  @Prop() readonly modalDisplay!: boolean
  @Prop({ default: 'SAVE' }) readonly saveButtonName!: string

  @Ref()
  readonly observer!: InstanceType<typeof ValidationObserver>

  async submitForm() {
    const isValid = await this.observer.validate()
    if (!isValid) {
      return
    }
    this.$emit('formSubmited')
  }
}
