










import { debounce } from 'lodash'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import LockingOverlay from '@/features/website/builder/components/LockingOverlay.vue'

// Null island.
// Yes it's a thing.
// Look it up, it's quite a fun anecdote.
const DEFAULT_PLACE = '0°N 0°W'
@Component({
  components: { LockingOverlay }
})
export default class MapFragment extends Vue {
  @Prop({ required: true }) place!: string

  waitingBeforeUpdate: boolean = false
  mapEmbedUrl: string = ''

  computeMapEmbedUrl(): void {
    const url = new URL('maps/embed/v1/place', 'https://www.google.com')

    // Empty strings are invalid queries in Embed API
    url.searchParams.set('q', this.place || DEFAULT_PLACE)
    url.searchParams.set('key', 'AIzaSyBZL4reHPCV0w5jhn7PDzFrySNx7IAibzQ')
    url.searchParams.set('language', this.$i18n.i18next.language)

    this.mapEmbedUrl = url.href
    this.waitingBeforeUpdate = false
  }

  debouncedComputeMapEmbedUrl = debounce(this.computeMapEmbedUrl, 3000)

  mounted(): void {
    this.computeMapEmbedUrl()
  }

  @Watch('place')
  onPlaceChanged(): void {
    this.waitingBeforeUpdate = true
    this.debouncedComputeMapEmbedUrl()
  }
}
