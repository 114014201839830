







import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class MobileNavItem extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) remixIcon!: string
}
