import type { PageElementType, PageElementCtor } from './PageElement'
import type { PageElement } from './index'
import type { EventLangCode } from '@/models/Event/Localized'
import type { PageBuilderConfig } from './Page'
import type { Renderable } from '@/models/VueTools'
import type { Event } from '@/models/Event'
import type { Website } from '@/models/Event/modules/Website'
import type { ColorPalette } from '@/models/Event/Identity/ColorPalette'
import type { ElementPropertyType } from '../../components/ControlPanel/components/PageBlocks/ElementPropertyItem/types'
import type { EventRights } from '@/models/Event/EventRights'

interface PropertyConditionContext<ElementType extends PageElement> {
  element: ElementType
  pageContent: PageBuilderConfig
  lang: EventLangCode
  isTopLevel: boolean
}

export interface ElementPropertyDefinition<ElementType extends PageElement> {
  type: ElementPropertyType
  key?: string
  label?: string
  icon?: string
  condition?: (context: PropertyConditionContext<ElementType>) => boolean
  content?: ElementPropertyDefinition<ElementType>[]
  defaultValue?: () => any
  [_: string]: any
}

export interface ElementCreationContext {
  website: Website
  pageConfig: PageBuilderConfig
  palette: ColorPalette
  mostReadableColor: string
}

export interface ElementDataClassStatic<ElementType extends PageElement> {
  create: (context: ElementCreationContext) => ElementType
}

export interface ElementTypeInfo<ElementType extends PageElement = PageElement> {
  renderer: Renderable
  typeNameSlug: string
  previewImageLink: string
  properties?: ElementPropertyDefinition<ElementType>[]
  isAvailable?(context: { event: Event; eventRights: EventRights }): boolean
  controlPanelExtra?: Renderable
  dataClass: Extract<PageElementCtor, new () => ElementType> & ElementDataClassStatic<ElementType>
}

export interface TypeNameAndInfo<ElementTypes extends PageElement = PageElement> {
  name: PageElementType<ElementTypes>
  info: ElementTypeInfo<ElementTypes>
}

class MapOfTypes extends Map<PageElementType, ElementTypeInfo> {}

class DictionaryBuilder {
  private _infos: ElementTypeInfo[]

  constructor(typeInfos: ElementTypeInfo[]) {
    this._infos = typeInfos
  }

  addType<ElT extends PageElement>(info: ElementTypeInfo<ElT>) {
    return new DictionaryBuilder([...this._infos, info] as ElementTypeInfo[])
  }

  finish(): ElementTypeDictionary {
    return new ElementTypeDictionary(this._infos)
  }
}

export class ElementTypeDictionary {
  private readonly _typeMap: MapOfTypes

  constructor(typeInfos?: ElementTypeInfo[]) {
    this._typeMap = new MapOfTypes()
    if (typeInfos) {
      for (const typeInfo of typeInfos) {
        this._typeMap.set(new typeInfo.dataClass().type, typeInfo)
      }
    }
  }

  static makeNew(): DictionaryBuilder {
    return new DictionaryBuilder([])
  }

  static merge(...dictionaries: ElementTypeDictionary[]): ElementTypeDictionary {
    const result = new ElementTypeDictionary()
    for (const dict of dictionaries) {
      for (const [type, info] of dict._typeMap.entries()) {
        result._typeMap.set(type, info)
      }
    }

    return result
  }

  /**
   * Locates information about a type given its name
   * @param typeName The name of the type to retrieve
   * @throws Error if the type cannot be found
   */
  get<ElementType extends PageElement>(typeName: PageElementType<ElementType>): ElementTypeInfo<ElementType> {
    const result = this._typeMap.get(typeName)

    if (result === undefined) {
      throw new Error(`Unknown element type: ${typeName}`)
    } else {
      // Conversion necessary because yeah technically the set might contain erroneous data I guess
      return result as unknown as ElementTypeInfo<ElementType>
    }
  }

  has(type: PageElementType): boolean {
    return this._typeMap.has(type)
  }

  *getAllAvailableTypes(context: { event: Event; eventRights: EventRights }): Generator<TypeNameAndInfo> {
    for (const [name, info] of this._typeMap) {
      if (typeof info.isAvailable === 'function') {
        if (!info.isAvailable(context)) {
          continue
        }
      }

      yield { name, info } as unknown as TypeNameAndInfo
    }
  }
}
