import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export interface CacheEntry {
  validUntil: Date
  promise: Promise<AxiosResponse>
  resolve(data: AxiosResponse): void
  reject(err: Error): void
}

/**
 * The main purpose of this cache is not caching in itself as caching is already
 * done by the browsers. The real purpose of this class is preventing concurrent
 * requests to the same URL.
 * The AxiosConcurrentCache achieves the following:
 *
 * const req1 = axios.get('/event/123/permissions');
 * const req2 = axios.get('/event/123/permissions');
 * const [res1, res2] = await Promise.all([req1, req2]);
 * Result:
 *  res1 => makes request
 *  res2 => waits for res1 to finish and uses the result of res1
 *
 * That is why in usual scenario TTL should be around 2-5 seconds.
 *
 * The code is inspired by https://github.com/arthurfiorette/axios-cache-interceptor
 * but we only use the concurrent request part of it.
 */
export class AxiosConcurrentCache {
  private ttlMs: number
  private cache = {} as Record<string, CacheEntry>
  constructor(o: { ttlMs: number }) {
    this.ttlMs = o.ttlMs
  }

  installOnAxios(axiosInstance: AxiosInstance) {
    axiosInstance.interceptors.request.use(
      (request) => this.interceptRequest(request),
      (error) => Promise.reject(error)
    )
    axiosInstance.interceptors.response.use(
      (response) => this.interceptResponseSuccess(response),
      (error: any) => this.interceptResponseError(error)
    )
  }

  private async interceptRequest(request: AxiosRequestConfig) {
    if (request.noCache) {
      return request
    }
    const key = this.getKey(request)
    if (request.method !== 'get') {
      this.invalidateCache()
      return request
    }
    const cacheEntry = this.fromCache(key)
    if (cacheEntry) {
      return this.respondWithCached(request, cacheEntry)
    }
    const entryPartial: Partial<CacheEntry> = { validUntil: new Date(Date.now() + this.ttlMs) }
    const promise = new Promise<AxiosResponse>((resolve, reject) => {
      entryPartial.resolve = resolve
      entryPartial.reject = reject
    })
    entryPartial.promise = promise

    // This promise is awaited in all the requests, so we need to catch the error
    // However if we do not catch it here it will trigger unhandler rejection
    // despite the fact that we catch it in interceptResponseError
    promise.catch(() => {})

    this.cache[key] = entryPartial as CacheEntry
    return request
  }

  private async interceptResponseSuccess(response: AxiosResponse) {
    const config = response.config
    const cacheEntry = this.getEntryForResponseConfig(config)
    if (cacheEntry) cacheEntry.resolve(response)
    return response
  }

  private async interceptResponseError(error: AxiosError) {
    const config = error.config
    const cacheEntry = this.getEntryForResponseConfig(config)
    if (cacheEntry) cacheEntry.reject(error)
    return Promise.reject(error)
  }

  private getEntryForResponseConfig(config: AxiosRequestConfig) {
    if (config.method !== 'get') {
      return null
    }
    const key = this.getKey(config)
    return this.fromCache(key)
  }

  private async respondWithCached(request: AxiosRequestConfig, cacheEntry: CacheEntry) {
    const cachedResponse = await cacheEntry.promise
    request.adapter = async (config): Promise<AxiosResponse> => ({
      config,
      data: cachedResponse.data,
      headers: cachedResponse.headers,
      status: cachedResponse.status,
      statusText: cachedResponse.statusText
    })
    return request
  }

  private fromCache(key: string) {
    this.cleanOldCacheEntries()
    const cacheEntry = this.cache[key]
    if (cacheEntry) {
      return cacheEntry
    }

    return null
  }

  private invalidateCache() {
    this.cache = {}
  }

  private cleanOldCacheEntries() {
    const now = new Date()
    Object.entries(this.cache).forEach(([key, cacheEntry]) => {
      if (cacheEntry.validUntil < now) {
        delete this.cache[key]
      }
    })
  }

  private getKey({ baseURL, url, params }: AxiosRequestConfig) {
    return `${baseURL || ''}${url || ''}${JSON.stringify(params)}`
  }
}
