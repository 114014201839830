










import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type GuestMessage from '@/models/Guest/Message'
import type { MessageTemplate } from '@/models/Event/Messages/template'
import { fromArrayToMap } from '@/utils/arrays'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({})
export default class ContactProfileMessagesTabContent extends Vue {
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventMessages() {
    return fromArrayToMap<MessageTemplate>(this.storeEvent.messages.templates ?? [], '_id')
  }

  get guestSentMessages(): (GuestMessage & { method: 'sms' | 'email' })[] {
    const guestMessages = this.guest.messages ?? []
    return guestMessages.map((message) => {
      const eventMessage = this.eventMessages[message.messageId]
      if (!eventMessage) throw new Error(`Message ${message.messageId} not found`)
      return {
        ...message,
        method: eventMessage.method
      }
    })
  }

  get sentEmails(): number {
    return this.guestSentMessages.filter((message) => message.method === 'email').length
  }

  get sentSms(): number {
    return this.guestSentMessages.filter((message) => message.method === 'sms').length
  }

  get isUnsubscribed(): boolean {
    return this.guest.unsubscribe ?? false
  }
}
