

































import { Component, Ref, Vue } from 'vue-property-decorator'
import type Guest from '@/models/Guest'

import type { FrontPreviewUrlParams } from '@/utils/frontPreviewUrl'
import { getFrontPreviewUrl } from '@/utils/frontPreviewUrl'

import type { BModal } from 'bootstrap-vue'
import { DigiIconButton, DigiLink } from '@/components/ui/actions'
import { SearchGuests } from '@/features/audience'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

@Component({
  components: {
    DigiLink,
    DigiIconButton,
    SearchGuests
  }
})
export default class PreviewFrontModal extends Vue {
  @Ref() readonly modal!: BModal
  urlParams: Omit<FrontPreviewUrlParams, 'eventId'> = {}

  openModal(urlParams: Omit<FrontPreviewUrlParams, 'eventId'> = {}) {
    this.urlParams = urlParams
    this.modal.show()
  }

  get searchPlaceholder(): string {
    return `${this.$t('SEARCH_BY')} ${this.$t('FIRSTNAME')}/${this.$t('LASTNAME')}/${this.$t('EMAIL')}`
  }

  get unloggedUrl(): string {
    return getFrontPreviewUrl({ ...this.urlParams, eventId: this.storeEvent._id })
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  logAsGuestUrl(guest: Guest): string {
    const guestId = guest._id
    return getFrontPreviewUrl({ ...this.urlParams, guestId, eventId: this.storeEvent._id })
  }
}
