var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"accompanists"},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('GUEST_MODAL_MULTIREGISTRATION_TITLE', { x: ((_vm.master.name) + " " + (_vm.master.firstname ? _vm.master.firstname : '')) }))+" ")]),(_vm.slavesData.length === 0)?_c('div',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('NO_ACCOMPANISTS')))]):_vm._e(),_c('AddAccompanistButton',{staticClass:"mb-4",attrs:{"master":_vm.master,"selected-fields-data":_vm.selectedFieldsData},on:{"accompanistCreated":_vm.handleAccompanistCreated,"accompanistSelected":_vm.associateAccompanist}}),(_vm.isLoading)?_c('DigiSpinner',{staticClass:"align-self-center"}):(_vm.slavesData.length)?[_c('BTableLite',{staticClass:"slavesTable",attrs:{"fields":_vm.tableFields,"items":_vm.slavesData,"responsive":"","hover":""},scopedSlots:_vm._u([{key:"cell(dissociateAccompanist)",fn:function(ref){
var slave = ref.item;
return [_c('DissociateAccompanistButton',{attrs:{"accompanist":slave,"master":_vm.master,"disabled":_vm.isLoading,"dissociate-accompanist":function () { return _vm.dissociateAccompanist(slave); }}})]}},{key:"cell(removeBtn)",fn:function(ref){
var slave = ref.item;
return [_c('DeleteAccompanistButton',{attrs:{"accompanist":slave,"master":_vm.master,"disabled":_vm.isLoading,"delete-accompanist":function () { return _vm.dissociateAndDeleteAccompanist(slave); }}})]}},{key:"cell(details)",fn:function(ref){
var guest = ref.item;
return [_c('DigiSimpleIconButton',{attrs:{"remix-icon":"edit-2-line","size":"sm","variant":"primary"},on:{"click":function($event){return _vm.openDetailsModal(guest)}}})]}},_vm._l((_vm.selectedFieldsData.filter(function (f) { return f.type === 'list'; })),function(ref){
var key = ref.key;
var list = ref.list;
return {key:"cell("+key+")",fn:function(ref){
var contact = ref.item;
return [_c('ul',{key:key},_vm._l((_vm.findValueFromKey(list, contact[key])),function(value,idx){return _c('li',{key:idx},[_vm._v(_vm._s(value))])}),0)]}}})],null,true)})]:_vm._e(),_c('ContactProfileModal',{ref:"accompanistDetailsModal",on:{"reload":_vm.loadSlavesData}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }