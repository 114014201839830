






















































import { Component, Ref, Watch } from 'vue-property-decorator'
import { assertAccount } from '@/services/assertAccount'
import type { Account } from '@/models/Account'
import Vue from 'vue'

import { DigiFormModal2 } from '@/components/ui/form/formLayouts'
import { activateEventByCouponCode } from '@/services/events'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import { getAccountCoupons } from '@/services/account'
import type { ActivationCoupon } from '@/models/Account/Coupon'
import { DigiLink } from '@/components/ui/actions'
import axios from 'axios'
import moment from 'moment'
import { EventDatesInput } from '@/features/eventSettings'
import type { EventIdentity } from '@/core'
import { patchEventIdentity } from '@/features/eventSettings/eventIdentity/patchEventIdentity'

type EventDates = { startDate: Date; endDate: Date }

@Component({ components: { DigiFormModal2, DigiLink, EventDatesInput } })
export default class EventActivationModal extends Vue {
  @Ref() readonly modal!: DigiFormModal2

  couponCode = ''
  isSubmitting = false
  isOpen = false
  coupons: ActivationCoupon[] = []
  errorMessage: string = ''
  inputSelection = ''

  eventDates: EventDates = {
    startDate: new Date(),
    endDate: new Date()
  }

  get eventIdentity(): EventIdentity {
    return this.$store.state.eventIdentity
  }

  get storeAccount(): Account {
    const storeAccount = assertAccount(this.$store.state.account.account)

    return storeAccount
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get savedEventDates(): EventDates {
    return {
      startDate: this.eventIdentity.startDate,
      endDate: this.eventIdentity.endDate
    }
  }

  @Watch('savedEventDates', { immediate: true })
  copySavedEventDates(): void {
    this.eventDates = { ...this.savedEventDates }
  }

  setInputSelection(value: string) {
    this.inputSelection = value
    if (value === 'otherCoupon') {
      this.couponCode = ''
    } else {
      this.couponCode = value
    }
  }

  getExpirationDate(coupon: ActivationCoupon): string {
    return moment(coupon.issueDate).add(coupon.monthsUntilCouponExpires, 'months').format('DD-MM-YYYY')
  }

  get hasModifications(): boolean {
    return this.couponCode.length > 0
  }

  get unexpiredCouponsWithCapacity() {
    return this.coupons.filter(
      (c) =>
        c.nbOfEvents - c.appliedTo.length > 0 &&
        moment(c.issueDate).add(c.monthsUntilCouponExpires, 'months') > moment()
    )
  }

  get displayCouponCodeSearch() {
    const display = this.inputSelection === 'otherCoupon' || this.coupons.length === 0

    return display
  }

  async activate() {
    this.errorMessage = ''
    this.isSubmitting = true
    try {
      await patchEventIdentity(this.eventDates, this.storeEvent._id)
      await activateEventByCouponCode(this.storeEvent._id, this.couponCode)
      const updatedEvent = await this.$store.dispatch('loadEventById', { id: this.$store.state.event.event._id })
      this.$store.commit('SET_EVENT', updatedEvent)
      this.$notify({
        type: 'success',
        text: this.$t('EVENT_SUCCESSFULLY_ACTIVATED')
      })
      this.isOpen = false
    } catch (error: unknown) {
      if (!axios.isAxiosError(error)) {
        throw error
      }
      const errorSlug = error?.response?.data?.slug
      if (errorSlug === 'SERVERR_COUPON_NOT_FOUND') {
        this.errorMessage = this.$t('COUPON_NOT_FOUND')
      } else if (errorSlug === 'SERVERR_COUPON_CAPACITY_REACHED') {
        this.errorMessage = this.$t('COUPON_CAPACITY_REACHED')
      } else {
        throw error
      }
    } finally {
      this.isSubmitting = false
    }
  }

  show() {
    this.isOpen = true
  }

  async mounted(): Promise<void> {
    const { coupons } = await getAccountCoupons()
    this.coupons = coupons
  }
}
