



















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { joinIfExist } from '@/utils/string'
import type Guest from '@/models/Guest'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'

@Component({ components: { DigiSimpleIconButton, DigiDeleteConfirmationModal } })
export default class DeleteAccompanistButton extends Vue {
  @Prop({ required: true }) readonly disabled!: boolean
  @Prop({ required: true }) readonly master!: Guest
  @Prop({ required: true }) readonly accompanist!: Guest
   @Prop({ required: true, type: Function }) readonly deleteAccompanist!: () => Promise<void>

  get accompanistFullName(): string {
    return joinIfExist([this.accompanist.firstname, this.accompanist.name])
  }

  get masterFullName(): string {
    return joinIfExist([this.master.firstname, this.master.name])
  }

  get message(): string {
    return this.$t('ACCOMPANIST_TABLE_CONFIRM_ACCOMPANIST_DELETION_MESSAGE', {
      accompanist: this.accompanistFullName,
      master: this.masterFullName
    })
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }
}
