
















import { Component } from 'vue-property-decorator'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { getDate } from '@/utils/time'
import EventStatusBadge from '../../components/Header/components/EventStatusBadge.vue'
import type { EventIdentity } from '@/core'

@Component({
  components: { EventStatusBadge }
})
export default class HeaderEventAbstract extends Vue {
  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventIdentity(): EventIdentity {
    return this.$store.state.eventIdentity
  }

  get formattedDate() {
    return getDate(this.eventIdentity.startDate, this.eventIdentity.timezone)
  }

  get eventName(): string {
    return this.eventIdentity.name
  }
}
