












import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DigiLimitedList extends Vue {
  @Prop({ required: true }) readonly items!: unknown[]
  @Prop({ default: 5 }) readonly max!: number
  @Prop({ default: true }) readonly canAdd!: boolean
  @Prop({ default: false }) readonly buttonTop!: boolean
  @Prop({}) readonly itemLabel?: string
  @Prop() readonly buttonLabel?: string

  get computedItemLabel() {
    const label = this.itemLabel !== undefined ? this.itemLabel : this.$t('FIELDS')
    return label.toLowerCase()
  }

  get displayedItems(): unknown[] {
    return this.items.slice(0, this.max)
  }

  get hiddenItemsCount(): number | undefined {
    const rest = this.items.length - this.max
    return rest > 0 ? rest : undefined
  }

  get isEmpty(): boolean {
    return this.items.length === 0
  }

  get classes() {
    return { 'digi-limited-list--btn-top': this.buttonTop, 'digi-limited-list--btn-centered': this.isEmpty }
  }
}
