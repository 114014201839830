import ContentElementBase from '../ContentElementBase'
import type { TextAlignment } from '../helperTypes'
import { i18n } from '@/utils/i18n'

export default class SocialElement extends ContentElementBase {
  static create(): SocialElement {
    return new SocialElement()
  }

  type: 'content-social' = 'content-social'
  prettyName = i18n.t('SOCIAL_LINKS')
  properties = {
    align: 'center' as TextAlignment
  }
  // @FIXME: Incomplete
}
