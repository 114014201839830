











import EditSocialNetworks from '@/components/features/editSocialNetworks/EditSocialNetworks.vue'
import type { BModal } from 'bootstrap-vue'
import { Vue, Component, Ref } from 'vue-property-decorator'

@Component({ components: { EditSocialNetworks } })
export default class SocialNetworksModal extends Vue {
  @Ref() readonly modal!: BModal

  onSave(): void {
    this.$notify({
      text: this.$t('MODIFICATIONS_SAVED'),
      type: 'success'
    })

    this.modal.hide()
  }

  show(): void {
    this.modal.show()
  }
}
