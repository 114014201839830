import type { DiscriminatorDescriptor } from 'class-transformer'
import type Form from '@/models/Event/Form'
import type { EventRights } from '@/models/Event/EventRights'
import type { Event } from '@/models/Event'

export enum BorderStyle {
  Solid = 'solid',
  Dashed = 'dashed',
  Dotted = 'dotted'
}

export class Border {
  color: string | null = null
  size: number = 1
  style: BorderStyle = BorderStyle.Solid
}

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export class BoxBorder extends Border {
  radius: number = 3
}

export type Padding = number[]

export abstract class LinkDestinationBase {
  abstract type: string
}

export class FormLinkDestination extends LinkDestinationBase {
  type = 'form' as const
  formId: NonNullable<Form['_id']> | null = null
}

export class PageLinkDestination extends LinkDestinationBase {
  type = 'page' as const
  pageId: string | null = null
}

export class AddToCalendarLink extends LinkDestinationBase {
  type = 'addToCalendar' as const
}

export class EmailLinkDestination extends LinkDestinationBase {
  type = 'email' as const
  link = ''
}

export class UrlLinkDestination extends LinkDestinationBase {
  type = 'link' as const
  link = ''
  newTab = false
}

export class FileLinkDestination extends LinkDestinationBase {
  type = 'filelink' as const
  link = ''
  newTab = false
}

export class MainCtaLinkDestination extends LinkDestinationBase {
  type = 'maincta' as const
}

export class ProgrammeLinkDestination extends LinkDestinationBase {
  type = 'programme' as const
}

export class NullLinkDestination extends LinkDestinationBase {
  type = 'none' as const
}

export type LinkDestinationConstructor =
  | typeof FormLinkDestination
  | typeof PageLinkDestination
  | typeof UrlLinkDestination
  | typeof EmailLinkDestination
  | typeof FileLinkDestination
  | typeof MainCtaLinkDestination
  | typeof ProgrammeLinkDestination
  | typeof AddToCalendarLink
  | typeof NullLinkDestination

export type LinkDestination = InstanceType<LinkDestinationConstructor>

export type LinkDestinationType = LinkDestination['type']

type InfoForLinkDestinationType<TypeNameT extends LinkDestinationType> = {
  typeName: TypeNameT
  class: Extract<LinkDestinationConstructor, new () => { type: TypeNameT }>
  condition?: ({ event, eventRights }: { event: Event; eventRights: EventRights }) => boolean
}

type TransformLinkDestinationTypesToInfos<TypesT extends LinkDestinationType[]> = {
  [Key in keyof TypesT]: TypesT[Key] extends LinkDestinationType ? InfoForLinkDestinationType<TypesT[Key]> : TypesT[Key]
}

export const linkDestinationTypeMap: TransformLinkDestinationTypesToInfos<
  ['form', 'page', 'link', 'email', 'filelink', 'maincta', 'programme', 'addToCalendar', 'none']
> = [
  {
    typeName: 'form',
    class: FormLinkDestination,
    condition({ event }) {
      const multiRegistrationFormId = event.modules_data.multiRegistration?.slaveFormId ?? null
      const forms = event.forms.filter((form) => !form.isRegistration && !(form._id === multiRegistrationFormId))

      return Boolean(forms && forms.length > 0)
    }
  },
  { typeName: 'page', class: PageLinkDestination },
  { typeName: 'link', class: UrlLinkDestination },
  { typeName: 'email', class: EmailLinkDestination },
  { typeName: 'filelink', class: FileLinkDestination },
  { typeName: 'maincta', class: MainCtaLinkDestination },
  {
    typeName: 'programme',
    class: ProgrammeLinkDestination,
    condition({ eventRights }) {
      return eventRights.features.eventApp.allowed
    }
  },
  {
    typeName: 'addToCalendar',
    class: AddToCalendarLink,
    condition({ event }) {
      return event.modules_data.website.displayAddToCalendarBtn ?? false
    }
  },
  { typeName: 'none', class: NullLinkDestination }
]

export const LinkDestinationDiscriminator: DiscriminatorDescriptor = {
  property: 'type',
  subTypes: linkDestinationTypeMap.map((item) => ({ name: item.typeName, value: item.class }))
}
