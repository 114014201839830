







import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class App extends Vue {
  get appTitle(): string {
    let title = 'Digitevent'

    if (this.$store.getters.hasEvent) title = this.$store.getters.event.label + ' - ' + title

    const routeSlug = this.$route.matched
      .slice()
      .reverse()
      .find((route) => route.meta?.name)?.meta.name

    if (routeSlug) title = this.$t(routeSlug) + ' - ' + title

    return title
  }

  @Watch('appTitle', { immediate: true })
  handler(title: string): void {
    document.title = title
  }
}
