import store from '@/store'
import type Rule from '@/models/Event/RulesData/Rule'
import { PAYMENT_TRIGGER_KIND } from './payementRuleTriggers'
import type { ReadonlyFullRouteConfig } from '@/core'

export const paymentRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'pricing',
    name: 'payment.settings.pricing',
    component: () => import('./views/PaymentSettings/PaymentSettings.vue'),
    meta: {
      name: 'FACTORY_PAYMENT_PRICING_SETTINGS',
      keywords: 'FACTORY_PRICING_AND_PAYMENT_METHODS_KEYWORDS',
      iconName: 'settings-3-line',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      }
    }
  },
  {
    path: 'promocodes',
    name: 'payment.settings.promocodes',
    component: () => import('./views/PromoCodeList/PromoCodeList.vue'),
    meta: {
      name: 'FACTORY_PAYMENT_PROMOCODES_SETTINGS',
      iconName: 'percent-line',
      keywords: 'FACTORY_PAYMENT_PROMOCODES_KEYWORDS',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.payment.promoCodes.length > 0
        }
      }
    }
  },
  {
    path: 'recoverFunds',
    name: 'payment.settings.recoverFunds',
    component: () => import('./views/RecoverFunds.vue'),
    meta: {
      name: 'CASHOUTS',
      iconName: 'wallet-3-line',
      keywords: 'FACTORY_CASHOUTS_KEYWORDS',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      }
    }
  },
  {
    path: 'ticketsAndAddons',
    name: 'payment.settings.ticketsAndAddons',
    component: () => import('./views/TicketAndAddonList/TicketAndAddonList.vue'),
    meta: {
      name: 'TICKETS_ADDONS',
      iconName: 'coupon-3-line',
      keywords: 'FACTORY_TICKETS_ADDONS_KEYWORDS',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return store.getters.event?.modules_data.payment.tickets?.length > 0
        }
      }
    }
  },
  {
    path: 'payment/tickets/:ticketId',
    name: 'payment.tickets.edition',
    component: () => import('./views/TicketEdition/TicketEdition.vue'),
    meta: {
      subPageOf: 'payment.settings.ticketsAndAddons'
    }
  },
  {
    path: 'payment/addons/:addonId',
    name: 'payment.addons.edition',
    component: () => import('./views/AddonEdition/AddonEdition.vue'),
    meta: {
      subPageOf: 'payment.settings.ticketsAndAddons'
    }
  },
  {
    path: 'payment/createOrder/:orderOwnerId',
    name: 'payment.createOrder',
    component: () => import('./views/OrderCreation/CreateOrder.vue'),
    props: true
  },
  {
    path: 'payment/guest/:guestId/order/:orderId',
    name: 'payment.orderDetails',
    component: () => import('./views/OrderDetails/OrderDetails.vue'),
    meta: {
      name: 'ORDER_DETAILS',
      iconName: 'coupon-3-line',
      searchable: false,
      requiredPermissions: {
        permissions: ['guestList_read'],
        redirectName: 'event.dashboard'
      }
    }
  },
  {
    path: 'payment/rules',
    name: 'payment.rules',
    component: () => import('./views/PaymentRulesListView.vue'),
    meta: {
      name: 'FACTORY_PAYMENT_RULES_LIST',
      iconName: 'printer-line',
      keywords: 'FACTORY_RULES_CHECKIN_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event.rules.ruleItems.filter((rule: Rule) => PAYMENT_TRIGGER_KIND.includes(rule.trigger.kind))
              .length > 0
          )
        }
      }
    }
  },
  {
    path: 'payment/rules/edition/:ruleId?',
    name: 'payment.rules.edition',
    component: () => import('./views/PaymentRulesEditionView.vue'),
    meta: {
      subPageOf: 'payment.rules',
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.registration.mode === 'paid',
        redirectName: 'event.settings'
      }
    }
  }
] as const
