import type { MongoDb } from '@/models/MongoDbTools'
import { plainToClass } from 'class-transformer'
import { $enum } from 'ts-enum-util'
import CheckboxCustomField from './fieldTypes/Checkbox'
import DateTimeCustomField from './fieldTypes/DateWithoutTimezone'
import EmailCustomField from './fieldTypes/Email'
import FileCustomField from './fieldTypes/File'
import ListCustomField from './fieldTypes/List'
import LongTextCustomField from './fieldTypes/LongText'
import NumberCustomField from './fieldTypes/Number'
import PhoneCustomField from './fieldTypes/Phone'
import TextCustomField from './fieldTypes/Text'
import LinkCustomField from './fieldTypes/Link'

export type CustomFieldId = MongoDb.ObjectId

export enum CustomFieldType {
  Number = 'number',
  Checkbox = 'checkbox',
  List = 'list',
  File = 'file',
  Phone = 'phone',
  Text = 'text',
  Email = 'email',
  LongText = 'longtext',
  DateWithoutTimezone = 'dateWithoutTimezone',
  Link = 'link'
}

export type CustomFieldConstructor =
  | typeof ListCustomField
  | typeof FileCustomField
  | typeof PhoneCustomField
  | typeof TextCustomField
  | typeof CheckboxCustomField
  | typeof NumberCustomField
  | typeof EmailCustomField
  | typeof LongTextCustomField
  | typeof DateTimeCustomField
  | typeof LinkCustomField

type CustomField = InstanceType<CustomFieldConstructor>

type CustomFieldTypeNameMapT = {
  [TypeNameT in CustomFieldType]: Extract<CustomFieldConstructor, new () => { type: TypeNameT }>
}

// @TODO: Make this procedural at some point?
// Idk this data doesn't move much so it's probably not necessary. It's cooler though.
const typeNameMap: CustomFieldTypeNameMapT = {
  [CustomFieldType.Checkbox]: CheckboxCustomField,
  [CustomFieldType.Number]: NumberCustomField,
  [CustomFieldType.List]: ListCustomField,
  [CustomFieldType.File]: FileCustomField,
  [CustomFieldType.Phone]: PhoneCustomField,
  [CustomFieldType.Text]: TextCustomField,
  [CustomFieldType.Email]: EmailCustomField,
  [CustomFieldType.LongText]: LongTextCustomField,
  [CustomFieldType.DateWithoutTimezone]: DateTimeCustomField,
  [CustomFieldType.Link]: LinkCustomField
}

export function getConstructorForFieldType<TypeNameT extends CustomFieldType>(
  type: TypeNameT
): CustomFieldTypeNameMapT[TypeNameT] {
  return typeNameMap[type]
}

type PlainObjectWithATypeString = { type: string; [_: string]: any }

namespace CustomField {
  export function fromPlainObject(obj: PlainObjectWithATypeString): CustomField {
    const safeTypeName = $enum(CustomFieldType).asValueOrThrow(obj.type)

    return plainToClass<CustomField, PlainObjectWithATypeString>(typeNameMap[safeTypeName], obj)
  }

  export function canConstructFromObject(obj: Record<string, any>): obj is PlainObjectWithATypeString {
    return 'type' in obj && typeof obj.type === 'string'
  }
}

export default CustomField
