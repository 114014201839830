import { eventIdentityRoutes } from '@/features/eventSettings/eventIdentity/routes'
import { inactiveRightsRoute } from './inactiveRights/routes'
import { legalEntityRoutes } from './legalEntity/routes'
import { permissionsRoutes } from './permissions/routes'
import { messageSettingsRoutes } from './messageSettings/routes'
import type { ReadonlyFullRouteConfig } from '@/core'

export const eventSettingsRoutes: ReadonlyFullRouteConfig = [
  ...eventIdentityRoutes,
  ...inactiveRightsRoute,
  ...legalEntityRoutes,
  ...permissionsRoutes,
  ...messageSettingsRoutes
] as const
