import { campaignFeature } from '@/features/campaigns'
import { singleRegistrationFeature } from '@/features/registration/singleRegistration'
import { audienceFeature } from '@/features/audience'
import { websiteFeature } from '@/features/website'
import { eventSettingsFeature } from '@/features/eventSettings'
import { paymentFeature } from '@/features/payment'
import { checkinFeature } from '@/features/checkin'
import { surveysFeature } from '@/features/surveys'
import { rulesFeature } from '@/features/rules'
import { multiRegistrationFeature } from '@/features/registration/multiRegistration'
import { programmeFeature } from '@/features/eventApp'
import { certificatesFeature } from '@/features/certificate'
import { dashboardFeature } from '@/features/dashboard'
import type { FeatureDictionary } from './types'

export const featureDictionary: FeatureDictionary = [
  dashboardFeature,
  audienceFeature,
  campaignFeature,
  websiteFeature,
  singleRegistrationFeature,
  multiRegistrationFeature,
  paymentFeature,
  programmeFeature,
  checkinFeature,
  surveysFeature,
  certificatesFeature,
  eventSettingsFeature,
  rulesFeature
]
