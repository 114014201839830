import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'

export class TextInfiniteScrollProperties extends PrefabPropertiesBase {}

export default abstract class TextInfiniteScrollElementBase extends PrefabElementBase {
  abstract type: `prefab-text-infinite-scroll-${number}`
  isFullPrefab: boolean = true

  @Type(() => TextInfiniteScrollProperties)
  properties: TextInfiniteScrollProperties = new TextInfiniteScrollProperties()
}
