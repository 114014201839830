import { Type } from 'class-transformer'

export class TagGroup {
  title!: string

  @Type(() => Tag)
  tags!: Tag[]
}

export class Tag {
  _id!: string
  title!: string
}
