



















import { Component, Prop, Ref } from 'vue-property-decorator'
import PlanningCard from '../PlanningCard.vue'
import type Guest from '@/models/Guest'
import type ScheduledItem from '@/models/Guest/Planning/ScheduledItem'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import type GroupActivitySchedule from '@/models/Guest/Planning/ScheduledItem/GroupActivity'
import type { GroupActivity } from '@/models/Event/modules/Programme'
import { removeNonMeetingScheduledItem } from '@/features/eventApp'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import GroupActivityLinkToEdition from './GroupActivityLinkToEdition.vue'
import GroupActivityDateRange from './GroupActivityDateRange.vue'
import GroupActivityLocation from './GroupActivityLocation.vue'
import PlanningCheckDate from '../PlanningCheckDate.vue'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'

@Component({
  components: {
    PlanningCard,
    GroupActivityLinkToEdition,
    DigiSimpleIconButton,
    GroupActivityDateRange,
    GroupActivityLocation,
    PlanningCheckDate,
    DigiDeleteConfirmationModal
  }
})
export default class GroupActivityCard extends Vue {
  @Prop({ required: true }) readonly planningGroupActivityId!: string
  @Prop({ required: true }) readonly guest!: Guest

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get planningGroupActivity(): GroupActivitySchedule {
    const planningGroupActivity = this.guest.planning?.scheduledItems.find(
      (item: ScheduledItem): item is GroupActivitySchedule =>
        item._id === this.planningGroupActivityId && item.kind === 'groupActivity'
    )
    if (!planningGroupActivity) {
      throw new Error('Could not find group activity in planning.')
    }
    return planningGroupActivity
  }

  get groupActivitySession(): GroupActivity {
    const groupActivitySession = this.storeEvent.modules_data.programme?.eventActivities.find(
      (activity): activity is GroupActivity =>
        activity._id === this.planningGroupActivity.eventActivityId && activity.kind === 'groupActivity'
    )
    if (!groupActivitySession) {
      throw new Error(`Could not find matchmaking session with id ${this.planningGroupActivity.eventActivityId}.`)
    }
    return groupActivitySession
  }

  get checkDate(): Date | null {
    const check = this.guest.checks?.find((item) => item.checkpointId === this.groupActivitySession._id)
    if (check) {
      return check.checkDate
    } else {
      return null
    }
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }

  async cancelRegistration() {
    await removeNonMeetingScheduledItem(this.$store.getters.eventId, this.guest._id, this.planningGroupActivity._id)
    this.$emit('deleted')
  }
}
