
















































import { Vue, Component, Prop } from 'vue-property-decorator'

import type { SortGroup } from '@/models/commons/sort'

import SortableFlat from '../../SortableFlat.vue'
import GroupHeader from './GroupHeader.vue'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({ components: { DigiSimpleIconButton, SortableFlat, GroupHeader } })
export default class GroupItem extends Vue {
  @Prop({ required: true }) readonly group!: SortGroup
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly hiddenItems?: string[]
  @Prop({ required: true, type: Function }) readonly deleteGroup!: () => Promise<void>
  @Prop() readonly deleteGroupModalSlugTitle!: string

  isCollapsed: boolean = true

  get isEmpty(): boolean {
    return this.group.items.length === 0
  }

  get hasOnlyHiddenItems(): boolean {
    const hiddenItems = this.hiddenItems
    if (!hiddenItems || this.isEmpty) {
      return false
    }
    return this.group.items.find((i) => !hiddenItems.includes(i)) === undefined
  }

  get showCollapseArrow(): boolean {
    return !this.isEmpty && !this.hasOnlyHiddenItems
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed
  }

  handleUpdate() {
    this.isCollapsed = false
    this.$emit('update')
  }
}
