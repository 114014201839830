import type { MongoDb } from '@/models/MongoDbTools'

export enum TriggerKind {
  ParticipationDecline = 'participationDecline',
  OrderInit = 'orderInit',
  OrderPaid = 'orderPaid',
  TicketGrant = 'ticketGrant',
  GuestRegistration = 'formSubmission', // name is for legacy reasons
  AcceptedMeetingRequest = 'acceptedMeetingRequest',
  TargetReceivesMeetingRequest = 'targetReceivesMeetingRequest',
  DeclinedMeetingRequest = 'declinedMeetingRequest',
  CanceledMeeting = 'canceledMeeting',
  RescheduledMeetingRequest = 'rescheduledMeetingRequest',
  CheckinAny = 'checkinAny',
  CheckinExact = 'checkinExact',
  SurveySubmission = 'surveySubmission',
  AccompanistRegistration = 'accompanistRegistration'
}

export abstract class TriggerBase {
  abstract readonly kind: TriggerKind
}

export class ParticipationDeclineTrigger extends TriggerBase {
  kind = TriggerKind.ParticipationDecline as const
}

export class OrderInitTrigger extends TriggerBase {
  kind = TriggerKind.OrderInit as const
}

export class OrderPaidTrigger extends TriggerBase {
  kind = TriggerKind.OrderPaid as const
}

export class TicketGrantTrigger extends TriggerBase {
  kind = TriggerKind.TicketGrant as const
}

export class GuestRegistrationTrigger extends TriggerBase {
  kind = TriggerKind.GuestRegistration as const
}

export class AcceptedMeetingRequestTrigger extends TriggerBase {
  kind = TriggerKind.AcceptedMeetingRequest as const
}

export class RescheduledMeetingRequestTrigger extends TriggerBase {
  kind = TriggerKind.RescheduledMeetingRequest as const
}

export class TargetReceivesMeetingRequestTrigger extends TriggerBase {
  kind = TriggerKind.TargetReceivesMeetingRequest as const
}

export class DeclinedMeetingRequestTrigger extends TriggerBase {
  kind = TriggerKind.DeclinedMeetingRequest as const
}

export class CanceledMeetingTrigger extends TriggerBase {
  kind = TriggerKind.CanceledMeeting as const
}

export class CheckinAnyCheckpointTrigger extends TriggerBase {
  kind = TriggerKind.CheckinAny as const
}

export class CheckinExactCheckpointTrigger extends TriggerBase {
  kind = TriggerKind.CheckinExact as const
  checkPointId!: MongoDb.ObjectId
}

export class SurveySubmissionTrigger extends TriggerBase {
  kind = TriggerKind.SurveySubmission as const
  formId!: MongoDb.ObjectId
}

export class AccompanistRegistrationTrigger extends TriggerBase {
  kind = TriggerKind.AccompanistRegistration as const
}

export type AnyTrigger =
  | ParticipationDeclineTrigger
  | OrderInitTrigger
  | OrderPaidTrigger
  | GuestRegistrationTrigger
  | AcceptedMeetingRequestTrigger
  | RescheduledMeetingRequestTrigger
  | TargetReceivesMeetingRequestTrigger
  | DeclinedMeetingRequestTrigger
  | CanceledMeetingTrigger
  | CheckinAnyCheckpointTrigger
  | CheckinExactCheckpointTrigger
  | SurveySubmissionTrigger
  | AccompanistRegistrationTrigger
  | TicketGrantTrigger

export const trigger = {
  ParticipationDecline: ParticipationDeclineTrigger,
  OrderInit: OrderInitTrigger,
  OrderPaid: OrderPaidTrigger,
  TicketGrant: TicketGrantTrigger,
  GuestRegistration: GuestRegistrationTrigger,
  AcceptedMeetingRequest: AcceptedMeetingRequestTrigger,
  RescheduledMeetingRequest: RescheduledMeetingRequestTrigger,
  TargetReceivesMeetingRequest: TargetReceivesMeetingRequestTrigger,
  DeclinedMeetingRequest: DeclinedMeetingRequestTrigger,
  CanceledMeeting: CanceledMeetingTrigger,
  CheckinAnyCheckpoint: CheckinAnyCheckpointTrigger,
  CheckinExactCheckpoint: CheckinExactCheckpointTrigger,
  SurveySubmission: SurveySubmissionTrigger,
  AccompanistRegistration: AccompanistRegistrationTrigger
}
