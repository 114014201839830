import type { ElementCreationContext } from '../../../types'
import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import { LoremIpsum } from 'lorem-ipsum'
import TextMediaElementBase, { TextMediaProperties } from '../TextMediaElementBase'

export class TextMedia1Properties extends TextMediaProperties {
  title: string = ''
  titleColor: string = ''
  frameTitle: string = ''
  frameTitleColor: string = ''
  frameBackgroundColor: string = ''
}

export default class TextMedia1Element extends TextMediaElementBase {
  static create({ palette }: ElementCreationContext): TextMedia1Element {
    const textImage = new TextMedia1Element()

    textImage.properties.title = i18n.t('AN_INSPIRING_TITLE')
    textImage.properties.frameTitle = i18n.t('AN_INSPIRING_TITLE')

    textImage.properties.mainText = new LoremIpsum({
      wordsPerSentence: { min: 5, max: 15 },
      sentencesPerParagraph: { min: 1, max: 3 }
    }).generateParagraphs(2)

    const darkestColor = palette.darkestColor
    const mostReadableColorOnDarkestColor = palette.getMostReadableColor(darkestColor.color)

    textImage.properties.titleColor = darkestColor.paletteCode

    textImage.properties.frameBackgroundColor = darkestColor.paletteCode
    textImage.properties.textColor = mostReadableColorOnDarkestColor.paletteCode
    textImage.properties.frameTitleColor = mostReadableColorOnDarkestColor.paletteCode

    return textImage
  }

  type = `prefab-text-media-1` as const

  @Type(() => TextMedia1Properties)
  properties: TextMedia1Properties = new TextMedia1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_TEXT_IMAGE1')
  }
}
