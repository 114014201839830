import { Type } from 'class-transformer'
import { PrefabPropertiesBase, PrefabElementBase } from '../PrefabElementBase'
import { MapMarker } from '../../types/helperTypes'

export class MapProperties extends PrefabPropertiesBase {
  @Type(() => MapMarker)
  marker = new MapMarker()
}

export default abstract class MapElementBase extends PrefabElementBase {
  abstract type: `prefab-map-${number}`

  @Type(() => MapProperties)
  properties: MapProperties = new MapProperties()
}
