











import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import VideoFragment from './VideoFragment.vue'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'
import type { VideoProperties } from '../../types/helperTypes'

@Component({
  components: {
    VideoFragment,
    DigiDeleteConfirmationModal
  }
})
export default class VideoFragmentWrapper extends Vue {
  @Prop({ required: true, type: Function }) readonly deleteVideo!: () => Promise<void>
  @Prop({ type: Object }) readonly video!: VideoProperties

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }
}
