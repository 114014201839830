import { Exclude, Type } from 'class-transformer'
import { z } from 'zod'

export enum EventLangCode {
  French = 'fr',
  English = 'en',
  Portuguese = 'pt',
  German = 'de',
  Spanish = 'es',
  Italian = 'it',
  Dutch = 'nl',
  Polish = 'pl',
  Japanese = 'ja',
  Latvian = 'lv',
  Hungarian = 'hu',
  Romanian = 'ro',
  Slovak = 'sk',
  Bulgarian = 'bg',
  Czech = 'cs',
  Finnish = 'fi',
  Greek = 'el',
  Lithuanian = 'lt'
}

export const zEventLangCode = z.nativeEnum(EventLangCode)

type Localized<ThingType> = {
  [lang in EventLangCode]?: ThingType
}

export class LocalizedPrimitive<T> implements Localized<T> {
  [EventLangCode.French]?: T;
  [EventLangCode.English]?: T;
  [EventLangCode.Portuguese]?: T;
  [EventLangCode.German]?: T;
  [EventLangCode.Spanish]?: T;
  [EventLangCode.Italian]?: T;
  [EventLangCode.Dutch]?: T;
  [EventLangCode.Polish]?: T;
  [EventLangCode.Japanese]?: T;
  [EventLangCode.Latvian]?: T;
  [EventLangCode.Hungarian]?: T;
  [EventLangCode.Romanian]?: T;
  [EventLangCode.Slovak]?: T;
  [EventLangCode.Bulgarian]?: T;
  [EventLangCode.Czech]?: T;
  [EventLangCode.Finnish]?: T;
  [EventLangCode.Greek]?: T;
  [EventLangCode.Lithuanian]?: T
}

export class LocalizedClass<T extends Object> implements Localized<T> {
  @Exclude()
  private type: new () => T;

  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.French]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.English]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Portuguese]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.German]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Spanish]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Italian]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Dutch]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Polish]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Japanese]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Latvian]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Hungarian]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Romanian]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Slovak]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Bulgarian]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Czech]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Finnish]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Greek]?: T;
  @Type((options) => (options?.newObject as LocalizedClass<T>).type)
  [EventLangCode.Lithuanian]?: T

  constructor(type: new () => T) {
    this.type = type
  }
}

const EventLangCodeSlugs: Record<EventLangCode, string> = {
  [EventLangCode.French]: 'EVENT_LANGUAGE_FRENCH',
  [EventLangCode.English]: 'EVENT_LANGUAGE_ENGLISH',
  [EventLangCode.Portuguese]: 'EVENT_LANGUAGE_PORTUGUESE',
  [EventLangCode.German]: 'EVENT_LANGUAGE_GERMAN',
  [EventLangCode.Spanish]: 'EVENT_LANGUAGE_SPANISH',
  [EventLangCode.Italian]: 'EVENT_LANGUAGE_ITALIAN',
  [EventLangCode.Dutch]: 'EVENT_LANGUAGE_DUTCH',
  [EventLangCode.Polish]: 'EVENT_LANGUAGE_POLISH',
  [EventLangCode.Japanese]: 'EVENT_LANGUAGE_JAPANESE',
  [EventLangCode.Latvian]: 'EVENT_LANGUAGE_LATVIAN',
  [EventLangCode.Hungarian]: 'EVENT_LANGUAGE_HUNGARIAN',
  [EventLangCode.Romanian]: 'EVENT_LANGUAGE_ROMANIAN',
  [EventLangCode.Slovak]: 'EVENT_LANGUAGE_SLOVAK',
  [EventLangCode.Bulgarian]: 'EVENT_LANGUAGE_BULGARIAN',
  [EventLangCode.Czech]: 'EVENT_LANGUAGE_CZECH',
  [EventLangCode.Finnish]: 'EVENT_LANGUAGE_FINNISH',
  [EventLangCode.Greek]: 'EVENT_LANGUAGE_GREEK',
  [EventLangCode.Lithuanian]: 'EVENT_LANGUAGE_LITHUANIAN'
}

export function getEventLangCodeSlug(langCode: EventLangCode): string {
  return EventLangCodeSlugs[langCode]
}
