








import { Component, Prop } from 'vue-property-decorator'
import type { AnyAction, PostWebhookAction } from '@/models/Event/RulesData/Rule/Action'
import { ActionKind } from '@/models/Event/RulesData/Rule/Action'
import Vue from 'vue'

@Component
export default class PostWebhookSettings extends Vue {
  @Prop({ required: true }) readonly action!: AnyAction

  get postWebHookAction(): PostWebhookAction {
    if (this.action.kind !== ActionKind.PostWebhook) {
      throw new Error('Unexpected: PostWebhookSettings can only manage action of kind PostWebhook')
    }

    return this.action
  }

  get url(): string {
    return this.postWebHookAction.url
  }
  set url(url: string) {
    this.$emit('update', { ...this.action, url })
  }
}
