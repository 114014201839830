import type EmailTemplate from './Email'
import type SmsTemplate from './Sms'

export { MessageId } from './Base'

export type MessageTemplate = EmailTemplate | SmsTemplate

export const isEmailTemplate = (message: Partial<MessageTemplate>): message is EmailTemplate =>
  message.method === 'email'

export type MessageMethod = MessageTemplate['method']
