import { i18n } from '@/utils/i18n'
import type { DeleteContext } from '../../types/PageElement'
import { LinearLayoutElementBase } from '../LinearLayout/LinearLayoutElementBase'

export default class ColumnElement extends LinearLayoutElementBase {
  static create(): ColumnElement {
    return new ColumnElement()
  }

  type: 'layout-column' = 'layout-column'

  get prettyName() {
    if (this.children.length > 1) {
      return i18n.t('COLUMN_OF_X_BLOCKS', { x: this.children.length })
    } else if (this.children.length === 1) {
      return i18n.t('COLUMN_OF_ONE_BLOCK')
    } else {
      return `${i18n.t('COLUMN')} (${i18n.t('EMPTY')})`
    }
  }

  onDeleteElement(context: DeleteContext) {
    const wasChild = this.isIdChild(context.deletedId)
    super.onDeleteElement(context)

    if (wasChild && this.hasAnyChildren === false) {
      context.pageConfig.deleteElement(context.id)
    }
  }
}
