

















import { Vue, Component, Prop } from 'vue-property-decorator'
import DigiLink from './DigiLink.vue'
import type { ColorVariant, LinkTargetType, Size } from './types'
import type { Location } from 'vue-router'

@Component({
  components: { DigiLink }
})
export default class DigiIconLink extends Vue {
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: 'primary' }) readonly variant!: ColorVariant
  @Prop({ default: 'md' }) readonly size!: Size
  @Prop() readonly href!: string | null
  @Prop() readonly to!: Location | null
  @Prop({ default: false }) readonly block!: boolean
  @Prop({ default: 0 }) readonly tabindex!: number
  @Prop() readonly target!: LinkTargetType
  @Prop({ required: true }) readonly remixIcon!: string
}
