import { cloneDeep } from 'lodash'
import { assertsString } from './string'

/**
 * Changes in-place the entirety of an array by the provided elements.
 *
 * It uses `splice()` in order to keep reference on the array, as simply
 * using `map()` would discard the array being rewritten and replace it
 * completely.
 */
export function replaceArrayContent<T extends any[]>(array: T, replaceValue: T): void {
  array.splice(0, array.length, ...replaceValue)
}

export function fromArrayToMap<T>(array: T[], mapKey: keyof T): Record<string, T> {
  const map: Record<string, T> = {}
  for (const object of array) {
    const key = object[mapKey]
    if (typeof key !== 'string') {
      throw 'This key must be a string to be used as a key :' + key
    }
    map[key] = { ...object }
  }
  return map
}

export function getArrayOfNumbersInRange({
  start,
  stop,
  step
}: {
  start: number
  stop: number
  step: number
}): number[] {
  return Array.from({ length: (stop - start) / step + 1 }, (_value: number, index: number) => start + index * step)
}

export function replaceElementInArray<T>(array: T[], index: number, newValue: T): T[] {
  return [...array.slice(0, index), newValue, ...array.slice(index + 1)]
}

export function sortArrayOfObjectsWithGivenOrder<T extends {}, A extends T[]>({
  arr,
  sortKey,
  sort,
  direction = 'ascending'
}: {
  arr: A
  sortKey: keyof T
  sort: string[]
  direction?: 'ascending' | 'descending'
}): A {
  const arrCopy = cloneDeep(arr)
  const sortMap = sort.reduce((sortMap: Map<string, number>, value, index) => {
    sortMap.set(value, index)
    return sortMap
  }, new Map())
  return arrCopy.sort((a, b) => {
    const aSortKeyValue = a[sortKey]
    assertsString(aSortKeyValue)
    const bSortKeyValue = b[sortKey]
    assertsString(bSortKeyValue)
    const aPriority = sortMap.get(aSortKeyValue)
    const bPriority = sortMap.get(bSortKeyValue)
    if (aPriority === undefined || bPriority === undefined) {
      throw new Error('Array not sortable')
    }
    return direction === 'ascending' ? aPriority - bPriority : bPriority - aPriority
  })
}
