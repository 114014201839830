


















import { Vue, Component, Prop } from 'vue-property-decorator'
import type { ColorVariant, Size } from './types'
import { DigiButton } from '@/components/ui/actions'

@Component({
  components: { DigiButton }
})
export default class DigiIconButton extends Vue {
  @Prop({ default: '' }) readonly variant!: ColorVariant | 'outline-primary'
  @Prop({ default: 'button' }) readonly type!: 'button' | 'submit' | 'reset'
  @Prop({ required: true }) readonly remixIcon!: string
  @Prop({ default: false }) readonly disabled!: boolean
  @Prop({ default: 'md' }) readonly size!: Size
  @Prop({ default: false }) readonly block!: boolean
  @Prop({ default: 0 }) readonly tabindex!: number
  @Prop() readonly isLoading!: boolean

  onClick() {
    this.$emit('click')
  }

  get cssClasses() {
    return {
      [`digit-icon-btn--${this.variant}`]: true,
      [`digit-icon-btn--${this.size}`]: true
    }
  }
}
