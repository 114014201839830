





















import { Component, Prop, Vue } from 'vue-property-decorator'
import { parseFormItemLabel } from '@/services/parseFormItemLabel/parseFormItemLabel'

@Component({
  components: {}
})
export default class DigiLabelWithDescriptionLayout extends Vue {
  @Prop({ default: null }) readonly labelSlug!: string | null
  @Prop({ default: null }) readonly descriptionSlug!: string | null
  @Prop({ default: null }) readonly supportArticleId!: string | null
  @Prop({ type: Boolean, default: false }) readonly isFieldRequired!: boolean

  get translatedLabel() {
    if (!this.labelSlug) return null
    return parseFormItemLabel(this.$t(this.labelSlug) as string)
  }

  get label(): string | null {
    let label: string | null = null
    if (this.translatedLabel) {
      label = this.translatedLabel.label
    }

    if (this.isFieldRequired) {
      label += ' *'
    }
    return label
  }

  get description(): string | null {
    if (this.descriptionSlug) {
      return this.$t(this.descriptionSlug)
    }

    if (this.translatedLabel?.description) {
      return this.translatedLabel.description
    }

    return null
  }

  get helpLink(): string | null {
    if (this.translatedLabel?.helpLink) {
      const link = this.translatedLabel.helpLink
      if (!link || !link.includes('http')) {
        console.error('No link provided for help link')
      }
      return link
    }
    return null
  }
}
