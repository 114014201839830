
















































import { Component, Prop, Ref } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { MomentInput } from 'moment'
import moment from 'moment'
import type { PendingMeeting } from '@/models/Guest/Planning/UnscheduledMeeting'
import MeetingRequestAcceptationModal from './MeetingRequestAcceptationModal.vue'
import MeetingRequestListGrouper from './MeetingRequestListGrouper.vue'
import type { ScheduledItemId } from '@/models/Guest/Planning/ScheduledItem'
import axios from 'axios'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { ContactProfileModal } from '@/features/audience/contactProfile'

@Component({
  components: {
    MeetingRequestAcceptationModal,
    MeetingRequestListGrouper,
    ContactProfileModal
  },
  filters: {
    timeAgo(isoDate: MomentInput) {
      return moment(isoDate).fromNow()
    }
  }
})
export default class MeetingRequests extends Vue {
  @Prop() readonly guest!: Guest

  @Ref() readonly meetingRequestAcceptationModal!: MeetingRequestAcceptationModal

  @Ref() readonly contactProfileModal!: ContactProfileModal

  requestBeingCancelled: ScheduledItemId | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get unscheduledMeetings() {
    return this.guest.planning?.unscheduledMeetings ?? []
  }

  get unscheduledMeetingsForGuest() {
    return this.unscheduledMeetings.filter((meeting) => meeting.target._guest === this.guest._id)
  }

  get unscheduledMeetingsFromGuest() {
    return this.unscheduledMeetings.filter((meeting) => meeting.applicant._guest === this.guest._id)
  }

  async acceptMeetingRequest(request: PendingMeeting) {
    this.meetingRequestAcceptationModal.acceptRequest(request)
  }

  async cancelMeetingRequest(targetRequest: PendingMeeting) {
    if (!this.guest.planning) throw new Error('Cannot cancel meeting request: Guest does not have planning data.')

    this.requestBeingCancelled = targetRequest._id

    try {
      await axios.put(`/backoffice/events/${this.storeEvent._id}/matchmaking/removeMeetingRequest/${targetRequest._id}`)
      this.guest.planning.unscheduledMeetings = this.guest.planning.unscheduledMeetings.filter(
        (requestInList) => requestInList._id !== targetRequest._id
      )
    } finally {
      this.requestBeingCancelled = null
    }
  }

  openContactProfileModal(guestId: string) {
    this.contactProfileModal.loadGuestFromIdAndOpenModal(guestId)
  }
}
