









import { Vue, Component, Prop } from 'vue-property-decorator'
import type { EventMenuItem } from '@/navigation/eventMenu'
import { isItemActive } from '@/navigation/eventMenu/utils'
import type { FullRouteConfig } from '@/core'
import { isEventMenuItemLink } from '@/navigation/eventMenu/types'

@Component({})
export default class MenuItem extends Vue {
  @Prop({ required: true }) readonly item!: EventMenuItem

  get cssClasses() {
    return {
      'menu-item--active': this.isActive
    }
  }

  get isLink() {
    return isEventMenuItemLink(this.item)
  }

  get isActive() {
    const route = this.$route
    if (route.name === undefined || route.name === null) {
      throw new Error('Route name is not defined')
    }
    return isItemActive(this.item, route as FullRouteConfig)
  }
}
