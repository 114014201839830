import { ElementTypeDictionary } from '../../types'
import TextInfiniteScroll1Element from './textInfiniteScroll1/TextInfiniteScroll1Element'

export const textInfiniteScrollDictionary: ElementTypeDictionary = ElementTypeDictionary.makeNew()
  .addType({
    dataClass: TextInfiniteScroll1Element,
    renderer: () => import('./textInfiniteScroll1/TextInfiniteScroll.vue'),
    properties: [{ type: 'color', label: 'TEXT_COLOR', key: 'properties.textColor' }],
    typeNameSlug: 'WEBSITE_BLOCK_TEXT_INFINITE_SCROLL',
    previewImageLink: require('@/assets/img/prefab-previews/textInfiniteScroll.jpg')
  })
  .finish()
