import { i18n } from '@/utils/i18n'
import { Type } from 'class-transformer'
import type { MediaProperties } from '../../../types/helperTypes'
import { PrefabPropertiesBase } from '../../PrefabElementBase'
import MediaElementBase from '../MediaElementBase'

export class Media1Properties extends PrefabPropertiesBase {
  media: MediaProperties | null = null
}

export default class Media1Element extends MediaElementBase {
  static create(): Media1Element {
    return new Media1Element()
  }

  isFullPrefab: boolean = true

  canHaveBackgroundImage = false

  backgroundImageRecommendedDimensions = {
    width: 1920,
    height: 1080
  }

  type = `prefab-media-1` as const

  @Type(() => Media1Properties)
  properties: Media1Properties = new Media1Properties()

  get prettyName(): string {
    return i18n.t('WEBSITE_BLOCK_IMAGES1')
  }
}
