import type { PasswordCriteria } from './types'
import validator from 'validator'
import type { PasswordValidationOption } from './types'

export const passwordCriterias = ['minSymbols', 'minLength', 'minNumbers', 'minUppercase', 'minLowercase'] as const

export const validatorPasswordOptions: Record<PasswordCriteria, number> = {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 1
}

const defaultPasswordOptions: PasswordValidationOption = {
  minLength: 0,
  minLowercase: 0,
  minUppercase: 0,
  minNumbers: 0,
  minSymbols: 0
}

function validateMinLength(string: string, minLength: number): boolean {
  return validator.isStrongPassword(string, { ...defaultPasswordOptions, minLength })
}

function validateMinLowercase(string: string, minLowercase: number): boolean {
  return validator.isStrongPassword(string, { ...defaultPasswordOptions, minLowercase })
}

function validateMinUppercase(string: string, minUppercase: number): boolean {
  return validator.isStrongPassword(string, { ...defaultPasswordOptions, minUppercase })
}

function validateMinNumbers(string: string, minNumbers: number): boolean {
  return validator.isStrongPassword(string, { ...defaultPasswordOptions, minNumbers })
}

function validateMinSymbols(string: string, minSymbols: number): boolean {
  return validator.isStrongPassword(string, { ...defaultPasswordOptions, minSymbols })
}

export function validatePassword(pwd: string): boolean {
  return Boolean(pwd) && validator.isStrongPassword(pwd, validatorPasswordOptions)
}

export function validateSinglePasswordCriteria(pwd: string): Record<PasswordCriteria, boolean> {
  pwd = pwd ?? ''
  const { minLength, minUppercase, minLowercase, minNumbers, minSymbols } = validatorPasswordOptions
  return {
    minLength: validateMinLength(pwd, minLength),
    minUppercase: validateMinUppercase(pwd, minUppercase),
    minLowercase: validateMinLowercase(pwd, minLowercase),
    minNumbers: validateMinNumbers(pwd, minNumbers),
    minSymbols: validateMinSymbols(pwd, minSymbols)
  }
}
